import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import spacetime from 'spacetime';

import Add from '@/assets/Add.svg?react';
import AddBold from '@/assets/AddBold.svg?react';
import Help from '@/assets/Help.svg?react';
import CreateRaise from '@/components/modals/create-raise-modal';
import { ProgressCard } from '@/components/ui/progress-card';
import { Table } from '@/components/ui/table';
import { useToast } from '@/components/ui/use-toast';
import {
  countKeysAndValues,
  countKeysAndValuesWithToggles,
  getStateFromLocation,
  offeringAndProblemStateToExclude,
  processGetRaiseByIdRes,
  siteBuilderInputsPerToggle,
} from '@/const';
import {
  Company,
  GetCompanyByIdDocument,
  GetRaiseByIdDocument,
  GetSecurityTokenByRaiseIdDocument,
  ListRaisesByCompanyIdDocument,
  ListRaisesDocument,
  Raise,
  UpdateRaiseByIdDocument,
} from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { cn } from '@/lib/utils';
import useBoundStore from '@/store';

export function Fundraise() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { toast } = useToast();

  // State variables
  const [searchParams, setSearchParams] = useSearchParams();
  const setSecurityToken = useBoundStore((state) => state.securityTokenSlice.setSecurityToken);
  const securityToken = useBoundStore((state) => state.securityTokenSlice.securityToken);

  const [activeTab, setActiveTab] = useState(0);
  const [company, setCompany] = useState<Company | null>(null);
  const [companyLoading, setCompanyLoading] = useState(true);
  const [raiseModalOpen, setRaiseModalOpen] = useState(false);
  const [raises, setRaises] = useState<Raise[]>([]);
  const [raisesLoading, setRaisesLoading] = useState(true);
  const [completedComplianceChecklist, setCompletedComplianceChecklist] = useState(false);
  const [selectedRaiseIndex, setSelectedRaiseIndex] = useState(0);
  const [currentRaise, setCurrentRaise] = useState<Raise | null>(null);
  const [currentRaiseLoading, setCurrentRaiseLoading] = useState(true);
  const [percentagesComplete, setPercentagesComplete] = useState<FormData>({
    general_information: null,
    my_team: null,
    capital_raise: null,
    business_details: null,
    past_raises: null,
    financials: null,
    use_of_funds: null,
    my_documents: null,
    broker_dealer: null,
    transfer_agent: null,
    site_builder: null,
  });

  const tabs = ['Draft / Pending', 'Live', 'Closed', 'Declined'];
  const columnNames = ['Round Name', 'Raise Amount (USD)', 'Regulation', 'Time'];

  const raisesData = useMemo(() => {
    return raises?.map((item) => [
      { value: item?.name, type: '' },
      { value: parseInt(item?.amount).toLocaleString(2), type: '' },
      { value: item?.offering_type, type: '' },
      { value: spacetime(Number(item?.created_at)).format('{month} {date}, {year}'), type: '' },
    ]);
  }, [raises]);

  const progressKeys = [
    {
      keys: [
        'general_information',
        'my_team',
        'capital_raise',
        'business_details',
        'past_raises',
        'financials',
        'use_of_funds',
        'my_documents',
      ],
      title: 'COMPLIANCE CHECKLIST',
      navigateTo: 'compliance-checklist',
    },
    {
      keys: ['broker_dealer', 'transfer_agent'],
      title: 'Exchange Checklist',
      navigateTo: 'exchange-checklist',
    },
    {
      keys: ['site_builder'],
      title: 'Offering Page',
      navigateTo: `site-builder/${raises[selectedRaiseIndex]?.id}`,
    },
  ];

  const progressCards = useMemo(() => {
    return progressKeys.map(({ keys, title, navigateTo }, index) => {
      const totalPercentage = keys.reduce((sum, key) => {
        return sum + (percentagesComplete[key]?.percentageComplete || 0);
      }, 0);

      const nonNullCount = keys.filter((key) => percentagesComplete[key] !== null).length;
      const progress = nonNullCount > 0 ? totalPercentage / nonNullCount : 0;

      return {
        progress: index === 0 && completedComplianceChecklist ? 100 : progress || 0,
        title,
        navigateTo,
      };
    });
  }, [percentagesComplete, completedComplianceChecklist]);

  const updateRaiseState = (newState) => {
    const indexOfRaise = raises.findIndex((x) => x.id === newState);
    console.log({ indexOfRaise, raises, newState });
    setSelectedRaiseIndex(indexOfRaise);
    setSearchParams(
      {
        ...Object.fromEntries(searchParams.entries()),
        raise: `${newState}`,
      },
      { replace: true },
    );
  };

  const handleGetUserCompany = async () => {
    try {
      const {
        data: { getCompanyById: getCompanyByIdRes },
      } = await client.query({
        query: GetCompanyByIdDocument,
        variables: { getCompanyByIdId: parseInt(`${id}`) },
      });
      setCompany(getCompanyByIdRes);
      setCompanyLoading(false);
      console.log({ getCompanyByIdRes });
    } catch (err) {
      setCompanyLoading(false);
      console.log({ err });
    }
  };

  const handleUpdateRaiseById = async (boxChecked) => {
    try {
      setCompletedComplianceChecklist(!completedComplianceChecklist);
      const {
        data: { updateRaiseById: updateRaiseByIdRes },
      } = await client.mutate({
        mutation: UpdateRaiseByIdDocument,
        variables: {
          id: raises[selectedRaiseIndex]?.id,
          input: {
            completed_compliance_checklist: boxChecked,
          },
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ updateRaiseByIdRes });
      setCompletedComplianceChecklist(!completedComplianceChecklist);
    } catch (err) {
      setCompletedComplianceChecklist(!completedComplianceChecklist);
      console.log({ err });
    }
  };

  const handleListRaises = async () => {
    try {
      const {
        data: { listRaisesByCompanyId: listRaisesByCompanyIdRes },
      } = await client.query({
        query: ListRaisesByCompanyIdDocument,
        variables: { company_id: id },
      });
      console.log({ listRaisesByCompanyIdRes });
      setRaises(listRaisesByCompanyIdRes);
      setRaisesLoading(false);
    } catch (err) {
      setRaisesLoading(false);
      console.log({ err });
    }
  };

  const handleGetRaiseById = async () => {
    try {
      setCurrentRaiseLoading(true);
      const {
        data: { getRaiseById: getRaiseByIdRes },
      } = await client.query({
        query: GetRaiseByIdDocument,
        variables: { id: raises[selectedRaiseIndex].id }, // COME BACK TO THIS
      });
      console.log({ getRaiseByIdRes });
      setCurrentRaiseLoading(false);
      setCompletedComplianceChecklist(getRaiseByIdRes?.completed_compliance_checklist);

      const allFormKeys = [
        'general_information',
        'my_team',
        'capital_raise',
        'business_details',
        'past_raises',
        'financials',
        'use_of_funds',
        'my_documents',
        'broker_dealer',
        'transfer_agent',
        'site_builder',
      ];

      const raiseWithFormProgress = processGetRaiseByIdRes(
        allFormKeys,
        getRaiseByIdRes,
        offeringAndProblemStateToExclude,
      );

      console.log({ raiseWithFormProgress });
      setPercentagesComplete(raiseWithFormProgress);
      setCurrentRaise(getRaiseByIdRes);
      setSecurityToken(getRaiseByIdRes.security_token);
    } catch (err) {
      setCurrentRaiseLoading(false);
      console.log({ err });
    }
  };

  const calculateOverallProgress = (checklists, extraVariable) => {
    console.log({ checklists });
    const complianceChecklistPoints = (checklists[0].progress / 100) * 40;
    const exchangeChecklistPoints = (checklists[1].progress / 100) * 20;
    const offeringPagePoints = (checklists[2].progress / 100) * 20;
    const extraVariablePoints = extraVariable ? 20 : 0;

    let totalScore =
      complianceChecklistPoints +
      exchangeChecklistPoints +
      offeringPagePoints +
      extraVariablePoints;

    if (totalScore > 100) {
      totalScore = 100;
    }

    return totalScore;
  };

  const handleAddRaise = (raiseInput: Raise) => {
    setRaises([...raises, raiseInput]);
    updateRaiseState(raiseInput.id);
  };

  useEffect(() => {
    handleGetUserCompany();
    handleListRaises();
  }, []);

  useEffect(() => {
    handleGetRaiseById();
  }, [selectedRaiseIndex, raises]);

  useEffect(() => {
    const raiseId = getStateFromLocation(searchParams, 'raise');
    if (raises.length > 0) {
      const indexOfRaise = raises.findIndex((x) => x.id === raiseId);
      console.log({ indexOfRaise });
      setSelectedRaiseIndex(indexOfRaise === -1 ? 0 : indexOfRaise);
    }
  }, [raises]);

  useEffect(() => {
    console.log({ securityToken });
  }, [securityToken]);

  return (
    <div className="flex w-full max-w-[1000px] flex-col pb-[60px]">
      <div>
        <img
          className="h-[200px] w-full rounded-[20px] border-2 bg-card object-cover"
          src="https://img.freepik.com/free-vector/gradient-black-background-with-cubes_23-2149146004.jpg"
        />
      </div>
      <img
        className={cn(
          'mx-auto mt-[-70px] size-[160px] rounded-[80px] border bg-background object-cover shadow',
          companyLoading && 'animate-pulse ',
        )}
        src={company?.logo}
      />
      <div className="mb-[54px] mt-[32px] flex justify-center ">
        <h1
          className={cn(
            'h-[48px] text-center text-[2rem]',
            companyLoading && 'w-[300px] animate-pulse rounded-[6px]',
          )}
        >
          {company?.legal_name}
        </h1>
      </div>
      <div className="mx-auto w-full max-w-[1200px] px-[10px] sm:px-[20px]">
        <div className="mb-[20px] rounded-[8px] bg-card shadow-[2px_2px_24px_0px_rgba(0,0,0,0.05)] sm:mb-[50px]">
          <div className="flex justify-between border-b-[#2D344E] py-[14px] pl-[24px]">
            <h3 className="text-[18px] font-medium">Your Capital Raises</h3>
            <button
              className="mr-[20px] flex items-center px-[10px] text-primary"
              onClick={() => {
                setRaiseModalOpen(!raiseModalOpen);
              }}
            >
              <Add className="mr-[10px] stroke-primary" /> Create Capital Raise
            </button>
          </div>
          <div className="overflow-x-scroll border-t py-[8px] pb-[16px] sm:overflow-x-auto">
            <Table
              ariaLabel="fundraiseTable"
              data={raisesData}
              columnNames={columnNames}
              selected={selectedRaiseIndex}
              isLoading={raisesLoading}
              onRowClick={(item, index) => {
                updateRaiseState(raises[index]?.id);
              }}
            />
          </div>
        </div>
        {raises?.length > 0 && (
          <>
            <ProgressCard
              progress={calculateOverallProgress(progressCards, securityToken)}
              centeredTitle={`${raises[selectedRaiseIndex]?.name} Overall Progress`}
              bgColor={'bg-[#880808]'} // Customize this color if needed
              seeTasks={false}
              loading={currentRaiseLoading}
            />
            <div className="mt-[20px] grid gap-[20px] sm:mt-[40px] sm:grid-cols-2 sm:gap-[40px]">
              {progressCards.map((item, index) => (
                <ProgressCard
                  key={index}
                  rightTitle={''}
                  leftTitle={item.title}
                  progress={item.progress}
                  tasksClick={() => {
                    navigate(`/${item.navigateTo}`);
                  }}
                  bgColor={'bg-[#880808]'}
                  checkbox={index === 0}
                  checked={completedComplianceChecklist}
                  setChecked={handleUpdateRaiseById}
                  loading={currentRaiseLoading}
                />
              ))}

              {!currentRaiseLoading && (
                <>
                  {securityToken ? (
                    <button className="box-border flex cursor-pointer flex-col rounded-[20px] bg-card p-[24px] shadow-[2px_2px_24px_0px_rgba(0,0,0,0.1)]">
                      <p className="mb-[16px] flex items-center text-sm font-semibold uppercase opacity-50">
                        View Security Token
                        <Help className="ml-1 size-[17px] stroke-[#000000]" />
                      </p>
                      <div className="flex size-full">
                        <img className="size-[86px] rounded-[5px]" src={securityToken.icon_url} />
                        <div className="pl-[15px]">
                          <p className="text-left text-[20px]">{securityToken.name}</p>
                          <p className="text-left">{securityToken.symbol}</p>
                        </div>
                      </div>
                    </button>
                  ) : (
                    <button
                      className={cn(
                        'box-border flex cursor-pointer items-center justify-center rounded-[20px] border-4 border-card bg-card shadow-[2px_2px_24px_0px_rgba(0,0,0,0.1)] transition-colors duration-200 ease-in ',
                        !completedComplianceChecklist
                          ? 'cursor-not-allowed opacity-60'
                          : 'hover:bg-card-faded',
                      )}
                      onClick={() => {
                        if (!completedComplianceChecklist) {
                          toast({
                            title: 'Error',
                            variant: 'destructive',
                            description: `You must complete the compliance checklist before creating a Security Token`,
                          });
                        } else {
                          navigate(`/create-token/${id}/${raises[selectedRaiseIndex].id}`);
                        }
                      }}
                    >
                      <p className="ml-[-15px] flex items-center text-[18px] font-semibold text-primary">
                        <AddBold className="mr-3 stroke-primary" />
                        Create Security Token
                      </p>
                    </button>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
      <CreateRaise
        open={raiseModalOpen}
        setOpen={setRaiseModalOpen}
        id={id}
        handleAddRaise={handleAddRaise}
      />
    </div>
  );
}

export default Fundraise;

type SectionData = {
  totalKeys: number;
  totalValues: number;
  percentageComplete: number | string;
};

type FormData = {
  [key: string]: SectionData | null;
};
