/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import AddIntellectualPropertyModal from '@/components/modals/add-intellectual-property';
import AddTrademarkModal from '@/components/modals/add-trademark';
import { AddButton } from '@/components/ui/add-button';
import { BackNext } from '@/components/ui/back-next';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';

const BusinessDetails = () => {
  const [openModal1, setOpenModal1] = useState<boolean>(false);
  const [openModal2, setOpenModal2] = useState<boolean>(false);

  const [defaultValues, setDefaultValues] = useState<any>({
    percentSlider: [0],
  });

  const methods = useForm({ defaultValues });
  const [risks, setRisks] = useState([
    {
      type: 'input',
      label: 'Risk factors for investors',
      state: 'riskFactorsForInvestors',
      placeholder: 'Add a risk',
    },
  ]);
  const formValues = methods.watch();

  const screenData1 = [
    {
      title: '',
      sectionClassName: '',
      sectionInputsClassName: '',
      inputs: [
        {
          type: 'textarea',
          label:
            'Who are your competitors? List at least 3 and include advantages and disadvantages',
          state: 'competitors',
          placeholder:
            'Who are your competitors? List at least 3 and include advantages and disadvantages',
        },
        {
          type: 'textarea',
          label: 'Short description of type of business',
          state: 'typeOfBusiness',
          placeholder: 'Short description of type of business',
        },
        {
          type: 'textarea',
          label: 'Short description of primary product(s)',
          state: 'primaryProducts',
          placeholder: 'Short description of primary product(s)',
        },
        {
          type: 'input',
          label: 'Business Model',
          state: 'businessModel',
          placeholder: 'Business Model',
        },
        {
          type: 'input',
          label: 'Go-to-market strategy',
          state: 'goToMarketStrategy',
          placeholder: 'Go-to-market strategy',
        },
        {
          type: 'textarea',
          label: 'Who are your customers? List any key customers',
          state: 'keyCustomers',
          placeholder: 'Who are your customers? List any key customers',
        },
        {
          type: 'checkboxColumn',
          label: 'Is the Company subject to Litigation?',
          state: 'subjectToLitigation',
          options: [
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ],
        },
      ],
    },
  ];

  useEffect(() => {
    const scrollableElement = document.getElementById('authLayoutContainerId');
    if (scrollableElement) {
      scrollableElement.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    console.log({ formValues });
  }, [formValues]);

  return (
    <div className="relative mx-[20px] flex w-full max-w-[800px] flex-col items-center">
      <h1 className="mb-[28px] w-full text-left text-[2rem] font-medium sm:mb-[32px]  sm:text-[2rem]">
        Business Details
      </h1>
      <div className="mb-[22px] flex w-full items-end">
        <WrappedInputSection
          methods={methods}
          data={[
            {
              title: '',
              sectionClassName: 'w-full',
              sectionInputsClassName: 'w-full gap-[8px]',
              inputs: risks,
            },
          ]}
        />
        <AddButton
          className="ml-[20px]"
          onClick={(e) => {
            setRisks([
              ...risks,
              {
                type: 'input',
                label: '',
                state: `riskFactorsForInvestors`,
                placeholder: 'Add a risk',
              },
            ]);
          }}
        />
      </div>
      <WrappedInputSection
        methods={methods}
        data={[{ inputs: screenData1[0].inputs?.slice(0, 6) }]}
      />
      <div className="my-[22px] flex w-full ">
        <div className="mr-[100px]">
          <p className="mb-[12px] text-sm opacity-80">Intellectual Property – Patents</p>
          <AddButton
            className=""
            alt
            onClick={(e) => {
              setOpenModal1(true);
            }}
          />
        </div>
        <div className="">
          <p className="mb-[12px] text-sm opacity-80">Trademarks – For each trademark list</p>
          <AddButton
            className=""
            alt
            onClick={(e) => {
              setOpenModal2(true);
            }}
          />
        </div>
      </div>
      <WrappedInputSection
        methods={methods}
        data={[{ inputs: screenData1[0].inputs?.slice(6, 7) }]}
      />
      <div className="mt-[30px] w-full">
        <BackNext className="mt-[20px]" numOfLevels={1} finalBtnText={'Save & continue'} />
      </div>
      <AddIntellectualPropertyModal open={openModal1} setOpen={setOpenModal1} />
      <AddTrademarkModal open={openModal2} setOpen={setOpenModal2} />
    </div>
  );
};

export default BusinessDetails;
