import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import AddBold from '@/assets/AddBold.svg?react';
import ChartLine from '@/assets/ChartLine.svg?react';
import OnboardingModal from '@/components/modals/onboard-modal';
import CompanyCard from '@/components/ui/company-card';
import { Company, GetUserCompaniesDocument } from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';

import SectionDivider from '../ui/section-divider';

function BusinessHome() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [companies, setCompanies] = useState<Company[] | []>([]);
  const [companiesLoading, setCompaniesLoading] = useState(true);

  const companiesWithLoad = useMemo(() => {
    if (companiesLoading) {
      return [1];
    } else {
      return companies;
    }
  }, [companies, companiesLoading]);

  const handleGetUserCompanies = async () => {
    try {
      const {
        data: { getUserCompanies: getUserCompaniesRes },
      } = await client.query({
        query: GetUserCompaniesDocument,
        // context: {
        //   clientName: ClientName.Dashboard,
        // },
      });
      setCompanies(getUserCompaniesRes);
      setCompaniesLoading(false);
      console.log({ getUserCompaniesRes });
    } catch (err) {
      setCompaniesLoading(false);
      console.log({ err });
    }
  };

  useEffect(() => {
    handleGetUserCompanies();
  }, []);

  return (
    <div className="grid w-full max-w-[1320px] flex-col gap-[25px]">
      <SectionDivider
        titles={[`Security Offering`]}
        color="#FFBD39"
        img={<ChartLine className="size-[22px] stroke-white" />}
      />
      <div className="flex w-full flex-wrap gap-[20px] p-[5px]">
        <button
          className="box-border flex h-[255px] w-[420px] cursor-pointer items-center justify-center rounded-[20px] border-4 border-card bg-card shadow-md transition-colors duration-200 ease-in hover:bg-card-faded"
          onClick={() => {
            navigate('/create-company');
          }}
        >
          <p className="ml-[-15px] flex font-semibold text-primary">
            <AddBold className="mr-3 stroke-primary" />
            Create Company
          </p>
        </button>
        {companiesWithLoad?.map((item, index) => (
          <CompanyCard loading={companiesLoading} item={item} index={index} />
        ))}
      </div>
      <OnboardingModal open={open} setOpen={setOpen} />
    </div>
  );
}

export default BusinessHome;
