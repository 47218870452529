import axios from 'axios';
import Papa from 'papaparse';

import {
  GetListUploadLinkDocument,
  GetPresignedLinkCloudflareDocument,
  GetPresignedLinkFaviconDocument,
} from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';

interface GPLResponse {
  data: {
    imagePresignedLink: string;
  };
}

const ensurePublicUrl = (urls) => {
  const publicUrl = urls.find((url) => url.includes('/public'));
  if (publicUrl) return publicUrl;

  const modifiedUrl = urls[0]
    .replace('/collectionBanner', '/public')
    .replace('/thumbnail', '/public');
  return modifiedUrl;
};

export const uploadToCSVPresignedLink = async (file, listName = '') => {
  console.log('THEFILE', { file, listName });

  await new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      step: (row, parser) => {
        console.log({ row }, JSON.stringify(row.meta.fields));
        if (JSON.stringify(row.meta.fields) !== '["name","email"]') {
          reject(new Error('ISERRROR TRUE'));
        }
        parser.abort();

        resolve(true);
      },
    });
  });
  console.log('LOG 1');
  const fileExtension = file.name.split('.')[1];
  if (fileExtension !== 'csv') {
    console.log('NOT A CSV', { fileExtension });
    throw new Error();
  }
  console.log(file.name.split('.csv')[0]);
  // const response = await getListUploadLink(file.name.split('.csv')[0]);

  const response = await client.mutate({
    mutation: GetListUploadLinkDocument,
    variables: {
      listName,
    },
  });

  console.log({ response });

  const presignedLink = response.data.getListUploadLink;
  console.log({ presignedLink });
  const formData = new FormData();
  formData.append('Content-Type', file.type);
  formData.append('key', presignedLink.fields.key);
  formData.append('bucket', presignedLink.fields.bucket);
  formData.append('X-Amz-Credential', presignedLink.fields.credential);
  formData.append('X-Amz-Algorithm', presignedLink.fields.algorithm);
  formData.append('X-Amz-Date', presignedLink.fields.date);
  formData.append('Policy', presignedLink.fields.policy);
  formData.append('X-Amz-Signature', presignedLink.fields.signature);
  formData.append('X-Amz-Security-Token', presignedLink.fields.securityToken);
  formData.append('x-amz-meta-list-name', presignedLink.fields.list_name);
  formData.append('file', file);

  await axios.post(presignedLink.url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    mode: 'no-cors',
  });
  return presignedLink;
};

export const uploadToPresignedLink = async (file: File, options: any) => {
  try {
    const fileExtension = file.name.slice(
      (Math.max(0, file.name.lastIndexOf('.')) || Infinity) + 1,
    );
    const fileName = file.name.slice(0, file.name.lastIndexOf('.'));

    const response = await client.mutate({
      mutation: GetPresignedLinkCloudflareDocument,
      variables: {
        input: {
          file_extension: fileExtension,
          name: fileName,
          mime_type: file.type,
          is_for_metadata_image: false,
        },
      },
      context: {
        clientName: ClientName.Marketplace,
      },
    });

    const presignedLink = response.data?.getPresignedLinkCloudflare;

    console.log({ presignedLink });

    const formData = new FormData();
    formData.append('file', file);

    const response2 = await axios.post(`${presignedLink}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log({ response2 });

    return { id: response2.data.result.id, url: ensurePublicUrl(response2.data.result.variants) };
  } catch (err) {
    console.log({ err });
    throw err;
  }
};

export const uploadToPresignedLinkFavicon = async (file: File) => {
  try {
    const response = await client.mutate({
      mutation: GetPresignedLinkFaviconDocument,
      context: {
        clientName: ClientName.Marketplace,
      },
    });

    const presignedLink = response.data?.getPresignedLinkFavicon;
    console.log({ presignedLink, file });

    const response2 = await axios.put(`${presignedLink.url}`, file, {
      headers: {
        'Content-Type': file.type,
        'Cache-Control': 'public,max-age=0,must-revalidate',
        'x-amz-acl': 'public-read',
      },
    });
    console.log({ response2 });

    return { id: '', url: response2, hostedUrl: presignedLink.hostedUrl };
  } catch (err) {
    console.log({ err });
    throw err;
  }
};

export const handleUpload = async (imgState: any, isFavicon = false) => {
  try {
    console.log({ imgState, isFavicon });
    if (!isFavicon) {
      const presignedLink = await uploadToPresignedLink(imgState, {
        is_for_metadata_image: false,
      });
      return presignedLink;
    } else {
      const { hostedUrl } = await uploadToPresignedLinkFavicon(imgState);
      return hostedUrl;
    }
  } catch (err) {
    console.error({ err });
    throw err;
  }
};
