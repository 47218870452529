import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';

import Plus from '@/assets/Plus.svg?react';
import FolderCard from '@/components/ui/folder-card';

const FolderCardsGroup: FunctionComponent<any> = ({
  folders,
  foldersLoading,
  setNewFolderInput = () => {},
  newFolderInput = '',
  createFolderLoading,
  onClick = () => {},
  handleCreateFolder = () => {},
}) => {
  const [newFolder, setNewFolder] = useState(false);

  return (
    <div className="max-h-[124px] min-h-[124px] overflow-y-scroll">
      <div className="relative flex flex-wrap gap-[10px]">
        <button
          className="hover:bg-card-faded z-10 flex h-[52px] items-center justify-center rounded-[12px] border bg-card px-[40px] text-primary transition-colors duration-200 ease-in"
          onClick={() => {
            setNewFolder(!newFolder);
          }}
        >
          <Plus className="mr-[8px] size-[16px] stroke-primary" />
          Create a folder
        </button>
        {createFolderLoading && (
          <FolderCard
            nameAsComponent={<div className="h-[22px] w-[120px] animate-pulse rounded-[6px]" />}
          />
        )}
        {newFolder && (
          <FolderCard
            newFolderInput={newFolderInput}
            setNewFolderInput={setNewFolderInput}
            create={true}
            onOutsideClick={() => {
              setNewFolder(!newFolder);
            }}
            onSubmit={(e) => {
              e.preventDefault();
              setNewFolder(false);
              handleCreateFolder();
            }}
          />
        )}
        {foldersLoading ? (
          [1, 1].map((item, index) => (
            <FolderCard
              key={index}
              nameAsComponent={<div className="h-[22px] w-[120px] animate-pulse rounded-[6px]" />}
            />
          ))
        ) : (
          <>
            {folders.length == 0 && (
              <p className="absolute top-0 flex h-[124px] w-full items-center justify-center pt-[10px] opacity-60">
                No Folders Here
              </p>
            )}
            {folders.map((item, index) => (
              <FolderCard
                key={index}
                name={item.slice(1)}
                onClick={() => {
                  onClick(item, index);
                }}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default FolderCardsGroup;
