import { useCallback, useEffect, useState } from 'react';

import { Checkbox } from '@/components/ui/checkbox';
import { Progress } from '@/components/ui/progress';
import { cn } from '@/lib/utils';

import ArrowRight from '../../assets/ArrowRight.svg?react';
import Help from '../../assets/Help.svg?react';

export function ProgressCard({
  progress = 0,
  tasksClick = () => {},
  bgColor = '',
  leftTitle = '',
  rightTitle = '',
  centeredTitle = '',
  seeTasks = true,
  checkbox = false,
  checked = false,
  setChecked = (arg) => {},
  loading = false,
  completeForms = 0,
  outOfForms = 1,
}) {
  return (
    <div
      className={cn(
        'relative rounded-[16px] bg-card p-[24px] shadow-[2px_2px_24px_0px_rgba(0,0,0,0.1)] ',
        `bg-[${bgColor}] h-[180px]`,
      )}
      style={{ backgroundColor: `${bgColor}` }}
    >
      {centeredTitle.length > 0 && (
        <div className="flex w-full justify-center">
          {loading ? (
            <div className="mb-[32px] h-[32px] w-[60%] animate-pulse rounded-[6px]" />
          ) : (
            <p className={'mb-[32px] text-center text-lg font-medium uppercase sm:text-2xl'}>
              {centeredTitle}
            </p>
          )}
        </div>
      )}
      {(leftTitle.length > 0 || rightTitle.length > 0) && (
        <div className=" mb-[16px] flex justify-between">
          {leftTitle.length > 0 && (
            <p className="flex items-center text-sm font-semibold uppercase opacity-50">
              {leftTitle} <Help className="ml-1 size-[17px] stroke-[#000000]" />
            </p>
          )}
          {!!(!loading && rightTitle.length > 0) && (
            <p className="absolute right-0 top-0 mr-[10px] mt-[10px] w-[120px] text-sm uppercase text-primary">
              {rightTitle}
            </p>
          )}
        </div>
      )}
      {loading ? (
        <div className="h-[16px] w-full animate-pulse rounded-full " />
      ) : (
        <Progress value={progress} />
      )}
      <div className="mb-[24px] mt-[16px] flex justify-between">
        {loading ? (
          <div className="h-[20px] w-[100px] animate-pulse rounded-[6px] " />
        ) : (
          <p className="flex items-center text-sm">
            <div className="mr-[10px] size-[10px] rounded-[10px] bg-primary" />
            <span className="opacity-70">Complete</span>{' '}
            <strong className="ml-[10px] text-sm">{parseInt(progress)}%</strong>
          </p>
        )}
        {/* {loading ? (
          <div className="h-[20px] w-[50px] animate-pulse rounded-[6px] " />
        ) : (
          <p className="text-sm font-semibold">
            {completeForms}/{outOfForms}
          </p>
        )} */}
      </div>
      {!loading && (
        <div className="flex justify-between">
          {seeTasks && (
            <button
              className="flex cursor-pointer text-sm text-primary hover:underline"
              onClick={tasksClick}
            >
              See All Tasks <ArrowRight className="stroke-primary" />
            </button>
          )}
          {checkbox && (
            <div className="flex p-0">
              <Checkbox
                className={''}
                checked={checked}
                onCheckedChange={(check) => {
                  setChecked(check);
                }}
              />
              <p className="m-0 ml-[10px] text-left text-sm font-medium">
                I've Completed This Form
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ProgressCard;
