import { useCallback, useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Nft } from '@/lib/__generated__/marketplace/graphql';
import useBoundStore from '@/store';

function SelectAsset({
  open,
  setOpen = (bool) => {},
  selectedAsset = null,
  selectedAssets = [],
  setSelectedAsset = (i) => {},
  setSelectedAssets = (i) => {},
  title = 'Select An Asset',
  withButton = false,
  onButtonClick = () => {},
  selectMultiple = false,
  buttonLoading = false,
}: SelectedAssetProps) {
  const assets = useBoundStore((state) => state.assetsSlice.assets);
  const isAllLoaded = useBoundStore((state) => state.assetsSlice.isAllLoaded);
  const fetchNextAssets = useBoundStore((state) => state.assetsSlice.fetchNextAssets);

  const scrollRef = useRef<HTMLDivElement>(null); // Ref for the InfiniteScroll component
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      console.log({
        scrollRef: scrollRef?.current,
        contentRef: contentRef?.current,
      });
      if (!scrollRef.current || !contentRef.current) return;

      const scrollHeight = scrollRef.current.clientHeight;
      const contentHeight = contentRef.current.clientHeight;
      console.log({ contentHeight, scrollHeight });

      // If content height is less than scroll height, fetch more assets
      if (contentHeight < scrollHeight && !isAllLoaded) {
        fetchNextAssets();
      }
    }, 100);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [assets, isAllLoaded, open]);

  const isSelected = useCallback(
    (item) => {
      return (
        selectedAssets.filter((selectedAsset) => selectedAsset.batch_id == item.batch_id).length > 0
      );
    },
    [selectedAssets],
  );

  return (
    <Dialog
      open={open}
      onOpenChange={(open_) => {
        setOpen(!open);
      }}
    >
      <DialogContent
        className="w-[800px] max-w-[90%] rounded-[16px]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogHeader>
          <DialogTitle className="text-center text-[22px] font-medium">{title}</DialogTitle>
        </DialogHeader>
        <div ref={scrollRef}>
          <InfiniteScroll
            className=""
            dataLength={assets.length}
            next={() => {
              console.log('NEXT FIRED');
              fetchNextAssets();
            }}
            hasMore={!isAllLoaded}
            height={'calc(100vh - 340px)'}
            loader={
              <div className="mt-[10px]">
                <div className="m-auto size-8 animate-spin rounded-full border-t-2 border-primary" />
              </div>
            }
          >
            <div className="grid grid-cols-4 gap-[10px] p-[5px] pt-0" ref={contentRef}>
              {assets.map((item, index) => (
                <button
                  key={item.batch_id}
                  className="overflow-hidden rounded-[10px] border-4 border-card shadow-md"
                  onClick={() => {
                    if (selectMultiple) {
                      if (!isSelected(item)) {
                        setSelectedAssets([...selectedAssets, item]);
                      } else {
                        setSelectedAssets(
                          selectedAssets.filter(
                            (selectedAsset) => selectedAsset.batch_id !== item.batch_id,
                          ),
                        );
                      }
                    } else {
                      if (selectedAsset !== index) {
                        setSelectedAsset(index);
                      } else {
                        setSelectedAsset(null);
                      }
                    }
                  }}
                >
                  <img
                    className="h-[164px] w-full rounded-[5px] object-cover"
                    src={item.media_ipfs_link}
                  />
                  <div className="flex p-[7px]">
                    <Checkbox
                      className={''}
                      checked={
                        selectMultiple
                          ? isSelected(item) // selectedAssets.includes(item.batch_id)
                          : selectedAsset == index
                      }
                      onCheckedChange={(checked) => {}}
                    />
                    <p className="m-0 ml-[10px] truncate text-left text-sm font-medium">
                      {item.name}
                    </p>
                  </div>
                </button>
              ))}
            </div>
          </InfiniteScroll>
        </div>
        {withButton && (
          <div className="flex justify-end">
            <Button
              loading={buttonLoading}
              className="text-md w-[180px] bg-primary p-[10px]"
              onClick={() => {
                onButtonClick();
              }}
            >
              Apply
            </Button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default SelectAsset;

type SelectedAssetProps = {
  open: boolean;
  setOpen: (bool) => void;
  selectedAsset?: null | number;
  selectedAssets?: Nft[];
  setSelectedAsset?: (i) => void;
  setSelectedAssets?: (i) => void;
  title?: string;
  withButton?: boolean;
  buttonLoading?: boolean;
  onButtonClick?: () => void;
  selectMultiple?: boolean;
};
