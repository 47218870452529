import { useState } from 'react';

import Delete from '@/assets/Delete.svg?react';
import { cn } from '@/lib/utils';

const DeleteButton = ({ onClick = (e) => {}, className = '' }) => {
  return (
    <button
      className={cn(
        'flex size-[48px] min-h-[48px] min-w-[48px] items-center justify-center rounded-full border bg-white',
        className,
      )}
      onClick={onClick}
      type="button"
    >
      <Delete className="w-[26px] stroke-primary" />
    </button>
  );
};

export { DeleteButton };
