import { useForm } from 'react-hook-form';

import { BackNext } from '@/components/ui/back-next';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';
import { countries } from '@/const/countries';

interface AddTrademarkProps {
  open: boolean;
  setOpen: Function;
}
function AddTrademarkModal({ open, setOpen = () => {} }: AddTrademarkProps) {
  const methods = useForm();

  const screenData1 = [
    {
      title: '',
      sectionClassName: '',
      sectionInputsClassName: '',
      inputs: [
        {
          type: 'input',
          label: 'Trademark',
          state: 'trademark',
          placeholder: 'Enter trademark name',
        },
        {
          type: 'input',
          label: 'Serial Number',
          state: 'serialNumber',
          placeholder: 'Enter serial number',
        },
        {
          type: 'calendar',
          label: 'Grant Date',
          state: 'grantDate',
          placeholder: 'MM/DD/YYYY',
        },
        {
          type: 'dropdown',
          label: 'Country',
          state: 'country',
          placeholder: 'Enter country of filing',
          options: countries,
        },
      ],
    },
  ];

  return (
    <Dialog
      open={open}
      onOpenChange={(open_) => {
        setOpen();
      }}
    >
      <DialogContent
        className="w-[550px] max-w-[90%] rounded-[16px]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogHeader>
          <DialogTitle className="text-semibold mb-[16px] text-center text-[18px]">
            Add a Trademark
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col">
          <WrappedInputSection
            methods={methods}
            data={[{ sectionClassName: 'mb-[22px]', inputs: screenData1[0].inputs?.slice(0, 6) }]}
          />
          <BackNext className="mt-[20px]" numOfLevels={4} finalBtnText={'Save & continue'} />
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default AddTrademarkModal;
