import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { cn } from '@/lib/utils';
import useBoundStore from '@/store';

function ListModal({
  open,
  setOpen = (bool) => {},
  selectedLists = [],
  setSelectedLists = (index) => {},
  onButtonClick = () => {},
  buttonLoading = false,
}: ListModalProps) {
  const customLists = useBoundStore((state) => state.listsAndRewardsSlice.customLists);
  const fetchCustomLists = useBoundStore((state) => state.listsAndRewardsSlice.fetchCustomLists);

  useEffect(() => {
    if (customLists.length == 0) {
      fetchCustomLists();
    }
  }, []);

  const isSelected = useCallback(
    (item) => {
      return (
        selectedLists.filter(
          (selectedList) => selectedList.uploaded_custom_list_id == item.uploaded_custom_list_id,
        ).length > 0
      );
    },
    [selectedLists],
  );

  return (
    <Dialog
      open={open}
      onOpenChange={(open_) => {
        setOpen(!open);
      }}
    >
      <DialogContent
        className="w-[800px] max-w-[90%] rounded-[16px]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogHeader>
          <DialogTitle className="text-center text-[22px] font-medium">Select List</DialogTitle>
        </DialogHeader>
        <div className="overflow-y-scroll p-[5px] pt-0" style={{ height: 'calc(100vh - 340px)' }}>
          <div className="grid grid-cols-2 gap-[10px]">
            {customLists.map((item, index) => (
              <button
                className={cn(
                  'flex h-[50px] items-center rounded-[5px] border pl-[15px] shadow-sm',
                )}
                key={index}
                onClick={() => {
                  console.log(item.uploaded_custom_list_id, selectedLists, isSelected(item));
                  if (!isSelected(item)) {
                    setSelectedLists([...selectedLists, item]);
                  } else {
                    setSelectedLists(
                      selectedLists.filter(
                        (selectedList) =>
                          selectedList.uploaded_custom_list_id !== item.uploaded_custom_list_id,
                      ),
                    );
                  }
                }}
              >
                <Checkbox
                  className={''}
                  checked={isSelected(item)}
                  onCheckedChange={(checked) => {}}
                />
                <p className="ml-[15px] truncate text-sm">{item.name}</p>
              </button>
            ))}
          </div>
        </div>
        <div className="flex justify-end">
          <Button
            loading={buttonLoading}
            className="text-md w-[180px] bg-primary p-[10px]"
            onClick={() => {
              onButtonClick();
            }}
          >
            Apply
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ListModal;

type ListType = {
  name: string;
  created_at: string;
  number_of_members: number;
  uploaded_custom_list_id?: number;
};

type ListModalProps = {
  open: boolean;
  setOpen: (bool) => void;
  selectedLists: ListType[] | [];
  setSelectedLists: (index) => void;
  onButtonClick?: () => void;
  buttonLoading?: boolean;
};
