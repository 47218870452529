import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import File from '@/assets/File.svg?react';
import AssignFolder from '@/components/modals/assign-folder';
import FileCardGroup from '@/components/ui/file-card-group';
import FolderCardsGroup from '@/components/ui/folder-cards-group';
import SectionDivider from '@/components/ui/section-divider';
import { useToast } from '@/components/ui/use-toast';
import { CreateFolderDocument, GetFolderDocument } from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { cn } from '@/lib/utils';

const FolderView: FunctionComponent<any> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { toast } = useToast();

  const prefixPath = location?.pathname?.slice(7).replaceAll('%20', ' ');

  const [foldersLoading, setFoldersLoading] = useState(true);
  const [filesLoading, setFilesLoading] = useState(true);
  const [selectFiles, setSelectFiles] = useState(false);
  const [createFolderLoading, setCreateFolderLoading] = useState(false);
  const [currentPath, setCurrentPath] = useState(prefixPath);

  const [selectedFilesObj, setSelectedFilesObj] = useState({});
  const [newFolderInput, setNewFolderInput] = useState('');

  const [moveFolders, setMoveFolders] = useState(false);

  const [files, setFiles] = useState<any>([]);
  const [folders, setFolders] = useState<string[]>([]);
  const [currentPageNum, setCurrentPageNum] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const mergeFoldersWithSlash = (folders, index) => {
    const selectedFolders = folders.slice(0, index + 1);
    return '/folder/' + selectedFolders.join('/');
  };

  const lastSlash = (path) => {
    const splitPath = path.split('/');
    return `/${splitPath[splitPath.length - 1]}`;
  };

  const currentPathList = useMemo(() => {
    return prefixPath.slice(1).split('/');
  }, [prefixPath]);

  const handleCreateFolder = async (otherPath = '', otherFunction = () => {}) => {
    try {
      setCreateFolderLoading(true);
      const {
        data: { createFolder: createFolderRes },
      } = await client.mutate({
        mutation: CreateFolderDocument,
        variables: {
          input: { path: otherPath.length > 0 ? otherPath : `${prefixPath}/${newFolderInput}` },
        },
        context: { clientName: ClientName.Marketplace },
      });
      console.log({ createFolderRes });
      setCreateFolderLoading(false);
      if (otherPath.length == 0) {
        setFolders([`/${newFolderInput}`, ...folders]);
        setNewFolderInput('');
      } else {
        otherFunction();
      }
    } catch (err: any) {
      console.log({ err });
      setCreateFolderLoading(false);
      toast({
        title: 'Error',
        variant: 'destructive',
        description: `${err.message}`,
      });
    }
  };

  const handleDeleteFiles = (nftIds) => {
    setFiles([...files].filter((item) => !nftIds.includes(item.uuid)));
  };

  const handleClearState = () => {
    try {
      const movedFiles = files.filter((item) => !selectedFilesObj[item.uuid]);
      console.log({ movedFiles });
      setFiles(movedFiles);
      setSelectedFilesObj({});
      setSelectFiles(false);
      setMoveFolders(false);
    } catch (err) {
      console.log({ err });
    }
  };

  const handleSelectFiles = (item) => {
    if (selectedFilesObj[item.uuid]) {
      setSelectedFilesObj({ ...selectedFilesObj, [item.uuid]: false });
    } else {
      setSelectedFilesObj({ ...selectedFilesObj, [item.uuid]: true });
    }
  };

  const handleReadFiles = async () => {
    try {
      setFoldersLoading(true);
      setFilesLoading(true);
      setFolders([]);
      setFiles([]);
      setCurrentPath(prefixPath);
      const {
        data: { getFolder: getFolderRes },
      } = await client.query({
        query: GetFolderDocument,
        variables: {
          input: { path: prefixPath, page_number: 0, batch_size: 100 },
        },
        context: { clientName: ClientName.Marketplace },
      });
      console.log({ getFolderRes });
      setFolders(getFolderRes.folders.map((item) => lastSlash(item.path)));
      setFoldersLoading(false);
      setFilesLoading(false);
      // setFiles([...files, ...getFolderRes.files]);
      setFiles([...getFolderRes.files]);
      if (getFolderRes.files.length < 100) {
        setHasMore(false);
      }
    } catch (err) {
      console.log({ err });
      setFoldersLoading(false);
      setFilesLoading(false);
    }
  };

  useEffect(() => {
    handleReadFiles();
  }, [location]);

  useEffect(() => {
    console.log({ location: location?.pathname?.slice(7) });
  }, [location]);

  return (
    <div className="grid w-full max-w-[1300px] flex-col gap-[25px]">
      <div className="relative">
        <div className="absolute bottom-0 mb-[10px] ml-[48px] flex">
          <button
            className="mr-[15px] text-lg font-medium hover:text-primary"
            onClick={() => {
              navigate('/my-content');
            }}
          >{`/ Root`}</button>
          {currentPathList.map((item, index) => (
            <button
              key={`folderSelect_${index}`}
              className={cn(
                'mr-[15px] text-lg font-medium',
                index !== currentPathList.length - 1 ? 'hover:text-primary' : '',
              )}
              onClick={() => {
                if (index !== currentPathList.length - 1) {
                  navigate(mergeFoldersWithSlash(currentPathList, index));
                  setFoldersLoading(true);
                }
              }}
            >
              {`/ ${item}`}
            </button>
          ))}
        </div>
        <SectionDivider color={'#08C299'} titles={['']} img={<File className="stroke-white" />} />
      </div>
      <FolderCardsGroup
        folders={folders}
        foldersLoading={foldersLoading}
        setNewFolderInput={setNewFolderInput}
        newFolderInput={newFolderInput}
        handleCreateFolder={handleCreateFolder}
        createFolderLoading={createFolderLoading}
        onClick={(item) => {
          navigate(`/folder${prefixPath}${item}`);
          setCurrentPath(`${prefixPath}${item}`);
          setSelectFiles(false);
        }}
      />
      <FileCardGroup
        filesLoading={filesLoading}
        files={files}
        selectedFilesObj={selectedFilesObj}
        setSelectedFilesObj={setSelectedFilesObj}
        setMoveFolders={setMoveFolders}
        selectFiles={selectFiles}
        setSelectFiles={setSelectFiles}
        setCurrentPageNum={setCurrentPageNum}
        currentPageNum={currentPageNum}
        hasMore={hasMore}
        deleteFiles={handleDeleteFiles}
        onClick={(item) => {
          handleSelectFiles(item);
        }}
      />
      <AssignFolder
        setOpen={setMoveFolders}
        open={moveFolders}
        folders={folders}
        selectedFilesObj={selectedFilesObj}
        handleCreateFolder={handleCreateFolder}
        clearState={handleClearState}
        currentPath={currentPath}
        originalPath={currentPath}
        setCurrentPath={setCurrentPath}
      />
    </div>
  );
};

export default FolderView;
