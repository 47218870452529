import { useState } from 'react';

import Add from '@/assets/Add.svg?react';
import { cn } from '@/lib/utils';

const AddButton = ({ onClick = (e) => {}, className = '', alt = false, addTxt = 'Add' }) => {
  return (
    <>
      {!alt ? (
        <button
          className={cn(
            'flex size-[48px] min-h-[48px] min-w-[48px] items-center justify-center rounded-full border bg-white',
            className,
          )}
          onClick={onClick}
          type="button"
        >
          <Add className="stroke-primary" />
        </button>
      ) : (
        <button
          className={cn(
            'h-[50px] w-[160px] border bg-card font-medium uppercase text-primary',
            className,
          )}
          onClick={onClick}
          type="button"
        >
          {addTxt}
        </button>
      )}
    </>
  );
};

export { AddButton };
