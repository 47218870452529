/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import Close from '@/assets/Close.svg?react';
import DirectorsInfoModal from '@/components/modals/directors-info';
import OfficersInfo from '@/components/modals/officers-info';
import TableInputsModal from '@/components/modals/table-inputs-modal';
import { AddButton } from '@/components/ui/add-button';
import { BackNext } from '@/components/ui/back-next';
import { Button } from '@/components/ui/button';
import { Table } from '@/components/ui/table';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';
import { statesInAmerica } from '@/const';
import { PersonIcon } from '@radix-ui/react-icons';

const TeamDetails = () => {
  const [openModal, setOpenModal] = useState(false);
  const [directorsModal, setDirectorsModal] = useState(false);
  const [officersModal, setOfficersModal] = useState(false);

  const [tableState, setTableState] = useState<any[]>([]);

  const [statesOfEmployees, setStatesOfEmployees] = useState<any[]>([]);
  const [defaultValues, setDefaultValues] = useState<any>({
    attributes: [{ field: '', value: '' }],
    ownershipPercentage: [0],
  });

  const methods = useForm({ defaultValues });
  const formValues = methods.watch();

  // INPUTS FOR SCREEN
  const screenData1 = [
    {
      title: '',
      sectionClassName: '',
      sectionInputsClassName: '',
      inputs: [
        {
          type: 'input',
          label: 'How many employees do you have',
          state: 'numberOfEmployees',
          placeholder: 'Enter number of employees',
        },
        {
          type: 'dropdown',
          label: 'In which states are employees in',
          state: 'employeeStates',
          placeholder: 'Select state',
          options: statesInAmerica,
        },
      ],
    },
  ];

  // INPUTS FOR MODAL
  const inputsForModal: any[] = [
    {
      type: 'input',
      label: 'Name',
      state: 'name',
      placeholder: 'Enter name',
    },
    {
      type: 'input',
      label: 'Class of Security',
      state: 'classOfSecurity',
      placeholder: 'Enter class of security',
    },
    {
      type: 'input',
      label: 'Number of Shares',
      state: 'numOfShares',
      placeholder: 'Enter number of shares',
    },
    {
      type: 'input',
      label: 'Percentage',
      state: 'percentage',
      placeholder: 'Enter percentage',
    },
  ];

  const tableInputs = inputsForModal.map((input) => {
    return {
      value: (
        <WrappedInputSection
          methods={methods}
          data={[
            {
              sectionClassName: 'rounded-[5px] absolute top-0 ml-[5px] left-0 w-[97%] mt-[5px]',
              inputs: [{ ...input, label: '' }],
            },
          ]}
        />
      ),
      className: 'pt-[4px] relative h-[60px]',
    };
  });

  const inputTableData = {
    screenData: [
      {
        inputs: inputsForModal,
      },
    ],
    columnNames: inputsForModal.map((input) => input.label),
    tableInputs,
  };
  // --- --- --- --- ---

  useEffect(() => {
    console.log({ tableState });
  }, [tableState]);

  const handleDeleteStates = (index) => {
    const statesOfEmployees_ = [...statesOfEmployees];
    statesOfEmployees_.splice(index, 1);
    setStatesOfEmployees(statesOfEmployees_);
  };

  useEffect(() => {
    const scrollableElement = document.getElementById('authLayoutContainerId');
    if (scrollableElement) {
      scrollableElement.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (!statesOfEmployees.includes(formValues?.employeeStates) && formValues?.employeeStates) {
      setStatesOfEmployees([formValues.employeeStates, ...statesOfEmployees]);
    }
  }, [formValues]);

  return (
    <div className="relative mx-[20px] flex min-h-[800px] w-full max-w-[800px] flex-col  ">
      <h1 className="mb-[28px] w-full text-left text-[2rem] font-medium sm:mb-[32px]  sm:text-[2rem]">
        Team Details
      </h1>
      <div className="mb-[22px]">
        <p className="mb-[12px] text-sm opacity-80">Who owns more than 20% voting power? </p>
        {tableState.length > 0 && (
          <div className="mb-[20px] rounded-[10px] bg-card py-[10px] shadow-md">
            <Table
              ariaLabel="table-inputs-modal"
              data={tableState}
              columnNames={inputTableData.columnNames}
              hoverFade={false}
            />
          </div>
        )}
        <AddButton
          className=""
          alt
          onClick={(e) => {
            setOpenModal(true);
          }}
        />
      </div>
      <div className="mb-[22px]">
        <p className="mb-[12px] text-sm opacity-80">
          Who are the officers or directors of the company?{' '}
        </p>
        <div className="flex items-center">
          <Button
            className="text-md h-[50px] w-[160px] border bg-card font-medium uppercase text-primary"
            onClick={(e) => {
              setOfficersModal(true);
            }}
          >
            Add Officers
          </Button>
          <p className="mx-[20px]"> or </p>
          <Button
            className="text-md h-[50px] w-[160px] border bg-card font-medium uppercase text-primary"
            onClick={(e) => {
              setDirectorsModal(true);
            }}
          >
            Add Directors
          </Button>
        </div>
        {!!(formValues.officerName || formValues.directorName) && (
          <div className="flex gap-[10px]">
            {formValues.directorName && (
              <button
                className="mt-[20px] flex items-center rounded-[5px] border bg-card p-[15px]"
                onClick={(e) => {
                  setDirectorsModal(true);
                }}
              >
                <PersonIcon className="mr-[10px] size-[15px] stroke-1" />
                <p className="font-normal">{formValues.directorName}</p>
              </button>
            )}
            {formValues.officerName && (
              <button
                className="mt-[20px] flex items-center rounded-[5px] border bg-card p-[15px]"
                onClick={(e) => {
                  setOfficersModal(true);
                }}
              >
                <PersonIcon className="mr-[10px] size-[15px] stroke-1" />
                <p className="font-normal">{formValues.officerName}</p>
              </button>
            )}
          </div>
        )}
      </div>
      <WrappedInputSection methods={methods} data={screenData1} />
      <div className="mt-[20px] flex gap-[10px]">
        {statesOfEmployees.map((item, index) => (
          <p
            className="flex items-center rounded-[5px] border border-primary p-[8px] px-[12px] text-sm"
            key={item}
          >
            {item}
            <button
              className="ml-[5px]"
              onClick={() => {
                handleDeleteStates(index);
              }}
            >
              <Close />
            </button>
          </p>
        ))}
      </div>
      <BackNext numOfLevels={1} finalBtnText={'Save & continue'} />
      <TableInputsModal
        title="Principal Security Holders"
        tableData={inputTableData}
        open={openModal}
        setOpen={setOpenModal}
        methods={methods}
        tableState={tableState}
        setTableState={setTableState}
      />
      <DirectorsInfoModal open={directorsModal} setOpen={setDirectorsModal} methods={methods} />
      <OfficersInfo open={officersModal} setOpen={setOfficersModal} methods={methods} />
    </div>
  );
};

export default TeamDetails;
