import { Dispatch, FunctionComponent, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Range } from 'react-range';

import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';

type PercentSliderType = {
  rangeValues: number[];
  setRangeValues: Dispatch<SetStateAction<number[]>>;
  disabled?: boolean;
};

const PercentSlider: FunctionComponent<PercentSliderType> = ({
  rangeValues = [20],
  setRangeValues = (item) => {},
  disabled = false,
}) => {
  return (
    <div className={cn('flex items-center', disabled ? 'cursor-not-allowed' : '')}>
      <div className="relative mr-[30px] w-[90px]">
        <p className="absolute right-0 z-10 mr-[14px] mt-[11px] text-text-color">%</p>
        <Input
          value={`${rangeValues[0]}`}
          onChange={(e) => {
            if (!disabled) {
              const newValue = parseInt(e.target.value.replace('%', ''), 10);
              if (newValue >= 1 && newValue <= 100) {
                setRangeValues([newValue]);
              }
            }
          }}
          className=""
        />
      </div>
      <Range
        step={1}
        min={0}
        max={100}
        values={rangeValues}
        onChange={(values) => {
          if (!disabled) {
            setRangeValues(values);
          }
        }}
        renderTrack={({ props, children }) => (
          <div className="h-[6px] w-full border border-[#ccc] bg-card" {...props}>
            <div
              className={cn('h-[6px] bg-primary', disabled ? 'cursor-not-allowed' : '')}
              style={{
                width: `${((rangeValues[0] - 1) / (100 - 1)) * 100}%`,
              }}
            />
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            className="mt-[-5px] size-[30px] rounded-full border-2 border-[#b6b6b6] bg-card"
            {...props}
          />
        )}
      />
    </div>
  );
};

export default PercentSlider;
