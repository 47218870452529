import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import draftToHtml from 'draftjs-to-html';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useLocation, useOutletContext } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import spacetime from 'spacetime';
import textVersion from 'textversionjs';

import { BackNext } from '@/components/ui/back-next';
import { Table } from '@/components/ui/table';
import { useToast } from '@/components/ui/use-toast';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';
import {
  CreateNftDocument,
  EditNftDocument,
  GetNftByIdForEditDocument,
} from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import useBoundStore from '@/store';

import { listOfTimeZones, recreateInputs, timezones } from '../../const/index';
import MyEditor from '../ui/editor';
import NftCard from '../ui/nft-card';

// const textVersion = require('textversionjs');

const CreateAsset: FunctionComponent = () => {
  type OutletContextType = [number, React.Dispatch<React.SetStateAction<number>>, string[]];
  const collections = useBoundStore((state) => state.collectionsSlice.collections);
  const payoutPrograms = useBoundStore((state) => state.payoutProgramsSlice.payoutPrograms);
  const dispatchAddToAllAssets = useBoundStore((state) => state.assetsSlice.dispatchAddToAllAssets);
  const dispatchUpdateAsset = useBoundStore((state) => state.assetsSlice.dispatchUpdateAsset);

  const { pathname } = useLocation();
  const { id } = useParams();

  const path = `/${pathname.split('/')[1]}`;
  const isEdit = path == '/edit-asset';

  const navigate = useNavigate();

  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(isEdit);
  const [currentLevel, setCurrentLevel, titles] = useOutletContext<OutletContextType>();
  const [selectedBtn, setSelectedBtn] = useState(0);
  const [defaultValues, setDefaultValues] = useState<any>({
    attributes: [{ field: '', value: '' }],
    xp_value: '',
    timezone: null,
    start_time: null,
  });

  const methods = useForm({
    defaultValues,
  });
  const { handleSubmit } = methods;
  const { toast } = useToast();
  const formValues = methods.watch();

  const today = spacetime();
  const currentTimeInTZ = today.time();

  const currentDate = new Date();
  const hundredYearsFromCurrentDate = spacetime.now().add(120, 'month');

  const selectedCollectionIndex = collections.findIndex(
    (x) => x.contract_address == formValues.collection_contract_address,
  );

  const defaultPayoutProgram = payoutPrograms.findIndex(
    (x) => x?.payout_program_id == collections[selectedCollectionIndex]?.payout_program_id,
  );

  const findTimezone = useMemo(() => {
    try {
      const matchingTimezones = spacetime.whereIts(currentTimeInTZ);
      console.log('FOUND findTimezone', { matchingTimezones, currentTimeInTZ });
      return listOfTimeZones.filter((timezone) =>
        matchingTimezones.includes(timezone.toLowerCase()),
      )[0];
    } catch (err) {
      console.log('Failed to findTimezone', err);
      return [spacetime().timezone().name][0];
    }
  }, []);

  const styleConfig = {
    linkStyle: (href, linkText) => {
      return linkText + ' ' + '(' + href + ')';
    },
    listIndentionTabs: 1,
  };

  const airdropBtns = [
    {
      title: 'Asset',
      type: 'NONE',
      onClick: () => {
        if (!isEdit) {
          setSelectedBtn(0);
        }
      },
    },
    {
      title: 'Asset Airdrop',
      type: 'PUBLIC',
      onClick: () => {
        if (!isEdit) {
          setSelectedBtn(1);
        }
      },
    },
  ];

  const handleFormatTime = (time, date, timeZone) => {
    try {
      const splitTime = time.split(':');
      const m = parseInt(splitTime[1]);
      const h = parseInt(splitTime[0]);
      const d = new Date(date).getDate();
      const mo = new Date(date).getMonth();
      const y = new Date(date).getFullYear();
      const formattedStartTime = new Date(y, mo, d, h, m, 0, 0).toLocaleString();
      return Math.floor(spacetime(formattedStartTime, timeZone).epoch / 1000);
    } catch (err) {
      console.error(err);
    }
  };

  const collectionsDropdown = useMemo(() => {
    return collections.map((item) => ({
      ...item,
      label: item.name,
      value: item.contract_address,
    }));
  }, [collections]);

  const payoutProgramsDropdown = useMemo(() => {
    return payoutPrograms.map((item) => ({
      ...item,
      label: item.name,
      value: JSON.stringify(item),
    }));
  }, [payoutPrograms]);

  const level1Inputs = useMemo(() => {
    const levelOneInputs = [
      {
        type: 'input',
        label: 'Name',
        state: 'name',
        placeholder: 'Enter asset name',
        requiredForNextStep: true,
      },
      {
        type: 'dropdown',
        label: 'Select A Collection',
        state: 'collection_contract_address',
        defaultValue: formValues?.collection_contract_address,
        placeholder: 'Select state',
        options: collectionsDropdown,
        requiredForNextStep: true,
      },
      {
        type: 'editorInput',
        label: 'Description',
        textarea: true,
        state: 'description',
        placeholder: 'Enter asset description',
        requiredForNextStep: true,
      },
      {
        type: 'imageUpload',
        label: 'Image or Video',
        state: 'image',
        filesInModal: ['image', 'video'],
        requiredForNextStep: true,
        noCrop: true,
      },
      {
        type: 'input',
        label: 'Quantity',
        state: 'number_of_tokens',
        placeholder: 'Number of assets available to mint',
        inputType: 'number',
        requiredForNextStep: true,
      },
    ];

    if (selectedBtn == 0) {
      levelOneInputs.push({
        type: 'currencyInput',
        label: 'Price',
        state: 'token_price',
        placeholder: '0.00',
        requiredForNextStep: true,
      });
    }

    return [
      {
        sectionClassName: '',
        inputs: levelOneInputs,
      },
    ];
  }, [selectedBtn, collections]);

  const level2Inputs = useMemo(() => {
    const levelTwoInputs: any = [
      {
        type: 'keyValueInput',
        label: 'Properties (Optional)',
        state: 'attributes',
        placeholder: 'Enter',
      },
    ];
    return [
      {
        sectionClassName: 'mb-[24px]',
        inputs: [
          {
            type: 'imageUpload',
            label: 'Metadata (Optional)',
            state: 'metadata',
            selectFromModal: false,
          },
        ],
      },
      {
        sectionClassName: '',
        inputs: levelTwoInputs,
      },
    ];
  }, []);

  const level3Inputs = [
    {
      inputs: [
        {
          type: 'calendar',
          label: 'Start date',
          state: 'start_date',
          halfWidth: true,
        },
        {
          type: 'timePicker',
          label: 'Start time',
          state: 'start_time',
          halfWidth: true,
        },
        {
          type: 'calendar',
          label: 'End date',
          state: 'end_date',
          halfWidth: true,
        },
        {
          type: 'timePicker',
          label: 'End time',
          state: 'end_time',
          halfWidth: true,
        },
        {
          type: 'dropdown',
          label: 'Timezone',
          state: 'timezone',
          placeholder: 'Select',
          defaultValue: findTimezone,
          options: timezones,
        },
        {
          type: 'dropdown',
          label: 'Select Payout Program',
          state: 'payoutprogram',
          options: payoutProgramsDropdown,
          // defaultValue: JSON.stringify(payoutPrograms[defaultPayoutProgram]),
          requiredForNextStep: true,
        },
        {
          type: 'checkboxColumn',
          state: 'shipping_required',
          options: [{ label: 'Requires Shipping', value: true }],
        },
      ],
    },
  ];

  const level4Inputs = [
    {
      inputs: [
        {
          type: 'calendar',
          state: 'start_date',
        },
        {
          type: 'input',
          state: 'number_of_tokens',
          placeholder: '',
        },
      ],
    },
  ];

  const royaltiesTable = {
    columns: ['Wallet Address', 'Secondary Royalties', 'Mint Royalties'],
    rows: [
      [
        { value: 'Account Owner', type: '', className: 'font-normal' },
        { value: '1%', type: '', className: 'font-bold' },
        { value: '100%', type: '', link: '', className: 'font-bold' },
      ],
    ],
  };

  const payoutRoyalties = useMemo(() => {
    if (formValues.payoutprogram) {
      const payoutObject = JSON.parse(formValues.payoutprogram);

      const getRowValues = (arr) => {
        return arr.mint.map((item, index) => [
          {
            value: item.receiver || `refferal-user-${index + 1}`,
            type: '',
            className: 'font-normal',
          },
          {
            value: `${arr.secondary[index]?.percent}%`,
            type: '',
            className: 'font-bold',
          },
          { value: `${item?.percent}%`, type: '', link: '', className: 'font-bold' },
        ]);
      };

      return [...getRowValues(payoutObject.royalties), ...getRowValues(payoutObject.referrals)];
    } else return royaltiesTable.rows;
  }, [formValues.payoutprogram]);

  const handleCreateAsset = async (data) => {
    try {
      if (currentLevel < 3) {
        setCurrentLevel(currentLevel + 1);
      } else {
        console.log({ data });
        setCreateLoading(true);
        const attributesWithBothValues = data.attributes.filter((attr) => {
          if (attr.field && attr.value) {
            return true;
          }
        });

        let createNftInput: any = {
          html_description: draftToHtml(convertToRaw(data.description.getCurrentContent())),
          payout_program_id: JSON.parse(data.payoutprogram).payout_program_id,
          shipping_required: !!data.shipping_required,
          start_time: handleFormatTime(data.start_time, data.start_date, data.timezone),
          end_time: handleFormatTime(data.end_time, data.end_date, data.timezone),
          airdrop_type: airdropBtns[selectedBtn].type,
        };

        const nftObject = {
          collection_contract_address: data.collection_contract_address,
          token_price: Math.floor(parseFloat(data.token_price) * 100) || 0,
          token_metadata: [
            {
              name: data.name,
              description: textVersion(
                draftToHtml(convertToRaw(data.description.getCurrentContent())),
                styleConfig,
              ),
              number_of_tokens: parseInt(data.number_of_tokens),
              attributes: attributesWithBothValues.map((item) => ({
                trait_type: item.field,
                value: item.value,
              })),
            },
          ],
          upload_id: data?.image?.uuid || data?.image?.id,
        };
        createNftInput = { ...createNftInput, ...nftObject };

        console.log({ createNftInput });

        const createAssetRes = await client.mutate({
          mutation: isEdit ? EditNftDocument : CreateNftDocument,
          variables: isEdit
            ? {
                input: {
                  token_price: selectedBtn === 1 ? 0 : parseFloat(data.token_price) * 100 || 0,
                  token_metadata: {
                    ...createNftInput.token_metadata[0],
                    mint_start_time: handleFormatTime(
                      data.start_time,
                      data.start_date,
                      data.timezone,
                    ),
                    mint_end_time: handleFormatTime(data.end_time, data.end_date, data.timezone),
                  },
                  shipping_required: !!data.shipping_required,
                  payout_program_id: JSON.parse(data.payoutprogram).payout_program_id,
                  // image: data?.image?.hostedUrl,
                  id: id,
                  html_description: draftToHtml(convertToRaw(data.description.getCurrentContent())),
                  upload_id: data?.image?.uuid || data?.image?.id,
                },
              }
            : {
                input: createNftInput,
              },
          context: {
            clientName: ClientName.Marketplace,
          },
        });

        if (isEdit) {
          dispatchUpdateAsset({
            ...createNftInput.token_metadata[0],
            batch_id: id,
            nft_id: id,
            // media_ipfs_link: createNftInput.image,
            media_ipfs_link: data.image.hostedUrl,
            media_mime_type: data?.image?.mime_type,
            mint_price: createNftInput.token_price,
            max_supply: createNftInput.token_metadata[0].number_of_tokens,
            mint_start_time: handleFormatTime(data.start_time, data.start_date, data.timezone),
            mint_end_time: handleFormatTime(data.end_time, data.end_date, data.timezone),
          });
        } else {
          dispatchAddToAllAssets({
            assets: [
              {
                name: data.name,
                max_supply: data.number_of_tokens,
                number_not_minted: data.number_of_tokens,
                media_ipfs_link: data.image.hostedUrl,
                media_mime_type: data?.image?.mime_type,
                mint_price: parseFloat(data.token_price) * 100,
              },
            ],
          });
        }
        setCreateLoading(false);
        navigate('/asset-home');
      }
    } catch (err) {
      setCreateLoading(false);
      toast({
        title: 'Error',
        variant: 'destructive',
        description: `${err}`,
      });
      console.log({ err });
    }
  };

  const handleGetAssetData = async () => {
    try {
      const {
        data: { getNftById: getNftByIdRes },
      } = await client.query({
        query: GetNftByIdForEditDocument,
        variables: {
          nft_id: `${id}`,
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ getNftByIdRes });
      const {
        name,
        html_description,
        media_ipfs_link,
        collection_contract_address,
        mint_price,
        max_supply,
        airdrop_type,
        attributes,
        mint_start_time,
        mint_end_time,
        payout_program_id,
        shipping_required,
      } = getNftByIdRes;

      const startTimeRes = recreateInputs(parseInt(mint_start_time), formValues.timezone);
      const endTimeRes = recreateInputs(parseInt(mint_end_time), formValues.timezone);
      console.log({ startTimeRes, endTimeRes, mint_start_time });

      const contentState = stateFromHTML(html_description);
      const editorState = EditorState.createWithContent(contentState);

      if (airdrop_type == 'NONE') {
        setSelectedBtn(0);
      } else {
        setSelectedBtn(1);
      }
      setPageLoading(false);
      methods.reset({
        ...defaultValues,
        name,
        description: editorState,
        image: { hostedUrl: media_ipfs_link },
        collection_contract_address,
        token_price: `${parseInt(mint_price) / 100}`,
        number_of_tokens: `${max_supply}`,
        attributes: attributes.map((item) => ({ field: item.trait_type, value: item.value })),
        start_time: startTimeRes?.time || currentDate.toTimeString().slice(0, 5),
        start_date: startTimeRes?.date ? new Date(startTimeRes?.date) : currentDate,
        end_time:
          endTimeRes?.time ||
          `${hundredYearsFromCurrentDate.hour()}:${hundredYearsFromCurrentDate.minute()}`,
        end_date: new Date(endTimeRes?.date || hundredYearsFromCurrentDate.epoch),
        payoutprogram: JSON.stringify(
          payoutPrograms.filter((item) => payout_program_id == item.payout_program_id)[0],
        ),
        shipping_required,
      });
    } catch (err) {
      console.log({ err });
    }
  };

  useEffect(() => {
    const tempValues = { ...defaultValues };
    tempValues.timezone = findTimezone;
    tempValues.start_time = currentDate.toTimeString().slice(0, 5);
    tempValues.start_date = currentDate;
    tempValues.end_time = `${hundredYearsFromCurrentDate.hour()}:${hundredYearsFromCurrentDate.minute()}`;
    tempValues.end_date = new Date(hundredYearsFromCurrentDate.epoch);
    setDefaultValues(tempValues);
    console.log({ tempValues });
    methods.reset(tempValues);
  }, [methods]);

  useEffect(() => {
    if (payoutPrograms.length > 0 && isEdit) {
      handleGetAssetData();
    }
  }, [defaultValues, payoutPrograms]);

  useEffect(() => {
    if (formValues.collection_contract_address && !formValues.payoutprogram) {
      methods.setValue('payoutprogram', JSON.stringify(payoutPrograms[defaultPayoutProgram]));
    }
  }, [formValues]);

  return (
    <div className="mx-auto flex min-h-[800px] w-full max-w-[1000px] flex-col gap-[25px]">
      <h1 className="text-3xl font-semibold"> {titles[currentLevel]} </h1>
      <form className="flex h-full flex-col gap-[25px]" onSubmit={handleSubmit(handleCreateAsset)}>
        <>
          {pageLoading ? (
            <div className="flex h-[40vh] items-center justify-center">
              <div className="m-auto size-10 animate-spin rounded-full border-t-2 border-primary" />
            </div>
          ) : (
            <>
              {currentLevel == 0 && (
                <>
                  <div className="flex justify-between">
                    {airdropBtns.map((item, index) => (
                      <button
                        key={`airdropBtn_${index}`}
                        className={`h-[60px] w-[45%] rounded-[5px] font-semibold shadow-md ${
                          selectedBtn !== index ? 'bg-card text-primary' : 'bg-primary text-white'
                        } ${isEdit ? 'cursor-not-allowed opacity-80' : ''}`}
                        onClick={item.onClick}
                      >
                        {item.title}
                      </button>
                    ))}
                  </div>
                  <div className="flex justify-between">
                    <div className="w-full">
                      <WrappedInputSection
                        methods={methods}
                        data={[{ inputs: level1Inputs[0].inputs.slice(0, 3) }]}
                      />
                    </div>
                    <div className="ml-[30px] min-w-[300px]">
                      <WrappedInputSection
                        methods={methods}
                        data={[{ inputs: [level1Inputs[0].inputs[3]] }]}
                      />
                    </div>
                  </div>
                  <h2 className="text-xl font-semibold"> Asset Pricing & QTY </h2>
                  <WrappedInputSection
                    methods={methods}
                    data={[{ ...level1Inputs[0], inputs: level1Inputs[0].inputs.slice(4) }]}
                  />
                </>
              )}
              {currentLevel == 1 && (
                <div className="flex flex-col gap-[25px]">
                  <WrappedInputSection methods={methods} data={level2Inputs} />
                </div>
              )}
              {currentLevel == 2 && (
                <div className="flex flex-col gap-[25px]">
                  <h2 className="text-xl font-semibold"> Date & Time </h2>
                  <WrappedInputSection
                    methods={methods}
                    data={[{ inputs: level3Inputs[0].inputs.slice(0, 5) }]}
                  />
                  <h2 className="text-xl font-semibold"> Royalties </h2>
                  <WrappedInputSection
                    methods={methods}
                    data={[{ inputs: level3Inputs[0].inputs.slice(5) }]}
                  />
                </div>
              )}
              {currentLevel == 3 && (
                <div className="flex flex-col gap-[65px]">
                  <div className="flex">
                    <NftCard
                      asset={{
                        name: formValues.name,
                        max_supply: formValues.number_of_tokens,
                        number_not_minted: formValues.number_of_tokens,
                        media_ipfs_link:
                          formValues?.image?.thumbnail || formValues?.image?.hostedUrl,
                        mint_price: parseFloat(formValues.token_price) * 100,
                      }}
                    />
                    <div className=" pl-[60px] pt-[20px]">
                      <h2 className="text-2xl font-semibold"> {formValues.name} </h2>
                      <p className="my-[20px] opacity-50">
                        {textVersion(
                          draftToHtml(convertToRaw(formValues.description.getCurrentContent())),
                        )}
                      </p>
                      <div className="flex">
                        <div className="flex w-[150px] flex-col justify-between py-[13px]">
                          <p className="font-medium">Is Available on</p>
                          <p className="font-medium">Quantity</p>
                        </div>
                        <div className="w-[300px]">
                          <WrappedInputSection methods={methods} data={level4Inputs} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h2 className="mb-[20px] text-xl font-semibold"> Royalties</h2>
                    <div className="overflow-x-scroll rounded-[10px] border border-input bg-card py-[8px] pb-[16px] shadow-sm sm:overflow-x-auto">
                      {payoutRoyalties.length > 0 && (
                        <Table
                          ariaLabel="royalties-table"
                          data={payoutRoyalties}
                          columnNames={royaltiesTable.columns}
                          isLoading={false}
                          className=""
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
        <BackNext
          numOfLevels={4}
          finalBtnText={isEdit ? 'APPLY EDIT' : 'Create'}
          currentLevel={currentLevel}
          setCurrentLevel={setCurrentLevel}
          loading={createLoading}
        />
      </form>
    </div>
  );
};

export default CreateAsset;
