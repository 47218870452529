/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';

import { BackNext } from '@/components/ui/back-next';
import { cn } from '@/lib/utils';

const UseOfFunds = () => {
  const initialFundsInputs = [
    {
      title: 'Intermediary Fees',
      last_year_value: '',
      this_year_value: '',
    },
    {
      title: 'Estimated Attorney Fees',
      last_year_value: '',
      this_year_value: '',
    },
    {
      title: 'Estimated Accountant/Auditor Fees',
      last_year_value: '',
      this_year_value: '',
    },
    {
      title: 'Marketing',
      last_year_value: '',
      this_year_value: '',
    },
    {
      title: 'Research and Development',
      last_year_value: '',
      this_year_value: '',
    },
    {
      title: 'Manufacturing',
      last_year_value: '',
      this_year_value: '',
    },
    {
      title: 'Equipment purchases',
      last_year_value: '',
      this_year_value: '',
    },
    {
      title: 'Purchase of real property',
      last_year_value: '',
      this_year_value: '',
    },
    {
      title: 'Future wages',
      last_year_value: '',
      this_year_value: '',
    },
    {
      title: 'Accrued wages',
      last_year_value: '',
      this_year_value: '',
    },
    {
      title: 'Accrued expenses of officers',
      last_year_value: '',
      this_year_value: '',
    },
    {
      title: 'Repayment of Debt',
      last_year_value: '',
      this_year_value: '',
    },
    {
      title: 'Repayment of obligations in arrears',
      last_year_value: '',
      this_year_value: '',
    },
    {
      title: 'General working capital',
      last_year_value: '',
      this_year_value: '',
    },
    {
      title: 'Offering marketing fees',
      last_year_value: '',
      this_year_value: '',
    },
    {
      title: 'Future wages',
      last_year_value: '',
      this_year_value: '',
    },
  ];

  const [fundsInputsState, setFundsInputsState] = useState(initialFundsInputs);

  const handleInputChange = (index, field, value) => {
    const updatedFundsInputs = [...fundsInputsState];
    updatedFundsInputs[index][field] = value;
    setFundsInputsState(updatedFundsInputs);
  };

  useEffect(() => {
    const scrollableElement = document.getElementById('authLayoutContainerId');
    if (scrollableElement) {
      scrollableElement.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    console.log({ fundsInputsState });
  }, [fundsInputsState]);

  return (
    <div className="relative mx-[20px] flex w-full max-w-[800px] flex-col items-center">
      <h1 className="mb-[28px] w-full text-left text-[2rem] font-medium sm:mb-[32px] sm:text-[2rem]">
        Use of Funds
      </h1>
      <div className="w-full">
        <div className="mb-[10px] flex opacity-70">
          <p className="min-w-[410px] text-sm ">Which funds do you use</p>
          <p className="box-border w-full text-sm">Last year</p>
          <p className="box-border w-full text-sm">This year</p>
        </div>
        <div className="border border-b-0 border-gray-300 bg-card shadow-md">
          {fundsInputsState.map(({ title, last_year_value, this_year_value }, index) => (
            <div
              key={title} // Added key for each element
              className={cn('flex  border-gray-300', index % 2 !== 0 && 'bg-transparent-lm-dark')}
            >
              <p className="w-[410px] p-[16px] font-light">{title}</p>
              <input
                className="border-b border-l border-gray-300 pl-[16px]"
                placeholder="Amount"
                value={last_year_value}
                onChange={(e) => {
                  handleInputChange(index, 'last_year_value', e.target.value);
                }}
              />
              <input
                className="border-b border-l border-gray-300 pl-[16px]"
                placeholder="Amount"
                value={this_year_value}
                onChange={(e) => {
                  handleInputChange(index, 'this_year_value', e.target.value);
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="mt-[30px] w-full">
        <BackNext className="mt-[20px]" numOfLevels={1} finalBtnText={'Save & continue'} />
      </div>
    </div>
  );
};

export default UseOfFunds;
