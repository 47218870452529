import React, { useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation } from 'react-router-dom';

import Plus from '@/assets/Plus.svg?react';
import { BackNext } from '@/components/ui/back-next';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import FolderCard from '@/components/ui/folder-card';
import { useToast } from '@/components/ui/use-toast';
import { GetFolderDocument, MoveFilesDocument } from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { cn } from '@/lib/utils';
import { ImageIcon, VideoIcon } from '@radix-ui/react-icons';

type FileObj = {
  uuid: string;
  s3_link: string;
  cloudflare_uid: string;
  mime_type: string;
  thumbnail: string;
  tokengated?: boolean;
};

function AssignFolder({
  open,
  setOpen = (bool) => {},
  folders = [],
  handleCreateFolder = (pat, otherFunction) => {},
  clearState = () => {},
  selectedFilesObj = {},
  setCurrentPath,
  currentPath = '',
  canSelectFiles = false,
  handleSetImage = (img) => {},
  filesInModal = [],
  createFolderLoading = false,
  tokengated = false,
}: any) {
  const [newFolder, setNewFolder] = useState(false);
  const [newFolderInput, setNewFolderInput] = useState('');
  const [moveLoading, setMoveLoading] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState<any>(null);
  const [nestedFolders, setNestedFolders] = useState<string[]>([]);
  const [foldersLoading, setFoldersLoading] = useState(false);
  const [files, setFiles] = useState<FileObj[]>([]);
  const [selectedFile, setSelectedFile] = useState<FileObj | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [currentPageNum, setCurrentPageNum] = useState(0);

  const { toast } = useToast();
  const location = useLocation();

  const originalPath1 = useMemo(() => {
    const path = location.pathname;
    const firstSlashIndex = path.indexOf('/');
    const secondSlashIndex = path.indexOf('/', firstSlashIndex + 1);
    return secondSlashIndex !== -1 ? path.substring(secondSlashIndex) : '';
  }, [location]);

  const currentFolders = useMemo(() => {
    if (
      originalPath1 == currentPath &&
      !canSelectFiles
      // && folders.length > 0
    ) {
      return folders;
    } else {
      return nestedFolders;
    }
  }, [nestedFolders, folders, currentPath, originalPath1]);

  const selectedFilesList = useMemo(() => {
    return Object.keys(selectedFilesObj).filter((item) => selectedFilesObj[item]);
  }, [selectedFilesObj]);

  const lastSlash = (path) => {
    const splitPath = path.split('/');
    return `/${splitPath[splitPath.length - 1]}`;
  };

  const handleMoveFiles = async () => {
    try {
      setMoveLoading(true);
      const filesToMove = selectedFilesList;
      console.log({ filesToMove });
      const {
        data: { moveFiles: moveFilesRes },
      } = await client.mutate({
        mutation: MoveFilesDocument,
        variables: { input: { upload_ids: filesToMove, path: `${currentPath}${selectedFolder}` } },
        context: { clientName: ClientName.Marketplace },
      });
      clearState();
      console.log({ moveFilesRes });
      setMoveLoading(false);
    } catch (err: any) {
      console.log({ err });
      setMoveLoading(false);
      toast({
        title: 'Error',
        variant: 'destructive',
        description: `${err.message}`,
      });
    }
  };

  const handleReadFiles = async () => {
    try {
      if (currentPageNum == 0) {
        setFoldersLoading(true);
      }
      const {
        data: { getFolder: getFolderRes },
      } = await client.query({
        query: GetFolderDocument,
        variables: {
          input: {
            path: currentPath.length == 0 ? `/` : currentPath,
            page_number: currentPageNum,
            batch_size: tokengated ? 100 : 30,
          },
        },
        context: { clientName: ClientName.Marketplace },
      });
      console.log({ getFolderRes });
      setNestedFolders(
        getFolderRes.folders.map((item) => lastSlash(item.path)).filter((item) => item !== '/'),
      );
      setFoldersLoading(false);
      if (canSelectFiles) {
        setFiles([...files, ...getFolderRes.files]);
        if (getFolderRes.files.length < 30) {
          setHasMore(false);
        }
      }
    } catch (err) {
      console.log({ err });
      setFoldersLoading(false);
    }
  };

  const filteredFile = useMemo(() => {
    return files.filter(
      (item) =>
        !!(
          (filesInModal.length == 0 || filesInModal.includes(item.mime_type.split('/')[0])) &&
          item.submarine == tokengated
        ),
    );
  }, [files]);

  const currentPathList = useMemo(() => {
    return currentPath.slice(1).split('/');
  }, [currentPath]);

  const mergeFoldersWithSlash = (folders, index) => {
    const selectedFolders = folders.slice(0, index + 1);
    return '/' + selectedFolders.join('/');
  };

  const handleSetNewFolder = () => {
    setNestedFolders([newFolderInput, ...currentFolders]);
    setNewFolderInput('');
  };

  const handleFolderClick = (item) => {
    setFiles([]);
    setCurrentPath(`${currentPath + `${item}`}`);
    setSelectedFolder(null);
    setHasMore(true);
    setCurrentPageNum(0);
  };

  useEffect(() => {
    if (originalPath1 !== currentPath || canSelectFiles) {
      handleReadFiles();
    }
  }, [currentPath, currentPageNum]);

  useEffect(() => {
    console.log({ currentPath, originalPath1, folders });
  }, [currentPath, location, folders]);

  return (
    <Dialog
      open={open}
      onOpenChange={(open_) => {
        setOpen(!open);
      }}
    >
      <DialogContent
        className="flex w-[900px] max-w-[90%] flex-col rounded-[16px]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogHeader>
          <DialogTitle className="text-2xl font-medium">
            {canSelectFiles ? 'Select A File' : `Move Files (${selectedFilesList.length} selected)`}
          </DialogTitle>
        </DialogHeader>
        <div>
          <div className="flex items-center gap-[20px] border-b pb-[20px]">
            <p className="font-medium">Сurrent location:</p>
            {currentPath == '' ? (
              <FolderCard
                height={42}
                nameAsComponent={<p className="mr-[15px] font-medium">{`/ Root`}</p>}
              />
            ) : (
              <FolderCard
                height={42}
                nameAsComponent={
                  <div className="flex">
                    <button
                      className="mr-[15px] font-medium hover:text-primary"
                      onClick={() => {
                        setCurrentPath('');
                        setSelectedFolder(null);
                        setFiles([]);
                        setHasMore(true);
                        setCurrentPageNum(0);
                      }}
                    >{`/ Root`}</button>
                    {currentPathList.map((item, index) => (
                      <button
                        key={`folderSelect_${index}`}
                        className={cn(
                          'mr-[15px] font-medium',
                          index !== currentPathList.length - 1 ? 'hover:text-primary' : '',
                        )}
                        onClick={() => {
                          if (index !== currentPathList.length - 1) {
                            setCurrentPath(mergeFoldersWithSlash(currentPathList, index));
                            // setFoldersLoading(true);
                          }
                        }}
                      >
                        {`/ ${item}`}
                      </button>
                    ))}
                  </div>
                }
              />
            )}
          </div>
          <div
            className={cn(
              'relative overflow-y-scroll border-b py-[7px]',
              canSelectFiles ? 'max-h-[110px] min-h-[110px]' : 'max-h-[170px] min-h-[170px]',
            )}
          >
            {foldersLoading ? (
              <div className="flex min-h-[95px] w-full items-center justify-center">
                <div className="size-7 animate-spin rounded-full border-t-2 border-primary" />
              </div>
            ) : (
              <div className="relative flex min-h-[95px] flex-wrap gap-[10px]">
                {/* {!canSelectFiles && (
                  <button
                    className="z-10 flex h-[42px] items-center justify-center rounded-[12px] border bg-card px-[20px] text-primary transition-colors duration-200 ease-in hover:bg-[#f3f3f3]"
                    onClick={() => {
                      setNewFolder(true);
                    }}
                  >
                    <Plus className="mr-[8px] size-[16px] stroke-primary" />
                    Create a folder
                  </button>
                )} */}
                {currentFolders.length == 0 && (
                  <p className="absolute top-0 flex size-full items-center justify-center opacity-60">
                    No Folders Here
                  </p>
                )}
                {newFolder && (
                  <FolderCard
                    height={42}
                    newFolderInput={newFolderInput}
                    setNewFolderInput={setNewFolderInput}
                    create={true}
                    onOutsideClick={() => {
                      setNewFolder(false);
                    }}
                    onSubmit={(e) => {
                      e.preventDefault();
                      setNewFolder(false);
                      handleCreateFolder(`${currentPath}/${newFolderInput}`, handleSetNewFolder);
                    }}
                  />
                )}
                {createFolderLoading && (
                  <FolderCard
                    height={42}
                    nameAsComponent={
                      <div className="h-[22px] w-[120px] animate-pulse rounded-[6px]" />
                    }
                  />
                )}
                {currentFolders.map((item, index) => (
                  <FolderCard
                    height={42}
                    key={index}
                    selected={item == selectedFolder}
                    name={item.slice(1)}
                    onDoubleClick={() => {
                      console.log('DOUBLE CKICK');
                      handleFolderClick(item);
                    }}
                    onClick={() => {
                      console.log({ item });
                      if (canSelectFiles) {
                        handleFolderClick(item);
                      } else {
                        setSelectedFolder(item);
                      }
                    }}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        {canSelectFiles && (
          <div>
            <h5 className="text-md my-[5px] mb-[7px] mt-[0px] border-b pb-[5px] font-medium">
              Your Content:
            </h5>
            <InfiniteScroll
              className="block"
              dataLength={filteredFile.length}
              next={() => {
                console.log('NEXT FIRED');
                setCurrentPageNum(currentPageNum + 1);
              }}
              scrollableTarget="authLayoutContainerId"
              height={315}
              hasMore={hasMore}
              loader={
                <div className="flex h-12 items-center justify-center">
                  <div className="m-auto size-6 animate-spin rounded-full border-t-2 border-primary" />
                </div>
              }
            >
              <div className="flex flex-wrap gap-[10px] overflow-y-scroll">
                {filteredFile.map((item) => (
                  <button key={item.uuid} className="relative overflow-hidden">
                    <div className="absolute right-0 top-0 mr-[5px] mt-[5px] rounded-[5px] border border-black bg-card p-[1px] px-[2px]">
                      {item.mime_type == 'video/mp4' ? (
                        <p className="flex text-xs">
                          <VideoIcon className="mr-1" />
                          Vid
                        </p>
                      ) : (
                        <p className="flex text-xs">
                          <ImageIcon className="mr-1" />
                          Img
                        </p>
                      )}
                    </div>
                    <img
                      src={item.s3_link || item?.thumbnail}
                      className={cn(
                        'h-[100px] w-[100px] cursor-pointer rounded-[5px] border bg-black object-contain shadow-sm',
                        selectedFile?.uuid == item.uuid ? 'border-[4px] border-primary' : '',
                      )}
                      onClick={() => {
                        setSelectedFile(item);
                      }}
                    />
                    <p className="absolute bottom-0 left-0 line-clamp-1 w-full bg-[#00000080] pb-[2px] pl-[5px] text-[10px] text-white">
                      {item.name}
                    </p>
                  </button>
                ))}
              </div>
            </InfiniteScroll>
          </div>
        )}
        <Button
          className="ml-auto h-[48px] w-[200px] border py-[10px] text-base shadow-md"
          type="submit"
          disabled={canSelectFiles ? selectedFile == null : selectedFolder == null}
          onClick={() => {
            if (canSelectFiles) {
              console.log('SELECT');
              setOpen(false);
              setSelectedFile(null);
              handleSetImage({ ...selectedFile, hostedUrl: selectedFile?.s3_link });
            } else {
              handleMoveFiles();
            }
          }}
        >
          {moveLoading ? (
            <>
              <div className=" size-5 animate-spin rounded-full border-t-2 border-card" />
            </>
          ) : canSelectFiles ? (
            'SELECT'
          ) : (
            'MOVE'
          )}
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export default AssignFolder;
