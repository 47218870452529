import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { AddButton } from '@/components/ui/add-button';
import { BackNext } from '@/components/ui/back-next';
import { DeleteButton } from '@/components/ui/delete-button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import InputWithDropdown from '@/components/ui/input-with-dropdown';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';

interface OfficersInfoProps {
  open: boolean;
  setOpen: Function;
  methods: any;
}
function OfficersInfoModal({ open, setOpen = () => {}, methods }: OfficersInfoProps) {
  const [previousEmployment, setPreviousEmployment] = useState([{ selected: '', inputValue: '' }]);

  const screenData1 = [
    {
      inputs: [
        {
          type: 'input',
          label: 'Full legal name of Officer (As it appears on your ID)',
          state: 'officerName',
          placeholder: 'Enter full legal name',
        },
        {
          type: 'calendar',
          label: 'When was your first date in the company',
          state: 'firstDateInCompany',
          placeholder: 'Enter first date',
        },
        {
          type: 'textarea',
          label: 'Bio',
          state: 'bio',
          placeholder: 'Enter bio',
        },
        {
          type: 'textarea',
          label: 'Responsibilities',
          state: 'responsibilities',
          placeholder: 'Describe responsibilities',
        },
        {
          type: 'percentSlider',
          label: '% ownership',
          state: 'ownershipPercentage',
          placeholder: 'Enter percentage ownership',
        },
        {
          type: 'calendar',
          label: 'DOB',
          state: 'dob',
          placeholder: 'MM/DD/YYYY',
        },
        {
          type: 'input',
          label: 'Birthplace',
          state: 'birthplace',
          placeholder: 'Enter birthplace',
        },
        {
          type: 'input',
          label: 'Mothers Maiden Name',
          state: 'mothersMaidenName',
          placeholder: "Enter mother's maiden name",
        },
        {
          type: 'location',
          label: 'Home Address',
          state: 'homeAddress',
          placeholder: 'Enter home address',
        },
        {
          type: 'input',
          label: 'Home #',
          state: 'homePhone',
          placeholder: 'Enter home phone number',
          halfWidth: true,
        },
        {
          type: 'phone',
          label: 'Cell #',
          state: 'cellPhone',
          placeholder: 'Enter cell phone number',
          halfWidth: true,
        },
        {
          type: 'input',
          label: 'Social Security #',
          state: 'socialSecurityNumber',
          placeholder: 'Enter social security number',
        },
        {
          type: 'input',
          label: 'Drivers License',
          state: 'driversLicense',
          placeholder: "Enter driver's license number",
        },
        {
          type: 'input',
          label: 'ID Doc #',
          state: 'idDocNumber',
          placeholder: 'Enter ID document number',
        },
        {
          type: 'dropdown',
          label: 'US State or country of issuance',
          state: 'stateOrCountryOfIssuance',
          placeholder: 'Select state or country',
        },
        {
          type: 'calendar',
          label: 'Issue Date',
          state: 'issueDate',
          placeholder: 'MM/DD/YYYY',
          halfWidth: true,
        },
        {
          type: 'calendar',
          label: 'Expiration Date',
          state: 'expirationDate',
          placeholder: 'MM/DD/YYYY',
          halfWidth: true,
        },
        {
          type: 'imageUpload',
          label: 'Сopy of ID',
          state: 'image',
          filesInModal: ['image'],
          requiredForNextStep: true,
          noCrop: true,
        },
        {
          type: 'input',
          label: 'Where were you previously employed?',
          state: 'previousEmployment',
          placeholder: 'Enter previous employer',
        },
        {
          type: 'input',
          label: 'What was your role?',
          state: 'previousRole',
          placeholder: 'Enter your role',
        },
        {
          type: 'calendar',
          label: 'Start Date',
          state: 'startDate',
          placeholder: 'MM/DD/YYYY',
          halfWidth: true,
        },
        {
          type: 'calendar',
          label: 'Last day of employment',
          state: 'endDate',
          placeholder: 'MM/DD/YYYY',
          halfWidth: true,
        },
      ],
    },
  ];

  return (
    <Dialog
      open={open}
      onOpenChange={(open_) => {
        setOpen();
      }}
    >
      <DialogContent
        className="w-[750px] max-w-[90%] rounded-[16px]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogHeader>
          <DialogTitle className="text-semibold mb-[16px] text-center text-[18px]">
            Information about Officers
          </DialogTitle>
        </DialogHeader>
        <div className="flex max-h-[750px] flex-col pb-[30px] pr-[10px]">
          <WrappedInputSection
            methods={methods}
            data={[{ inputs: screenData1[0].inputs.slice(0, 4) }]}
          />
          <div className="my-[22px]">
            <p className="mb-1 text-sm opacity-80">
              Where were you previously employed? (Past 3 Years)
            </p>
            <InputWithDropdown
              stateList={previousEmployment}
              setStateList={setPreviousEmployment}
            />
          </div>
          <WrappedInputSection
            methods={methods}
            data={[{ inputs: screenData1[0].inputs.slice(4, 99) }]}
          />
          <div className="mt-[20px] pb-[20px]">
            <BackNext numOfLevels={1} finalBtnText={'Save & continue'} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default OfficersInfoModal;
