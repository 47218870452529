import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import NFTImg from '@/assets/NFTImg.svg?react';
import Plus from '@/assets/Plus.svg?react';
import UtilityIcon from '@/assets/UtilityIcon.svg?react';
import ConfirmModal from '@/components/modals/confirmation-modal';
import SelectAsset from '@/components/modals/select-asset';
import { Button } from '@/components/ui/button';
import { Switch } from '@/components/ui/switch.tsx';
import AutoExpandingTextarea from '@/components/ui/textarea-expanding';
import { useToast } from '@/components/ui/use-toast';
import {
  AssignUtilityDocument,
  DeleteUtilityDocument,
  GetNftsByIdsDocument,
  GetUtilityByIdDocument,
  Nft,
  UpdateUtilityDocument,
} from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { cn } from '@/lib/utils';
import useBoundStore from '@/store';
import { Stream } from '@cloudflare/stream-react';
import { CheckCircledIcon, Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';

import SectionDivider from '../ui/section-divider';

const UtilitiyView: FunctionComponent<any> = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [utilActive, setUtilActive] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [selectedAssets, setSelectedAssets] = useState<Nft[]>([]);
  const [nftsByIds, setNftsByIds] = useState<NftByIdType[]>([]);
  const [nftsByIdsLoading, setNftsByIdsLoading] = useState<boolean>(true);
  const [utilityDataLoading, setUtilityDataLoading] = useState(true);
  const [utilityData, setUtilityData] = useState({
    accessLink: '',
    description: '',
    name: '',
    details: { cover_image_cid: '' },
    assignments: [],
  });
  const [utilityName, setUtilityName] = useState('');
  const [utilityDescrption, setUtilityDescrption] = useState('');
  const [assignUtilLoading, setAssignUtilLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();
  const { toast } = useToast();

  const handleAssignUtility = async () => {
    try {
      console.log({
        assignUtilityInput: {
          utilityId: id,
          batchIds: selectedAssets.map((item) => item.batch_id),
        },
      });
      setAssignUtilLoading(true);
      const {
        data: { assignUtility: assignUtilityRes },
      } = await client.mutate({
        mutation: AssignUtilityDocument,
        variables: {
          assignUtilityInput: {
            utilityId: id,
            batchIds: selectedAssets.map((item) => item.batch_id),
          },
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ assignUtilityRes });
      const {
        data: { getNftsByIds: getNftsByIdsRes },
      } = await client.query({
        query: GetNftsByIdsDocument,
        variables: {
          nft_ids: selectedAssets,
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      setNftsByIds([...getNftsByIdsRes, ...nftsByIds]);
      setAssignUtilLoading(false);
      setModalOpen(false);
      setSelectedAssets([]);
    } catch (err) {
      setAssignUtilLoading(false);
      toast({
        title: 'Error',
        variant: 'destructive',
        description: `${err}`,
      });
      console.log({ err });
    }
  };

  const handleDeleteUtil = async () => {
    try {
      setDeleteLoading(true);
      const res = await client.mutate({
        mutation: DeleteUtilityDocument,
        variables: {
          utilityId: id,
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      setDeleteLoading(false);
      navigate('/utilities');
    } catch (err) {
      setDeleteLoading(false);
      toast({
        title: 'Error',
        variant: 'destructive',
        description: `${err}`,
      });
      console.log({ err });
    }
  };
  const handleEditUtil = async (bool: null | boolean = null) => {
    try {
      setEditLoading(true);
      await client.mutate({
        mutation: UpdateUtilityDocument,
        variables: {
          input: {
            utilityId: id,
            utility: {
              name: utilityName,
              description: utilityDescrption,
              type: 'EXCLUSIVE_VIDEO',
              details: {
                active: bool !== null ? bool : utilActive,
              },
            },
          },
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      setIsEdit(false);
      setEditLoading(false);
      setUtilityData({
        ...utilityData,
        name: utilityName,
        description: utilityDescrption,
      });
    } catch (err) {
      setEditLoading(false);
      toast({
        title: 'Error',
        variant: 'destructive',
        description: `${err}`,
      });
      console.log({ err });
    }
  };

  const handleGetUtilityById = async () => {
    try {
      const {
        data: { getUtilityById: getUtilityByIdRes },
      } = await client.query({
        query: GetUtilityByIdDocument,
        variables: {
          utilityId: id,
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      setUtilityDataLoading(false);
      setUtilityData(getUtilityByIdRes);
      setUtilityName(getUtilityByIdRes.name);
      setUtilityDescrption(getUtilityByIdRes.description);
      setUtilActive(getUtilityByIdRes.details.active);
      console.log({
        getUtilityByIdRes,
        bool: getUtilityByIdRes.details.active,
      });

      const {
        data: { getNftsByIds: getNftsByIdsRes },
      } = await client.query({
        query: GetNftsByIdsDocument,
        variables: {
          nft_ids: getUtilityByIdRes.assignments.map((item) => item.nft_id),
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      setNftsByIds(getNftsByIdsRes);
      setNftsByIdsLoading(false);
      console.log({ getNftsByIdsRes });
    } catch (err) {
      setNftsByIdsLoading(false);
      toast({
        title: 'Error',
        variant: 'destructive',
        description: `${err}`,
      });
      console.log({ err });
    }
  };

  useEffect(() => {
    handleGetUtilityById();
  }, []);

  return (
    <div className="grid w-full max-w-[1100px] flex-col gap-[20px]">
      <div className="relative">
        <SectionDivider
          titles={[`Edit Utility`]}
          img={<UtilityIcon className="stroke-white" stroke="white" />}
          color="#ffbd39"
        />
        <div className="absolute bottom-0 right-0">
          <Button
            className="mb-[15px] mr-[10px] rounded-md border bg-primary px-[20px] py-[6px] font-normal text-card"
            onClick={() => {
              setIsEdit(!isEdit);
            }}
          >
            <Pencil1Icon className="mr-[10px] font-semibold" />
            Edit
          </Button>
          <Button
            className="mb-[15px] rounded-md border bg-[#C70000] px-[20px] py-[6px] font-normal text-card hover:bg-[#C70000]/90"
            onClick={() => {
              setConfirmModalOpen(true);
            }}
          >
            <TrashIcon className="mr-[10px] font-semibold" />
            Delete
          </Button>
        </div>
      </div>
      <div className="flex h-[300px] w-full gap-[25px]">
        <div className="flex h-[300px] w-[400px] min-w-[400px] items-center justify-center rounded-sm bg-black">
          {utilityData?.accessLink === null ? (
            <p className="text-card"> Video Not Avaiable </p>
          ) : (
            <>
              {utilityData?.accessLink ? (
                <Stream
                  key={utilityData?.accessLink}
                  className="w-[400px] max-w-[400px] "
                  controls
                  src={utilityData?.accessLink}
                />
              ) : (
                <div className="size-10 animate-spin rounded-full border-t-2 border-card" />
              )}
            </>
          )}
        </div>
        <div className="flex w-full flex-col gap-[10px]">
          <div className="flex w-full rounded-[5px] border bg-card p-[12px] shadow-sm">
            {utilityDataLoading ? (
              <div className="mr-[20px] h-[100px] w-[160px] min-w-[160px] animate-pulse rounded-sm object-cover" />
            ) : (
              <img
                className="mr-[20px] h-[100px] w-[160px] min-w-[160px] rounded-sm object-cover"
                src={utilityData.details.cover_image_cid}
              />
            )}
            {isEdit ? (
              <form
                className="flex w-full flex-col"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleEditUtil();
                }}
              >
                <AutoExpandingTextarea
                  className="my-[7px] w-full p-0 text-[22px] font-semibold"
                  value={utilityName}
                  initialHeight={'50px'}
                  onChange={(e) => {
                    setUtilityName(e);
                    console.log(e);
                  }}
                />
                <AutoExpandingTextarea
                  className="p-0 text-base"
                  value={utilityDescrption}
                  onChange={(e) => {
                    setUtilityDescrption(e);
                  }}
                />
                <Button loading={editLoading} className="ml-auto mt-[10px] w-[100px]">
                  Apply
                </Button>
              </form>
            ) : (
              <>
                {utilityDataLoading ? (
                  <div>
                    <div
                      className={cn('my-[10px] h-[27px] w-[100px] animate-pulse rounded-[6px]')}
                    />
                    <div className={cn('h-[24px] w-[180px] animate-pulse rounded-[6px]')} />
                  </div>
                ) : (
                  <div>
                    <p className={'my-[7px] text-[22px] font-semibold'}>{utilityData.name}</p>
                    <p className={'text-base'}>{utilityData.description}</p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <SectionDivider
        titles={[`Utility Status`]}
        img={<CheckCircledIcon stroke="white" className="size-[22px] stroke-white text-card" />}
        color="#ff6661"
      />

      <div className="my-[20px] flex">
        <p>Deactivate</p>
        <div className="relative mx-[20px]">
          <Switch
            key={`${utilActive}`}
            checked={utilActive}
            onCheckedChange={(bool) => {
              setUtilActive(bool);
              handleEditUtil(bool);
            }}
            className="right-0"
          />
        </div>
        <p>Activate</p>
      </div>
      <SectionDivider
        titles={[`Apply Utility to Assets`]}
        img={<NFTImg className="stroke-white" stroke="white" />}
        color="#9256ff"
      />
      <div className="flex gap-[10px]">
        <button
          className="flex h-[192px] w-[150px] items-center justify-center rounded-[10px] bg-card shadow-md"
          onClick={() => {
            setModalOpen(!modalOpen);
          }}
        >
          <Plus className="mr-[8px] size-[30px] stroke-primary" />
        </button>
        {nftsByIdsLoading
          ? [1, 1].map((item, index) => (
              <button
                key={index}
                className="overflow-hidden rounded-[10px] border-4 border-card bg-card shadow-md"
              >
                <div className="size-[150px] animate-pulse rounded-[5px] object-cover" />
                <div className="flex p-[7px]">
                  <div className="m-0 my-[2px] h-[18px] w-[100px] animate-pulse truncate rounded-[6px] text-left text-sm font-medium" />
                </div>
              </button>
            ))
          : nftsByIds?.map((item, index) => (
              <button
                key={item.id}
                className="overflow-hidden rounded-[10px] border-4 border-card bg-card shadow-md"
              >
                <img
                  className="size-[150px] rounded-[5px] object-cover"
                  src={item.media_ipfs_link}
                />
                <div className="flex p-[7px]">
                  <p className="m-0 truncate text-left text-sm font-medium">{item.name}</p>
                </div>
              </button>
            ))}
      </div>
      <SelectAsset
        open={modalOpen}
        setOpen={setModalOpen}
        title="Apply Utility to Assets"
        selectedAssets={selectedAssets}
        buttonLoading={assignUtilLoading}
        withButton
        selectMultiple
        onButtonClick={() => {
          handleAssignUtility();
        }}
        setSelectedAssets={(i) => {
          console.log(i);
          setSelectedAssets(i);
        }}
      />
      <ConfirmModal
        open={confirmModalOpen}
        onConfirm={() => {
          handleDeleteUtil();
        }}
        confirmLoading={deleteLoading}
        title="Confirm Delete"
        nameToType={utilityData.name}
        setOpen={() => {
          setConfirmModalOpen(!confirmModalOpen);
        }}
      />
    </div>
  );
};

export default UtilitiyView;

type NftByIdType = {
  id: string;
  media_ipfs_link: string;
  name: string;
};
