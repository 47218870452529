import * as jose from 'jose';
import { createJSONStorage, persist } from 'zustand/middleware';

import { lens } from '@dhmk/zustand-lens';

const initialReloadSliceState: ReloadSliceState = {
  myContentState: 0,
};

export const reloadSlice = lens<ReloadSlice>(
  (set, get): ReloadSlice => ({
    ...initialReloadSliceState,
    reloadMyContent: () => {
      set({ myContentState: get().myContentState + 1 });
    },
  }),
);

export interface ReloadSliceState {
  myContentState: number;
}
export interface ReloadSliceActions {
  reloadMyContent: () => void;
}
export type ReloadSlice = ReloadSliceState & ReloadSliceActions;
