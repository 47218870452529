import {
  GetAllNftsDocument,
  ListRealEstateDocument,
} from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { lens } from '@dhmk/zustand-lens';

const initialRealEstateSliceState: RealEstateSliceState = {
  realEstate: [],
  loadedPage: null,
  isLoading: false,
  isLoadingNext: false,
  isAllLoaded: false,
  initialLoad: true,
};

export const realEstateSlice = lens<RealEstateSlice>(
  (set, get): RealEstateSlice => ({
    ...initialRealEstateSliceState,
    dispatchSetIsLoading: (isLoading: RealEstateSliceState['isLoading']) => {
      set({
        isLoading,
      });
    },
    dispatchSetIsLoadingNext: (isLoadingNext: RealEstateSliceState['isLoadingNext']) => {
      set({
        isLoadingNext,
      });
    },
    dispatchSetInitialLoad: (initialLoad: RealEstateSliceState['initialLoad']) => {
      set({
        initialLoad,
      });
    },
    dispatchAddToAllRealEstate: ({
      realEstate,
      loadedPage,
      isAllLoaded,
      addToEnd = false,
    }: Pick<RealEstateSliceState, 'realEstate' | 'loadedPage' | 'isAllLoaded' | 'addToEnd'>) => {
      if (!realEstate || !Array.isArray(realEstate)) {
        throw Error('expecting realEstate to be an array');
      }
      set((state) => ({
        realEstate: addToEnd
          ? state.realEstate.concat(realEstate)
          : realEstate.concat(state.realEstate),
        loadedPage: loadedPage !== null ? loadedPage : get().loadedPage,
        isAllLoaded: isAllLoaded || get().isAllLoaded,
        isLoading: false,
        isLoadingNext: false,
      }));
    },
    dispatchUpdateRealEstate: (realEstate) => {
      console.log({ realEstate });
      let newRealEstate: Array<Record<any, any>> = get().realEstate.map((item) => {
        if (item.name === realEstate.name || realEstate.id === item.id) {
          return {
            ...item,
            ...realEstate,
            batch_id: realEstate.nft_id,
          };
        }
        return item;
      });
      const indexOfRealEstate = newRealEstate.findIndex(
        (item) => item.name === realEstate.name || realEstate.nft_id == item.batch_id,
      );
      if (indexOfRealEstate == -1) {
        newRealEstate = [{ ...realEstate, batch_id: realEstate.nft_id }, ...newRealEstate];
      }
      console.log({ newRealEstate });
      set({
        realEstate: newRealEstate,
      });
    },
    fetchRealEstate: async () => {
      const isAllLoaded = get().isAllLoaded;
      const loadedPage = get().loadedPage;
      const dispatchSetIsLoading = get().dispatchSetIsLoading;
      const dispatchSetIsLoadingNext = get().dispatchSetIsLoadingNext;
      const dispatchAddToAllRealEstate = get().dispatchAddToAllRealEstate;
      const dispatchSetInitialLoad = get().dispatchSetInitialLoad;

      if (isAllLoaded) {
        console.log('🦊 | fetchRealEstate | isAllLoaded so do not proceed');
      }
      try {
        const newPage = loadedPage === null ? 0 : loadedPage + 1;
        const batchSize = 15;
        console.log('🦊 | fetchRealEstate | newPage', newPage, 'batchSize', batchSize);
        dispatchSetIsLoading(true);
        if (newPage > 0) {
          dispatchSetIsLoadingNext(true);
        }
        const response = await client.query({
          query: ListRealEstateDocument,
          variables: {
            input: { active: false },
          },
          context: {
            clientName: ClientName.Marketplace,
          },
        });
        let listRealEstateRes;
        if (response.data && response.data.listRealEstate) {
          listRealEstateRes = response.data.listRealEstate;
          console.log({ listRealEstateRes });
        }
        const newIsAllLoaded = listRealEstateRes?.length < batchSize;
        // prettier-ignore
        console.log('🦊 | fetchNextFats | isAllLoaded', newIsAllLoaded, 'colls.length', listRealEstateRes?.length, 'newPage', newPage)
        dispatchSetInitialLoad(false);
        set((state) => ({
          loadedPage: newPage,
          isAllLoaded: newIsAllLoaded,
          realEstate: listRealEstateRes,
        }));
      } catch (getRealEstateErr) {
        console.log({ getRealEstateErr });
        dispatchSetIsLoading(false);
      }
    },
    deleteRealEstate: (batch_id) => {
      set({
        realEstate: get().realEstate.filter((item) => item.batch_id !== batch_id),
      });
    },
  }),
);

export type RealEstate = any; // TODO: revisit

export interface RealEstateSliceState {
  loadedPage: null | number;
  realEstate: RealEstate[]; // all
  isLoading: boolean;
  isAllLoaded: boolean;
  isLoadingNext: boolean;
  initialLoad: boolean;
  addToEnd?: boolean;
}

export interface RealEstateSliceActions {
  dispatchSetIsLoading: (isLoading: RealEstateSliceState['isLoading']) => void;
  dispatchSetIsLoadingNext: (isLoadingNext: RealEstateSliceState['isLoadingNext']) => void;
  dispatchSetInitialLoad: (initialLoad: RealEstateSliceState['initialLoad']) => void;
  dispatchAddToAllRealEstate: ({
    realEstate,
    loadedPage,
    isAllLoaded,
    addToEnd,
  }: Pick<RealEstateSliceState, 'realEstate' | 'loadedPage' | 'addToEnd' | 'isAllLoaded'>) => void;
  dispatchUpdateRealEstate: (RealEstate) => void;
  fetchRealEstate: () => Promise<void>;
  deleteRealEstate: (arg) => void;
}

export type RealEstateSlice = RealEstateSliceState & RealEstateSliceActions;
