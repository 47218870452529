import { useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

export function SelectorDropdown({
  control,
  name = '',
  options = [{ label: '', value: '' }],
  selected = '',
  placeholder = '',
  label = '',
  underText = '',
  isError = '',
  required = false,
  callOnBlur = (event) => {
    console.log('INPUT BLUR', event);
  },
  onSelect = (event) => {
    console.log('INPUT SELECT', event);
  },
  className = '',
  defaultValue = '',
  disabled = false,
  ...props
}: any) {
  return (
    <div className="relative flex w-full items-center">
      {control ? (
        <Controller
          name={name}
          control={control}
          rules={{ required }}
          render={({ field }) => (
            <Select
              defaultValue={defaultValue || field.value}
              onValueChange={(value) => {
                console.log({ value });
                field.onChange(value);
                callOnBlur({ [name]: value });
              }}
              disabled={disabled}
            >
              <SelectTrigger
                label={label}
                className={`h-[48px] w-full ${className} ${isError ? 'border-red-500' : ''}`}
                disabled={disabled}
              >
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
              <SelectContent position="popper">
                {options.map((item, index) => {
                  if (!item.value) {
                    return (
                      <div className="flex cursor-not-allowed py-[10px] pl-[32px]" key={`${index}`}>
                        <div className="mr-[15px] size-[15px] animate-spin rounded-full border-t-2 border-primary" />
                        <p className="text-sm">{item.label}</p>
                        <p className="ml-[10px] text-xs opacity-80">(Available after load)</p>
                      </div>
                    );
                  } else {
                    return (
                      <SelectItem key={`${index}`} value={item.value}>
                        {item.label}
                      </SelectItem>
                    );
                  }
                })}
              </SelectContent>
            </Select>
          )}
        />
      ) : (
        <Select
          defaultValue={defaultValue}
          value={selected}
          onValueChange={(value) => {
            console.log({ value });
            callOnBlur({ [name]: value });
            onSelect(value);
          }}
          disabled={disabled}
        >
          <SelectTrigger
            label={label}
            className={`w-full ${className} ${isError ? 'border-red-500' : ''}`}
            disabled={disabled}
          >
            <SelectValue placeholder={placeholder} />
          </SelectTrigger>
          <SelectContent position="popper">
            {options.map((item, index) => (
              <SelectItem key={`${index}`} value={item.value}>
                {item.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      )}
      <p
        className={`absolute ml-[5px] mt-[1px] text-xs opacity-80 ${isError ? 'text-red-500' : ''}`}
      >
        {underText}
      </p>
    </div>
  );
}

export default SelectorDropdown;
