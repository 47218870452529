import React, { useEffect, useRef } from 'react';

type TextareaProps = {
  className: string;
  value: string;
  onChange?: (e) => void;
  initialHeight?: string;
};

const AutoExpandingTextarea = ({
  className = '',
  value = '',
  onChange,
  initialHeight,
}: TextareaProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    adjustHeight();
  }, [initialHeight, value]);

  const adjustHeight = () => {
    if (textareaRef.current) {
      if (initialHeight) {
        textareaRef.current.style.height = initialHeight;
      } else {
        textareaRef.current.style.height = 'auto';
        const newHeight = textareaRef.current.scrollHeight + 'px';
        textareaRef.current.style.height = newHeight;
      }

      const currentHeight = textareaRef.current.offsetHeight;
      const requiredHeight = textareaRef.current.scrollHeight;
      if (requiredHeight > currentHeight) {
        textareaRef.current.style.height = requiredHeight + 'px';
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.(event.target.value);
    adjustHeight();
  };

  return (
    <textarea
      ref={textareaRef}
      className={`focus:shadow-outline resize-none overflow-hidden rounded border p-2 focus:outline-none ${className}`}
      value={value}
      onChange={handleChange}
    />
  );
};

export default AutoExpandingTextarea;
