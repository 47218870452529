import { GetFolderDocument } from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { lens } from '@dhmk/zustand-lens';

const initialFilesSliceState: FilesSliceState = {
  files: [],
  folders: [],
  isAllLoaded: false,
  currentPage: null,
};

export const filesSlice = lens<FilesSlice>(
  (set, get): FilesSlice => ({
    ...initialFilesSliceState,
    fetchFiles: async (reload) => {
      const loadedPage = get().currentPage;
      const batchSize = 20;
      const newPage = reload ? 0 : loadedPage === null ? 0 : loadedPage + 1;

      const {
        data: { getFolder: getFolderRes },
      } = await client.query({
        query: GetFolderDocument,
        variables: { input: { page_number: newPage, batch_size: batchSize, path: '/' } },
        context: { clientName: ClientName.Marketplace },
      });
      console.log({ getFolderRes });
      if (getFolderRes.files < batchSize) {
        set({
          isAllLoaded: false,
        });
      }
      const newIsAllLoaded = getFolderRes?.files?.length < batchSize;
      console.log({ getFolderRes, batchSize });
      set({
        files: [...get().files, ...getFolderRes.files],
        folders: getFolderRes.folders.map((item) => item.path).filter((item) => item !== '/'),
        isAllLoaded: newIsAllLoaded,
        currentPage: newPage,
      });
    },
    updateFile: (name, file) => {
      const fileToUpdate = get().files.findIndex((x) => x.name == name);
      console.log({ name, file, fileToUpdate });
      const files_ = [...get().files];
      if (fileToUpdate == -1) {
        files_.shift();
        files_.unshift(file);
      } else {
        files_.splice(fileToUpdate, 1, file);
      }
      set({ files: files_ });
    },
    deleteFiles: (nftIds) => {
      set({
        files: [...get().files].filter((item) => !nftIds.includes(item.uuid)),
      });
    },
    dispatchSetFiles: (files) => {
      set({
        files,
      });
    },
    dispatchSetFolders: (folders) => {
      set({
        folders,
      });
    },
    dispatchSetCurrentPage: (num) => {
      set({
        currentPage: num,
      });
    },
  }),
);

type File = {
  s3_link: string | null;
  mime_type: string;
  name: string;
  uuid: string;
  uid: string;
  created_at: string;
  cloudflare_uid: string;
  accessLink: string | null;
  submarine: boolean;
  thumbnail: string;
  // "__typename":"Upload"
};

export interface FilesSliceState {
  files: File[];
  folders: [];
  isAllLoaded: boolean;
  currentPage: number | null;
}

export interface FilesSliceActions {
  fetchFiles: (reload?: boolean) => void;
  updateFile: (name: string, file: File) => void;
  deleteFiles: (arg) => void;
  dispatchSetFiles: (arg) => void;
  dispatchSetFolders: (arg) => void;
  dispatchSetCurrentPage: (num: number) => void;
}

export type FilesSlice = FilesSliceState & FilesSliceActions;
