import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import QRCode from 'qrcode.react';
import ClampLines from 'react-clamp-lines';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import spacetime from 'spacetime';

import Bathroom from '@/assets/bathroom.svg?react';
import Calendar from '@/assets/calendar.svg?react';
import Car from '@/assets/car.svg?react';
import Dishwasher from '@/assets/dishwasher.svg?react';
import Dollar from '@/assets/Dollar2.svg?react';
import House from '@/assets/house.svg?react';
import Residence from '@/assets/residence.svg?react';
import Square3d from '@/assets/square3d.svg?react';
import Star from '@/assets/star.svg?react';
import Vertical from '@/assets/vertical.svg?react';
import ConfirmModal from '@/components/modals/confirmation-modal';
import { Button } from '@/components/ui/button';
import CopyInput from '@/components/ui/copy-input';
import { Switch } from '@/components/ui/switch.tsx';
import {
  DeleteRealEstateDocument,
  EditRealEstateDocument,
  GetRealEstateDocument,
  RealEstate,
} from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import useBoundStore from '@/store';
import { DownloadIcon, Pencil2Icon, TrashIcon } from '@radix-ui/react-icons';

const RealEstateView = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [realEstate, setRealEstate] = useState<RealEstate | null>(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [deleteLoading, setDeleteloading] = useState<boolean>(false);
  const [locked, setLocked] = useState<boolean>(false);

  const { id } = useParams();
  const selectedProject = useBoundStore((state) => state.projectSlice.selectedProject);

  const reactURL = `${selectedProject?.react_urls}/view-real-estate/${id}`;

  const propertyDetails = useMemo(() => {
    const generalDetails = [
      {
        label: 'Property Size',
        value: loading ? '' : `${realEstate?.square_footage?.toLocaleString()} sqft`,
        icon: <House stroke="black" />,
      },
      {
        label: 'Year Built',
        value:
          realEstate?.date_built && !loading
            ? spacetime(parseInt(realEstate?.date_built))?.year()
            : '',
        icon: <Calendar stroke="black" />,
      },
      { label: 'Property Type', value: realEstate?.type, icon: <Star stroke="black" /> },
    ];
    let mergedDetails: any = [];
    if (realEstate?.type == 'Commercial') {
      const commercialFeatures = [
        {
          label: 'Acres',
          value: realEstate?.acres?.toLocaleString(),
          icon: <Square3d stroke="black" />,
        },
        {
          label: 'Average Sqft',
          value: realEstate?.avg_square_footage?.toLocaleString(),
          icon: <House stroke="black" />,
        },
        {
          label: 'Average Rent',
          value: loading ? '' : `$${realEstate?.avg_rent?.toLocaleString()}`,
          icon: <Dollar style={{ height: 24, width: 24 }} stroke="black" />,
        },
        {
          label: 'Floors in building',
          value: loading ? '' : `${realEstate?.num_building_floors?.toLocaleString()} Floors`,
          icon: <Vertical stroke="black" />,
        },
      ];
      mergedDetails = [...generalDetails, ...commercialFeatures];
    } else {
      const commercialFeatures = [
        { label: 'Bedrooms', value: realEstate?.num_bedrooms, icon: <House stroke="black" /> },
        { label: 'Bathrooms', value: realEstate?.num_bathrooms, icon: <Bathroom stroke="black" /> },
        { label: 'Levels', value: realEstate?.floor_number, icon: <Vertical stroke="black" /> },
        {
          label: 'Garage',
          value: loading ? '' : `${realEstate?.num_garage_cars} Car`,
          icon: <Car stroke="black" />,
        },
        {
          label: 'Appliances',
          value: loading ? '' : realEstate?.appliances ? 'Included' : 'Excluded',
          icon: <Dishwasher stroke="black" />,
        },
      ];
      mergedDetails = [...generalDetails, ...commercialFeatures];
    }
    return mergedDetails;
  }, [realEstate, loading]);

  const assetDetailsValues = [
    { key: 'Price', value: `$${realEstate?.price?.toLocaleString()}` },
    { key: 'Target IRR', value: `${realEstate?.target_irr?.toFixed(2).toLocaleString()}%` },
    {
      key: 'Target Average Cash on cash',
      value: `${realEstate?.target_avg?.toFixed(2).toLocaleString()}%`,
    },
  ];

  const handleGetRealEstate = async () => {
    try {
      const {
        data: { getRealEstate: getRealEstateRes },
      } = await client.query({
        query: GetRealEstateDocument,
        variables: { id: parseInt(id || '0') },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ getRealEstateRes });
      setLocked(getRealEstateRes.locked);
      setRealEstate(getRealEstateRes);
      setLoading(false);
    } catch (err) {
      console.log({ err });
      setLoading(false);
    }
  };

  const handleDeleteRealEstate = async () => {
    try {
      setDeleteloading(true);
      const {
        data: { deleteRealEstate: deleteRealEstateRes },
      } = await client.mutate({
        mutation: DeleteRealEstateDocument,
        variables: {
          id: parseInt(id || '0'),
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ deleteRealEstateRes });
      setDeleteloading(false);
      // deleteAsset(`${id}`);
      navigate('/all-real-estate');
    } catch (err) {
      console.log({ err });
      setDeleteloading(false);
    }
  };

  const handleEditRealEstate = async (bool) => {
    try {
      const {
        data: { editRealEstate: editRealEstateRes },
      } = await client.mutate({
        mutation: EditRealEstateDocument,
        variables: {
          input: {
            locked: bool,
            id: parseInt(`${id}`),
          },
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ editRealEstateRes });
      setLocked(!locked);
    } catch (err) {
      console.log({ err });
    }
  };

  useEffect(() => {
    handleGetRealEstate();
  }, []);

  return (
    <div className="grid w-full max-w-[1100px] flex-col gap-[25px]">
      <div className="rounded-[5px] bg-card p-[30px]">
        <div className="relative flex w-full">
          {loading ? (
            <div className={`mr-[30px] min-h-[100px]  min-w-[150px] animate-pulse rounded-[5px]`} />
          ) : (
            <img
              className={`mr-[30px] max-w-[150px] object-contain object-top`}
              src={realEstate?.media_uploads[0].s3_link}
            />
          )}
          <div className="flex w-full flex-col gap-[5px]">
            {loading ? (
              <>
                <div className="h-[32px] w-[200px] animate-pulse rounded-[6px]" />
                <div className="h-[24px] w-[100px] animate-pulse rounded-[6px]" />
                <div className="mt-[10px] h-[24px] w-[350px] animate-pulse rounded-[6px]" />
              </>
            ) : (
              <>
                <h3 className="w-full pr-[260px] text-2xl font-semibold">{realEstate?.name}</h3>
                <h5 className="flex opacity-50">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/212a598d304505ce8e42af365f6dc4553fdededb5b9209e72c94beff98482efe?"
                    className="mr-2 aspect-square w-[18px] shrink-0"
                  />
                  {realEstate?.address}
                </h5>
                {realEstate?.description && (
                  <ClampLines
                    id="really-unique-id"
                    text={realEstate?.description}
                    lines={3}
                    ellipsis="..."
                    moreText="Show More"
                    lessText="Show Less"
                    className="mt-[10px]"
                    innerElement="p"
                  />
                )}
              </>
            )}
          </div>
          <div className="absolute right-0 top-0 ml-[auto] flex">
            <Button
              className="mr-[10px] flex h-auto w-[110px] items-center bg-[#C70000] py-[10px] font-medium text-white hover:bg-[#C70000]/90"
              loading={deleteLoading}
              onClick={() => {
                setConfirmModalOpen(true);
              }}
            >
              <TrashIcon className="mr-2 w-[15px] stroke-white" />
              Delete
            </Button>
            <Button
              className="flex h-auto items-center font-medium text-white"
              onClick={() => {
                navigate(`/edit-real-estate/${id}`);
              }}
            >
              <Pencil2Icon className="mr-3 stroke-white" />
              Edit Asset
            </Button>
          </div>
        </div>
      </div>
      <div className="flex rounded-[5px] bg-card p-[30px]">
        {propertyDetails.map(({ label, value, icon }) => (
          <div className="flex w-full flex-col items-center justify-center capitalize" key={label}>
            {icon}
            <p
              className={
                loading
                  ? 'mt-[16px] h-[24px] w-[30px] animate-pulse rounded-[6px]'
                  : 'mt-[12px] w-full whitespace-nowrap text-center text-lg font-medium '
              }
            >
              {value}
            </p>
            <p className="mt-2 w-full text-center text-sm text-neutral-500">{label}</p>
          </div>
        ))}
      </div>
      <div className="flex gap-[25px]">
        <div className="relative flex w-full flex-col gap-[20px] rounded-[5px] bg-card p-[30px]">
          <h2 className="text-center text-xl font-semibold">Share Property</h2>
          <div className="flex justify-center">
            <div className="w border-text-faded rounded-[10px] border-2 p-[10px]">
              <QRCode id="NftQRCode" value={reactURL} size={110} />
            </div>
          </div>
          <CopyInput value={reactURL} />
          <Button className="absolute bottom-0 right-0 mb-[98px] mr-[30px]" onClick={() => {}}>
            <DownloadIcon className="size-4 stroke-card" />
          </Button>
        </div>
        <div className="flex w-full flex-col gap-[15px] rounded-[5px] bg-card p-[30px]">
          {assetDetailsValues.map(({ key, value }, index) => (
            <div key={`details_value_${index}`} className="flex justify-between">
              <p className="opacity-50">{key}</p>
              <p
                className={
                  loading ? 'h-[24px] w-[100px] animate-pulse rounded-[6px]' : 'font-semibold'
                }
              >
                {loading ? '' : value}
              </p>
            </div>
          ))}
          <div key={`details_value_0`} className="relative mt-[20px] flex justify-between">
            <p className="opacity-50">Locked</p>
            {loading ? (
              <div className="h-[24px] w-[52px] animate-pulse rounded-full" />
            ) : (
              <Switch
                key={`${locked}`}
                checked={locked}
                onCheckedChange={(bool) => {
                  // field.onChange(bool);
                  // handleEditNft({ [item?.state]: !field.value });
                  handleEditRealEstate(bool);
                }}
                className="absolute right-0"
              />
            )}
          </div>
        </div>
        {/* <div className="flex w-full flex-col gap-[20px] border-t-2 pt-[15px]"></div> */}
      </div>
      <ConfirmModal
        open={confirmModalOpen}
        onConfirm={() => {
          handleDeleteRealEstate();
        }}
        confirmLoading={deleteLoading}
        title="Confirm Delete"
        nameToType={realEstate?.name}
        setOpen={() => {
          setConfirmModalOpen(!confirmModalOpen);
        }}
      />
    </div>
  );
};

export default RealEstateView;
