import React, { useState } from 'react';

import {
  closestCenter,
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, rectSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const initialItems = [
  { batch_id: '1', content: 'Card 1' },
  { batch_id: '2', content: 'Card 2' },
  { batch_id: '3', content: 'Card 3' },
  { batch_id: '4', content: 'Card 4' },
  { batch_id: '5', content: 'Card 5' },
  { batch_id: '6', content: 'Card 6' },
  { batch_id: '7', content: 'Card 7' },
  { batch_id: '8', content: 'Card 8' },
  { batch_id: '9', content: 'Card 9' },
  { batch_id: '10', content: 'Card 10' },
  { batch_id: '11', content: 'Card 11' },
  { batch_id: '12', content: 'Card 12' },
];

const SortableItem = ({ id, content }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id, // Pass batch_id as id here
  });

  const transformStyle = CSS.Transform.toString(transform);

  return (
    <div
      ref={setNodeRef}
      style={{ transform: transformStyle, transition }}
      className={`w-[100px] rounded border border-gray-300 bg-gray-200 p-[16px] ${
        isDragging ? 'bg-gray-300 opacity-80' : 'opacity-100'
      }`}
      {...attributes}
      {...listeners}
    >
      {content}
    </div>
  );
};

const App = () => {
  const [items, setItems] = useState(initialItems);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.batch_id === active.id);
      const newIndex = items.findIndex((item) => item.batch_id === over.id);

      setItems((items) => arrayMove(items, oldIndex, newIndex));
    }
  };

  return (
    <div className="max-w-[464px] border border-red-500 p-[16px]">
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={items.map((item) => item.batch_id)} strategy={rectSortingStrategy}>
          <div className="flex flex-row flex-wrap gap-[10px]">
            {items.map((item) => (
              <SortableItem key={item.batch_id} id={item.batch_id} content={item.content} />
            ))}
          </div>
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default App;
