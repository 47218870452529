import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import SiteFooter from '@/components/ui/site-footer';
import { cn } from '@/lib/utils';

interface SelectFooterProps {
  open: boolean;
  setOpen: Function;
  selectedFooterTheme: number;
  setSelectedFooterTheme: Function;
  background: string;
  logoUrl: string;
  textColor: string;
  terms: string;
  block1: [string, string, string];
  block2: [string, string, string];
  socials: {
    instagram: string;
    youtube: string;
    discord: string;
    facebook: string;
    tiktok: string;
    twitter: string;
    telegram: string;
  };
}
function SelectFooter({
  open,
  setOpen = () => {},
  selectedFooterTheme = 0,
  setSelectedFooterTheme = () => {},
  background = '',
  logoUrl = '',
  textColor = '',
  terms = 'string',
  block1 = ['', '', ''],
  block2 = ['', '', ''],
  socials = {
    instagram: '',
    youtube: '',
    discord: '',
    facebook: '',
    tiktok: '',
    twitter: '',
    telegram: '',
  },
}: SelectFooterProps) {
  const { instagram, youtube, discord, facebook, tiktok, twitter, telegram } = socials;

  return (
    <Dialog
      open={open}
      onOpenChange={(open_) => {
        setOpen();
      }}
    >
      <DialogContent
        className="w-[1000px] max-w-[90%] rounded-[16px]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogHeader>
          <DialogTitle className="text-[22px] font-medium">Footer themes</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col">
          <label className="mb-[5px] opacity-80">Footer theme 1</label>
          <button
            className={cn(
              'mb-[20px] rounded-[10px] border-2 border-[#d3d3d3] p-[10px]',
              selectedFooterTheme == 0 ? 'border-primary' : '',
            )}
            onClick={() => {
              setSelectedFooterTheme(0);
            }}
          >
            <SiteFooter
              theme1={false}
              open
              background={background}
              textColor={textColor}
              logoUrl={logoUrl}
              block1={block1}
              block2={block2}
              terms={terms}
              socials={{
                instagram,
                youtube,
                discord,
                facebook,
                tiktok,
                twitter,
                telegram,
              }}
            />
          </button>
          <label className="mb-[5px] opacity-80">Footer theme 2</label>
          <button
            className={cn(
              'rounded-[10px] border-2 border-[#d3d3d3] p-[10px]',
              selectedFooterTheme == 1 ? 'border-primary' : '',
            )}
            onClick={() => {
              setSelectedFooterTheme(1);
            }}
          >
            <SiteFooter
              open
              background={background}
              textColor={textColor}
              logoUrl={logoUrl}
              block1={block1}
              block2={block2}
              terms={terms}
              socials={{
                instagram,
                youtube,
                discord,
                facebook,
                tiktok,
                twitter,
                telegram,
              }}
            />
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default SelectFooter;
