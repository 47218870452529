/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { BackNext } from '@/components/ui/back-next';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';

const GeneralInfo = () => {
  const [defaultValues, setDefaultValues] = useState<any>({
    percentSlider: [0],
  });

  const methods = useForm({ defaultValues });

  const MOCKData = [
    {
      title: '',
      sectionClassName: '',
      sectionInputsClassName: '',
      inputs: [
        {
          type: 'input',
          label: 'Official Company Name',
          state: 'companyName',
          placeholder: 'Enter your official company name',
        },
        {
          type: 'checkboxColumn',
          label: 'What is your legal status?',
          state: 'legalStatus',
          options: [
            { label: 'Corporation', value: 'Corporation' },
            { label: 'S Corporation', value: 'S Corporation' },
            { label: 'LLC', value: 'LLC' },
            { label: 'Public Benefit Corporation', value: 'Public Benefit Corporation' },
            { label: 'Sole Proprietorship', value: 'Sole Proprietorship' },
            { label: 'Partnership', value: 'Partnership' },
            { label: '501(c)(3) Non-Profit', value: '501(c)(3) Non-Profit' },
          ],
        },
        {
          type: 'input',
          label: 'Which state are you organized/incorporated?',
          state: 'stateIncorporated',
          placeholder: 'Enter the state of incorporation',
        },
        {
          type: 'calendar',
          label: 'What is the date of organization/incorporation?',
          state: 'incorporationDate',
          placeholder: 'MM/DD/YYYY',
        },
        {
          type: 'input',
          label: 'What is your tax ID?',
          state: 'taxId',
          placeholder: 'Enter your tax ID',
        },
        {
          type: 'location',
          label: 'Company address',
          state: 'companyAddress',
          placeholder: 'Enter your company address',
        },
        {
          type: 'phone',
          label: 'Company phone number',
          state: 'companyPhoneNumber',
          placeholder: 'Enter your company phone number',
        },
        {
          type: 'input',
          label: 'Company website',
          state: 'companyWebsite',
          placeholder: 'Enter your company website URL',
        },
      ],
    },
  ];

  useEffect(() => {
    const scrollableElement = document.getElementById('authLayoutContainerId');
    if (scrollableElement) {
      scrollableElement.scrollTo(0, 0);
    }
  }, []);

  return (
    <div className="relative mx-[20px] flex w-full max-w-[800px] flex-col items-center">
      <h1 className="mb-[28px] w-full text-left text-[2rem] font-medium sm:mb-[32px]  sm:text-[2rem]">
        Basic Company Information
      </h1>
      <WrappedInputSection methods={methods} data={MOCKData} />
      <div className="mt-[30px] w-full">
        <BackNext className="mt-[20px]" numOfLevels={1} finalBtnText={'Save & continue'} />
      </div>
    </div>
  );
};

export default GeneralInfo;
