import { useEffect } from 'react';
import { differenceInMilliseconds } from 'date-fns';

import config from '@/config';
import { refetchAdminToken } from '@/lib/helpers/auth';
import useBoundStore from '@/store';

function useRenewAdminTokenSetup(): void {
  const tokenExpiresAt = useBoundStore((state) => state.authSlice.tokenExpiresAt);
  useEffect(() => {
    const refreshThreshold = config.tokenRefreshThreshold * 60 * 1000; // 1 minutes before expiration
    let timeout;

    const setupTokenRenewal = () => {
      if (tokenExpiresAt === null) return;

      const expirationTime = new Date(tokenExpiresAt * 1000);
      const timeUntilExpiry = differenceInMilliseconds(expirationTime, new Date());

      // Set timeout to refresh the token before it expires
      timeout = setTimeout(() => {
        refetchAdminToken();
      }, timeUntilExpiry - refreshThreshold);
    };

    setupTokenRenewal();

    // Clear timeout when the component unmounts or tokenExpiresAt changes
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [tokenExpiresAt]);
}

export default useRenewAdminTokenSetup;
