import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';
import { CreateCompanyDocument } from '@/lib/__generated__/marketplace/graphql';
import { updateCompany } from '@/lib/api/mutation';
import { client, ClientName } from '@/lib/apollo';
import useBoundStore from '@/store';

import { statesInAmerica } from '../../const';

export function OnboardingModal({ open = false, setOpen = (boolean) => {} }) {
  const { companies } = useBoundStore((state) => state.companySlice);
  const methods = useForm({ defaultValues: {} });
  const { handleSubmit } = methods;

  const [onboardLevel, setOnboardLevel] = useState(0);

  const handleCancel = () => {
    if (onboardLevel == 1) {
      setOnboardLevel(0);
    } else {
      setOpen(false);
    }
  };

  const onSubmit = async (data1) => {
    try {
      console.log({ data1 });
      if (onboardLevel == 0) {
        setOnboardLevel(onboardLevel + 1);
      } else {
        setTimeout(() => {
          console.log('SUBMITTED');
        }, 500);
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const handleUpdateCompany = async (input) => {
    try {
      console.log('BLUR', input);
      if (input) {
        let ceo = false;
        if (['name', 'email', 'phone'].includes(Object.keys(input)[0])) {
          ceo = true;
        }
        const updateCompanyRes = await updateCompany(
          parseInt(companies[0].id),
          ceo ? { ceo: input } : input,
        );
        console.log({ updateCompanyRes });
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const handleCreateCompany = async () => {
    try {
      const {
        data: { createCompany: createCompanyRes },
      } = await client.query({
        query: CreateCompanyDocument,
        variables: {
          input: {
            website: null,
            tax_id: null,
            state_of_organization: null,
            phone: null,
            logo: null,
            active: null,
            address: null,
            ceo_officer_id: null,
            date_of_organization: null,
            legal_name: null,
            legal_status: null,
            ceo: {
              phone: null,
              name: null,
              email: null,
            },
          },
        },
        context: {
          clientName: ClientName.Dashboard,
        },
      });
      console.log({ createCompanyRes });
    } catch (err) {
      console.log({ err });
    }
  };

  const titles = ['Set up a business', 'Company Information'];

  const OnboardingData1 = [
    {
      callOnBlur: handleUpdateCompany,
      inputs: [
        {
          type: 'input',
          label: 'Company legal name',
          state: 'legal_name',
          placeholder: 'Enter company name',
          // requiredForNextStep: true,
        },
        {
          type: 'checkboxColumn',
          label: 'Type of Organization',
          state: 'legal_status',
          placeholder: 'Select website theme',
          // requiredForNextStep: true,
          options: [
            { label: 'Corporation', value: 'Corporation' },
            { label: 'S Corporation', value: 'S Corporation' },
            { label: 'LLC', value: 'LLC' },
            { label: 'Public Benefit Corporation', value: 'Public Benefit Corporation' },
            { label: 'Sole Proprietorship', value: 'Sole Proprietorship' },
            { label: 'Partnership', value: 'Partnership' },
            { label: '501(c)(3) Non-Profit', value: '501(c)(3) Non-Profit' },
          ],
        },
        {
          type: 'input',
          label: 'Company website',
          state: 'website',
          placeholder: 'lorem@ipsum.com',
          // requiredForNextStep: true,
        },
        {
          type: 'imageUpload',
          label: 'Company logo',
          state: 'logo',
          smallImg: true,
          // requiredForNextStep: true,
        },
        {
          type: 'input',
          label: 'CEO/Managing Partners Name',
          state: 'name',
          placeholder: 'Jon Doe',
          // requiredForNextStep: true,
        },
        {
          type: 'input',
          label: 'CEO/Managing Partners email',
          state: 'email',
          placeholder: 'lorem@ipsum.com',
          // requiredForNextStep: true,
        },
        {
          type: 'phone',
          label: 'CEO/Managing Partners phone number',
          state: 'phone',
          placeholder: '917-444-4444',
          // requiredForNextStep: true,
        },
      ],
    },
  ];

  const OnboardingData2 = [
    {
      callOnBlur: handleUpdateCompany,
      inputs: [
        {
          type: 'dropdown',
          label: 'Which state are you organized/incorporated?',
          state: 'state_of_organization',
          placeholder: 'Select state',
          options: statesInAmerica,
          // requiredForNextStep: true,
        },
        {
          type: 'input',
          label: 'Company address',
          state: 'address',
          placeholder: '123 Main St, Anytown, USA',
          // requiredForNextStep: true,
        },
        {
          type: 'calendar',
          label: 'What is the date of organization/incorporation?',
          state: 'date_of_organization',
          placeholder: 'Chainstarters',
          // requiredForNextStep: true,
        },
        {
          type: 'input',
          label: 'What is your tax ID?',
          state: 'tax_id',
          // requiredForNextStep: true,
        },
      ],
    },
  ];

  useEffect(() => {
    if (companies?.length > 0) {
      methods.reset({
        ...companies[0],
        name: companies[0].officers[0].full_legal_name,
        phone: companies[0].officers[0].cell_phone,
        email: companies[0].officers[0].email,
      });
    }
  }, [companies]);

  return (
    <Dialog
      open={open}
      onOpenChange={(open_) => {
        if (!open_) handleCancel();
      }}
    >
      <DialogContent
        className="rounded-[16px] sm:max-w-[800px]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogHeader>
          <DialogTitle className="mb-[12px] text-center text-lg">
            {titles[onboardLevel]}
          </DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          {onboardLevel == 0 && <WrappedInputSection methods={methods} data={OnboardingData1} />}

          {onboardLevel == 1 && <WrappedInputSection methods={methods} data={OnboardingData2} />}

          <DialogFooter className="mt-[22px]">
            {/* QUESTION: why are we not using Button for this, cancel button? */}
            <button
              className="mt-2 h-auto w-[100%] rounded-sm bg-[#F8F8F8] py-[16px] text-base text-primary hover:bg-[#F8F8F8] sm:mt-auto sm:w-[50%]"
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <Button
              className="h-auto w-[100%] rounded-sm py-[16px] text-base sm:w-[50%]"
              type="submit"
            >
              Save & continue
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default OnboardingModal;
