import { useState } from 'react';

import { Dialog, DialogContent } from '@/components/ui/dialog';
import { cn } from '@/lib/utils';
import { Stream } from '@cloudflare/stream-react';

function ViewFileModal({ open = true, setOpen = (bool) => {}, file }) {
  const [loadingVideo, setLoadingVideo] = useState(true);

  const handleRenderComponent = () => {
    if (file?.mime_type?.includes('image')) {
      return <img src={file.s3_link} />;
    } else if (file?.mime_type?.includes('video')) {
      return (
        <div className="relative min-h-[250px] min-w-[250px] ">
          <Stream
            className={cn('w-[600px] max-w-[600px]', loadingVideo ? 'hidden' : 'block')}
            controls
            src={file?.cloudflare_uid}
            onLoadedData={() => {
              setLoadingVideo(false);
            }}
          />
          {loadingVideo && (
            <div className="flex h-full min-h-[250px] items-center justify-center">
              <div className="m-auto size-10 animate-spin rounded-full border-t-2 border-primary" />
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open_) => {
        setOpen(!open);
        setLoadingVideo(true);
      }}
    >
      <DialogContent
        className="w-auto max-w-[90%] rounded-[16px] p-[0px]"
        onClick={(e) => {
          e.stopPropagation();
        }}
        noPadding
      >
        <div className="p-0">{handleRenderComponent()}</div>
      </DialogContent>
    </Dialog>
  );
}

export default ViewFileModal;
