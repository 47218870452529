import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import File from '@/assets/File.svg?react';
import UploadImg from '@/assets/UploadImg.svg?react';
import AssignFolder from '@/components/modals/assign-folder';
import ViewFileModal from '@/components/modals/view-file-modal';
import { Dropzone } from '@/components/ui/dropzone';
import FileCardGroup from '@/components/ui/file-card-group';
import FolderCardsGroup from '@/components/ui/folder-cards-group';
import { useToast } from '@/components/ui/use-toast';
import VideoUpload from '@/components/ui/video-upload';
import { CreateFolderDocument } from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { cn } from '@/lib/utils';
import useBoundStore from '@/store';
import { ImageIcon, VideoIcon } from '@radix-ui/react-icons';

import SectionDivider from '../ui/section-divider';

const MyContent: FunctionComponent = () => {
  const [imagesSelected, setImagesSelected] = useState(true);
  const [selectedFilesObj, setSelectedFilesObj] = useState({});
  const [newFolderInput, setNewFolderInput] = useState('');
  const [currentPageNum, setCurrentPageNum] = useState(0);
  const [currentPath, setCurrentPath] = useState('');

  const [moveFolders, setMoveFolders] = useState(false);
  const [foldersLoading, setFoldersLoading] = useState(false);
  const [filesLoading, setFilesLoading] = useState(false);
  const [selectFiles, setSelectFiles] = useState(false);
  const [createFolderLoading, setCreateFolderLoading] = useState(false);
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [fileForModal, setFileForModal] = useState({});

  const myContentState = useBoundStore((state) => state.reloadSlice.myContentState);
  const {
    fetchFiles,
    dispatchSetFiles,
    deleteFiles,
    isAllLoaded,
    files,
    dispatchSetFolders,
    folders,
  } = useBoundStore((state) => state.filesSlice);

  const navigate = useNavigate();
  const { toast } = useToast();

  const handleCreateFolder = async (otherPath = '', otherFunction = () => {}) => {
    try {
      setCreateFolderLoading(true);
      const {
        data: { createFolder: createFolderRes },
      } = await client.mutate({
        mutation: CreateFolderDocument,
        variables: { input: { path: otherPath.length > 0 ? otherPath : `/${newFolderInput}` } },
        context: { clientName: ClientName.Marketplace },
      });
      console.log({ createFolderRes });
      setCreateFolderLoading(false);
      if (otherPath.length == 0) {
        dispatchSetFolders([`/${newFolderInput}`, ...folders]);
        setNewFolderInput('');
      } else {
        otherFunction();
      }
    } catch (err: any) {
      console.log({ err });
      setCreateFolderLoading(false);
      toast({
        title: 'Error',
        variant: 'destructive',
        description: `${err.message}`,
      });
    }
  };

  const handleReadFiles = async (reload = false) => {
    try {
      setFoldersLoading(true);
      setFilesLoading(true);
      await fetchFiles(reload);
      setFoldersLoading(false);
      setFilesLoading(false);
    } catch (err) {
      console.log({ err });
      setFoldersLoading(false);
      setFilesLoading(false);
    }
  };

  const handleSelectFiles = (item) => {
    if (selectedFilesObj[item.uuid]) {
      setSelectedFilesObj({ ...selectedFilesObj, [item.uuid]: false });
    } else {
      setSelectedFilesObj({ ...selectedFilesObj, [item.uuid]: true });
    }
  };

  const handleClearState = () => {
    try {
      const movedFiles = files.filter((item) => !selectedFilesObj[item.uuid]);
      console.log({ movedFiles });
      dispatchSetFiles(movedFiles);
      setSelectedFilesObj({});
      setSelectFiles(false);
      setMoveFolders(false);
    } catch (err) {
      console.log({ err });
    }
  };

  // this get trigger when call reloadContent
  useEffect(() => {
    console.log('🦛 | file: my-content.tsx:116 | here');
    if (myContentState) handleReadFiles(true);
  }, [myContentState]);

  // this get trigger onload and when infinite next page get triggered
  useEffect(() => {
    console.log('🦛 | file: my-content.tsx:116 | there');
    handleReadFiles();
  }, [currentPageNum]);

  useEffect(() => {
    console.log({ hasMore: !isAllLoaded });
  }, [isAllLoaded]);

  return (
    <div className="grid w-full max-w-[1100px] flex-col gap-[25px]">
      <SectionDivider
        color={'#ffbd39'}
        titles={['Upload files']}
        img={<UploadImg className=" stroke-card" />}
      />
      <div className="flex w-[500px] flex-col">
        <p className="mb-[10px] text-[#808080]">
          Files will be stored in My Content until applied to an Asset or Utility
        </p>
        <div className="mb-[15px] flex">
          <button
            className={cn(
              'mr-[10px] flex w-full items-center justify-center rounded-[5px] border bg-card py-[5px] text-primary',
              imagesSelected ? 'bg-primary text-white' : '',
            )}
            onClick={() => {
              setImagesSelected(true);
            }}
          >
            <ImageIcon
              className={cn('mr-[10px]', !imagesSelected ? 'stroke-primary' : 'stroke-white')}
            />
            Image
          </button>
          <button
            className={cn(
              'flex w-full items-center justify-center  rounded-[5px] border bg-card py-[5px] text-primary',
              !imagesSelected ? 'bg-primary text-white' : '',
            )}
            onClick={() => {
              setImagesSelected(false);
            }}
          >
            <VideoIcon
              className={cn('mr-[10px]', imagesSelected ? 'stroke-primary' : 'stroke-white')}
            />
            Video
          </button>
        </div>
        {imagesSelected ? (
          <Dropzone
            label={''}
            underText={''}
            selectFromModal={false}
            imgPreview={''}
            handleDrop={(img) => {
              console.log('THERE WAS A CHANGE', { files, length: files.length, img });
              dispatchSetFiles([
                {
                  s3_link: img.hostedUrl,
                  mime_type: img.type,
                  name: img.name,
                  uuid: img.id,
                },
                ...files,
              ]);
            }}
          />
        ) : (
          <VideoUpload
            // tokengated={!!field?.isTokengated}
            // tokengated={isTokengated}
            setUploadedVideo={(img) => {
              console.log({ img });
              dispatchSetFiles([{ loading: true, ...img }, ...files]);
            }}
          />
        )}
      </div>
      <SectionDivider
        color={'#08C299'}
        titles={['Folders']}
        img={<File className="stroke-white" />}
      />
      <FolderCardsGroup
        folders={folders}
        foldersLoading={foldersLoading}
        setNewFolderInput={setNewFolderInput}
        newFolderInput={newFolderInput}
        handleCreateFolder={handleCreateFolder}
        createFolderLoading={createFolderLoading}
        onClick={(item) => {
          navigate(`/folder${item}`);
        }}
      />
      <FileCardGroup
        filesLoading={filesLoading}
        files={files}
        selectedFilesObj={selectedFilesObj}
        setSelectedFilesObj={setSelectedFilesObj}
        setMoveFolders={setMoveFolders}
        selectFiles={selectFiles}
        setSelectFiles={setSelectFiles}
        setCurrentPageNum={setCurrentPageNum}
        currentPageNum={currentPageNum}
        hasMore={!isAllLoaded}
        deleteFiles={deleteFiles}
        onClick={(item) => {
          if (item.submarine) {
            toast({
              title: 'Video Locked',
              variant: 'destructive',
              description: 'You cannot view tokengated video here',
            });
            return;
          }
          if (!selectFiles) {
            setFileModalOpen(true);
            setFileForModal(item);
            console.log({ item });
          } else {
            handleSelectFiles(item);
          }
        }}
      />
      <AssignFolder
        setOpen={setMoveFolders}
        open={moveFolders}
        folders={folders}
        selectedFilesObj={selectedFilesObj}
        handleCreateFolder={handleCreateFolder}
        clearState={handleClearState}
        currentPath={currentPath}
        setCurrentPath={setCurrentPath}
      />
      <ViewFileModal
        open={fileModalOpen}
        file={fileForModal}
        setOpen={() => {
          setFileModalOpen(!fileModalOpen);
          setTimeout(() => {
            setFileForModal({});
          }, 100);
        }}
      />
    </div>
  );
};

export default MyContent;
