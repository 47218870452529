import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useOutletContext } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import { Dropzone } from '@/components/ui/dropzone';
import { Input } from '@/components/ui/input';
import { useToast } from '@/components/ui/use-toast';
import { CreateListFromCsvDocument } from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { uploadToCSVPresignedLink } from '@/lib/imageUpload';
import useBoundStore from '@/store';
import { ListBulletIcon } from '@radix-ui/react-icons';

// @ts-expect-error bun maybe?
import UserListCSV from '../../additional-assets/user_list_template.csv';
import SectionDivider from '../ui/section-divider';

const CreateList: FunctionComponent = () => {
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [csv, setCsv] = useState({});
  const [listName, setListName] = useState('');
  const [csvBlob, setCsvBlob] = useState({ hostedUrl: '' });

  const addCustomList = useBoundStore((state) => state.listsAndRewardsSlice.addCustomList);

  const navigate = useNavigate();
  const { toast } = useToast();

  const handleCreateList = async () => {
    try {
      setCreateLoading(true);
      console.log('CREATE INPUT ', csv);
      const presignedLink = await uploadToCSVPresignedLink(csv, listName);
      console.log({ presignedLink });

      navigate('/rewards');
      addCustomList({
        name: listName,
        number_of_members: 'Uploading...',
      });
      setTimeout(() => {
        toast({
          title: 'Success!',
          description: 'A list has been successfully created',
          variant: 'success',
        });
      }, 1000);
    } catch (err) {
      setCreateLoading(false);
      toast({
        title: 'Error',
        variant: 'destructive',
        description: `${err}`,
      });
      console.log({ err });
    }
  };

  const openCsvInNewTab = (file) => {
    const escapeHtml = (str) => {
      return str
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;');
    };

    const reader = new FileReader();
    reader.onload = function (event) {
      // Explicitly check if event.target is not null
      if (event.target !== null) {
        const csvContent = event.target.result;
        const htmlContent = `<html><body><pre>${escapeHtml(csvContent)}</pre></body></html>`;

        const blob = new Blob([htmlContent], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        setCsvBlob({ hostedUrl: url });
      } else {
        // Handle the case where event.target is null
        console.error('Failed to read file');
      }
    };
    reader.readAsText(file);
  };

  useEffect(() => {
    console.log({ csvBlob });
  }, [csvBlob]);

  return (
    <div className="flex w-full max-w-[700px] flex-col gap-[20px]">
      <SectionDivider
        titles={[`Create a List`]}
        img={<ListBulletIcon stroke="white" className="size-[19px] stroke-white text-card" />}
        color="#08C299"
      />
      <Input
        label="List Name"
        placeholder="Enter List Name"
        value={listName}
        onChange={(e) => {
          setListName(e.target.value);
        }}
      />
      <div>
        <p className="mb-[10px] text-sm font-semibold">Upload List</p>
        <p className="text-sm opacity-60">
          The user list should be a CSV file. It should contain user names and email addresses.
          Click
          <a
            href={UserListCSV}
            download="user_template.csv"
            className="mx-[5px] cursor-pointer font-semibold text-primary underline"
          >
            here
          </a>
          to download a template of acceptable list format.
        </p>
      </div>
      <Dropzone
        label={''}
        underText={''}
        selectFromModal={false}
        withoutApiCall
        imgPreview={csvBlob.hostedUrl == '' ? { hostedUrl: '' } : csvBlob}
        handleDrop={(file) => {
          console.log('THERE WAS A CHANGE', { file });
          setCsv(file.fullFile);
          openCsvInNewTab(file.fullFile);
        }}
        csv
      />
      <Button
        className="w-full"
        loading={createLoading}
        disabled={`${csvBlob.hostedUrl}` == '' || listName.length == 0}
        onClick={() => {
          handleCreateList();
        }}
      >
        Create
      </Button>
    </div>
  );
};

export default CreateList;
