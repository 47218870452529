import React, { useEffect, useRef, useState } from 'react';

import { BackNext } from '@/components/ui/back-next';
import FileUploadButton from '@/components/ui/file-upload';

const MyDocuments = () => {
  const documents = [
    {
      title: 'Articles of Incorporation',
      uploaded: '',
    },
    {
      title: 'Bylaws and Operating Agreement',
      uploaded: '',
    },
    {
      title: 'Cap Table',
      uploaded: '',
    },
    {
      title: 'White Paper',
      uploaded: '',
    },
    {
      title: 'Warrants and other convertible securities',
      uploaded: '',
    },
    {
      title: 'Material Contracts with customers or vendors',
      uploaded: '',
    },
    {
      title: 'Copies of financing documents (promissory notes, loan agreements)',
      uploaded: '',
    },
    {
      title: 'Employee options or equity incentive plans',
      uploaded: '',
    },
    {
      title: 'Financial Statements prepared/reviewed by public accountant',
      uploaded: '',
    },
    {
      title: 'Tax ID',
      uploaded: '',
    },
    {
      title: 'Copies of prior offering documents',
      uploaded: '',
    },
  ];

  const [documentsState, setDocumentsState] = useState(documents);
  const fileInputsRef = useRef<(HTMLInputElement | null)[]>([]);

  const handleFileUpload = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const updatedDocuments = [...documentsState];
    updatedDocuments[index].uploaded = file ? file.name : '';
    setDocumentsState(updatedDocuments);
  };

  const triggerFileInput = (index: number) => {
    if (fileInputsRef.current[index]) {
      fileInputsRef.current[index]!.click();
    }
  };

  return (
    <div className="relative mx-[20px] flex w-full max-w-[800px] flex-col items-center">
      <h1 className="mb-[28px] w-full text-left text-[2rem] font-medium sm:mb-[32px] sm:text-[2rem]">
        My Documents
      </h1>
      <div className="flex w-full flex-col gap-[18px]">
        {documentsState.map(({ title, uploaded }, index) => (
          <div key={title} className="flex items-center">
            <p className="w-full font-light opacity-80">{title}</p>
            <FileUploadButton
              uploaded={uploaded}
              onFileChange={(e) => {
                handleFileUpload(index, e);
              }}
              onEditClick={() => {
                triggerFileInput(index);
              }}
            />
            <input
              type="file"
              accept="image/*"
              className="hidden"
              ref={(el) => (fileInputsRef.current[index] = el)}
              onChange={(e) => {
                handleFileUpload(index, e);
              }}
            />
          </div>
        ))}
      </div>
      <div className="mt-[30px] w-full">
        <BackNext className="mt-[20px]" numOfLevels={1} finalBtnText={'Save & continue'} />
      </div>
    </div>
  );
};

export default MyDocuments;
