import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import MintWizard from '@/assets/Mintwizard.svg?react';
import DisplayFile from '@/components/ui/display-file';
import SectionDivider from '@/components/ui/section-divider';
import { DeleteFilesDocument } from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { cn } from '@/lib/utils';
import { CheckIcon, LockClosedIcon, PlayIcon, Share2Icon, TrashIcon } from '@radix-ui/react-icons';

const FileCardGroup: FunctionComponent<any> = ({
  filesLoading,
  files,
  selectedFilesObj,
  setSelectedFilesObj,
  selectFiles,
  setSelectFiles,
  onClick = () => {},
  setMoveFolders,
  setCurrentPageNum,
  currentPageNum,
  hasMore,
  fileUploading = false,
  deleteFiles = (indexes) => {},
}) => {
  const selectedFilesList = useMemo(() => {
    return Object.keys(selectedFilesObj).filter((item) => selectedFilesObj[item]);
  }, [selectedFilesObj]);

  const handleDeleteFolder = async () => {
    try {
      const {
        data: { createFolder: createFolderRes },
      } = await client.mutate({
        mutation: DeleteFilesDocument,
        variables: { input: { upload_ids: selectedFilesList } },
        context: { clientName: ClientName.Marketplace },
      });
      console.log({ createFolderRes });
      deleteFiles(selectedFilesList);
    } catch (err: any) {
      console.log({ err });
      // toast({
      //   title: 'Error',
      //   variant: 'destructive',
      //   description: `${err.message}`,
      // });
    }
  };

  return (
    <div className="grid w-full max-w-[1300px] flex-col gap-[25px]">
      <div className="relative">
        <SectionDivider
          color={'#9256FF'}
          titles={['Files']}
          img={<MintWizard className="stroke-white" />}
        />
        <div className="absolute bottom-0 left-0 mb-[10px] ml-[120px] flex h-[32px] items-center border-l pl-[20px]">
          {files.length !== 0 && (
            <button
              className="text-md rounded-full border bg-card p-[4px] px-[15px] text-primary shadow-sm"
              onClick={() => {
                setSelectFiles(!selectFiles);
                setSelectedFilesObj({});
              }}
            >
              {selectFiles ? 'Cancel' : 'Select'}
            </button>
          )}
          {selectFiles && (
            <>
              <p className="mx-[25px] opacity-60"> Selected: {selectedFilesList.length} </p>
              {selectedFilesList.length > 0 && (
                <div className="flex">
                  <button
                    onClick={() => {
                      setMoveFolders(true);
                    }}
                  >
                    <Share2Icon className="size-[22px] fill-primary stroke-1 text-primary" />
                  </button>
                </div>
              )}
              {selectedFilesList.length > 0 && (
                <div className="ml-2 flex">
                  <button
                    onClick={() => {
                      console.log({ selectedFilesList });
                      handleDeleteFolder();
                    }}
                  >
                    <TrashIcon className="size-[22px] fill-primary stroke-1 text-primary" />
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {false ? (
        <div className="relative flex w-full flex-wrap gap-[10px]">
          {[1, 1, 1].map((item, index) => (
            <div
              key={index}
              className="size-[200px] animate-pulse cursor-pointer rounded-[15px] shadow-md"
            />
          ))}
        </div>
      ) : (
        <>
          {files.length == 0 && (
            <p className="mt-[10px] flex min-h-[200px] w-full items-center justify-center opacity-60">
              No Files Here
            </p>
          )}
          <InfiniteScroll
            className="block"
            dataLength={files.length}
            next={() => {
              console.log('NEXT FIRED');
              setCurrentPageNum(currentPageNum + 1);
            }}
            scrollableTarget="authLayoutContainerId"
            hasMore={hasMore}
            loader={
              <div className="flex h-12 items-center justify-center">
                <div className="m-auto size-6 animate-spin rounded-full border-t-2 border-primary" />
              </div>
            }
          >
            <div className="relative flex w-full flex-wrap gap-[10px]">
              <>
                {fileUploading && (
                  <div className="flex size-[200px] items-center justify-center rounded-[15px] border bg-[#000000]">
                    <div className="m-auto size-8 animate-spin rounded-full border-t-2 border-card" />
                  </div>
                )}
                {files.map((item) => (
                  <button
                    key={`${item.uuid}`}
                    className={cn(
                      'relative size-[200px] max-w-[200px] overflow-hidden rounded-[15px] border-2',
                      selectFiles ? 'border-[2px] border-[#c1c1c1] shadow-sm' : '',
                      selectedFilesObj[item.uuid] && selectFiles ? 'border-primary' : '',
                    )}
                    onClick={() => {
                      onClick(item);
                    }}
                  >
                    {item.loading ? (
                      <div className="flex h-full items-center bg-black">
                        <div className="m-auto size-8 animate-spin rounded-full border-t-2 border-card" />
                      </div>
                    ) : (
                      <>
                        {selectFiles && (
                          <div
                            className={cn(
                              'absolute right-0 top-0 z-20 m-[10px] flex h-[28px] w-[28px] items-center justify-center rounded-full border-[2px] border-[#808080] bg-card',
                              selectedFilesObj[item.uuid] ? 'border-primary' : '',
                            )}
                          >
                            {selectedFilesObj[item.uuid] && (
                              <CheckIcon className="size-[18px] stroke-primary" />
                            )}
                          </div>
                        )}
                        {!item.submarine ? (
                          <DisplayFile
                            thumbnail={item.thumbnail}
                            type={item.mime_type}
                            cloudflare_uid={item.cloudflare_uid}
                            src={item.s3_link}
                            className="size-[200px] cursor-pointer bg-black object-contain shadow-md"
                          />
                        ) : (
                          <div className="flex size-full flex-col items-center justify-center bg-[#000000]">
                            <LockClosedIcon className="size-[40px] stroke-white " />
                            <p className="mt-[10px] w-[50%] text-xs text-white">
                              This Video is TokenGated
                            </p>
                          </div>
                        )}
                      </>
                    )}
                    <div className="absolute bottom-0 z-10 w-full max-w-[200px] bg-[#00000080] px-[6px] py-[4px]">
                      <p className="truncate text-left text-sm text-white">{item.name}</p>
                    </div>
                  </button>
                ))}
              </>
            </div>
          </InfiniteScroll>
        </>
      )}
    </div>
  );
};

export default FileCardGroup;
