import React from 'react';
import { useNavigate } from 'react-router';

import { ProgressCard } from '@/components/ui/progress-card';

function LegalChecklist() {
  const navigate = useNavigate();

  const progressCards = [
    { progress: 0, title: 'Broker Dealer Checklist', navigateTo: 'broker-dealer-checklist' },
    {
      progress: 0,
      title: 'Transfer Agent Checklist',
      navigateTo: 'transfer-agent-checklist',
    },
  ];

  return (
    <div className="flex w-full flex-col items-center">
      <div className="my-[70px] flex w-full max-w-[80%] flex-col items-center justify-center gap-[40px] lg:w-[820px]">
        <h1 className="text-center  text-xl  sm:text-3xl">Exchange Checklist</h1>
        <p className="text-center text-2xl font-light">
          In order to raise capital it is necessary to file the appropriate forms with the SEC. The
          Exchange Checklist will walk you through the process and collect the required information
          along the way.
        </p>
      </div>
      <div className="mt-[20px] grid w-full max-w-[1000px] gap-[20px] px-[30px] md:mt-[40px] md:grid-cols-2 md:gap-[40px]">
        {progressCards.map((item, index) => (
          <ProgressCard
            key={index}
            rightTitle={''}
            leftTitle={item.title}
            progress={item.progress}
            tasksClick={() => {
              navigate(`/${item.navigateTo}`);
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default LegalChecklist;
