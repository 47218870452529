import React, { useEffect, useMemo, useState } from 'react';

import Contracts from '@/assets/Contracts.svg?react';
import Upload from '@/assets/Upload.svg?react';
import AssignFolder from '@/components/modals/assign-folder';
import TokengateModal from '@/components/modals/tokengate-modal';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { cn } from '@/lib/utils';
import useBoundStore from '@/store';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import Tus from '@uppy/tus';

import '@uppy/core/dist/style.css';
import '../../additional-styles/style.css';

type VideoUploadProps = {
  tokengated?: boolean;
  setUploadedVideo: (arg) => void;
  isError?: boolean | undefined;
  withModalSelector?: boolean;
  uploadedVideo?: any;
};

const VideoUpload = ({
  tokengated = false,
  setUploadedVideo = (img) => {},
  isError = false,
  withModalSelector = false,
  uploadedVideo = null,
}: VideoUploadProps) => {
  const [tokengateModalOpen, setTokengateModalOpen] = useState(false);
  const [isTokengated, setisTokengated] = useState(tokengated);
  const [fileInState, setFileInState] = useState<{ id?: string }>({});
  const [currentPath, setCurrentPath] = useState('');
  const [openVidsModal, setOpenVidsModal] = useState(false);

  const selectedProject = useBoundStore((state) => state.projectSlice.selectedProject);
  const token = localStorage.getItem('adminToken');
  const { toast } = useToast();

  const uppyVideoUpload = useMemo(
    () =>
      new Uppy({ debug: true, autoProceed: true }).use(Tus, {
        endpoint: `https://cloudflare.chainstarters.xyz/${selectedProject.project_name}/upload?apiKey=${token}`,
        chunkSize: 50 * 1024 * 1024,
        retryDelays: [0, 3000, 5000, 10000, 20000],
      }),
    [selectedProject],
  );

  const createNewURL = (oldURL) => {
    const basePart = oldURL.split('?')[0]; // Split at '?' and take the first part
    const videoId = basePart.split('/')[4]; // Extracts the video ID

    const customerId =
      selectedProject.project_name === 'videomart' ? 'wubw5yvkxeddvos4' : 't2pt9vez6v5e9856';

    const newBaseURL = `https://customer-${customerId}.cloudflarestream.com`;
    return `${newBaseURL}/${videoId}/watch`;
  };

  const extractVideoUid = (url) => {
    const regex = /\/tus\/([a-zA-Z0-9]+)(\?.*)?$/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const handleSubmitModal = () => {
    if (fileInState.id) {
      uppyVideoUpload.retryUpload(fileInState.id);
      setFileInState({});
      console.log('RESUME', fileInState);
    }
  };

  useEffect(() => {
    console.log({ isError });
  }, [isError]);

  useEffect(() => {
    uppyVideoUpload?.getPlugin('Tus')?.setOptions({
      headers: isTokengated ? { 'Upload-Metadata': 'requiresignedurls' } : {},
    });

    // Optional: Handle the complete event
    uppyVideoUpload.on('complete', (result) => {
      // Handle completion, e.g., showing a success message
      console.log('VIDEO UPLOAD COMPLETE', { result });
      if (result.successful.length > 0 && result?.successful[0]?.response?.uploadURL) {
        if (!isTokengated) {
          setUploadedVideo({
            id: result.successful[0].id,
            name: result.successful[0].name,
            url: createNewURL(result.successful[0].response.uploadURL),
          }); // REVISIT
          console.log(createNewURL(result.successful[0].response.uploadURL));
        } else {
          setUploadedVideo({
            id: result.successful[0].id,
            url: extractVideoUid(result.successful[0].response.uploadURL),
          });
          console.log(extractVideoUid(result.successful[0].response.uploadURL));
        }
      } else {
        console.log('VIDEO UPLOAD ERROR', result?.failed);
        if (result.failed[0].error.includes('Existing file')) {
          toast({
            title: 'Error',
            variant: 'destructive',
            description: `This video has already been uploaded.`,
          });
        } else {
          toast({
            title: 'Error',
            variant: 'destructive',
            description: `There has been an error uploading your video`,
          });
        }
      }
    });
  }, [isTokengated, uppyVideoUpload]);

  uppyVideoUpload.on('file-added', (file) => {
    console.log({ file });

    if (!file.type?.includes('video')) {
      uppyVideoUpload.cancelAll();
      toast({
        title: 'Error',
        variant: 'destructive',
        description: `You can only upload a video to this file input. Please select image and try again.`,
      });
    } else if (file.size > 21474836480) {
      uppyVideoUpload.cancelAll();
      toast({
        title: 'Error',
        variant: 'destructive',
        description: `Max video size of 20GB exceeded. Please try again with a smaller video.`,
      });
    }
    if (!tokengated) {
      uppyVideoUpload.pauseResume(file.id);
      setTokengateModalOpen(true);
    }
    setFileInState(file);
  });

  return (
    <div>
      {withModalSelector && (
        <div className="mb-[10px] flex items-center">
          <Button
            className=""
            onClick={(e) => {
              e.preventDefault();
              setOpenVidsModal(true);
            }}
          >
            Select video from My Content
          </Button>
          <p className="ml-[10px] font-medium"> Or </p>
        </div>
      )}
      <div
        className={cn(
          'UppyDropzoneContainer flex flex-col',
          isError ? 'UppyDropzoneContainerError' : '',
        )}
      >
        <Dashboard uppy={uppyVideoUpload} plugins={[]} />
        <TokengateModal
          open={tokengateModalOpen}
          setOpen={() => {
            setTokengateModalOpen(!tokengateModalOpen);
          }}
          tokengated={isTokengated}
          setTokengated={(bool) => {
            setisTokengated(bool);
          }}
          handleSubmit={handleSubmitModal}
        />
        {withModalSelector && (
          <AssignFolder
            setOpen={setOpenVidsModal}
            open={openVidsModal}
            folders={[]}
            currentPath={currentPath}
            setCurrentPath={setCurrentPath}
            canSelectFiles
            filesInModal={['video']}
            tokengated={true}
            handleSetImage={(item) => {
              setUploadedVideo({ ...item, url: item.uid });
            }}
          />
        )}
      </div>
      {!!(tokengated && uploadedVideo) && (
        <p className="mt-[10px] flex">
          <Contracts className="mr-[10px]" />
          {uploadedVideo.name}
        </p>
      )}
    </div>
  );
};

export default VideoUpload;
