import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import spacetime from 'spacetime';

import MembershipBenefits from '@/assets/membershipBenefits.svg?react';
import Portfolio from '@/assets/portfolio.svg?react';
import Transactions from '@/assets/transactions.svg?react';

const ProfileTabs = ({ activeTab = 0, setActiveTab = (arg) => {} }) => {
  const tabs = [
    // { title: 'Overview Assets', icon: <OverviewAssets className="mr-[10px]" /> },
    { title: 'Holding Assets', icon: <Portfolio className="mr-[10px]" /> },
    { title: 'All Transactions', icon: <Transactions className="mr-[10px]" /> },
    { title: 'Membership Benefits', icon: <MembershipBenefits className="mr-[10px]" /> },
  ];

  return (
    <div
      // style={{ maxWidth: 'calc(100vw - 500px)' }}
      className="border-label-color relative flex h-[40px] w-full border-b-2"
    >
      {tabs.map((tab, index) => (
        <button
          key={index}
          className={`box-border flex w-[33%] items-center justify-center stroke-primary pt-0 text-[16px] font-medium text-blue-500 opacity-80 transition-colors duration-500 ${
            activeTab == index
              ? ' fill-primary stroke-primary text-primary'
              : 'text-text-color opacity-70 hover:opacity-100 '
          } max-w-[1100px]:min-w-[210px]`}
          onClick={() => {
            setActiveTab(index);
          }}
        >
          {tab.icon}
          {tab.title}
        </button>
      ))}
      <div
        className="transition-left absolute bottom-0 left-0 mb-[-2px] ml-0 h-[2px] w-[33%] bg-primary duration-500"
        style={{ left: `${activeTab * 33}%` }}
      ></div>
    </div>
  );
};

export default ProfileTabs;
