import gql from 'graphql-tag';

import { client } from '../../lib/apollo';

export const getPresignedLink = async (input): Promise<{ imagePresignedLink: string }> => {
  const { data } = await client.mutate({
    mutation: GET_PRESIGNED_LINK,
    variables: { input },
  });
  if (!data) throw new Error('No data returned from mutation');
  return data;
};

export const updateCompany = async (id, input): Promise<{ updateCompany: string }> => {
  const { data } = await client.mutate({
    mutation: UPDATE_COMPANY,
    variables: { id, input },
  });
  if (!data) throw new Error('No data returned from mutation');
  return data;
};

const GET_PRESIGNED_LINK = gql`
  mutation getPresignedLink($input: GetPresignedLinkInput!) {
    getPresignedLink(input: $input) {
      hostedUrl
    }
  }
`;

const UPDATE_COMPANY = gql`
  mutation updateCompany($id: Int!, $input: CompanyInput!) {
    updateCompany(id: $id, input: $input) {
      id
    }
  }
`;
