import { arrayMove, rectSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const SortableItem = ({ id, content }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  });

  const transformStyle = CSS.Transform.toString(transform);

  return (
    <div
      ref={setNodeRef}
      style={{ transform: transformStyle, transition }}
      className={`z-999 rounded-[20px] ${isDragging ? 'bg-grey-500 bg-card opacity-80' : 'bg-card opacity-100'}`}
      {...attributes}
      {...listeners}
    >
      {content}
    </div>
  );
};

export default SortableItem;
