import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { BackNext } from '@/components/ui/back-next';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import InputWithDropdown from '@/components/ui/input-with-dropdown';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';

interface DirectorsInfoProps {
  open: boolean;
  setOpen: Function;
  methods: any;
}
function DirectorsInfoModal({ open, setOpen = () => {}, methods }: DirectorsInfoProps) {
  const [previousEmployment, setPreviousEmployment] = useState([{ selected: '', inputValue: '' }]);

  const screenData1 = [
    {
      inputs: [
        {
          type: 'input',
          label: 'Full legal name of Director (As it appears on your ID)',
          state: 'directorName',
          placeholder: 'Enter full legal name',
        },
        {
          type: 'input',
          label: 'What is their role',
          state: 'directorRole',
          placeholder: 'Enter role',
        },
        {
          type: 'calendar',
          label: 'Date of board service',
          state: 'boardServiceDate',
          placeholder: 'MM/DD/YY',
        },
        {
          type: 'textarea',
          label: 'Responsibilities',
          state: 'responsibilities',
          placeholder: 'Describe responsibilities',
        },
        {
          type: 'input',
          label: 'What industry is this in',
          state: 'industry',
          placeholder: 'Enter industry',
        },
        {
          type: 'calendar',
          label: 'Dates of employment',
          state: 'employmentDates',
          placeholderStart: 'Start date',
          halfWidth: true,
        },
        {
          type: 'calendar',
          label: '',
          state: 'employmentDates2',
          placeholderStart: 'End date',
          halfWidth: true,
        },
      ],
    },
  ];

  return (
    <Dialog
      open={open}
      onOpenChange={(open_) => {
        setOpen();
      }}
    >
      <DialogContent
        className="w-[750px] max-w-[90%] rounded-[16px]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogHeader>
          <DialogTitle className="text-semibold mb-[16px] text-center text-[18px]">
            Information about Directors
          </DialogTitle>
        </DialogHeader>
        <div className="flex max-h-[750px] flex-col  pr-[10px]">
          <WrappedInputSection
            methods={methods}
            data={[{ inputs: screenData1[0].inputs.slice(0, 4) }]}
          />
          <div className="my-[22px]">
            <p className="mb-1 text-sm opacity-80">
              Where were you previously employed? (Past 3 Years)
            </p>
            <InputWithDropdown
              stateList={previousEmployment}
              setStateList={setPreviousEmployment}
            />
          </div>
          <WrappedInputSection
            methods={methods}
            data={[{ inputs: screenData1[0].inputs.slice(4, 99) }]}
          />
          <div className="mt-[20px] pb-[0px]">
            <BackNext numOfLevels={1} finalBtnText={'Save & continue'} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default DirectorsInfoModal;
