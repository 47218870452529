import { FunctionComponent } from 'react';

import ExclusiveVideo from '@/assets/Exclusive-video-icon.svg?react';
import { cn } from '@/lib/utils';

const UtilCard: FunctionComponent<any> = ({
  image = '',
  name = '',
  description = '',
  loading = false,
  active = true,
  onClick = () => {},
}) => {
  return (
    <button
      className="relative flex h-[293px] w-full flex-col overflow-hidden rounded-[16px] border bg-card shadow-md transition-shadow duration-500 ease-in-out hover:shadow-lg "
      onClick={onClick}
    >
      {name && (
        <p
          className={cn(
            'absolute left-0 top-0 ml-[15px]	rounded-b-sm px-[12px] py-[3px] text-sm uppercase text-card',
            active ? 'bg-[#22bb33]' : 'bg-[#BB2124]',
          )}
        >
          {active ? 'Active' : 'Disabled'}
        </p>
      )}
      {loading ? (
        <div className="h-[180px] w-full animate-pulse" />
      ) : (
        <img className="h-[180px] w-full object-cover" src={image} />
      )}
      <div className="relative w-full p-[15px]">
        {!loading && (
          <ExclusiveVideo
            className={cn('absolute right-0 mr-[10px] h-[30px] w-[30px] stroke-primary')}
          />
        )}
        {loading ? (
          <h1 className="mb-[10px] mt-[5px] h-[22px] w-[150px] animate-pulse rounded-[6px]" />
        ) : (
          <h1 className="mb-[5px] line-clamp-1 pr-[30px] text-left text-lg font-semibold">
            {name}
          </h1>
        )}
        {loading ? (
          <p className="h-[22px] w-[250px] animate-pulse rounded-[6px]" />
        ) : (
          <p className="text-md line-clamp-2 h-[48px] text-left opacity-50">{description}</p>
        )}
      </div>
    </button>
  );
};

export default UtilCard;
