import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';

import File from '@/assets/File.svg?react';
import { cn } from '@/lib/utils';

const FolderCard: FunctionComponent<any> = ({
  onSubmit = () => {},
  onClick = () => {},
  onDoubleClick = () => {},
  newFolderInput,
  setNewFolderInput,
  create = false,
  name = '',
  nameAsComponent = null,
  onOutsideClick = () => {},
  selected = false,
  height = 52,
}) => {
  const ref = useRef<HTMLDivElement>(null); // Specify the type of the ref

  function handleClickOutside(event: MouseEvent) {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      if (create) {
        onOutsideClick();
      }
    }
  }

  useEffect(() => {
    if (create) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [create, onOutsideClick]);
  return (
    <>
      {create ? (
        <form
          className="flex items-center justify-center rounded-[12px] border border-input bg-card px-[30px]"
          style={{ height }}
          onSubmit={onSubmit}
          ref={ref}
        >
          <File stroke="white" className="stroke-text mr-[10px]" />
          <input
            autoFocus
            className="w-[120px] border"
            value={newFolderInput}
            onChange={(e) => {
              setNewFolderInput(e.target.value);
            }}
          />
        </form>
      ) : (
        <button
          className={cn(
            'flex items-center justify-center rounded-[12px] border border-input bg-card px-[30px]',
            selected ? 'border-primary' : '',
          )}
          style={{ height }}
          onClick={onClick}
          onDoubleClick={onDoubleClick}
        >
          <File stroke="white" className="stroke-text mr-[10px]" />
          {name.length > 0 && <p>{name}</p>}
          {nameAsComponent}
        </button>
      )}
    </>
  );
};

export default FolderCard;
