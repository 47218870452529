import * as React from 'react';

import { cn } from '@/lib/utils';
import * as SwitchPrimitives from '@radix-ui/react-switch';

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, checked, ...props }, ref) => {
  // const [checked, setChecked] = React.useState(false);

  return (
    <SwitchPrimitives.Root
      className={cn(
        'peer inline-flex h-6 w-[52px] shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors data-[state=checked]:bg-primary data-[state=unchecked]:bg-input focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50',
        className,
      )}
      defaultChecked={checked}
      {...props}
      ref={ref}
    >
      <span
        className={`absolute text-xs ${
          checked ? 'left-0 ml-[4px] text-white' : 'right-0 mr-[3px]'
        }`}
      >
        {checked ? 'ON' : 'OFF'}
      </span>
      <SwitchPrimitives.Thumb
        className={cn(
          'pointer-events-none block h-5 w-5 rounded-full bg-card shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-7 data-[state=unchecked]:translate-x-0',
        )}
      />
    </SwitchPrimitives.Root>
  );
});
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
