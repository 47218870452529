import chroma from 'chroma-js';
import spacetime from 'spacetime';
import soft from 'timezone-soft';

import CsLogo from '@/assets/CSLogo.svg';
import MwLogo from '@/assets/MwLogo3.png';
import config from '@/config';
import { GetUploadedCustomListsDocument } from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { transactionLinksObj } from '@chainstarters/ui-profile';

export const listOfTimeZones = [
  'Pacific/Samoa',
  'Pacific/Honolulu',
  'Pacific/Tahiti',
  'Africa/Cairo',
  'America/Anchorage',
  'America/Los_Angeles',
  'America/Denver',
  'America/Chicago',
  'America/New_York',
  'America/Halifax',
  'America/Argentina//Buenos_Aires',
  'America/Sao_Paulo',
  'Atlantic/Azores',
  'Europe/London',
  'Europe/Berlin',
  'Europe/Helsinki',
  'Europe/Istanbul',
  'Asia/Tehran',
  'Asia/Dubai',
  'Asia/Kabul',
  'Indian/Maldives',
  'Asia/Calcutta',
  'Asia/Kathmandu',
  'Asia/Dhaka',
  'Indian/Cocos',
  'Asia/Bangkok',
  'Asia/Hong_Kong',
  'Asia/Pyongyang',
  'Asia/Tokyo',
  'Australia/Darwin',
  'Australia/Brisbane',
  'Australia/Adelaide',
  'Australia/Sydney',
  'Pacific/Nauru',
  'Pacific/Auckland',
  'Pacific/Kiritimati',
];

export const statesInAmerica = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

const currentOffset = (region, halfHour = false) => {
  const offset = spacetime.now(region).timezone().current.offset;
  if (halfHour) {
    return offset.toString().split('.', 1);
  }
  return offset;
};

export function formatTimestamp(timestampString) {
  const date = new Date(Number(timestampString));
  const s = spacetime(date);
  return s.format('nice');
}

export const timezones = [
  {
    value: `Pacific/Samoa`,
    label: `(GMT ${currentOffset('Pacific/Samoa')}:00) Midway Island, Samoa`,
  },
  {
    value: `Pacific/Honolulu`,
    label: `(GMT ${currentOffset('Pacific/Honolulu')}:00) Hawaii`,
  },
  {
    value: `Pacific/Tahiti`,
    label: `(GMT ${currentOffset('Pacific/Tahiti')}:00) Tahiti`,
  },
  {
    value: `America/Anchorage`,
    label: `(GMT ${currentOffset('America/Anchorage')}:00) Anchorage, Alaska`,
  },
  {
    value: `America/Los_Angeles`,
    label: `(GMT ${currentOffset('America/Los_Angeles')}:00) Pacific Time (US & Canada)`,
  },
  {
    value: `America/Denver`,
    label: `(GMT ${currentOffset('America/Denver')}:00) Mountain Time (US & Canada)`,
  },
  {
    value: `America/Chicago`,
    label: `(GMT ${currentOffset('America/Chicago')}:00) Central Time (US & Canada), Mexico City`,
  },
  {
    value: `America/New_York`,
    label: `(GMT ${currentOffset('America/New_York')}:00) Eastern Time (US & Canada)`,
  },
  {
    value: `America/Halifax`,
    label: `(GMT ${currentOffset('America/Halifax')}:00) Atlantic Time (Canada), Halifax`,
  },
  {
    value: `America/Argentina/Buenos_Aires`,
    label: `(GMT ${currentOffset(
      'America/Argentina/Buenos_Aires',
    )}:00) Brazil, Buenos Aires, Georgetown`,
  },
  {
    value: `America/Sao_Paulo`,
    label: `(GMT ${currentOffset('America/Sao_Paulo')}:00) Sao Paulo, Mid-Atlantic`,
  },
  {
    value: `Atlantic/Azores`,
    label: `(GMT ${currentOffset('Atlantic/Azores')}:00) Azores, Cape Verde Islands`,
  },
  {
    value: 'Africa/Cairo',
    label: `Cairo EG Time`,
  },
  {
    value: `Europe/London`,
    label: `(GMT) Western Europe Time, London, Lisbon, Casablanca`,
  },
  {
    value: `Europe/Berlin`,
    label: `(GMT +${currentOffset('Europe/Berlin')}:00) Brussels, Copenhagen, Madrid, Paris`,
  },
  {
    value: `Europe/Helsinki`,
    label: `(GMT +${currentOffset('Europe/Helsinki')}:00) Kaliningrad, Helsinki, South Africa`,
  },
  {
    value: `Europe/Istanbul`,
    label: `(GMT +${currentOffset('Europe/Istanbul')}:00) Istanbul, Moscow`,
  },
  {
    value: `Asia/Tehran`,
    label: `(GMT +${currentOffset('Asia/Tehran', true)}:30) Tehran`,
  },
  { value: `Asia/Dubai`, label: `(GMT +${currentOffset('Asia/Dubai')}:00) Dubai, Baku` },
  { value: `Asia/Kabul`, label: `(GMT +${currentOffset('Asia/Kabul', true)}:30) Kabul` },
  {
    value: `Indian/Maldives`,
    label: `(GMT +${currentOffset('Indian/Maldives')}:00) Maldives`,
  },
  {
    value: `Asia/Calcutta`,
    label: `(GMT +${currentOffset('Asia/Calcutta', true)}:30) Bombay, Calcutta, Madras, New Delhi`,
  },
  {
    value: `Asia/Kathmandu`,
    label: `(GMT +${currentOffset('Asia/Kathmandu', true)}:45) Kathmandu, Pokhara`,
  },
  {
    value: `Asia/Dhaka`,
    label: `(GMT +${currentOffset('Asia/Dhaka')}:00) Almaty, Dhaka, Colombo`,
  },
  {
    value: `Indian/Cocos`,
    label: `(GMT +${currentOffset('Indian/Cocos', true)}:30) Yangon, Mandalay`,
  },
  {
    value: `Asia/Bangkok`,
    label: `(GMT +${currentOffset('Asia/Bangkok')}:00) Bangkok, Hanoi, Jakarta`,
  },
  {
    value: `Asia/Hong_Kong`,
    label: `(GMT +${currentOffset('Asia/Hong_Kong')}:00) Beijing, Perth, Singapore, Hong Kong`,
  },
  {
    value: `Asia/Pyongyang`,
    label: `(GMT +${currentOffset('Asia/Pyongyang', true)}:30) Pyongyang`,
  },
  { value: `Asia/Tokyo`, label: `(GMT +${currentOffset('Asia/Tokyo')}:00) Tokyo` },
  {
    value: `Australia/Darwin`,
    label: `(GMT +${currentOffset('Australia/Darwin', true)}:30) Adelaide, Darwin`,
  },
  {
    value: `Australia/Brisbane`,
    label: `(GMT +${currentOffset('Australia/Brisbane')}:00) Eastern Australia, Guam, Vladivostok`,
  },
  {
    value: `Australia/Adelaide`,
    label: `(GMT +${currentOffset('Australia/Adelaide', true)}:30) Central Time - Adelaide`,
  },
  {
    value: `Australia/Sydney`,
    label: `(GMT +${currentOffset('Australia/Sydney')}:00) Eastern Time - Melbourne, Sydney`,
  },
  { value: `Pacific/Nauru`, label: `(GMT +${currentOffset('Pacific/Nauru')}:00) Nauru` },
  {
    value: `Pacific/Auckland`,
    label: `(GMT +${currentOffset('Pacific/Auckland')}:00) Auckland`,
  },
  {
    value: `Pacific/Kiritimati`,
    label: `(GMT +${currentOffset('Pacific/Kiritimati')}:00) Kiritimati`,
  },
];

export const handleDisplayTime = (tz) => {
  const display = soft(tz)[0];
  let show = display.standard.abbr;
  const s = spacetime.now(display.iana);
  if (display.daylight && s.isDST()) {
    show = display.daylight.abbr;
  }
  return show;
};

export const getColorProperties = (hex) => {
  const color = chroma(hex);
  return {
    r: color.get('rgb.r'),
    g: color.get('rgb.g'),
    b: color.get('rgb.b'),
    a: color.alpha(),
    h: Math.round(color.get('hsl.h')),
    s: Math.round(color.get('hsl.s') * 100),
    v: Math.round(color.get('hsl.l') * 100),
    hex: color.hex(),
    rgba: color.css(),
  };
};

export const recreateInputs = (timestamp, timeZone) => {
  try {
    // Convert the timestamp to a date in the specified timezone
    const s = spacetime(parseInt(timestamp), timeZone);

    // Extract the date and time components
    const year = s.year();
    const month = s.month() - 1; // JavaScript months are 0-indexed
    const day = s.date();
    const hours = s.hour();
    const minutes = s.minute();

    // Create a Date object
    const date = new Date(year, month, day, hours, minutes);

    // Format the time to match your original input format
    const time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

    return { time, date, timeZone };
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const zeroAddress = '0x0000000000000000000000000000000000000000';

export const handleGetLists = async (setState) => {
  try {
    const {
      data: { getUploadedCustomLists: getUploadedCustomListsRes },
    } = await client.query({
      query: GetUploadedCustomListsDocument,
      context: {
        clientName: ClientName.Marketplace,
      },
    });
    console.log({ getUploadedCustomListsRes });
    setState(getUploadedCustomListsRes);
  } catch (err) {
    console.log({ err });
  }
};

export const pickTextColorBasedOnBgColor = (bgColor, lightColor, darkColor) => {
  const color = bgColor?.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
};

export enum AccessLevel {
  NONE = 0,
  BASE = 10,
  VIEW = 20,
  LIST = 25,
  EDIT = 30,
  CREATE = 40,
  ADMIN = 50,
  OWNER = 60,
}

export const permissionNames = {
  0: 'NONE',
  10: 'BASE',
  20: 'VIEW',
  25: 'LIST',
  30: 'EDIT',
  40: 'CREATE',
  50: 'ADMIN',
  60: 'OWNER',
};

export const yesOrNoDropdownInputs = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
];

export const companyNameAndLogo = {
  name: config.isMetaworks ? 'Metaworks' : 'Chainstarters',
  logo: config.isMetaworks ? MwLogo : CsLogo,
};

export const generateUniqueFileName = (fileName) => {
  const extension = fileName.split('.').pop();
  const baseName = fileName.substring(0, fileName.lastIndexOf('.'));
  const uniqueCode = Math.random().toString(36).substring(2, 7);
  return `cropped_${baseName}_${uniqueCode}.${extension}`;
};

export const polygonScanLink = (transaction_hash, chain_id) =>
  `${transactionLinksObj[chain_id]}/tx/${transaction_hash}`;

export const countKeysAndValues = (obj, excludeKeys: string[] = []) => {
  if (obj === null) return { totalKeys: 0, totalValues: 1, percentageComplete: 0 };

  const filteredEntries = Object.entries(obj).filter(([key]) => !excludeKeys.includes(key));

  const totalKeys = filteredEntries.length;
  const totalValues = filteredEntries.filter(([, value]) => Boolean(value)).length;
  const percentageComplete = totalKeys === 0 ? 0 : (totalValues / totalKeys) * 100;

  return { totalKeys, totalValues, percentageComplete: percentageComplete.toFixed(2) };
};

export const processGetRaiseByIdRes = (allFormKeys, raisesState, excludeKeys: string[] = []) => {
  return Object.fromEntries(
    Object.entries(raisesState)
      .filter(([key]) => allFormKeys.includes(key)) // Only include keys from allFormKeys
      .map(([key, value]) => [
        key,
        countKeysAndValues(value, excludeKeys), // Apply countKeysAndValues to all selected keys
      ]),
  );
};

export const offeringAndProblemStateToExclude = [
  'problemSectionToggled',
  'solutionSectionToggled',
  'productSectionToggled',
  'tractionSectionToggled',
  'businessModelSectionToggled',
  'marketSectionToggled',
  'competitionSectionToggled',
  'visionModelSectionToggled',
  'investorsSectionToggled',
  'advisorToggled',
  'teamToggled',
  'FAQsSectionToggled',
  'socialLinksSectionToggled',
  // non toggle state
  'totalNumberOfFields',
  'totalNumberOfFieldsComplete',
];

export const siteBuilderInputsPerToggle = {
  problemSectionToggled: 2,
  solutionSectionToggled: 3,
  productSectionToggled: 3,
  tractionSectionToggled: 2,
  businessModelSectionToggled: 2,
  marketSectionToggled: 3,
  competitionSectionToggled: 2,
  visionModelSectionToggled: 2,
  investorsSectionToggled: 2,
  advisorToggled: 5,
  teamToggled: 5,
  FAQsSectionToggled: 1,
  socialLinksSectionToggled: 4,
};

export const countKeysAndValuesWithToggles = (
  obj: Record<string, any>, // The input object with key-value pairs
  excludeKeys: string[] = [], // Explicitly define excludeKeys as an array of strings
  toggleInputMap: any,
) => {
  let totalKeys = 0;
  let totalValues = 0;

  Object.entries(obj).forEach(([key, value]) => {
    // Use Object.prototype.hasOwnProperty.call to safely check if the key exists in the toggleInputMap
    if (Object.prototype.hasOwnProperty.call(toggleInputMap, key)) {
      const associatedInputs = toggleInputMap[key];

      // If toggle state is false, count all associated inputs as complete
      if (value === false) {
        totalKeys += associatedInputs;
        totalValues += associatedInputs;
      }
    } else if (!excludeKeys.includes(key)) {
      // If it's not a toggle state, count the actual field value
      totalKeys += 1;
      if (value) {
        totalValues += 1;
      }
    }
  });

  const percentageComplete = totalKeys === 0 ? 0 : (totalValues / totalKeys) * 100;

  return { totalKeys, totalValues, percentageComplete: percentageComplete.toFixed(2) };
};

// NON RELATED

const allFormKeys = [
  'general_information',
  'my_team',
  'capital_raise',
  'business_details',
  'past_raises',
  'financials',
  'use_of_funds',
  'my_documents',
  'broker_dealer',
  'transfer_agent',
  'site_builder',
];

export const getStateFromLocation = (searchParams, fieldName) => {
  return searchParams?.get(fieldName) || 0;
};

export const extractDataByKeyword = (formValues, fieldMap, fieldOrder, keyword) => {
  const result = [];
  const groupedData = {};

  Object.keys(formValues).forEach((key) => {
    if (key.startsWith(keyword)) {
      // Use startsWith to match the exact prefix
      const index = key.match(/\d+$/)?.[0]; // Extract index (e.g., 0, 1, 2)
      if (index !== undefined) {
        if (!groupedData[index]) groupedData[index] = {};

        // Check if the key matches any of the field types (Q, A, Name, Bio, Photo)
        const fieldType = Object.keys(fieldMap(keyword)).find((type) => key.includes(`${type}_`));

        if (fieldType) {
          groupedData[index][fieldType] = {
            ...fieldMap(keyword)[fieldType],
            state: key, // Dynamically assign the state (FAQ or team key)
          };
        }
      }
    }
  });

  // Organize fields in the correct order: (Q then A, or Name then Bio then Photo, etc.)
  Object.keys(groupedData).forEach((index) => {
    const orderedFields = fieldOrder.map((field) => groupedData[index][field] || null);
    result.push(orderedFields);
  });

  return result;
};
