import { useState } from 'react';
import spacetime from 'spacetime';

import Add from '@/assets/Add.svg?react';
import SectionDivider from '@/components/ui/section-divider';
import { ListBulletIcon } from '@radix-ui/react-icons';

import Table from './table';

type CollectionType = {
  ui_price: number;
  quantity: number;
  price: number;
  created_at: string;
  event_type: string;
};

type ActivityViewProps = {
  events: CollectionType[];
  className: string;
  loading: boolean;
};

const ActivityView = ({ events, className = '', loading = false }: ActivityViewProps) => {
  const royaltiesTable = {
    columns: ['Event', 'Price', 'Date'],
    rows: events?.map(({ ui_price, price, created_at, event_type, quantity }) => [
      { value: ui_price == 0 ? 'AIRDROP' : event_type },
      { value: `$${(price / 100 || ui_price / 100) * quantity ?? 0}` },
      { value: spacetime(parseInt(created_at)).format('{month} {date} {year}') },
    ]),
  };

  return (
    <div className="rounded-[10px] border border-input">
      <div className="px-[15px] pt-[15px]">
        <SectionDivider
          color={'#ffbd39'}
          titles={['Item Activity']}
          textClass="text-lg"
          img={<ListBulletIcon stroke="white" className="stroke-white text-card" />}
        />
      </div>

      <div className="flex flex-col pb-[12px]">
        <Table
          ariaLabel="activity-table"
          data={royaltiesTable.rows}
          columnNames={royaltiesTable.columns}
          isLoading={loading}
          className={className}
        />
      </div>
    </div>
  );
};

export default ActivityView;
