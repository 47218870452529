import * as React from 'react';
import { Check } from 'lucide-react';

import { cn } from '@/lib/utils';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';

interface CheckboxProps extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  label?: string;
  containerClassname?: string;
  htmlLabel?: string;
  htmlFor?: string;
}

const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>(
  ({ className, containerClassname, label, htmlLabel, htmlFor, ...props }, ref) => {
    return (
      <div className={cn('flex items-center', containerClassname)}>
        <CheckboxPrimitive.Root
          ref={ref}
          className={cn(
            'peer h-4 w-4 shrink-0 rounded-sm border border-[#A0A0A0] ring-offset-background data-[state=checked]:bg-primary data-[state=checked]:text-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
            className,
          )}
          {...props}
        >
          <CheckboxPrimitive.Indicator
            className={cn('flex items-center justify-center text-current')}
          >
            <Check className="size-4" />
          </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
        {label && label?.length > 0 && (
          <label className="ml-2 text-sm" htmlFor={htmlFor}>
            {label}
          </label>
        )}
        {htmlLabel && (
          <label
            htmlFor={htmlFor}
            className="ml-2 text-sm [&_a]:text-primary"
            dangerouslySetInnerHTML={{ __html: htmlLabel }}
          />
        )}
      </div>
    );
  },
);
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
