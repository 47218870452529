import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useLocation, useOutletContext } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import spacetime from 'spacetime';

import { BackNext } from '@/components/ui/back-next';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';
import {
  CreateCollectionDocument,
  EditCollectionDocument,
  GetCollectionByIdForEditDocument,
} from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import useBoundStore from '@/store';

import { listOfTimeZones, timezones } from '../../const/index';

const CreateCollection: FunctionComponent = () => {
  const [defaultValues, setDefaultValues] = useState<any>();
  const [createLoading, setCreateLoading] = useState<boolean>(false);

  const { toast } = useToast();
  const { id } = useParams();
  const selectedProject = useBoundStore((state) => state.projectSlice.selectedProject);
  const dispatchAddToCollections = useBoundStore(
    (state) => state.collectionsSlice.dispatchAddToAllCollections,
  );
  const dispatchUpdateCollection = useBoundStore(
    (state) => state.collectionsSlice.dispatchUpdateCollection,
  );
  const payoutPrograms = useBoundStore((state) => state.payoutProgramsSlice.payoutPrograms);

  type OutletContextType = [number, React.Dispatch<React.SetStateAction<number>>, string[]];
  const [currentLevel, setCurrentLevel, titles] = useOutletContext<OutletContextType>();

  const methods = useForm({
    defaultValues,
  });
  const navigate = useNavigate();
  const { handleSubmit } = methods;
  const { pathname } = useLocation();
  const path = `/${pathname.split('/')[1]}`;

  const isEdit = path == '/edit-collection';
  const [pageLoading, setPageLoading] = useState<boolean>(isEdit);

  const today = spacetime();
  const currentTimeInTZ = today.time();

  const currentDate = new Date();
  const twelveMonthsFromCurrentDate = spacetime.now().add(12, 'month');

  const handleFormatTime = (time, date, timeZone) => {
    try {
      const splitTime = time.split(':');
      const m = parseInt(splitTime[1]);
      const h = parseInt(splitTime[0]);
      const d = new Date(date).getDate();
      const mo = new Date(date).getMonth();
      const y = new Date(date).getFullYear();
      const formattedStartTime = new Date(y, mo, d, h, m, 0, 0).toLocaleString();
      return Math.floor(spacetime(formattedStartTime, timeZone).epoch / 1000);
    } catch (err) {
      console.error(err);
    }
  };

  const payoutProgramsDropdown = useMemo(() => {
    return payoutPrograms.map((item) => ({
      ...item,
      label: item.name,
      value: JSON.stringify(item),
    }));
  }, [payoutPrograms]);

  const findTimezone = useMemo(() => {
    try {
      const matchingTimezones = spacetime.whereIts(currentTimeInTZ);
      console.log('FOUND findTimezone');
      return listOfTimeZones.filter((timezone) =>
        matchingTimezones.includes(timezone.toLowerCase()),
      )[0];
    } catch (err) {
      console.log('Failed to findTimezone', err);
      return [spacetime().timezone().name][0];
    }
  }, []);

  const level1Inputs = [
    {
      sectionClassName: '',
      inputs: [
        {
          type: 'input',
          label: 'Name',
          state: 'name',
          placeholder: 'Enter collection name',
          requiredForNextStep: true,
        },
        {
          type: 'textarea',
          label: 'Description',
          textarea: true,
          state: 'description',
          placeholder: 'Enter collection description',
          requiredForNextStep: true,
        },
        {
          type: 'imageUpload',
          label: 'Logo Image',
          state: 'logo_image',
          smallImg: true,
          filesInModal: ['image'],
          requiredForNextStep: true,
          aspect: 1,
        },
        {
          type: 'imageUpload',
          label: 'Banner Image',
          state: 'cover_image',
          filesInModal: ['image'],
          requiredForNextStep: true,
          aspect: 5 / 1,
        },
      ],
    },
  ];

  const level2Inputs = useMemo<any[]>(() => {
    const levelTwoInputs: any = [
      {
        type: 'dropdown',
        label: 'Select Payout Program',
        state: 'payoutprogram',
        placeholder: 'Select state',
        options: payoutProgramsDropdown,
        requiredForNextStep: true,
      },
      {
        type: 'input',
        label: 'Links',
        state: 'twitter_link',
        placeholder: 'Your Twitter',
        halfWidth: true,
      },
      {
        type: 'input',
        state: 'tiktok_link',
        placeholder: 'Your Tiktok',
        halfWidth: true,
      },
      {
        type: 'input',
        state: 'facebook_link',
        placeholder: 'Your Facebook',
        halfWidth: true,
      },
      {
        type: 'input',
        state: 'whatsapp_link',
        placeholder: 'Your WhatsApp',
        halfWidth: true,
      },
      {
        type: 'input',
        state: 'instagram_link',
        placeholder: 'Your Instagram',
        halfWidth: true,
      },
      {
        type: 'input',
        state: 'discord_link',
        placeholder: 'Your Discord',
        halfWidth: true,
      },
      {
        type: 'input',
        state: 'youtube_link',
        placeholder: 'Your Youtube',
        halfWidth: true,
      },
      {
        type: 'input',
        state: 'telegram_link',
        placeholder: 'Your Telegram',
        halfWidth: true,
      },
      {
        type: 'input',
        state: 'linkedin_link',
        placeholder: 'Your Linkedin',
        halfWidth: true,
      },
      {
        type: 'input',
        state: 'external_link',
        placeholder: 'Your Website',
        halfWidth: true,
      },
    ];

    const timedateInputs = [
      {
        type: 'calendar',
        label: 'Start date',
        state: 'start_date',
        halfWidth: true,
        requiredForNextStep: true,
      },
      {
        type: 'timePicker',
        label: 'Start time',
        state: 'start_time',
        halfWidth: true,
        requiredForNextStep: true,
      },
      {
        type: 'calendar',
        label: 'End date',
        state: 'end_date',
        halfWidth: true,
        requiredForNextStep: true,
      },
      {
        type: 'timePicker',
        label: 'End time',
        state: 'end_time',
        halfWidth: true,
        requiredForNextStep: true,
      },
      {
        type: 'dropdown',
        label: 'Timezone',
        state: 'timezone',
        placeholder: 'Select',
        defaultValue: findTimezone,
        requiredForNextStep: true,
        options: timezones,
      },
    ];

    return [
      {
        sectionClassName: 'gap-[10px]',
        sectionInputsClassName: 'gap-[10px]',
        inputs: levelTwoInputs,
      },
    ];
  }, [path, payoutPrograms]);

  const handleGetCollectionData = async () => {
    try {
      const {
        data: { getCollectionById: collectionByIdRes },
      } = await client.query({
        query: GetCollectionByIdForEditDocument,
        variables: {
          collection_contract_address: `${id}`,
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      const {
        name,
        description,
        cover_image,
        logo_image,
        external_link,
        telegram_link,
        discord_link,
        tiktok_link,
        whatsapp_link,
        facebook_link,
        instagram_link,
        twitter_link,
        youtube_link,
        linkedin_link,
        payout_program_id,
      } = collectionByIdRes;
      console.log({ collectionByIdRes });
      const payoutProgram = payoutPrograms.filter(
        (item) => item.payout_program_id == payout_program_id,
      );
      const editDefaultData = {
        name,
        description,
        cover_image: { hostedUrl: cover_image },
        logo_image: { hostedUrl: logo_image },
        external_link,
        telegram_link,
        discord_link,
        tiktok_link,
        whatsapp_link,
        facebook_link,
        instagram_link,
        twitter_link,
        youtube_link,
        linkedin_link,
        payoutprogram: payoutProgram.length > 0 ? JSON.stringify(payoutProgram[0]) : {},
      };
      console.log({ editDefaultData });
      methods.reset(editDefaultData);
      setPageLoading(false);
    } catch (err) {
      console.log({ err });
    }
  };

  const handleCreateCollection = async (data) => {
    try {
      if (currentLevel == 0) {
        setCurrentLevel(currentLevel + 1);
      } else {
        console.log({ data });
        setCreateLoading(true);

        if (!selectedProject) {
          setCreateLoading(false);
          throw Error('missing selectedProject');
        }

        const createCollectionInput: any = {
          name: data.name,
          description: data.description,
          twitter_link: data.twitter_link,
          tiktok_link: data.tiktok_link,
          facebook_link: data.facebook_link,
          whatsapp_link: data.whatsapp_link,
          instagram_link: data.instagram_link,
          discord_link: data.discord_link,
          youtube_link: data.youtube_link,
          telegram_link: data.telegram_link,
          linkedin_link: data.linkedin_link,
          external_link: data.external_link,
          payout_program_id: JSON.parse(data.payoutprogram).payout_program_id,
          logo_image: data?.logo_image?.hostedUrl || data?.logo_image,
          cover_image: data?.cover_image?.hostedUrl || data?.cover_image,
        };
        let newObject = {};

        newObject = {
          is_mainnet: true,
          chain_id: selectedProject.chain_id,
        };
        console.log({ createCollectionInput });

        const res = await client.mutate({
          mutation: isEdit ? EditCollectionDocument : CreateCollectionDocument,
          variables: isEdit
            ? {
                input: { ...createCollectionInput, contract_address: id },
              }
            : {
                input: { ...createCollectionInput, ...newObject },
              },
          context: {
            clientName: ClientName.Marketplace,
          },
        });
        console.debug('🦊 | handleCreateCollection | res:', res);
        if (isEdit) {
          dispatchUpdateCollection({ ...createCollectionInput, contract_address: id });
        } else {
          dispatchAddToCollections({ collections: [createCollectionInput] });
        }
        setCreateLoading(false);
        navigate('/asset-home');
      }
    } catch (err) {
      setCreateLoading(false);
      toast({
        title: 'Error',
        variant: 'destructive',
        description: `${err}`,
      });
      console.log({ err });
    }
  };

  useEffect(() => {
    const tempValues = { ...defaultValues };
    tempValues.timezone = findTimezone;
    tempValues.start_time = currentDate.toTimeString().slice(0, 5);
    tempValues.start_date = currentDate;
    tempValues.end_time = `${twelveMonthsFromCurrentDate.hour()}:${twelveMonthsFromCurrentDate.minute()}`;
    tempValues.end_date = new Date(twelveMonthsFromCurrentDate.epoch);
    setDefaultValues(tempValues);
    methods.reset(tempValues);
  }, [methods]);

  useEffect(() => {
    if (isEdit && payoutPrograms.length > 0) {
      handleGetCollectionData();
    }
  }, [payoutPrograms]);

  return (
    <form
      className="relative mx-auto flex min-h-[800px] w-full max-w-[800px] flex-col gap-[25px]"
      onSubmit={handleSubmit(handleCreateCollection)}
    >
      <h1 className="text-3xl font-semibold"> {titles[currentLevel]} </h1>
      {pageLoading ? (
        <div className="flex h-full items-center justify-center">
          <div className="m-auto size-10 animate-spin rounded-full border-t-2 border-primary" />
        </div>
      ) : (
        <>
          {currentLevel == 0 && <WrappedInputSection methods={methods} data={level1Inputs} />}
          {currentLevel == 1 && (
            <>
              <div className="flex flex-col gap-[10px]">
                <WrappedInputSection
                  methods={methods}
                  data={[{ inputs: level2Inputs[0].inputs.slice(0, 1) }]}
                />
                <WrappedInputSection
                  methods={methods}
                  data={[
                    {
                      ...level2Inputs[0],
                      inputs: level2Inputs[0].inputs.slice(1, level2Inputs[0].inputs.length - 10),
                    },
                  ]}
                />
                <div className="mt-[30px]">
                  <WrappedInputSection
                    methods={methods}
                    data={[
                      {
                        ...level2Inputs[0],
                        inputs: level2Inputs[0].inputs.slice(level2Inputs[0].inputs.length - 10),
                      },
                    ]}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}

      <BackNext
        numOfLevels={2}
        finalBtnText={`${isEdit ? 'APPLY EDIT' : 'CREATE COLLECTION'}`}
        currentLevel={currentLevel}
        setCurrentLevel={setCurrentLevel}
        loading={createLoading}
      />
    </form>
  );
};

export default CreateCollection;

interface CreateCollectionInput {
  name: string;
  symbol?: string;
  description?: string;
  logo_image: string;
  cover_image?: string;
  seller_fee_basis_points?: number;
  minting_payer: string;
  blockchain: string; // : "ethereum" | "polygon"
  network: string;
  is_mainnet: boolean;
  is_public: boolean;
  external_link?: string;
  telegram_link?: string;
  discord_link?: string;
  tiktok_link?: string;
  whatsapp_link?: string;
  facebook_link?: string;
  instagram_link?: string;
  twitter_link?: string;
  youtube_link?: string;
  linkedin_link?: string;
  payout_program_id?: number;
  version?: ContractVersion;
  type?: string;
  is_custom_event?: boolean;
  show_on_home_page?: boolean;
  show_on_collection_page?: boolean;
}

type ContractVersion = '4.0.0';
