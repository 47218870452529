import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { AddButton } from '@/components/ui/add-button';
import { BackNext } from '@/components/ui/back-next';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Table } from '@/components/ui/table';

function TableInputsModal({
  open,
  setOpen = () => {},
  title = '',
  methods = {},
  tableState = [],
  setTableState = (arg) => {},
  tableData,
}: TableInputsProps) {
  const formValues = methods.watch();

  const tableColumns = useMemo(
    () => [...tableState, tableState ? tableData?.tableInputs : []],
    [tableState],
  );

  const handleFinalBtnClick = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open_) => {
        setOpen();
      }}
    >
      <DialogContent
        className="w-[1100px] max-w-[90%] rounded-[16px]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogHeader>
          <DialogTitle className="text-semibold mb-[16px] text-center text-[18px]">
            {title}
          </DialogTitle>
        </DialogHeader>
        <form
          className="flex min-h-[400px] flex-col overflow-y-scroll"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Table
            ariaLabel="table-inputs-modal"
            data={tableColumns}
            columnNames={tableData?.columnNames ?? []}
            hoverFade={false}
          />
          {tableState && (
            <AddButton
              alt
              className="ml-auto mt-[10px] w-[100px] py-[10px] shadow-md"
              onClick={(e) => {
                e.preventDefault();

                console.log({ formValues });
                setTableState([
                  ...tableState,
                  [
                    { value: formValues.name, className: 'py-[5px] text-md font-medium' },
                    {
                      value: formValues.classOfSecurity,
                      className: 'py-[5px] text-md font-medium',
                    },
                    { value: formValues.numOfShares, className: 'py-[5px] text-md font-medium' },
                    { value: formValues.percentage, className: 'py-[5px] text-md font-medium' },
                  ],
                ]);
                methods.reset();
              }}
            />
          )}
        </form>
        <div className="mx-auto mt-[30px] flex w-[500px]">
          <BackNext
            numOfLevels={1}
            finalBtnText={'Save & continue'}
            finalBtnClick={handleFinalBtnClick}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default TableInputsModal;

interface TableInputsProps {
  open: boolean;
  setOpen?: Function;
  tableData?: TableData;
  title: string;
  methods: any;
  setTableState: Function;
  tableState: any[];
}

interface Input {
  type: 'input';
  label: string;
  state: string;
  placeholder: string;
}

interface ScreenData {
  inputs: Input[];
}

interface TableInput {
  value: JSX.Element;
  className: string;
}

interface TableData {
  screenData: ScreenData[];
  columnNames: string[];
  tableInputs: TableInput[];
}
