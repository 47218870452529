import { useEffect, useMemo } from 'react';
import { isAddress } from 'ethereum-address';

export default function ProgressBar({
  royalties,
  field,
  label = '',
  unassigned = '',
  totalAlloactions,
  isEmailOrAddress,
}) {
  const typeColors = {
    [field]: [
      '#FFBD39',
      '#FF6661',
      '#9256FF',
      '#08C299',
      '#0895C2',
      '#E739F7',
      // others
      '#26BCDB',
      '#14CDD5',
      '#00DACC',
    ],
  };

  const sortedRoyalties = useMemo(() => {
    return royalties.sort((i, j) => j[field] - i[field]);
  }, [royalties]);

  const totalAllocationsLeft = useMemo(() => {
    return 100 - totalAlloactions(field);
  }, [totalAlloactions]);

  const formatWalletOrEmail = (input) => {
    const { isEmail, isAddress } = isEmailOrAddress(input);

    if (isEmail) {
      return input;
    } else if (isAddress) {
      return `${input.slice(0, 6)}...${input.slice(-4)}`;
    } else {
      return <span className="text-red-500">{input}</span>;
    }
  };

  return (
    <div className="rounded-2xl pb-[0px]" onClick={() => {}}>
      {label.length > 0 && <p className="mb-[15px]">{label}</p>}
      {totalAllocationsLeft < 0 ? (
        <div className="flex h-[36px] w-full rounded-full bg-red-500" />
      ) : (
        <div className="flex h-[36px] w-full overflow-hidden rounded-full">
          {sortedRoyalties.map(
            (r, index) =>
              r[field] > 0 && (
                <div
                  className={`relative h-full min-w-[30px] `}
                  style={{
                    width: `${parseFloat(r[field])}%`,
                    backgroundColor: typeColors[field][index],
                  }}
                  key={index}
                >
                  {/* <div className="absolute bottom-0 z-10 mb-[-45px] flex w-full flex-col items-center">
              <p className=" flex items-center text-xs">
                <div
                  className="mr-2 size-[10px] rounded-full"
                  style={{ backgroundColor: typeColors[field][index] }}
                />
                {r.wallet_address}
              </p>
              <p className="ml-[18px] mt-[3px] text-center text-sm font-bold">{`${parseFloat(
                r[field],
              )}%`}</p>
            </div> */}
                </div>
              ),
          )}
          {totalAllocationsLeft !== 0 && (
            <div
              className="relative w-full border bg-[#f3f3f3]"
              style={{ width: `${totalAllocationsLeft}%` }}
            >
              {/* <div className="absolute bottom-0 z-10 mb-[-45px] flex w-full flex-col items-center">
          <p className=" flex items-center text-xs">
            <div className="mr-2 size-[10px] rounded-full" />
            {unassigned}
          </p>
          <p className="ml-[18px] mt-[3px] text-center text-sm font-bold">{`${totalAllocationsLeft}%`}</p>
        </div> */}
            </div>
          )}
        </div>
      )}

      <div className="flex p-[20px]">
        {sortedRoyalties.map((r, index) => {
          if (parseFloat(r[field])) {
            return (
              <div className="mr-[40px]" key={index}>
                <div className=" flex items-center text-sm">
                  <div
                    className="mr-2 size-[10px] rounded-full"
                    style={{ backgroundColor: typeColors[field][index] }}
                  />
                  <p className="h-[20px]">{formatWalletOrEmail(r.user_id)}</p>
                </div>
                <p className="ml-[18px] mt-[10px] text-left text-sm font-bold">{`${parseFloat(
                  r[field],
                ).toFixed(3)}%`}</p>
              </div>
            );
          }
        })}
        <div className="mr-[40px]">
          <div className=" flex items-center text-sm">
            <div className="mr-2 size-[10px] rounded-full" style={{ backgroundColor: 'grey' }} />
            <p className="h-[20px]">{unassigned}</p>
          </div>
          <p className="ml-[18px] mt-[10px] text-left text-sm font-bold">{`${totalAllocationsLeft.toFixed(3)}%`}</p>
        </div>
      </div>
    </div>
  );
}
