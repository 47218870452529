import { useEffect, useMemo, useRef, useState } from 'react';
import { isAddress } from 'ethereum-address';
import { Controller, useFormContext } from 'react-hook-form';

import Add from '@/assets/Add.svg?react';
import Delete from '@/assets/Delete.svg?react';
import Edit from '@/assets/Edit.svg?react';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';

export default function ProgressInput({
  otherRoyalties = [],
  totalMintRoyalties,
  royaltyType,
  item = {},
  index = 0,
  addUserBtn = '+ ADD USER',
  setValidWalletAddresses,
  state,
  setQuantityErr,
  quantityErr,

  setUserIdErr,
  userIdErr,
  validateInputs,
  validWalletAddresses,
  stateField1 = '',
  quantity = '',
  total = '',
  isEmailOrAddress,
}: any) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const getTotalRoyalties = (type, currentRoyalties) => {
    let total = 0;
    [...otherRoyalties, ...currentRoyalties].forEach((user) => {
      total += user[type] ? parseInt(user[type], 10) : 0;
    });
    return total;
  };

  const handleInputRoyalty = (e, _field, index, type, setErr, totalpercentage) => {
    const inputValue = e.target.value;
    if (
      inputValue.length <= 5 &&
      inputValue.split('.').length - 1 < 2 &&
      (inputValue === '' || !isNaN(parseFloat(inputValue)))
    ) {
      console.log({ inputValue }, !isNaN(parseFloat(inputValue)));

      const copy = [..._field.value];
      const newRoyalty = inputValue === '' ? '' : inputValue;
      copy[index][type] = newRoyalty;
      copy[index].percentage = (parseInt(newRoyalty) / parseInt(total)) * 100;
      setErr('');
      console.log({ total });
      _field.onChange(copy);
      // const total = getTotalRoyalties(type, copy);
      // if (total > totalpercentage) {
      //   setErr(
      //     `All ${
      //       type == 'mint_royalties' ? 'mint' : 'secondary'
      //     } royalties must total to ${totalpercentage}`,
      //   );
      // } else {
      //   setErr('');
      // }
    }
  };

  return (
    <Controller
      control={control}
      name={state}
      rules={{ validate: (value) => validateInputs() || 'ErOR' }}
      render={({ field: _field }) => (
        <div className={'flex flex-col gap-[20px]'}>
          {_field.value?.map((item, index) => (
            <div
              key={index}
              className="box-border flex w-full flex-col justify-between gap-[15px] "
            >
              <div className="flex justify-evenly">
                <Input
                  label="Email or wallet Address"
                  placeholder=""
                  value={item[stateField1]}
                  isError={validWalletAddresses[index]}
                  underText={validWalletAddresses[index]}
                  // underText={userIdErr}
                  className="w-[98%]"
                  onChange={(e) => {
                    const copy = [..._field.value];
                    copy[index][stateField1] = e.target.value;
                    _field.onChange([...copy]);
                  }}
                />
                <Input
                  label="Quantity"
                  placeholder=""
                  value={item[quantity]}
                  isError={quantityErr.length > 0}
                  underText={quantityErr}
                  className="w-[98%]"
                  onChange={(e) => {
                    handleInputRoyalty(e, _field, index, quantity, setQuantityErr, 100);
                  }}
                />
                {index + 1 == _field.value.length ? (
                  <button
                    className="mt-auto flex size-[48px] w-full max-w-[48px] items-center justify-center rounded-full border bg-card"
                    // type="submit"
                    onClick={() => {
                      if (_field.value.length <= 0) {
                        _field.onChange([
                          {
                            [stateField1]: '',
                            [quantity]: '',
                          },
                        ]);
                        return;
                      }
                      const copy = [..._field.value];
                      _field.onChange([
                        ...copy,
                        {
                          [stateField1]: '',
                          [quantity]: '',
                        },
                      ]);
                    }}
                  >
                    <Add className="stroke-primary" />
                  </button>
                ) : (
                  <button
                    className="mt-auto flex size-[48px] w-full max-w-[48px] items-center justify-center rounded-full border bg-card"
                    // type="submit"
                    onClick={() => {
                      const copy = [..._field.value];
                      copy.splice(index, 1);
                      _field.onChange(copy);
                    }}
                  >
                    <Delete className="h-5 stroke-[#2D344E]" />
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    />
  );
}
