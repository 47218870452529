import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';

type DeletModalProps = {
  open: boolean;
  setOpen: (bool) => void;
  onConfirm: () => void;
  title?: string;
  subTitle?: string;
  nameToType?: string;
  confirmLoading?: boolean;
};

function ConfirmModal({
  open,
  setOpen = (bool) => {},
  title = '',
  subTitle = '',
  nameToType = '',
  onConfirm = () => {},
  confirmLoading = false,
}: DeletModalProps) {
  const [confirmText, setConfirmText] = useState('');

  const handleConfirm = async () => {
    if (nameToType === confirmText) {
      onConfirm();
    }
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open_) => {
        setOpen(!open);
      }}
    >
      <DialogContent
        className="flex w-[450px] max-w-[90%] flex-col rounded-[16px]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {title && <h1 className="text-xl font-medium">{title}</h1>}
        {subTitle && <p className="text-sm">{subTitle}</p>}
        {nameToType && (
          <div className="flex flex-col">
            <Input
              className="border p-[8px]"
              label={`Type "${nameToType}" to confirm.`}
              onChange={(e) => {
                setConfirmText(e.target.value);
              }}
              value={confirmText}
            />
          </div>
        )}
        <div className="flex w-full justify-end gap-[15px]">
          <Button
            className="w-[100px] rounded-md border bg-card py-[6px] text-black hover:bg-card"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="w-[100px] rounded-md border bg-primary py-[6px] text-card"
            onClick={handleConfirm}
            disabled={confirmText !== nameToType}
            loading={confirmLoading}
          >
            Confirm
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmModal;
