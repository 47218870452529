import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import QRCode from 'qrcode.react';
import ClampLines from 'react-clamp-lines';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import spacetime from 'spacetime';

import ConfirmModal from '@/components/modals/confirmation-modal';
import { Button } from '@/components/ui/button';
import CopyInput from '@/components/ui/copy-input';
import SectionDivider from '@/components/ui/section-divider';
import { Switch } from '@/components/ui/switch.tsx';
import { Table } from '@/components/ui/table';
import { handleDisplayTime } from '@/const/index';
import {
  DeleteNftDocument,
  EditNftDocument,
  GetNftByIdDocument,
} from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import useBoundStore from '@/store';
import { DownloadIcon, TableIcon } from '@radix-ui/react-icons';
import { Pencil2Icon, TrashIcon } from '@radix-ui/react-icons';

const AssetView: FunctionComponent<any> = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      show_on_home_page: true,
      show_on_collection_page: true,
    },
  });

  const navigate = useNavigate();

  const { id } = useParams();
  const [asset, setAsset] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const [deleteLoading, setDeleteloading] = useState<boolean>(false);

  const deleteAsset = useBoundStore((state) => state.assetsSlice.deleteAsset);
  const selectedProject = useBoundStore((state) => state.projectSlice.selectedProject);
  const reactURL = `${selectedProject?.react_urls}/view/${id}`;

  const handleGetData = async () => {
    try {
      const nftByIdRes = await client.query({
        query: GetNftByIdDocument,
        variables: {
          nft_id: `${id}`,
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      setAsset(nftByIdRes.data.getNftById);
      setValue('show_on_home_page', nftByIdRes.data.getNftById.show_on_home_page);
      setValue('show_on_collection_page', nftByIdRes.data.getNftById.show_on_collection_page);
      setLoading(false);
    } catch (err) {
      console.log({ err });
      setLoading(false);
    }
  };

  const handleEditNft = async (data) => {
    try {
      console.log(data);

      await client.mutate({
        mutation: EditNftDocument,
        variables: { input: { id, ...data } },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
    } catch (err) {
      console.debug('🦊 | handleEditNft | err:', err);
    }
  };

  const handleDeleteAsset = async () => {
    try {
      setDeleteloading(true);
      const {
        data: { deleteCollection: deleteCollectionRes },
      } = await client.mutate({
        mutation: DeleteNftDocument,
        variables: {
          nft_id: `${id}`,
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ deleteCollectionRes });
      setDeleteloading(false);
      deleteAsset(`${id}`);
      navigate('/asset-home');
    } catch (err) {
      console.log({ err });
      setDeleteloading(false);
    }
  };

  const downloadQR = () => {
    const canvas = document.getElementById('NftQRCode') as HTMLCanvasElement;
    console.log({ canvas });
    if (canvas) {
      const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      const downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = 'NFT_QRCode.png';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const assetPurchasesData = useMemo(() => {
    return {
      columnNames: ['User', 'Price', 'Date'],
      data: asset?.purchases?.map((item) => [
        {
          value: item.purchase_type,
          className: 'text-md font-semibold capitalize',
        },
        {
          value: `$${(item.value_paid_usd / 100 || asset.mint_price).toFixed(2)}`,
          className: 'text-md',
        },
        {
          value: spacetime(parseInt(item.created_at)).format('MM/DD/YYYY'),
          className: 'text-md',
        },
      ]),
    };
  }, [asset]);

  const assetDetailsValues = [
    { key: 'Supply', value: asset?.max_supply },
    { key: 'Price', value: `$${(asset?.mint_price / 100).toFixed(2)}` },
    {
      key: 'Start Time',
      value: spacetime(parseInt(asset?.mint_start_time)).format(
        '{month} {date} {year} at {hour}:{minute} {ampm}',
      ),
    },
    {
      key: 'Time Zone',
      value: handleDisplayTime(spacetime(asset?.mint_start_time).timezone()?.name),
    },
  ];

  const editDisplayOnToggles: ToggleItem[] = [
    { title: 'Display on Homepage', state: 'show_on_home_page' },
    { title: 'Display on Collection', state: 'show_on_collection_page' },
  ];

  useEffect(() => {
    handleGetData();
  }, []);

  const formValues = watch();
  useEffect(() => {
    console.log({ formValues });
  }, [formValues]);

  return (
    <div className="grid w-full max-w-[1100px] flex-col gap-[25px]">
      <div className="rounded-[5px] bg-card p-[30px]">
        <div className="relative flex w-full">
          {loading ? (
            <div className={`mr-[30px] min-h-[100px]  min-w-[150px] animate-pulse rounded-[5px]`} />
          ) : (
            <img
              className={`mr-[30px] max-w-[150px] object-contain object-top`}
              src={asset?.media_ipfs_link}
            />
          )}
          <div className="flex w-full flex-col gap-[5px]">
            {loading ? (
              <>
                <div className="h-[32px] w-[200px] animate-pulse rounded-[6px]" />
                <div className="h-[24px] w-[100px] animate-pulse rounded-[6px]" />
                <div className="mt-[10px] h-[24px] w-[350px] animate-pulse rounded-[6px]" />
              </>
            ) : (
              <>
                <h3 className="w-full pr-[260px] text-2xl font-semibold">{asset?.name}</h3>
                <h5 className="opacity-50">{asset?.collection_name}</h5>
                {asset?.description && (
                  <ClampLines
                    id="really-unique-id"
                    text={asset?.description}
                    lines={3}
                    ellipsis="..."
                    moreText="Show More"
                    lessText="Show Less"
                    className="mt-[10px]"
                    innerElement="p"
                  />
                )}
              </>
            )}
          </div>
          <div className="absolute right-0 top-0 ml-[auto] flex">
            <Button
              className="mr-[10px] flex h-auto w-[110px] items-center bg-[#C70000] py-[10px] font-medium text-white hover:bg-[#C70000]/90"
              loading={deleteLoading}
              onClick={() => {
                setConfirmModalOpen(true);
              }}
            >
              <TrashIcon className="mr-2 w-[15px] stroke-white" />
              Delete
            </Button>
            <Button
              className="flex h-auto items-center font-medium text-white"
              onClick={() => {
                navigate(`/edit-asset/${id}`);
              }}
            >
              <Pencil2Icon className="mr-3 stroke-white" />
              Edit Asset
            </Button>
          </div>
        </div>
      </div>
      <div className="flex gap-[25px]">
        <div className="relative flex w-full flex-col gap-[20px] rounded-[5px] bg-card p-[30px]">
          <h2 className="text-center text-xl font-semibold">Share Asset</h2>
          <div className="flex justify-center">
            <div className="w rounded-[10px] border-2 p-[10px]">
              <QRCode id="NftQRCode" value={reactURL} size={110} />
            </div>
          </div>
          <CopyInput value={reactURL} />
          <Button
            className="absolute bottom-0 right-0 mb-[98px] mr-[30px]"
            onClick={() => {
              downloadQR();
            }}
          >
            <DownloadIcon className="size-4 stroke-card" />
          </Button>
        </div>
        <div className="flex w-full flex-col gap-[15px] rounded-[5px] bg-card p-[30px]">
          {assetDetailsValues.map(({ key, value }, index) => (
            <div key={`details_value_${index}`} className="flex justify-between">
              <p className="opacity-50">{key}</p>
              <p
                className={
                  loading ? 'h-[24px] w-[100px] animate-pulse rounded-[6px]' : 'font-semibold'
                }
              >
                {loading ? '' : value}
              </p>
            </div>
          ))}
          <div className="flex w-full flex-col gap-[20px] border-t-2 pt-[15px]">
            {editDisplayOnToggles.map((item, index) => (
              <form
                key={`details_value_${index}`}
                className="relative flex justify-between"
                // onSubmit={handleSubmit(handleEditNft)}
              >
                <p className="opacity-50">{item.title}</p>
                {loading ? (
                  <div className="h-[24px] w-[52px] animate-pulse rounded-full" />
                ) : (
                  <Controller
                    control={control}
                    name={`${item?.state}`}
                    render={({ field }) => (
                      <Switch
                        key={`${field.value}`}
                        checked={field.value}
                        onCheckedChange={(bool) => {
                          field.onChange(bool);
                          handleEditNft({ [item?.state]: !field.value });
                        }}
                        className="absolute right-0"
                      />
                    )}
                  />
                )}
              </form>
            ))}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-[15px] rounded-[5px] bg-card p-[10px] py-[20px]">
        <SectionDivider
          titles={[`Purchases`]}
          img={<TableIcon className="size-4 stroke-card stroke-1" />}
          color="#FFBD39"
        />
        {assetPurchasesData?.data?.length > 0 ? (
          <Table
            ariaLabel="asset-purchase-history"
            columnNames={assetPurchasesData.columnNames}
            data={assetPurchasesData.data}
            isLoading={false}
          />
        ) : (
          <div className="flex h-[120px] items-center justify-center">
            <p className="opacity-60">This asset has no purchase history</p>
          </div>
        )}
      </div>
      <ConfirmModal
        open={confirmModalOpen}
        onConfirm={() => {
          handleDeleteAsset();
        }}
        confirmLoading={deleteLoading}
        title="Confirm Delete"
        nameToType={asset?.name}
        setOpen={() => {
          setConfirmModalOpen(!confirmModalOpen);
        }}
      />
    </div>
  );
};

export default AssetView;

type ToggleItem = {
  title: string;
  state: 'show_on_home_page' | 'show_on_collection_page';
};
