// import { getPayoutPrograms } from 'lib/api/query';

import { ListPayoutProgramsDocument } from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { lens } from '@dhmk/zustand-lens';

const initialPayoutProgramsSliceState: PayoutProgramsSliceState = {
  payoutPrograms: [],
  isLoading: true,
};

export const payoutProgramsSlice = lens<PayoutProgramsSlice>(
  (set, get): PayoutProgramsSlice => ({
    ...initialPayoutProgramsSliceState,
    reset: () => {
      set(initialPayoutProgramsSliceState, false, { type: 'payoutProgramsSlice/reset' });
    },
    dispatchSetIsLoading: (isLoading: PayoutProgramsSliceState['isLoading']) => {
      set({
        isLoading,
      });
    },
    dispatchAddToPayoutPrograms: ({
      payoutPrograms,
    }: Pick<PayoutProgramsSliceState, 'payoutPrograms'>) => {
      if (!payoutPrograms || !Array.isArray(payoutPrograms)) {
        throw Error('expecting payoutPrograms to be an array');
      }
      set((state) => ({
        payoutPrograms: payoutPrograms.concat(state.payoutPrograms),
        isLoading: false,
      }));
    },
    dispatchEditPayoutPrograms: ({ payout_program_id, payoutProgram }: EditPayoutProgramsInput) => {
      const payoutPrograms = [...get().payoutPrograms];

      const indexOfPayout = payoutPrograms.findIndex(
        (item) => item.payout_program_id === payout_program_id,
      );
      payoutPrograms.splice(indexOfPayout, 1, {
        ...payoutPrograms[indexOfPayout],
        ...payoutProgram,
        payout_program_id,
      });
      console.log({ indexOfPayout, payoutPrograms });
      set((state) => ({
        payoutPrograms: payoutPrograms,
        isLoading: false,
      }));
    },
    fetchPayoutPrograms: async () => {
      const dispatchSetIsLoading = get().dispatchSetIsLoading;
      const dispatchAddToPayoutPrograms = get().dispatchAddToPayoutPrograms;
      try {
        dispatchSetIsLoading(true);
        const response = await client.query({
          query: ListPayoutProgramsDocument,
          context: {
            clientName: ClientName.Marketplace,
          },
        });
        let getAllCollectionsRes;
        if (response.data && response.data.listPayoutPrograms) {
          getAllCollectionsRes = response.data.listPayoutPrograms;
          console.log({ getAllCollectionsRes });
        }
        // prettier-ignore
        dispatchAddToPayoutPrograms({
          payoutPrograms: getAllCollectionsRes,
        });
      } catch (getPayoutProgramsErr) {
        console.log({ getPayoutProgramsErr });
        dispatchSetIsLoading(false);
      }
    },
  }),
);

export type PayoutPrograms = any; // TODO: revisit

export type EditPayoutProgramsInput = {
  payout_program_id: string;
  payoutProgram: PayoutPrograms;
};

export interface PayoutProgramsSliceState {
  payoutPrograms: PayoutPrograms[]; // all
  id?: number;
  isLoading: boolean;
}

export interface PayoutProgramsSliceActions {
  reset: () => void;
  dispatchSetIsLoading: (isLoading: PayoutProgramsSliceState['isLoading']) => void;
  dispatchAddToPayoutPrograms: ({
    payoutPrograms,
  }: Pick<PayoutProgramsSliceState, 'payoutPrograms'>) => void;
  dispatchEditPayoutPrograms: ({
    payout_program_id,
    payoutProgram,
  }: EditPayoutProgramsInput) => void;
  fetchPayoutPrograms: () => Promise<void>;
}

export type PayoutProgramsSlice = PayoutProgramsSliceState & PayoutProgramsSliceActions;
