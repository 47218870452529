import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';

import Team from '@/assets/Team.svg?react';
import { Button } from '@/components/ui/button';
import TeamCard from '@/components/ui/team-card';
import { useToast } from '@/components/ui/use-toast';
import { AccessLevel, permissionNames } from '@/const';
import {
  AddUserToProjectDocument,
  GetProjectUsersDocument,
} from '@/lib/__generated__/dashboard/graphql';
import { client, ClientName } from '@/lib/apollo';
import useBoundStore from '@/store';

import SectionDivider from '../ui/section-divider';

const Settings: FunctionComponent<any> = () => {
  const selectedProject = useBoundStore((state) => state.projectSlice.selectedProject);
  const decodedToken = useBoundStore((state) => state.authSlice.decodedToken);
  const isSuperUser = decodedToken?.user?.super_user;
  const my_access_level = decodedToken?.projects?.[selectedProject?.project_name]?.access_level;

  const { toast } = useToast();

  const [team, setTeam] = useState<PermissionObject[] | []>([]);
  const [email, setEmail] = useState('');
  const [selectedPerm, setSelectedPerm] = useState(null);

  const [teamLoading, setTeamLoading] = useState(true);
  const [createTeamLoading, setCreateTeamLoading] = useState(false);

  const handleRemoveItem = (index) => {
    const team_ = [...team];
    console.log({ team_, index });
    team_.splice(index, 1);
    setTeam(team_);
  };

  const handleCreateUser = async (e) => {
    try {
      e.preventDefault();
      setCreateTeamLoading(true);
      const {
        data: { addUserToProject: addUserToProjectRes },
      } = await client.mutate({
        mutation: AddUserToProjectDocument,
        variables: {
          input: {
            email,
            // first_name: '',
            // last_name: '',
            // project_permissions: {
            project: selectedProject.project_name,
            // title: permissionNames[selectedPerm ?? AccessLevel.CREATE],
            access_level: selectedPerm ?? AccessLevel.CREATE,
            // },
          },
        },
        context: { clientName: ClientName.Dashboard },
      });
      console.log({ selectedPerm, team });
      setTeam([
        { email, permissions: { access_level: selectedPerm ? parseInt(selectedPerm) : undefined } },
        ...team,
      ]);
      setEmail('');
      setCreateTeamLoading(false);
      toast({
        title: 'Success!',
        description: 'The user has been added to this project',
        variant: 'success',
      });
      console.log({ addUserToProjectRes });
    } catch (error) {
      console.log({ error });
      const err = error as Error;
      setCreateTeamLoading(false);
      toast({
        title: 'error!',
        description: err.message,
        variant: 'destructive',
      });
    }
  };

  const handleGetProjectTeamRes = async () => {
    try {
      const {
        data: { getProjectUsers: getProjectTeamRes },
      } = await client.query({
        query: GetProjectUsersDocument,
        variables: { project_id: selectedProject.id },
        context: { clientName: ClientName.Dashboard },
      });
      setTeam(getProjectTeamRes);
      setTeamLoading(false);
      console.log({ getProjectTeamRes });
    } catch (err) {
      console.log({ err });
      setTeamLoading(false);
    }
  };

  useEffect(() => {
    console.log({ decodedToken });
    handleGetProjectTeamRes();
  }, [selectedProject]);

  return (
    <div className="grid w-full max-w-[1000px] flex-col gap-[35px]">
      <div className="flex flex-col gap-[20px]">
        <SectionDivider
          color={'#ffbd39'}
          titles={['Project team']}
          img={<Team className="fill-transparent stroke-white" />}
        />
        <div className="flex flex-col gap-[20px]">
          {(isSuperUser || my_access_level >= 50) && (
            <form className="flex items-center" onSubmit={handleCreateUser}>
              <p className="w-[130px] font-medium">Add New User:</p>
              <TeamCard
                item={{ email }}
                create
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                onSelect={(item) => {
                  setSelectedPerm(item);
                }}
              />
              <Button
                className="ml-[10px] h-[50px] w-[125px] px-[20px]"
                loading={createTeamLoading}
              >
                Add User +
              </Button>
            </form>
          )}
          <div className="flex flex-wrap justify-between gap-[10px]">
            {teamLoading
              ? [1, 1, 1, 1]?.map((item, index) => (
                  <TeamCard key={`teamMemberLoading_${index}`} isLoading />
                ))
              : team?.map((item, index) => (
                  <TeamCard
                    index={index}
                    key={`teamMember_${index}`}
                    item={item}
                    update
                    handleRemoveItem={handleRemoveItem}
                  />
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;

type PermissionObject = {
  email: string;
  permissions?: {
    title?: string;
    access_level?: number;
  };
};
