import { FunctionComponent, useState } from 'react';

import { cn } from '@/lib/utils';

const CollectionCard: FunctionComponent<any> = ({
  collection,
  onClick = () => {},
  loading = false,
  marginBottom = 0,
  className = '',
}) => {
  const isCreating = !collection.contract_address && !loading;

  return (
    <button
      className={cn(
        'box-border w-[308px] cursor-pointer rounded-[16px] border-4 border-card bg-card shadow-md transition-shadow duration-500 ease-in-out hover:shadow-lg',
        className,
      )}
      onClick={onClick}
      style={{ marginBottom }}
    >
      <div
        className={`relative h-32 overflow-hidden rounded-[12px] border-0 border-solid ${
          loading ? 'animate-pulse' : ''
        }`}
      >
        {!loading && (
          <img src={collection?.cover_image} className="size-full object-cover" alt="Cover" />
        )}

        {isCreating && (
          <button className="rounded-tl-12 absolute left-0 top-0 border-0 bg-primary px-2 py-1 text-xs font-normal text-white">
            CREATING...
          </button>
        )}
      </div>
      <div className="px-4 py-6">
        {loading ? (
          <p className="h-[24px] w-[100px] animate-pulse rounded-[6px]" />
        ) : (
          <p className="line-clamp-1 text-left text-base font-semibold">{collection?.name}</p>
        )}
        <p className="mt-2 line-clamp-2 min-h-[40px] text-left text-sm text-text-faded">
          {loading ? (
            <div className="h-[24px] w-[160px] animate-pulse rounded-[6px]" />
          ) : (
            collection?.description
          )}
        </p>
        <p className="mt-2 flex h-6 w-24 items-center justify-center rounded-md bg-primary-10 text-xs font-semibold">
          {loading ? (
            <div className="h-6 w-24 animate-pulse rounded-[6px]" />
          ) : (
            <>{collection.number_of_nfts || 0} Items</>
          )}
        </p>
      </div>
    </button>
  );
};

export default CollectionCard;
