import React, { DependencyList, useEffect, useRef, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import ReactCrop, { Crop, makeAspectCrop, PixelCrop } from 'react-image-crop';

import { Button } from '@/components/ui/button';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import ImageCropper from '@/components/ui/image-cropper';
import { generateUniqueFileName } from '@/const';
import { handleUpload } from '@/lib/imageUpload';
import { ChevronRightIcon } from '@radix-ui/react-icons';

import 'react-image-crop/dist/ReactCrop.css';

const CropImageSelector = ({
  open = true,
  setOpen = (bool) => {},
  title = 'Crop Your Image',
  imgSrc = '',
  handleConfirmCrop = (cropData) => {},
  aspect = undefined,
  fileState = {},
}: any) => {
  const [crop, setCrop] = useState<Crop | undefined>(undefined);
  const [imgSrcState, setImgSrcState] = useState('');
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [confirmIsLoading, setConfirmIsLoading] = useState(false);

  // Modify image URL variant
  const handleModifyVariantUrl = (imageUri, variant) => {
    if (imageUri?.includes('imagedelivery')) {
      const baseUrl = imageUri.substring(0, imageUri.lastIndexOf('/') + 1);
      return baseUrl + variant + '?fit=contain';
    } else {
      return imageUri;
    }
  };

  // Select file for cropping
  const onSelectFile = async () => {
    setCrop(undefined); // Makes crop preview update between images.
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setImgSrcState(reader.result?.toString() || '');
    });

    let file;
    if (fileState?.file?.s3_link) {
      const fileFromLink = await fetch(fileState.file.s3_link);
      const blob = await fileFromLink.blob();
      file = new File([blob], fileState.file.name, { type: fileState.file.mime_type });
    } else if (fileState?.file instanceof File) {
      file = fileState?.file;
    }

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (imgSrc.length > 0) {
      onSelectFile();
    }
  }, [imgSrc]);

  // Handle download and upload of cropped image
  const handleConfirmCropImg = async () => {
    setConfirmIsLoading(true);
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;

    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist');
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    );
    const ctx = offscreen.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    );

    const blob = await offscreen.convertToBlob({
      type: 'image/png',
    });
    const file = new File([blob], generateUniqueFileName(fileState.file.name), { type: blob.type });

    const hostedUrl = await handleUpload(file, false);

    handleConfirmCrop({
      ...fileState?.file,
      name: generateUniqueFileName(fileState.file.name),
      hostedUrl: hostedUrl.url,
    });
    setConfirmIsLoading(false);
    setOpen(false);
  };

  const handleSkip = async () => {
    console.log({ fileState, imgSrc });
    handleConfirmCrop({
      ...fileState?.file,
      hostedUrl: imgSrc,
    });
    setConfirmIsLoading(false);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open_) => {
        setOpen(!open);
      }}
    >
      <DialogContent
        className="box-border w-auto rounded-[16px] border-0 border-red-500 p-0 "
        onClick={(e) => {
          e.stopPropagation();
        }}
        noPadding
      >
        <div className="p-[15px]">
          <h1 className="mb-[15px] w-full text-center text-xl font-medium">{title}</h1>
          <ImageCropper
            crop={crop}
            setCrop={setCrop}
            aspect={aspect}
            imgSrc={handleModifyVariantUrl(imgSrcState, 'preCrop')}
            previewCanvasRef={previewCanvasRef}
            imgRef={imgRef}
            completedCrop={completedCrop}
            setCompletedCrop={setCompletedCrop}
          />
          <div className="mt-[10px] flex justify-end">
            <Button
              loading={confirmIsLoading}
              className="w-[105px] rounded-[5px] bg-primary px-[20px] py-[5px] text-background"
              onClick={() => {
                handleConfirmCropImg();
              }}
            >
              Confirm
            </Button>
            <button
              className="ml-[10px] flex items-center rounded-[5px] border-0 px-[10px] py-[5px] font-medium"
              onClick={() => {
                handleSkip();
              }}
            >
              Skip
              <ChevronRightIcon className="ml-[4px] size-5 stroke-text-color" />
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CropImageSelector;
