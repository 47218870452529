import React from 'react';

import { cn } from '@/lib/utils';
import { Pencil1Icon } from '@radix-ui/react-icons';

interface FileUploadLabelProps {
  uploaded: string;
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEditClick: () => void;
  className?: string;
}

const FileUploadLabel: React.FC<FileUploadLabelProps> = ({
  uploaded,
  onFileChange,
  onEditClick,
  className = '',
}) => {
  return (
    <label
      className={cn(
        'box-border flex h-[56px] min-w-[200px] cursor-pointer items-center justify-center border bg-card px-[12px] font-medium text-blue-600',
        className,
      )}
    >
      {uploaded ? (
        <>
          <p className="overflow-hidden truncate whitespace-nowrap text-sm font-normal">
            {uploaded}
          </p>
          <button
            type="button"
            className="ml-3 flex size-[27px] min-w-[27px] items-center justify-center rounded-full border border-primary"
            onClick={onEditClick}
          >
            <Pencil1Icon className="size-[14px] stroke-primary" />
          </button>
        </>
      ) : (
        'ADD'
      )}
      <input type="file" accept="image/*" className="hidden" onChange={onFileChange} />
    </label>
  );
};

export default FileUploadLabel;
