import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useLocation, useOutletContext } from 'react-router-dom';

import { BackNext } from '@/components/ui/back-next';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';
import { CreateUtilityDocument } from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { Stream } from '@cloudflare/stream-react';

const CreateUtility: FunctionComponent<any> = () => {
  const [defaultValues, setDefaultValues] = useState<any>({
    isExclusiveVideo: true,
  });
  const [currentLevel, setCurrentLevel, titles] = useOutletContext<OutletContextType>();
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [accessLink, setAccessLink] = useState('');

  const navigate = useNavigate();
  const methods = useForm({
    defaultValues,
  });
  const formValues = methods.watch();

  const { handleSubmit } = methods;

  const handleCreateUtil = async (data) => {
    try {
      if (currentLevel < 2) {
        setCurrentLevel(currentLevel + 1);
      } else {
        setCreateLoading(true);
        console.log({ data });
        const { name, description, cover_image_cid, cloudflare_video } = data;
        const utilityInput = {
          name,
          type: 'EXCLUSIVE_VIDEO',
          description,
          details: {
            cover_image_cid: cover_image_cid.hostedUrl,
            cloudflare_uid: cloudflare_video.url,
          },
        };
        const res = await client.mutate({
          mutation: CreateUtilityDocument,
          variables: {
            utilityInput,
          },
          context: {
            clientName: ClientName.Marketplace,
          },
        });
        navigate('/utilities');
      }
      setCreateLoading(false);
    } catch (err) {
      console.log({ err });
      setCreateLoading(false);
    }
  };

  const level1Inputs = [
    {
      sectionClassName: '',
      inputs: [
        {
          type: 'imageUpload',
          label: 'Thumbnail',
          state: 'cover_image_cid',
          requiredForNextStep: true,
          filesInModal: ['image'],
          noCrop: true,
        },
        {
          type: 'input',
          label: 'Name',
          state: 'name',
          placeholder: 'Exclusive video title',
          requiredForNextStep: true,
        },
        {
          type: 'textarea',
          label: 'Description',
          textarea: true,
          state: 'description',
          placeholder: 'Exclusive video description',
          requiredForNextStep: true,
        },
      ],
    },
  ];

  const level2Inputs = [
    {
      sectionClassName: '',
      inputs: [
        {
          type: 'checkboxColumn',
          state: 'isExclusiveVideo',
          options: [{ label: 'Select Exclusive Video', value: true }],
        },
        {
          type: 'videoUpload',
          state: 'cloudflare_video',
          sectionInputsClassName: 'px-[20px]',
          isTokengated: true,
          requiredForNextStep: true,
        },
        {
          type: 'checkboxColumn',
          state: 'isExclusiveVideo',
          options: [{ label: 'Enter Exclusive Link', value: false }],
        },
        {
          type: 'input',
          state: 'exclusiveLink',
          placeholder: 'Enter URL',
          sectionInputsClassName: 'px-[20px]',
        },
      ],
    },
  ];

  useEffect(() => {
    let checkInterval;
    let stopCheckTimeout;

    if (currentLevel === 2) {
      checkInterval = setInterval(() => {
        const uploadedVideo = localStorage.getItem('uploadedVideo');
        if (uploadedVideo) {
          setAccessLink(JSON.parse(uploadedVideo).accessLink);
          clearInterval(checkInterval); // Stop checking
          clearTimeout(stopCheckTimeout); // Clear the stop timeout
          localStorage.removeItem('uploadedVideo'); // Clear the item from localStorage
        }
      }, 1000); // Check every 1000 milliseconds (1 second)

      stopCheckTimeout = setTimeout(() => {
        clearInterval(checkInterval); // Stop checking after 60 seconds
      }, 60000); // 60 seconds
    }

    return () => {
      if (checkInterval) {
        clearInterval(checkInterval); // Clear interval when component unmounts or currentLevel changes
      }
      if (stopCheckTimeout) {
        clearTimeout(stopCheckTimeout); // Clear timeout
      }
    };
  }, [currentLevel]);

  useEffect(() => {
    console.log({ formValues });
  }, [formValues]);

  return (
    <form
      className="relative mx-auto flex min-h-[800px] w-full max-w-[800px] flex-col gap-[25px]"
      onSubmit={handleSubmit(handleCreateUtil)}
    >
      <h1 className="text-3xl font-semibold"> {titles[currentLevel]} </h1>
      <>
        {currentLevel == 0 && <WrappedInputSection methods={methods} data={level1Inputs} />}
        {currentLevel == 1 && (
          <div className="mx-auto ">
            <WrappedInputSection methods={methods} data={level2Inputs} />
          </div>
        )}
        {currentLevel == 2 && (
          <div className="flex flex-col gap-[65px]">
            <div className="flex">
              <div className="min-w-[400px] overflow-hidden rounded-[10px] border shadow-md">
                <img
                  className="h-[250px] w-full object-cover"
                  src={formValues?.cover_image_cid?.hostedUrl}
                />
                <div className="p-[20px]">
                  <h1 className="mb-[10px] text-xl font-semibold">{formValues.name}</h1>
                  <p className="text-md opacity-50">{formValues.description}</p>
                </div>
              </div>
              {/* <div className="w-full pl-[60px] pt-[20px]">
                <h1 className="mb-[20px] text-2xl font-semibold "> Your Exclusive Video: </h1>
                <div className="flex size-[300px] flex-col items-center justify-center rounded-[10px] border">
                  {accessLink?.length == 0 ? (
                    <div>
                      <div className="m-auto mb-[30px] size-10 animate-spin rounded-full border-t-2 border-primary" />
                      <p> Video Processing... </p>
                    </div>
                  ) : (
                    <Stream className="h-[300px] w-full" controls src={accessLink} />
                  )}
                </div>
              </div> */}
            </div>
          </div>
        )}
      </>
      <BackNext
        numOfLevels={3}
        finalBtnText={`CREATE UTILITY`}
        currentLevel={currentLevel}
        setCurrentLevel={setCurrentLevel}
        loading={createLoading}
      />
    </form>
  );
};

export default CreateUtility;

type OutletContextType = [number, React.Dispatch<React.SetStateAction<number>>, string[]];
