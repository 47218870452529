import React, { useEffect, useMemo, useRef, useState } from 'react';

// import { ReactComponent as Copy } from 'components/ProfileView/images/Copy.svg';
// import config from 'config';
// import { isLightMode } from 'const';
// import ReactTooltip from 'react-tooltip';
import { cn } from '@/lib/utils';
// import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { CheckIcon, CopyIcon } from '@radix-ui/react-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CopyText = ({ text = '', valueToCopy = '', className = '' }) => {
  const [codeCopied, setCodeCopied] = useState(false);

  return (
    <button
      className={cn(
        `flex cursor-pointer items-center justify-start duration-300 ${className}`,
        // isLightMode ? 'hover:bg-transparent-lm-dark' : 'hover:bg-transparent-dm-dark',
      )}
      onClick={() => {
        navigator.clipboard.writeText(valueToCopy.length > 0 ? valueToCopy : text);
        setCodeCopied(!codeCopied);
        setTimeout(() => {
          setCodeCopied(false);
        }, 1000);
      }}
      data-tip
      data-for="infoTool"
    >
      {text}
      {!codeCopied ? (
        <CopyIcon className="ml-[10px] min-w-[20px] max-w-[20px] cursor-pointer stroke-text-color" />
      ) : (
        <CheckIcon className="ml-[10px] min-w-[20px] max-w-[20px]" />
      )}
      {/* <ReactTooltip
        id="infoTool"
        place="left"
        effect="solid"
        getContent={() => (
          <div>
            <p className="text-sm text-white">Copy To Clipboard</p>
          </div>
        )}
      /> */}
    </button>
  );
};

export default CopyText;
