import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { cn } from '@/lib/utils';
import useBoundStore from '@/store';

type GlobalUseModalProps = {
  open: boolean;
  setOpen: (bool) => void;
  title?: string;
  subText?: string;
  buttonText?: string;
  onButtonClick?: () => void;
};

function GlobalUseModal({
  open = false,
  setOpen = (bool) => {},
  title = '',
  subText = '',
  buttonText = '',
  onButtonClick = () => {},
}: GlobalUseModalProps) {
  return (
    <Dialog
      open={open}
      onOpenChange={(open_) => {
        setOpen(!open);
      }}
    >
      <DialogContent
        className="w-[500px] max-w-[90%] rounded-[16px]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogHeader>
          <DialogTitle className="text-center text-[24px] font-semibold">{title}</DialogTitle>
        </DialogHeader>
        {subText && <p className="text-center ">{subText}</p>}
        {buttonText && (
          <Button className="mx-auto my-[10px] w-[300px]" onClick={onButtonClick}>
            {buttonText}
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default GlobalUseModal;
