import { useState } from 'react';

import { Input } from '@/components/ui/input';
import { CheckIcon, CopyIcon } from '@radix-ui/react-icons';

const CopyInput = ({ value }) => {
  const [copied, setCopied] = useState(false);

  return (
    <div
      className="relative cursor-pointer"
      onClick={() => {
        setCopied(true);
        navigator.clipboard.writeText(value);
        setTimeout(() => {
          setCopied(false);
        }, 1000);
      }}
    >
      <div className="absolute right-0 z-10 mr-[10px] flex h-full items-center">
        {copied ? (
          <CheckIcon className="h-6 w-6 bg-card stroke-[#78dd78]" />
        ) : (
          <CopyIcon className="h-5 w-5 bg-card" />
        )}
      </div>
      <Input value={value} onChange={() => {}} />
    </div>
  );
};

export default CopyInput;
