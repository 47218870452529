import { Control, Controller } from 'react-hook-form';

import { Checkbox } from '@/components/ui/checkbox';
import { cn } from '@/lib/utils';

interface RadioProps {
  options: { label: string; value: string }[];
  control: Control<Record<string, any>>;
  name?: string;
  label?: string;
  isVertical?: boolean;
  underText?: string;
  isError?: any;
  required?: boolean;
  callOnBlur?: (input) => void;
}

const RadioGroup = ({
  options,
  control,
  name = '',
  label = '',
  isVertical = true,
  underText = '',
  isError = '',
  required = false,
  callOnBlur = (input) => {
    console.log('INPUT BLUR', input);
  },
}: RadioProps) => {
  return (
    <div className="relative">
      {label?.length > 0 && <p className="mb-2 text-sm opacity-80">{label}</p>}
      <div className={isVertical ? '' : 'flex'}>
        {options.map((option, index) => (
          <Controller
            key={index}
            control={control}
            name={name}
            rules={{ required }}
            render={({ field }) => (
              <div
                className={`flex items-center text-sm opacity-80 ${
                  isVertical && index !== options.length - 1 ? 'mb-[10px]' : 'mr-[26px]'
                } ${index == options.length - 1 ? 'mb-[0px]' : ''}`}
              >
                <Checkbox
                  className={cn('bg-card', isError ? 'border-red-500' : '')}
                  checked={field.value === option.value}
                  onCheckedChange={(checked) => {
                    field.onChange(checked ? option.value : null);
                    callOnBlur({ [name]: option.value });
                  }}
                />

                <label
                  className="ml-2 text-sm"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {option.label}
                </label>
              </div>
            )}
          />
        ))}
      </div>
      <p
        className={`absolute ml-[5px] mt-[1px] text-xs opacity-80 ${isError ? 'text-red-500' : ''}`}
      >
        {underText}
      </p>
    </div>
  );
};

export { RadioGroup };
