import { FunctionComponent, useState } from 'react';

const RealEstateCard: FunctionComponent<any> = ({
  item,
  loading = false,
  onClick = () => {},
  smallNft = false,
}) => {
  const isCreating = item?.token_status !== 'ACTIVE' && !loading;

  return (
    <>
      <div
        className="box-border min-h-[340px] min-w-[244px] max-w-[244px] cursor-pointer rounded-[20px] border-4 border-card bg-card shadow-md transition-shadow duration-500 ease-in-out hover:shadow-lg"
        onClick={onClick}
      >
        <div className="flex h-full flex-col">
          <div
            className={`relative flex max-h-[236px] min-h-[236px] min-w-[236px] max-w-[236px] items-center justify-center overflow-hidden rounded-[16px] bg-gray-700 ${
              loading ? 'animate-pulse' : ''
            }`}
          >
            {!loading && <img src={item?.s3_link} />}
            {isCreating && (
              <button
                className={
                  'absolute left-0 top-0 ml-[10px] border-0 bg-primary px-2 py-1 text-xs font-normal text-white'
                }
              >
                CREATING
              </button>
            )}
          </div>
          <div className="flex h-full flex-col justify-between px-4 pb-2 pt-3">
            {loading ? (
              <p className="h-[20px] w-[100px] animate-pulse rounded-[6px]" />
            ) : (
              <p className="line-clamp-3 text-base font-semibold">{item?.name}</p>
            )}
            <div className="flex items-end justify-between">
              {!loading ? (
                <>
                  <p className="text-sm font-medium text-text-faded">
                    {parseFloat(item.price) > 0
                      ? `$${Intl.NumberFormat('en-US').format(item.price)}`
                      : 'Free'}
                  </p>
                  <p className="text-xs text-text-faded">
                    Target COC:
                    <strong className="ml-2">{item?.target_avg}%</strong>
                  </p>
                </>
              ) : (
                <>
                  <p className="h-[20px] w-[40px] animate-pulse rounded-[6px]" />
                  <p className="h-[20px] w-[70px] animate-pulse rounded-[6px]" />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RealEstateCard;
