import React, { useState } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import spacetime from 'spacetime';

import { cn } from '@/lib/utils';

export default function LinearChart({
  data = [{}],
  loading,
  xAxisLabel = '',
  dateKey = 'date',
  useMoment = true,
  priceKey = 'cc',
  total = '',
  title = '',
  selectedDate = 0,
  setSelectedDate = (arg) => {},
}) {
  const sortByDatesList = [{ label: '7D' }, { label: '1M' }, { label: '1Y' }, { label: 'All' }];

  if (loading)
    return (
      <ResponsiveContainer height={187}>
        <AreaChart data={data} margin={{ right: 0, left: 0, bottom: 0 }}>
          <CartesianGrid vertical={false} scale="linear" strokeDasharray="3 0" />
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="10%" stopOpacity={0.7} stopColor="#d9ddff" />
              <stop offset="90%" stopOpacity={0} stopColor="#FFFFFF" />
            </linearGradient>
          </defs>
        </AreaChart>
      </ResponsiveContainer>
    );

  return (
    <div className="rounded-[10px] border border-input">
      <div className="topSec flex justify-between p-[12px_15px]">
        <h4 className="text-text-color m-0 text-[16px] font-semibold">{title}</h4>
        <div className="rounded-[10px] border p-[3px]">
          {sortByDatesList.map((item, index) => (
            <button
              key={`date_${index}`}
              className={cn(
                'px-[8px] text-sm',
                index == selectedDate ? 'rounded-[8px] border border-primary' : '',
              )}
              onClick={() => {
                setSelectedDate(index);
              }}
            >
              {item.label}
            </button>
          ))}
        </div>
        <p className="m-0 font-medium">
          Total: <span className="text-[#63C94F]">{total}</span>
        </p>
      </div>
      <ResponsiveContainer height={189}>
        <AreaChart
          data={data}
          // label={}
          margin={{ right: 0, left: 0, bottom: 0 }}
        >
          <CartesianGrid vertical={false} scale="linear" strokeDasharray="3 0" />
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="30%" stopOpacity={0.7} stopColor="#d9ddff" />
              <stop offset="70%" stopOpacity={0} stopColor="#FFFFFF" />
            </linearGradient>
          </defs>

          <Tooltip
            content={({ active, payload, label }) => (
              <div className="rounded-[10px] border bg-card px-[10px] py-[5px] shadow-md">
                {useMoment ? (
                  <p className="time">
                    {data[label]
                      ? spacetime(data[label][`${dateKey}`] / 1).format('{month} {date} {year}')
                      : ''}
                  </p>
                ) : (
                  <p className="time">{data[label] ? data[label][`${dateKey}`] : ''}</p>
                )}
                <p className="price">
                  ${`${data[label] ? (data[label][`${priceKey}`] / 100).toFixed(2) : ''}`}
                </p>
              </div>
            )}
          />
          <Area
            type="monotoneX"
            dataKey={`total_sales`}
            // stroke="var(--warning)"
            strokeWidth={3}
            fillOpacity={1}
            fill="url(#colorUv)"
            stroke="#4555FF"
          />

          <YAxis
            ticks={[]}
            interval="preserveStart"
            dataKey="total_sales"
            tickFormatter={(tick) => `$${(tick / 100).toFixed(2)}`}
            axisLine={false}
            width={65}
            tickMargin={5}
            mirror={false}
            domain={[0, 'dataMax + 1000']}
            tick={{ fontSize: '14px' }}
          />
          <XAxis axisLine={false} tick={false}>
            <Label value={xAxisLabel} />
          </XAxis>
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
