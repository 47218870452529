import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import { cn } from '@/lib/utils';

const LinkCard = ({ item, imgClassName = '', disabled = false, withLink = true }) => {
  const navigate = useNavigate();

  return (
    <>
      {withLink ? (
        <a
          key={`${item}`}
          href={`${item.link}`}
          target={'_blank'}
          className={cn(
            'w-[348px] cursor-pointer rounded-[16px] bg-card p-[16px] pb-[0px] shadow-md',
            disabled ? 'cursor-not-allowed opacity-60' : '',
          )}
          rel="noreferrer"
        >
          <div className="bg-card-link flex h-[164px] w-full items-center justify-center rounded-[10px] border">
            <img className={imgClassName} src={item.img} />
          </div>
          <p className="mb-[25px] mt-[16px] text-center font-semibold">{item.title}</p>
        </a>
      ) : (
        <button
          key={`${item}`}
          className={cn(
            'w-[348px] cursor-pointer rounded-[16px] bg-card p-[16px] pb-[0px] shadow-md',
            disabled ? 'cursor-not-allowed opacity-60' : '',
          )}
          rel="noreferrer"
          onClick={() => {
            navigate(item.link);
          }}
        >
          <div className="bg-card-link flex h-[164px] w-full items-center justify-center rounded-[10px] border">
            <img className={imgClassName} src={item.img} />
          </div>
          <p className="mb-[25px] mt-[16px] text-center font-semibold">{item.title}</p>
        </button>
      )}
    </>
  );
};

export default LinkCard;
