import { useState } from 'react';

import { AddButton } from '@/components/ui/add-button';
import { DeleteButton } from '@/components/ui/delete-button';
import { SelectorDropdown } from '@/components/ui/selector-dropdown';

interface InputState {
  selected: string;
  inputValue: string;
}

interface InputWithDropdownProps {
  stateList: [] | InputState[];
  setStateList: Function;
}
function InputWithDropdown({ stateList = [], setStateList = (arg) => {} }: InputWithDropdownProps) {
  const handleDeleteItem = (index) => {
    const previousEmployment_ = [...stateList];
    previousEmployment_.splice(index, 1);
    setStateList(previousEmployment_);
  };

  return (
    <div className="flex flex-col gap-[10px]">
      {stateList.map((item, index) => (
        <div className="flex h-[48px]" key={`dropdown_input_${index}`}>
          <div className="mr-[5px] flex w-full rounded-[5px] border">
            <input
              className="w-full pl-[16px] text-sm"
              placeholder="Name of company"
              value={item.inputValue}
              onChange={(e) => {
                const previousEmployment_ = [...stateList];
                previousEmployment_[index].inputValue = e.target.value;
                setStateList(previousEmployment_);
              }}
            />
          </div>
          <div className="flex w-[220px] flex-col">
            <SelectorDropdown
              name={'projectSelect'}
              options={[
                { label: 'Role 1', value: 'Role 1' },
                { label: 'Role 2', value: 'Role 2' },
              ]}
              underText={''}
              isError={false}
              required={false}
              className="h-[48px] rounded-[6px] bg-card text-base"
              selected={item.selected}
              onSelect={(selectedItem, selectedIndex) => {
                console.log({ selectedItem });
                const previousEmployment_ = [...stateList];
                previousEmployment_[index].selected = selectedItem;
                setStateList(previousEmployment_);
              }}
            />
          </div>
          {index + 1 === stateList.length ? (
            <AddButton
              className="ml-[10px]"
              onClick={(e) => {
                setStateList([...stateList, { selected: '', inputValue: '' }]);
              }}
            />
          ) : (
            <DeleteButton
              className="ml-[10px]"
              onClick={(e) => {
                handleDeleteItem(index);
              }}
            />
          )}
        </div>
      ))}
    </div>
  );
}

export default InputWithDropdown;
