import { useForm } from 'react-hook-form';

import { BackNext } from '@/components/ui/back-next';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';

interface PastRaisesProps {
  open: boolean;
  setOpen: Function;
  setPastRaises: Function;
}
function PastRaisesModal({
  open,
  setOpen = () => {},
  setPastRaises = (arg) => {},
}: PastRaisesProps) {
  const methods = useForm();
  const formValues = methods.watch();

  const screenData1 = [
    {
      sectionClassName: 'mb-[22px]',
      inputs: [
        {
          type: 'input',
          label: 'What other securities have you authorized and issued',
          state: 'authorizedSecurities',
          placeholder: 'Describe authorized and issued securities',
        },
        {
          type: 'input',
          label: 'Number of Authorized Common Shares/Units',
          state: 'authorizedCommonShares',
          placeholder: 'Enter number of authorized common shares/units',
        },
        {
          type: 'currencyInput',
          label: 'Par Value',
          state: 'commonParValue',
          placeholder: 'Enter par value of common shares/units',
        },
        {
          type: 'input',
          label: 'Number of outstanding common shares/units',
          state: 'outstandingCommonShares',
          placeholder: 'Enter number of outstanding common shares/units',
        },
        {
          type: 'input',
          label: 'Number of authorized preferred shares/units',
          state: 'authorizedPreferredShares',
          placeholder: 'Enter number of authorized preferred shares/units',
        },
        {
          type: 'currencyInput',
          label: 'Par Value',
          state: 'preferredParValue',
          placeholder: 'Enter par value of preferred shares/units',
        },
        {
          type: 'input',
          label: 'Number of outstanding preferred shares/units',
          state: 'outstandingPreferredShares',
          placeholder: 'Enter number of outstanding preferred shares/units',
        },
      ],
    },
  ];

  const handleFinalBtnClick = () => {
    console.log({ formValues });
    methods.reset();
    setPastRaises(formValues);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open_) => {
        setOpen();
      }}
    >
      <DialogContent
        className="w-[700px] max-w-[90%] rounded-[16px]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogHeader>
          <DialogTitle className="text-semibold mb-[16px] text-center text-[18px]">
            Past raise
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col">
          <WrappedInputSection methods={methods} data={screenData1} />
          <BackNext
            className="mt-[20px]"
            numOfLevels={1}
            finalBtnText={'Save & continue'}
            finalBtnClick={handleFinalBtnClick}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default PastRaisesModal;
