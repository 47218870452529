import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import NFTImg from '@/assets/NFTImg.svg?react';
import ConfirmModal from '@/components/modals/confirmation-modal';
import { Button } from '@/components/ui/button';
import {
  DeleteCollectionDocument,
  GetAllNftsByCollectionDocument,
  GetCollectionByIdDocument,
} from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import useBoundStore from '@/store';
import { Pencil2Icon, TrashIcon } from '@radix-ui/react-icons';

import NftCard from '../ui/nft-card';
import SectionDivider from '../ui/section-divider';

const CollectionView: FunctionComponent<any> = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [assets, setAssets] = useState<any>([]);
  const [collection, setCollection] = useState<any>({});
  const [collectionLoading, setCollectionLoading] = useState<any>(true);
  const [nftsLoading, setNftsLoading] = useState<any>(true);
  const [deleteLoading, setDeleteloading] = useState<boolean>(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const deleteCollection = useBoundStore((state) => state.collectionsSlice.deleteCollection);

  const handleGetData = async () => {
    try {
      const collectionByIdRes = await client.query({
        query: GetCollectionByIdDocument,
        variables: {
          collection_contract_address: `${id}`,
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      setCollection(collectionByIdRes.data.getCollectionById);
      setCollectionLoading(false);

      const allNftsInCollection = await client.query({
        query: GetAllNftsByCollectionDocument,
        variables: {
          input: {
            collection_contract_address: `${id}`,
          },
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      setAssets(allNftsInCollection.data.getAllNftsByCollection);
      setNftsLoading(false);
    } catch (err) {
      console.log({ err });
    }
  };

  const handleDeleteCollection = async () => {
    try {
      setDeleteloading(true);
      const {
        data: { deleteCollection: deleteCollectionRes },
      } = await client.mutate({
        mutation: DeleteCollectionDocument,
        variables: {
          collection_contract_address: `${id}`,
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ deleteCollectionRes });
      setDeleteloading(false);
      deleteCollection(`${id}`);
      navigate('/home');
    } catch (err) {
      console.log({ err });
      setDeleteloading(false);
    }
  };

  useEffect(() => {
    handleGetData();
  }, []);

  return (
    <div className="grid w-full max-w-[1100px] flex-col gap-[25px]">
      <div className="relative flex h-[260px] w-full items-center overflow-hidden rounded-[12px] border">
        <img
          src={collection.cover_image}
          className={`absolute size-full object-cover ${collectionLoading ? 'animate-pulse' : ''}`}
        />
        <img
          src={collection.logo_image}
          className={`z-10 ml-[40px] size-[200px] rounded-[12px] object-contain ${
            collectionLoading ? 'animate-pulse' : ''
          }`}
        />
      </div>
      <div className="relative flex flex-col gap-[25px]">
        <div className="absolute right-0 top-0 ml-[auto] flex h-auto items-center py-[10px] font-medium">
          <Button
            className="mr-[10px] flex h-auto w-[110px] items-center bg-[#C70000] py-[10px] font-medium text-white hover:bg-[#C70000]/90"
            loading={deleteLoading}
            onClick={() => {
              setConfirmModalOpen(true);
            }}
          >
            <TrashIcon className="mr-2 w-[15px]  stroke-white" />
            Delete
          </Button>
          <Button
            className="flex h-auto items-center py-[10px] font-medium text-white"
            onClick={() => {
              navigate(`/edit-collection/${id}`);
            }}
          >
            <Pencil2Icon className="mr-3  stroke-white" />
            Edit Collection
          </Button>
        </div>

        {collectionLoading ? (
          <div className="h-[40px] w-[400px] animate-pulse rounded-[6px]" />
        ) : (
          <h1 className="pr-[200px] text-4xl font-semibold"> {collection.name} </h1>
        )}
        {collectionLoading ? (
          <div className="h-[24px] w-[250px] animate-pulse rounded-[6px]" />
        ) : (
          <p> {collection.description} </p>
        )}
      </div>
      <SectionDivider
        color={'#9256FF'}
        titles={[`Items (${assets.length})`]}
        img={<NFTImg className="stroke-white" stroke="white" />}
      />
      {nftsLoading ? (
        <div className="flex w-full flex-wrap gap-[20px]">
          {[{ loading: true }, { loading: true }].map((nft) => (
            <NftCard asset={nft} loading={nft.loading} />
          ))}
        </div>
      ) : (
        <>
          {assets.length > 0 ? (
            <div className="flex w-full flex-wrap gap-[20px]">
              {assets.map((nft, index) => (
                <NftCard
                  asset={nft}
                  onClick={() => {
                    if (nft.batch_id) {
                      navigate(`/asset/${nft.batch_id}`);
                    }
                  }}
                />
              ))}
            </div>
          ) : (
            <div className="flex h-[340px] items-center justify-center rounded-[20px] bg-card shadow-sm">
              <p className="opacity-60">This collection does not contain any NFTs</p>
            </div>
          )}
        </>
      )}
      <ConfirmModal
        open={confirmModalOpen}
        onConfirm={() => {
          handleDeleteCollection();
        }}
        confirmLoading={deleteLoading}
        title="Confirm Delete"
        nameToType={collection.name}
        setOpen={() => {
          setConfirmModalOpen(!confirmModalOpen);
        }}
      />
    </div>
  );
};

export default CollectionView;
