import { FormProvider, useForm } from 'react-hook-form';

import { InputsSection } from '@/components/ui/inputs-section';

const WrappedInputSection = ({ data, methods }) => {
  return (
    <FormProvider {...methods}>
      <InputsSection data={data} />
    </FormProvider>
  );
};

export { WrappedInputSection };
