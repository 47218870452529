import { lens } from '@dhmk/zustand-lens';

import { StoreType } from '.';

const initialLoadingOverlaySlice: LoadingOverlaySliceState = {
  isLoading: false,
};

export const loadingOverlaySlice = lens<LoadingOverlaySlice>(
  (set, get): LoadingOverlaySlice => ({
    ...initialLoadingOverlaySlice,
    setIsLoading: (isLoading) => {
      set({ isLoading });
    },
  }),
);
export const selectShowLoadingOverlay = (state: StoreType) => state.loadingOverlaySlice.isLoading;
export interface LoadingOverlaySliceState {
  isLoading: boolean;
}
export interface LoadingOverlaySliceActions {
  setIsLoading: (isLoading: LoadingOverlaySliceState['isLoading']) => void;
}
export type LoadingOverlaySlice = LoadingOverlaySliceState & LoadingOverlaySliceActions;
