import { lens } from '@dhmk/zustand-lens';

const initialProjectsSliceState: ProjectSliceState = {
  projects: [],
  selectedProject: null,
};

export const projectSlice = lens<ProjectSlice>(
  (set, get): ProjectSlice => ({
    ...initialProjectsSliceState,
    setProjects: (projects) => {
      set({ projects }, false, {
        type: 'projectSlice/setProjects',
        projects,
      });
    },
    setSelectedProject: (selectedProject) => {
      set({ selectedProject }, false, {
        type: 'projectSlice/setSelectedProject',
        selectedProject,
      });
    },
    reset: () => {
      set(initialProjectsSliceState, false, {
        type: 'projectSlice/reset',
      });
    },
  }),
);

export const selectMarketplaceClientIsReady = (state) =>
  Boolean(state.projectSlice.selectedProject?.graphql_endpoint_url);

import { Project } from '@/lib/__generated__/dashboard/graphql';

export interface ProjectSliceState {
  projects: Project[];
  selectedProject: null | Project;
}
export interface ProjectSliceActions {
  setProjects: (projects: ProjectSliceState['projects']) => void;
  setSelectedProject: (selectedProject: ProjectSliceState['selectedProject']) => void;
  reset: () => void;
}

export type ProjectSlice = ProjectSliceState & ProjectSliceActions;
