import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { isAddress } from 'ethereum-address';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import Adduser from '@/assets/Adduser.svg?react';
import Coins from '@/assets/Coins.svg?react';
import Divbox from '@/assets/Divbox.svg?react';
import { BackNext } from '@/components/ui/back-next';
import { useToast } from '@/components/ui/use-toast';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';
import {
  CreatePayoutProgramDocument,
  GetPayoutProgramDocument,
  UpdatePayoutProgramDocument,
} from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import useBoundStore from '@/store';

import RoyaltyInput from '../ui/royalty-input';
import RoyaltyProgressBar from '../ui/royalty-progress';
import SectionDivider from '../ui/section-divider';

const PayoutProgram: FunctionComponent<any> = () => {
  const methods = useForm({
    defaultValues: {
      royalties1: [
        {
          name: 'Owner',
          displayName: 'user 1',
          username: '',
          wallet_address: '',
          mint_royalties: '',
          secondary_royalties: '',
        },
      ],
      royalties2: [],
    },
  });

  const { handleSubmit } = methods;
  const { toast } = useToast();
  const { pathname } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  const path = `/${pathname.split('/')[1]}`;
  const isEdit = path == '/edit-payout-program';

  const dispatchAddToPayoutPrograms = useBoundStore(
    (state) => state.payoutProgramsSlice.dispatchAddToPayoutPrograms,
  );
  const dispatchEditPayoutPrograms = useBoundStore(
    (state) => state.payoutProgramsSlice.dispatchEditPayoutPrograms,
  );

  const [loading, setLoading] = useState(false);
  const [mintErr, setMintErr] = useState('');
  const [secondaryErr, setSecondaryErr] = useState('');
  const [validWalletAddresses, setValidWalletAddresses] = useState([true, true, true, true, true]);
  const [screenLoading, setScreenLoading] = useState(true);

  const formValues = methods.watch();

  const allRoyalties = useMemo(() => {
    return [...formValues.royalties1, ...formValues.royalties2];
  }, [formValues]);

  const getTotalRoyalties = useCallback(() => {
    let total_mint = 0;
    let total_secondary = 0;
    allRoyalties.forEach((user) => {
      total_mint += user.mint_royalties ? parseFloat(user.mint_royalties) : 0;
      total_secondary += user.secondary_royalties ? parseFloat(user.secondary_royalties) : 0;
    });
    return { total_mint, total_secondary };
  }, [formValues]);

  const handleMaxRangeReached = (new_value, index, royalty_type) => {
    try {
      const royaltyCopy = allRoyalties;
      royaltyCopy.splice(index, 1);
      const assignedFees = royaltyCopy.map((a) => a[royalty_type]);
      const sumOfRoyalties = assignedFees.reduce((a, b) => parseFloat(a) + parseFloat(b));
      return parseFloat(sumOfRoyalties) + parseFloat(new_value);
    } catch (err) {
      console.warn('error setting max range reached', err);
      return false;
    }
  };

  const totalMintRoyalties = useCallback(
    (royaltyType) => {
      let total = 0;
      allRoyalties.forEach((user) => {
        if (user[royaltyType]) {
          total += parseFloat(user[royaltyType]);
        }
      });
      return total;
    },
    [allRoyalties],
  );

  const validateInputs = useCallback(
    (data) => {
      let errstring = '';
      if (getTotalRoyalties().total_mint < 100 || getTotalRoyalties().total_mint > 100) {
        setMintErr(`All mint royalties must total to 100`);
        errstring = 'All mint royalties must total to 100';
      } else {
        setMintErr('');
      }
      if (getTotalRoyalties().total_secondary > 20) {
        setSecondaryErr(`All secondary royalties must be equal to or less than 20`);
        errstring = 'All mint royalties must total to 100';
      } else {
        setSecondaryErr('');
      }
      const payoutReceivers = [...formValues.royalties1];
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      const findEmptyWalletOrEmail = payoutReceivers.findIndex(
        (x) =>
          x.wallet_address == '' ||
          !!(!emailRegex.test(x.wallet_address) && !isAddress(x.wallet_address)),
      );
      if (findEmptyWalletOrEmail !== -1) {
        const validAddresses = [...validWalletAddresses];

        validAddresses[findEmptyWalletOrEmail] = false;
        setValidWalletAddresses(validAddresses);
        errstring = 'Email or Wallet address invalid';
      } else {
        setValidWalletAddresses([true, true, true, true, true]);
      }
      console.log({ data, errstring });
      return errstring.length > 0 ? errstring : true;
    },
    [getTotalRoyalties, validWalletAddresses],
  );

  const handleCreatePayoutProgram = async (data) => {
    try {
      console.log({ data });
      setLoading(true);
      const mintArray: { receiver: string; percent: number; tier: number; display_name: string }[] =
        [];
      const secondaryArray: {
        receiver: string;
        percent: number;
        tier: number;
        display_name: string;
      }[] = [];

      const mintReferralArray: { tier: number; percent: number }[] = [];
      const secondaryReferralArray: { tier: number; percent: number }[] = [];

      console.log({ allRoyalties });

      allRoyalties.forEach((r) => {
        if (r.displayName.includes('user')) {
          mintArray.push({
            receiver: r.wallet_address,
            percent: parseFloat(r.mint_royalties == '' ? '0' : r.mint_royalties),
            tier: mintArray.length + 1,
            display_name: r.displayName,
          });
          secondaryArray.push({
            receiver: r.wallet_address,
            percent: parseFloat(r.secondary_royalties == '' ? '0' : r.secondary_royalties),
            tier: secondaryReferralArray.length + 1,
            display_name: r.displayName,
          });
        } else {
          mintReferralArray.push({
            tier: mintReferralArray.length + 1,
            percent: parseFloat(r.mint_royalties == '' ? '0' : r.mint_royalties),
          });
          secondaryReferralArray.push({
            tier: secondaryReferralArray.length + 1,
            percent: parseFloat(r.secondary_royalties == '' ? '0' : r.secondary_royalties),
          });
        }
      });

      const newRoyaltyProgram = {
        name: data.name,
        royalties: {
          mint: mintArray,
          secondary: secondaryArray,
        },
        referrals: {
          mint: mintReferralArray,
          secondary: secondaryReferralArray,
        },
      };

      console.log({ newRoyaltyProgram });
      if (isEdit) {
        const {
          data: {
            updatePayoutProgram: { payout_program_id },
          },
        } = await client.mutate({
          mutation: UpdatePayoutProgramDocument,
          variables: {
            payoutProgramId: parseInt(`${id}`),
            payoutProgramInput: newRoyaltyProgram,
          },
          context: { clientName: ClientName.Marketplace },
        });
        dispatchEditPayoutPrograms({
          payoutProgram: newRoyaltyProgram,
          payout_program_id: parseInt(`${id}`),
        });
      } else {
        const {
          data: {
            createPayoutProgram: { payout_program_id },
          },
        } = await client.mutate({
          mutation: CreatePayoutProgramDocument,
          variables: { payoutProgramInput: newRoyaltyProgram },
          context: { clientName: ClientName.Marketplace },
        });
        dispatchAddToPayoutPrograms({
          payoutPrograms: [
            { ...newRoyaltyProgram, payout_program_id: parseInt(`${payout_program_id}`) },
          ],
        });
      }

      toast({
        title: 'Success!',
        description: 'A list has been successfully created',
        variant: 'success',
      });
      navigate('/asset-home');
      setLoading(false);
    } catch (err) {
      console.log({ err });
      setLoading(false);
    }
  };

  const handleGetPayout = async () => {
    try {
      const {
        data: { getPayoutProgram: getPayoutProgramRes },
      } = await client.query({
        query: GetPayoutProgramDocument,
        variables: {
          payout_program_id: parseInt(`${id}`),
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ getPayoutProgramRes });
      setScreenLoading(false);

      const userRoyalties = [];
      const referralRoyalties = [];

      getPayoutProgramRes.royalties.mint.forEach((item, index) =>
        userRoyalties.push({
          ...item,
          displayName: `user-${index + 1}`,
          secondary_royalties: getPayoutProgramRes.royalties.secondary[index].percent,
          wallet_address: item.receiver,
          mint_royalties: item.percent,
        }),
      );
      getPayoutProgramRes.referrals.mint.forEach((item, index) =>
        referralRoyalties.push({
          ...item,
          displayName: `referral-${index + 1}`,
          secondary_royalties: getPayoutProgramRes.referrals.secondary[index].percent,
          wallet_address: item.receiver,
          mint_royalties: item.percent,
        }),
      );
      console.log({ userRoyalties, referralRoyalties, name: getPayoutProgramRes.name });

      methods.reset({
        royalties1: userRoyalties,
        royalties2: referralRoyalties,
        name: getPayoutProgramRes.name,
      });

      // setTitle(getPayoutProgramRes.name);
      // setRoyalties(userRoyalties);
      // setRoyalties2(referralRoyalties);
    } catch (err) {
      console.log({ err });
    }
  };

  const nameInput = [
    {
      sectionClassName: '',
      inputs: [
        {
          type: 'input',
          label: 'Payout Program Name',
          state: 'name',
          placeholder: 'Enter Payout Program Name',
          requiredForNextStep: true,
        },
      ],
    },
  ];

  useEffect(() => {
    if (isEdit) {
      handleGetPayout();
    } else {
      setScreenLoading(false);
    }
  }, []);

  return (
    <div className="grid w-full max-w-[1100px] flex-col gap-[25px]">
      {screenLoading ? (
        <div className="flex h-[60vh] items-center justify-center">
          <div className="m-auto size-10 animate-spin rounded-full border-t-2 border-primary" />
        </div>
      ) : (
        <>
          <SectionDivider
            titles={[`Create Payout Program`]}
            img={<Coins className="stroke-white" stroke="white" />}
            color="#FFBD39"
          />
          <div>
            <p className="mb-2 font-semibold">How Payout Programs Work</p>
            <div className="rounded border bg-card px-[12px] py-[16px]">
              <p>
                Set the royalty percentage for
                <span className="font-medium text-primary"> On Mint Royalties (initial sale) </span>
                and the percentage for
                <span className="font-medium text-primary">
                  {' '}
                  Secondary Royalties (subsequential sales).{' '}
                </span>
                Name the program, include the desired number of users, and create a template to
                apply to Collections or Assets.
              </p>
            </div>
          </div>
          <WrappedInputSection methods={methods} data={nameInput} />
          <SectionDivider
            titles={[`Royalty Receivers`]}
            img={<Adduser stroke="white" />}
            color="#FF6661"
          />
          <FormProvider {...methods}>
            <RoyaltyInput
              methods={methods}
              state={'royalties1'}
              handleMaxRangeReached={handleMaxRangeReached}
              totalMintRoyalties={totalMintRoyalties}
              royaltyType="payout_receivers"
              otherRoyalties={formValues.royalties2}
              mintErr={mintErr}
              setMintErr={setMintErr}
              secondaryErr={secondaryErr}
              setSecondaryErr={setSecondaryErr}
              validateInputs={validateInputs}
              validWalletAddresses={validWalletAddresses}
              setValidWalletAddresses={setValidWalletAddresses}
            />
            <SectionDivider
              titles={[`Referral Tiers (optional)`]}
              img={<Adduser stroke="white" />}
              color="#FF6661"
            />
            <RoyaltyInput
              methods={methods}
              state={'royalties2'}
              handleMaxRangeReached={handleMaxRangeReached}
              addUserBtn={'+ Referral Tier (optional)'}
              totalMintRoyalties={totalMintRoyalties}
              royaltyType="secondary_royalties"
              otherRoyalties={formValues.royalties1}
              mintErr={mintErr}
              setMintErr={setMintErr}
              secondaryErr={secondaryErr}
              setSecondaryErr={setSecondaryErr}
              validateInputs={validateInputs}
              validWalletAddresses={validWalletAddresses}
              setValidWalletAddresses={setValidWalletAddresses}
            />
          </FormProvider>
          <SectionDivider
            titles={[`Payout Program Preview`]}
            img={<Divbox stroke="white" />}
            color="#9256FF"
          />
          <RoyaltyProgressBar
            totalMintRoyalties={totalMintRoyalties}
            royalties={allRoyalties}
            royaltyType="mint_royalties"
            label="On Mint Royalties"
          />
          <RoyaltyProgressBar
            totalMintRoyalties={totalMintRoyalties}
            royalties={allRoyalties}
            royaltyType="secondary_royalties"
            label="Secondary Royalties"
            unassigned={'Token Seller'}
          />
          <form
            onSubmit={handleSubmit(handleCreatePayoutProgram, (err) => {
              console.error('Invalid input handleCreatePayoutProgram', err);
              toast({
                title: 'Error',
                variant: 'destructive',
                description: 'Invalid input',
              });
            })}
          >
            <BackNext numOfLevels={1} finalBtnText="Create" loading={loading} />
          </form>
        </>
      )}
    </div>
  );
};

export default PayoutProgram;
