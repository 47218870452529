import { useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';

import { auth } from '@/lib/firebase';
import useBoundStore from '@/store';

export default function useFirebaseAuthSetup() {
  const setFirebaseAuthChecked = useBoundStore((state) => state.authSlice.setFirebaseAuthChecked);
  // const setEmailVerified = useBoundStore((state) => state.authSlice.setEmailVerified);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log('🦊 | useFirebaseAuthSetup | onAuthStateChanged | user:', user);
      // TODO: see if should save the user in the store or use those info some how or not, ot just need the accessToken
      setFirebaseAuthChecked(true);
      // not sure if need to set to the store. can just always grab from the auth?.currentUser?.emailVerified
      // setEmailVerified(user?.emailVerified ?? false);
      console.log('🦛 | user.emailVerified:', user?.emailVerified);
    });

    // Just return the unsubscribe function.  React will call it when it's
    // no longer needed.
    return unsubscribe;
  }, []);
}
