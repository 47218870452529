import React, { useState } from 'react';

const MovingGradientButton = ({
  children,
  className = '',
  style = {},
  backgroundImage = '',
  ...props
}) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setMousePosition({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <button
      className={`relative flex items-center justify-center overflow-hidden rounded-[10px] border bg-white shadow-md transition-all duration-500 ${className}`}
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={style}
      {...props}
    >
      <div
        className={`absolute inset-0 transition-opacity duration-500 ${isHovered ? 'opacity-100' : 'opacity-0'}`}
        style={{
          background: `radial-gradient(circle at ${mousePosition.x}px ${mousePosition.y}px, white 3%, rgba(0, 0, 0, 0.07) 80%)`,
        }}
      />
      {backgroundImage && (
        <img
          className="absolute top-0 object-cover opacity-[7%]"
          src={backgroundImage}
          alt="Background"
        />
      )}
      <span className="relative z-10 flex text-[32px] font-semibold text-text-color">
        {children}
      </span>
    </button>
  );
};

export default MovingGradientButton;
