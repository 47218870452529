import { FunctionComponent, useEffect, useState } from 'react';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const MyEditor = ({
  editorState = EditorState.createEmpty(),
  setEditorState = (text) => {},
  isError = '',
}: any) => {
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const toolbar = {
    options: [
      'inline',
      'blockType',
      'fontSize',
      'fontFamily',
      'list',
      'textAlign',
      'colorPicker',
      'link',
      'embedded',
      'emoji',
      'image',
      'remove',
      'history',
    ],
    inline: {
      inDropdown: false,
      className: 'wizinline',
      component: undefined,
      dropdownClassName: undefined,
      bold: { className: undefined },
      italic: { className: undefined },
      underline: { className: undefined },
      strikethrough: { className: undefined },
      monospace: { className: 'wizmonospace' },
      superscript: { className: 'wizsuperscript' },
      subscript: { className: 'wizsubscript' },
      indent: { className: 'wizindent' },
    },
    blockType: {
      inDropdown: true,
      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
      className: 'wizblockType',
    },
    fontSize: {
      icon: 'fontSize',
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
      className: 'wizfontSize',
    },
    fontFamily: {
      options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
      className: 'wizfontFamily',
    },
    list: {
      inDropdown: false,
      className: 'wizlist',
      indent: { className: 'wizindent' },
      outdent: { className: 'wizindent' },
    },
    textAlign: {
      inDropdown: false,
      className: 'wiztextAlign',
    },
    colorPicker: {
      icon: 'color',
      className: 'wizcolorPicker',
    },
    link: {
      inDropdown: false,
      className: 'wizlink',
    },
    emoji: {
      icon: 'emoji',
      className: 'wizemoji',
    },
    embedded: {
      icon: 'embedded',
      className: 'wizembedded',
    },
    image: {
      icon: 'image',
      className: 'wizimg',
    },
    remove: { icon: 'eraser', className: 'wizremove', component: undefined },
    history: {
      inDropdown: false,
      className: 'wizhistory',
    },
    indent: {
      className: 'wizindent',
    },
  };

  return (
    <div className={`editorContainer w-full ${isError ? 'editorError' : ''}`}>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbar={toolbar}
      />
    </div>
  );
};

export default MyEditor;
