import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import config from '@/config';
import useFirebaseAuthSetup from '@/hooks/use-firebase-auth-setup';
import useBoundStore from '@/store';
import { selectShowLoadingOverlay } from '@/store/loading-overlay-slice';

import LoadingOverlay from '../ui/loading-overlay';
import { Toaster } from '../ui/toaster';

function SimpleLayout() {
  useFirebaseAuthSetup();

  // theme setup
  useEffect(() => {
    if (config.isMetaworks) {
      const root = document.documentElement;
      root.setAttribute('data-theme', 'metaworks');
    }
  }, []);

  const showLoadingOverlay = useBoundStore(selectShowLoadingOverlay);

  return (
    <>
      {showLoadingOverlay && <LoadingOverlay />}
      <Outlet />
      <Toaster />
    </>
  );
}

export default SimpleLayout;
