import {
  GetUploadedCustomListsDocument,
  ListRewardsProgramsDocument,
  RewardsProgram,
  UploadedCustomList,
} from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { lens } from '@dhmk/zustand-lens';

const initialListsAndRewardsSliceState: ListsAndRewardsSliceState = {
  customListsLoading: false,
  customLists: [],
  rewardsProgramLoading: false,
  rewardsPrograms: [],
};

export const listsAndRewardsSlice = lens<ListsAndRewardsSlice>(
  (set, get): ListsAndRewardsSlice => ({
    ...initialListsAndRewardsSliceState,
    fetchCustomLists: async () => {
      set({ customListsLoading: true });
      const {
        data: { getUploadedCustomLists: getUploadedCustomListsRes },
      } = await client.query({
        query: GetUploadedCustomListsDocument,
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ getUploadedCustomListsRes });
      set({ customListsLoading: false, customLists: getUploadedCustomListsRes });
    },
    addCustomList: (customList) => {
      set({ customLists: [customList, ...get().customLists] });
    },
    fetchRewardsProgram: async () => {
      set({ rewardsProgramLoading: true });
      const {
        data: { listRewardsPrograms: listRewardsProgramsRes },
      } = await client.query({
        query: ListRewardsProgramsDocument,
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      set({ rewardsProgramLoading: false, rewardsPrograms: [...listRewardsProgramsRes].reverse() });
    },
    addRewardsProgram: (rewardsProgram) => {
      set({ customLists: [...get().customLists, rewardsProgram] });
    },
  }),
);

export interface ListsAndRewardsSliceState {
  customListsLoading: boolean;
  customLists: UploadedCustomList[];
  rewardsProgramLoading: boolean;
  rewardsPrograms: RewardsProgram[];
}
export interface ListsAndRewardsSliceActions {
  fetchCustomLists: () => void;
  addCustomList: (arg) => void;
  fetchRewardsProgram: () => void;
  addRewardsProgram: (arg) => void;
}
export type ListsAndRewardsSlice = ListsAndRewardsSliceState & ListsAndRewardsSliceActions;
