/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Progress } from '@/components/ui/progress';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';

const TransferAgentChecklist = () => {
  const methods = useForm();

  const EquityTypeInputs = [
    {
      type: 'input',
      label: 'Class of shares',
      state: 'classOfShares',
      placeholder: 'Enter class of shares',
      numOfIndents: 1,
      showIf: (offeringType) => offeringType == 'Equity',
      showBasedOn: 'offeringType',
    },
    {
      type: 'input',
      label: 'Name of share',
      state: 'nameOfShare',
      placeholder: 'Enter name of share',
      numOfIndents: 1,
      showIf: (offeringType) => offeringType == 'Equity',
      showBasedOn: 'offeringType',
    },
    {
      type: 'input',
      label: 'Voting rights',
      state: 'votingRights',
      placeholder: 'Enter voting rights',
      numOfIndents: 1,
      showIf: (offeringType) => offeringType == 'Equity',
      showBasedOn: 'offeringType',
    },
    {
      type: 'input',
      label: 'Price per share',
      state: 'pricePerShare',
      placeholder: 'Enter price per share',
      numOfIndents: 1,
      showIf: (offeringType) => offeringType == 'Equity',
      showBasedOn: 'offeringType',
    },
    {
      type: 'checkboxRow',
      label: 'Do they offer dividend',
      state: 'doTheyOfferDividend',
      placeholder: 'Do they offer dividend?',
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
      numOfIndents: 1,
      showIf: (offeringType) => offeringType == 'Equity',
      showBasedOn: 'offeringType',
    },
    {
      type: 'input',
      label: 'What interest rate',
      state: 'whatInterestRate',
      placeholder: 'Enter interest rate',
      numOfIndents: 2,
      showIf: (doTheyOfferDividend) => doTheyOfferDividend == 'Yes',
      showBasedOn: 'doTheyOfferDividend',
    },
    {
      type: 'checkboxColumn',
      label: 'Interest payment schedule',
      state: 'interestPaymentSchedule',
      placeholder: 'Select interest payment schedule',
      options: [
        { label: 'Accrued', value: 'Accrued' },
        { label: 'Monthly', value: 'Monthly' },
        { label: 'Quarterly', value: 'Quarterly' },
        { label: 'Annually', value: 'Annually' },
      ],
      numOfIndents: 2,
      showIf: (doTheyOfferDividend) => doTheyOfferDividend == 'Yes',
      showBasedOn: 'doTheyOfferDividend',
    },
    {
      type: 'checkboxRow',
      label: 'Is there a revenue share',
      state: 'isThereARevenueShare',
      placeholder: 'Is there a revenue share?',
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
      numOfIndents: 2,
      showIf: (doTheyOfferDividend) => doTheyOfferDividend == 'Yes',
      showBasedOn: 'doTheyOfferDividend',
    },
    {
      type: 'input',
      label: 'What interest rate',
      state: 'whatInterestRate',
      placeholder: 'Enter interest rate',
      numOfIndents: 3,
      showIf: (isThereARevenueShare) => isThereARevenueShare == 'Yes',
      showBasedOn: 'isThereARevenueShare',
    },
    {
      type: 'checkboxColumn',
      label: 'Interest payment schedule',
      state: 'interestPaymentSchedule',
      placeholder: 'Select interest payment schedule',
      options: [
        { label: 'Accrued', value: 'Accrued' },
        { label: 'Monthly', value: 'Monthly' },
        { label: 'Quarterly', value: 'Quarterly' },
        { label: 'Annually', value: 'Annually' },
      ],
      numOfIndents: 3,
      showIf: (isThereARevenueShare) => isThereARevenueShare == 'Yes',
      showBasedOn: 'isThereARevenueShare',
    },
  ];

  const DebtTypeInputs = [
    {
      type: 'input',
      label: 'Terms in months',
      state: 'termsInMonths',
      placeholder: 'Enter terms in months',
      numOfIndents: 1,
      showIf: (offeringType) => offeringType == 'Debt',
      showBasedOn: 'offeringType',
    },
    {
      type: 'input',
      label: 'Interest rate',
      state: 'interestRate',
      placeholder: 'Enter interest rate',
      numOfIndents: 1,
      showIf: (offeringType) => offeringType == 'Debt',
      showBasedOn: 'offeringType',
    },
    {
      type: 'checkboxColumn',
      label: 'Regulation type',
      state: 'regulationType',
      options: [
        { label: 'Accrued', value: 'Accrued' },
        { label: 'Monthly', value: 'Monthly' },
        { label: 'Quarterly', value: 'Quarterly' },
        { label: 'Annually', value: 'Annually' },
      ],
      placeholder: 'Select regulation type',
      numOfIndents: 1,
      showIf: (offeringType) => offeringType == 'Debt',
      showBasedOn: 'offeringType',
    },
    {
      type: 'checkboxRow',
      label: 'Does it convert',
      state: 'doesItConvert',
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
      placeholder: 'Does it convert?',
      numOfIndents: 1,
      showIf: (offeringType) => offeringType == 'Debt',
      showBasedOn: 'offeringType',
    },
    {
      type: 'input',
      label: 'What valuation cap',
      state: 'whatValuationCap',
      placeholder: 'Enter valuation cap',
      numOfIndents: 2,
      showIf: (doesItConvert) => doesItConvert == 'Yes',
      showBasedOn: 'doesItConvert',
    },
    {
      type: 'input',
      label: 'Discount rate',
      state: 'discountRate',
      placeholder: 'Enter discount rate',
      numOfIndents: 2,
      showIf: (doesItConvert) => doesItConvert == 'Yes',
      showBasedOn: 'doesItConvert',
    },
    {
      type: 'input',
      label: 'Price per share',
      state: 'pricePerShare',
      placeholder: 'Enter price per share',
      numOfIndents: 2,
      showIf: (doesItConvert) => doesItConvert == 'Yes',
      showBasedOn: 'doesItConvert',
    },
  ];

  const MOCKData = [
    {
      title: 'Transfer Agent Registration',
      sectionClassName:
        'relative mb-[32px] grid w-full gap-[16px] rounded-[8px] bg-card p-[28px] shadow-[2px_2px_24px_0px_rgba(0,0,0,0.1)] sm:p-[48px]',
      inputs: [
        {
          type: 'input',
          label: 'Company Legal Name',
          state: 'companyName',
          placeholder: 'Acme Corporation',
        },
        {
          type: 'input',
          label: 'Company Entity (Inc. or LLC, or Corp)',
          state: 'companyEntity',
          placeholder: 'Inc.',
        },
        {
          type: 'input',
          label: 'Company website URL',
          state: 'companyWebsite',
          placeholder: 'www.acmecorp.com',
        },
        {
          type: 'input',
          label: 'Company Full Address',
          state: 'companyAddress',
          placeholder: '123 Main St, Anytown, USA',
        },
        { type: 'input', label: 'CEO Name', state: 'CEOName', placeholder: 'John Doe' },
        {
          type: 'input',
          label: 'CEO Email',
          state: 'CEOEmail',
          placeholder: 'johndoe@acmecorp.com',
        },
        { type: 'phone', label: 'CEO Phone', state: 'CEOPhone', placeholder: '303-883-4261' },
        {
          type: 'input',
          label: 'Jurisdiction of company incorporation',
          state: 'CompanyJurisdiction',
          placeholder: 'Delaware, USA',
        },
        { type: 'calendar', label: 'Date of Incorporation', state: 'IncorporationDate' },
        {
          type: 'input',
          label: 'Tax identification number',
          state: 'TaxID',
          placeholder: '12-3456789',
        },
        {
          type: 'input',
          label: 'Company Sector',
          state: 'companySector',
          placeholder: 'Technology',
        },
        { type: 'input', label: 'Year-end date MM-DD', state: 'yearEnd', placeholder: '12-31' },
      ],
    },
    {
      title: 'Additional offering details',
      sectionClassName:
        'relative mb-[48px] grid w-full gap-[16px] rounded-[8px] bg-card p-[28px] shadow-[2px_2px_24px_0px_rgba(0,0,0,0.1)] sm:p-[48px]',
      inputs: [
        {
          type: 'checkboxColumn',
          label: 'Regulation type',
          state: 'regulationType',
          options: [
            { label: 'A', value: 'A' },
            { label: 'CF', value: 'CF' },
            { label: 'D', value: 'D' },
          ],
        },
        {
          type: 'input',
          label: 'Broker-Dealer',
          state: 'brokerDealer',
          placeholder: 'XYZ Brokerage',
        },
        {
          type: 'input',
          label: 'Escrow Provider',
          state: 'escrowProvider',
          placeholder: 'ABC Escrow Services',
        },
        {
          type: 'input',
          label: 'Legal name for the offering',
          state: 'legalNameForTheOffering',
          placeholder: 'Acme Corp Series A',
        },
        { type: 'input', label: 'CEO Phone', state: 'ceoPhone', placeholder: '303-883-4261' },
        { type: 'input', label: 'CEO name', state: 'ceoName', placeholder: 'John Doe' },
        {
          type: 'input',
          label: 'CEO email',
          state: 'ceoEmail',
          placeholder: 'johndoe@acmecorp.com',
        },
        { type: 'imageUpload', label: 'Offering document', state: 'offeringDocument' },
        { type: 'input', label: 'Form C/Form 1A', state: 'formCForm1A', placeholder: 'Form C' },
        {
          type: 'checkboxRow',
          label: 'Offering Type',
          state: 'offeringType',
          options: [
            { label: 'Equity', value: 'Equity' },
            { label: 'Debt', value: 'Debt' },
          ],
        },
        ...EquityTypeInputs,
        ...DebtTypeInputs,
        {
          type: 'input',
          label: 'Minimum investment',
          state: 'minimumInvestment',
          placeholder: '$1,000',
        },
        {
          type: 'input',
          label: 'Maximum investment',
          state: 'maximumInvestment',
          placeholder: '$10,000',
        },
        {
          type: 'input',
          label: 'Min raise amount for offering',
          state: 'minRaiseAmountForOffering',
          placeholder: '$100,000',
        },
        {
          type: 'input',
          label: 'Max raise an amount for offering',
          state: 'maxRaiseAnAmountForOffering',
          placeholder: '$1,000,000',
        },
        {
          type: 'input',
          label: 'URL for the offering page',
          state: 'urlForTheOfferingPage',
          placeholder: 'www.acmecorp.com/offering',
        },
        { type: 'input', label: 'SSL on each site', state: 'sslOnEachSite', placeholder: 'Yes' },
      ],
    },
  ];

  useEffect(() => {
    const scrollableElement = document.getElementById('authLayoutContainerId');
    if (scrollableElement) {
      scrollableElement.scrollTo(0, 0);
    }
  }, []);

  return (
    <div className="mx-[20px] flex flex-col items-center">
      <h1 className="mb-[28px] mt-[44px] text-center text-[2rem] font-medium sm:mb-[48px] sm:mt-[64px] sm:text-[2.75rem]">
        Transfer Agent Checklist
      </h1>
      <div className="sticky top-0 z-10 mb-[20px] w-full lg:mb-[0px] lg:ml-[-160px] lg:w-[800px] transferAgentWidth:ml-[-360px]">
        <div className="left-0 w-full pt-[20px] lg:absolute lg:w-[155px]">
          <p className="mb-[12px] text-lg font-semibold">Progress</p>
          <Progress value={85} />
          <p className="mt-[8px] text-right text-sm font-light">
            Percentage: <span className="font-semibold text-primary">85%</span>{' '}
          </p>
        </div>
      </div>
      <div className="w-full lg:ml-[200px] lg:w-[800px] transferAgentWidth:ml-[0px]">
        <WrappedInputSection methods={methods} data={MOCKData} />
      </div>
      <button className="mb-[48px] rounded-[4px] bg-primary px-[32px] py-[16px] text-base uppercase text-white ">
        Save & continue
      </button>
    </div>
  );
};

export default TransferAgentChecklist;
