import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';

import Blocks from '@/assets/Blocks.svg?react';
import DigitalAsset from '@/assets/DigitalAsset.png';
import Realestate from '@/assets/Realestate.png';
import Security from '@/assets/Security.png';
import Stablecoin from '@/assets/Stablecoin.png';
import Ticket from '@/assets/Ticket.png';
import TokengatedVid from '@/assets/TokengatedVid.png';
import TokenStar1 from '@/assets/TokenStar1.png';
import TokenStar2 from '@/assets/TokenStar2.png';
import LinkCard from '@/components/ui/link-card';
import config from '@/config';
import useBoundStore from '@/store';

import SectionDivider from '../ui/section-divider';

import 'react-horizontal-scrolling-menu/dist/styles.css';

const Home: FunctionComponent<any> = () => {
  const selectedProject = useBoundStore((state) => state.projectSlice.selectedProject);

  const offerings = [
    {
      title: 'Digital Asset',
      img: DigitalAsset,
      link: '/asset-home',
    },
    {
      title: 'Real estate offering',
      img: Realestate,
      link: '/all-real-estate',
    },
    {
      title: 'Security Offering',
      img: Security,
      link: config.isMetaworks ? '' : '/company-home',
    },
  ];

  const access = [
    {
      title: 'Token-Gated Video',
      img: TokengatedVid,
      link: '/utilities',
    },
    {
      title: 'Ticketed Events',
      img: Ticket,
      link: '',
    },
  ];

  const tokens = [
    {
      title: 'Rewards Token',
      img: TokenStar2,
      link: '/asset-home',
    },
    {
      title: 'Project Stable coins',
      img: TokenStar1,
      link: '',
    },
    {
      title: 'Currency Token',
      img: Stablecoin,
      link: '',
    },
  ];

  return (
    <div className="grid w-full max-w-[1300px] flex-col gap-[25px]">
      <SectionDivider
        color={'#9256FF'}
        titles={['My Marketplace']}
        img={<Blocks className="stroke-white" />}
      />
      <div className="flex h-[50px] items-center rounded-[5px] bg-card shadow-sm">
        <strong className="flex min-w-[120px] justify-center text-lg">URL:</strong>
        <input className="w-full text-lg" value={selectedProject?.react_urls} />
        <button
          className="mr-[2px] h-[40px] w-[180px] rounded-[5px] bg-primary text-base font-semibold text-white"
          onClick={() => {
            window.open(selectedProject?.react_urls);
          }}
        >
          GO TO SITE
        </button>
      </div>
      <h2 className="text-2xl font-semibold"> Offerings </h2>
      <div className="flex gap-[24px]">
        {offerings.map((item, index) => (
          <LinkCard
            withLink={false}
            disabled={item.link == ''}
            item={item}
            imgClassName="h-[120px]"
          />
        ))}
      </div>
      <h2 className="text-2xl font-semibold"> Access </h2>
      <div className="flex gap-[24px]">
        {access.map((item, index) => (
          <LinkCard
            withLink={false}
            disabled={item.link == ''}
            item={item}
            imgClassName="h-[120px]"
          />
        ))}
      </div>
      <h2 className="text-2xl font-semibold"> Tokens </h2>
      <div className="flex gap-[24px]">
        {tokens.map((item, index) => (
          <LinkCard withLink={false} disabled item={item} imgClassName="h-[120px]" />
        ))}
      </div>
    </div>
  );
};

export default Home;
