/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';

import Delete from '@/assets/Delete.svg?react';
import Edit from '@/assets/Edit.svg?react';
import PastRaisesModal from '@/components/modals/past-raise-modal';
import { AddButton } from '@/components/ui/add-button';
import { BackNext } from '@/components/ui/back-next';

const PastRaises = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [pastRaises, setPastRaises] = useState<any[]>([]);

  const handleSetPastRaises = (pastRaiseObj = { asdf: '' }) => {
    setPastRaises([pastRaiseObj, ...pastRaises]);
  };

  const handleDeleteRaise = (index) => {
    const pastRaises_ = [...pastRaises];
    pastRaises_.splice(index, 1);
    console.log({ pastRaises_ });
    setPastRaises(pastRaises_);
  };

  const matchedObject = {
    authorizedSecurities: 'What other securities have you authorized and issued',
    authorizedCommonShares: 'Number of Authorized Common Shares/Units',
    commonParValue: 'Par Value',
    outstandingCommonShares: 'Number of outstanding common shares/units',
    authorizedPreferredShares: 'Number of authorized preferred shares/units',
    preferredParValue: 'Par Value',
    outstandingPreferredShares: 'Number of outstanding preferred shares/units',
  };

  useEffect(() => {
    const scrollableElement = document.getElementById('authLayoutContainerId');
    if (scrollableElement) {
      scrollableElement.scrollTo(0, 0);
    }
  }, []);

  return (
    <div className="relative mx-[20px] flex min-h-[800px] w-full max-w-[800px] flex-col items-center">
      <h1 className="mb-[28px] w-full text-left text-[2rem] font-medium sm:mb-[32px]  sm:text-[2rem]">
        Past Raises & loans
      </h1>
      {pastRaises.length == 0 && (
        <div className="mb-[22px] w-full">
          <p className="mb-[12px] text-sm opacity-80">Past Raise</p>
          <button
            className={
              'h-[50px] w-full border bg-card font-medium uppercase text-primary shadow-md'
            }
            onClick={() => {
              setOpenModal(true);
            }}
            type="button"
          >
            add a past raise
          </button>
        </div>
      )}
      {pastRaises.length > 0 && (
        <>
          {pastRaises.map((item, index) => {
            const parsedItems = Object.keys(item);
            return (
              <div className="mb-[22px] w-full" key={item}>
                <p className="mb-[12px] text-sm opacity-80">Past Raise #{index + 1}</p>
                <div
                  className={
                    'relative flex max-h-[180px] w-full flex-col gap-[10px] overflow-hidden rounded-[10px] border bg-card p-[30px] shadow-md'
                  }
                >
                  {parsedItems.map((innerItem) => (
                    <div className="flex flex-col border-b pb-[10px]" key={innerItem}>
                      <div className="absolute right-0 top-0 flex p-[20px] ">
                        <button
                          className="mr-[10px] flex size-[35px] items-center justify-center rounded-full border border-text-color opacity-50"
                          onClick={() => {
                            handleDeleteRaise(index);
                          }}
                        >
                          <Delete className="w-[20px]" />
                        </button>
                        {/* <button className="flex size-[35px] items-center justify-center rounded-full border border-primary">
                          <Edit />
                        </button> */}
                      </div>
                      <p className="mr-[10px] text-lg font-light opacity-60">
                        {matchedObject[innerItem]}:
                      </p>
                      <p className="text-lg ">{item[innerItem]}</p>
                    </div>
                  ))}
                  <div className="absolute bottom-0 left-0 flex h-[100px] w-full items-end justify-end bg-gradient-to-b from-transparent to-gray-100 pr-[50px]" />
                </div>
              </div>
            );
          })}
          <div className="mb-[22px] w-full">
            <p className="mb-[12px] text-sm opacity-80">Past raise #{pastRaises.length + 1}</p>
            <AddButton
              className="shadow-md"
              alt
              onClick={(e) => {
                setOpenModal(true);
              }}
            />
          </div>
        </>
      )}
      <div className="border"></div>
      <BackNext numOfLevels={1} finalBtnText={'Save & continue'} />
      <PastRaisesModal
        open={openModal}
        setOpen={setOpenModal}
        setPastRaises={handleSetPastRaises}
      />
    </div>
  );
};

export default PastRaises;
