import Decimal from 'decimal.js';
// import { ITransactionEventRes, IUserTransactionEvent } from 'interface/schema.interface';
// import { IStoreUserTransactions } from 'interface/store/walletSlice.interface'; // IMPORT THESE

// import { isNullOrUndefined } from './checkEmpty';
// import { autoFormatNumber } from './formatter';

const DEFAULT_DECIMAL_TO_FIX = {
  USD: 2,
  MATIC: 2,
  WETH: 5,
  ETH: 5,
};

const isNullOrUndefined = (val) => {
  return val === undefined || val === null;
};

const isEmpty = (val) => {
  if (isNullOrUndefined(val)) return true;
  if (typeof val === 'string') return val.trim() === '';
  return false;
};

export const numberWithCommas = (number: number | string): string => {
  const str = number.toString().split('.');
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return str.join('.');
};

export const safeParseFloat = (floatStr): number | string | null | undefined => {
  if (!floatStr) return floatStr;
  if (typeof floatStr !== 'string') {
    // console.warn('not string so return the input as it is');
    return floatStr;
  }
  // return parseFloat(floatStr.replace(/,/g, ''));
  return new Decimal(floatStr.replace(/,/g, '')).toNumber();
};

export const formatNumber = (
  number: string | number,
  toFix: undefined | number = undefined,
  useCommaFormat = true,
): string => {
  if (isEmpty(number) || isNaN(number as number)) return '';
  const num = safeParseFloat(number) as number;
  let ret;
  if (typeof toFix === 'number') ret = num.toFixed(toFix);
  if (useCommaFormat) ret = numberWithCommas(ret);
  return ret;
};

export const autoFormatNumber = (
  number: string | number,
  currency?: string,
  _decimal?: number,
): string => {
  const decimal = _decimal ?? DEFAULT_DECIMAL_TO_FIX[`${currency}`];
  if (decimal === undefined) {
    console.warn(`${currency} not in DEFAULT_DECIMAL_TO_FIX`);
    return String(number);
  }
  return formatNumber(number, decimal);
};

export const formatToStoreUserTransactions = (
  userTransactions: Array<Partial<any>>,
  userWalletAddress: string,
): any => {
  return userTransactions
    .filter((trans) => !!trans.primary_event) // filter out primary event null
    .map((trans) => {
      const { primary_event, asset_info, receipt } = trans as {
        primary_event?: any;
        asset_info: {
          name?: string;
          decimals?: number;
          media_ipfs_link?: string;
          icon_url?: string;
        };
        receipt?: {
          name: string;
          transaction_hash: string;
        };
      };
      const {
        event_id,
        nft_id,
        event_type,
        transaction_hash,
        ui_price,
        ui_to,
        ui_from,
        media_ipfs_link,
        media_mime_type,
        nft_name,
        created_at,
      } = primary_event;
      const priceUsd = !isNullOrUndefined(ui_price)
        ? new Decimal(ui_price as number).div(100).toNumber()
        : null;
      const priceUsdString = !isNullOrUndefined(ui_price)
        ? autoFormatNumber(`${priceUsd}`, 'USD')
        : null;
      const isMoneyOut = ui_from !== userWalletAddress;
      const address = isMoneyOut ? (ui_from as string) : (ui_to as string);
      return {
        event_id,
        nft_id,
        event_type,
        transaction_hash,
        ui_price,
        ui_to,
        ui_from,
        media_ipfs_link,
        media_mime_type,
        nft_name,
        created_at,
        priceUsdString,
        isMoneyOut,
        address,
        asset_info,
        receipt,
        primary_event,
      };
    });
};
