/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import TableInputsModal from '@/components/modals/table-inputs-modal';
import { AddButton } from '@/components/ui/add-button';
import { BackNext } from '@/components/ui/back-next';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';
import { yesOrNoDropdownInputs } from '@/const';

const Financials = () => {
  const [defaultValues, setDefaultValues] = useState<any>({
    attributes: [{ field: '', value: '' }],
  });
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [currentInputs, setCurrentInputs] = useState<any[]>([]);
  const [tableStates, setTableStates] = useState({
    'Class of Securities': [],
    'Loans and Other Debt': [],
    Warrants: [],
    'Prior Offerings': [],
  });

  const methods = useForm({ defaultValues });

  const screenData1 = [
    {
      title: '',
      sectionClassName: '',
      sectionInputsClassName: '',
      inputs: [
        {
          type: 'currencyInput',
          label: 'Amount spent on R&D',
          state: 'rdAmount',
          placeholder: 'Enter amount spent on R&D',
        },
        {
          type: 'textarea',
          label: 'Real Property ownership or leases',
          state: 'realProperty',
          placeholder: 'Describe real property ownership or leases',
        },
        {
          type: 'currencyInput',
          label: 'What is your monthly burn rate',
          state: 'monthlyBurnRate',
          placeholder: 'Enter monthly burn rate',
        },
        {
          type: 'checkboxRow',
          label:
            'Will the Company require additional financing in excess of the offering proceeds in the next 12 months?',
          state: 'additionalFinancing',
          options: yesOrNoDropdownInputs,
          placeholder: 'Enter financing requirements',
        },
        {
          type: 'checkboxRow',
          label:
            'Does the Company have additional sources of capital other than the offering proceeds?',
          state: 'additionalCapitalSources',
          options: yesOrNoDropdownInputs,
          placeholder: 'Describe additional sources of capital',
        },
        {
          type: 'checkboxRow',
          label: 'Are additional sources of capital necessary?',
          state: 'capitalNecessary',
          placeholder: 'Explain necessity of additional capital',
          options: yesOrNoDropdownInputs,
        },
        {
          type: 'input',
          label: 'Has the company made any material capital expenditures over the last two years',
          state: 'materialExpenditures',
          placeholder: 'Describe material capital expenditures',
        },
        {
          type: 'textarea',
          label: 'What are planned capital expenditures over the next 12 months',
          state: 'plannedExpenditures',
          placeholder: 'Enter estimated capital expenditures',
        },
        {
          type: 'keyValueInput',
          label: 'Properties (Optional)',
          state: 'attributes',
          keyPlaceholder: 'Name',
          valuePlaceholder: 'Relationship',
        },
        {
          type: 'textarea',
          label:
            'Describe there any conflicts of interest between the Company and any related person',
          state: 'conflictsOfInterest',
          placeholder: 'Provide details of any conflicts of interest',
        },
        {
          type: 'input',
          label: 'How may the terms of the securities offered change',
          state: 'securitiesTermsChange',
          placeholder: 'Describe how terms may change',
        },
        {
          type: 'textarea',
          label:
            'Describe the financial condition of the company, please include the extent material, liquidity, capital resources, and historical results of operations.',
          state: 'financialCondition',
          placeholder: 'e.g. "Masked Ape"',
        },
        {
          type: 'checkboxColumn',
          label: 'Do you have a CIK under the current company name?',
          state: 'cikExists',
          options: [
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ],
        },
        {
          type: 'input',
          label: 'If so, what is it',
          state: 'cikNumber',
          placeholder: 'Enter CIK number',
        },
      ],
    },
  ];

  const addButtons = [
    {
      title: 'Class of Securities',
      completed: false,
    },
    {
      title: 'Loans and Other Debt',
      completed: false,
    },
    {
      title: 'Warrants',
      completed: false,
    },
    {
      title: 'Prior Offerings',
      completed: false,
    },
  ];

  // INPUTS FOR MODAL
  const classOfSecuritiesInputs: any[] = [
    // Class of Securities
    {
      type: 'input',
      label: 'Class',
      state: 'className',
      placeholder: 'Enter class name',
    },
    {
      type: 'input',
      label: 'Amount outstanding',
      state: 'amountOutstanding',
      placeholder: 'Enter amount outstanding',
    },
    {
      type: 'input',
      label: 'Voting rights',
      state: 'votingRights',
      placeholder: 'Enter voting rights',
    },
    {
      type: 'input',
      label: 'Dividend rights',
      state: 'dividendRights',
      placeholder: 'Enter dividend rights percentage',
    },
    {
      type: 'input',
      label: 'Anti-dilution rights',
      state: 'antiDilutionRights',
      placeholder: 'Enter anti-dilution rights percentage',
    },
    {
      type: 'input',
      label: 'Liquidation rights',
      state: 'liquidationRights',
      placeholder: 'Enter liquidation rights percentage',
    },
  ];

  const loansAndOtherDebtInputs: any[] = [
    // Loans and other debt
    {
      type: 'input',
      label: 'Type of debt',
      state: 'debtType',
      placeholder: 'Enter type of debt',
    },
    {
      type: 'input',
      label: 'Name of creditor',
      state: 'creditorName',
      placeholder: 'Enter name of creditor',
    },
    {
      type: 'input',
      label: 'Amount outstanding',
      state: 'amountOutstanding',
      placeholder: 'Enter amount outstanding',
    },
    {
      type: 'input',
      label: 'Interest rate',
      state: 'interestRate',
      placeholder: 'Enter interest rate',
    },
    {
      type: 'input',
      label: 'Collateral or security',
      state: 'collateralOrSecurity',
      placeholder: 'Enter collateral or security details',
    },
    {
      type: 'input',
      label: 'Maturity date',
      state: 'maturityDate',
      placeholder: 'Enter maturity date',
    },
    {
      type: 'input',
      label: 'Other material terms',
      state: 'materialTerms',
      placeholder: 'Enter other material terms',
    },
  ];

  const warrantsInputs: any[] = [
    // Warrants
    {
      type: 'input',
      label: 'Underlying security',
      state: 'underlyingSecurity',
      placeholder: 'Enter underlying security',
    },
    {
      type: 'input',
      label: 'Amount outstanding',
      state: 'amountOutstanding',
      placeholder: 'Enter amount outstanding',
    },
    {
      type: 'input',
      label: 'Strike price',
      state: 'strikePrice',
      placeholder: 'Enter strike price',
    },
    {
      type: 'input',
      label: 'Expiration date',
      state: 'expirationDate',
      placeholder: 'Enter expiration date',
    },
    {
      type: 'input',
      label: 'Name of holder',
      state: 'holderName',
      placeholder: 'Enter name of holder',
    },
    {
      type: 'input',
      label: 'Other material terms',
      state: 'materialTerms',
      placeholder: 'Enter other material terms',
    },
  ];

  const priorOfferings: any[] = [
    // Prior Offerings
    {
      type: 'input',
      label: 'Security Type',
      state: 'securityType',
      placeholder: 'Enter security type',
    },
    {
      type: 'input',
      label: 'Number sold',
      state: 'numberSold',
      placeholder: 'Enter number sold',
    },
    {
      type: 'input',
      label: 'Money raised',
      state: 'moneyRaised',
      placeholder: 'Enter amount of money raised',
    },
    {
      type: 'input',
      label: 'Use of Proceeds',
      state: 'useOfProceeds',
      placeholder: 'Enter use of proceeds',
    },
  ];

  const modalContents = {
    'Class of Securities': classOfSecuritiesInputs,
    'Loans and Other Debt': loansAndOtherDebtInputs,
    Warrants: warrantsInputs,
    'Prior Offerings': priorOfferings,
  };

  const getCurrentTableState = (title: string) => ({
    tableState: tableStates[title],
    setTableState: (newState: any[]) => {
      setTableStates((prev) => ({
        ...prev,
        [title]: newState,
      }));
    },
  });

  const { tableState, setTableState } = getCurrentTableState(modalTitle);

  const openModalWithContent = (title: string) => {
    setModalTitle(title);
    setCurrentInputs(modalContents[title]);
    setOpenModal(true);
  };

  const tableInputs = currentInputs.map((input) => {
    return {
      value: (
        <WrappedInputSection
          methods={methods}
          data={[
            {
              sectionClassName: 'rounded-[5px] absolute top-0 ml-[5px] left-0 w-[97%] mt-[5px]',
              inputs: [{ ...input, label: '' }],
            },
          ]}
        />
      ),
      className: 'pt-[4px] relative h-[60px]',
    };
  });
  const inputTableData = {
    screenData: [
      {
        inputs: currentInputs,
      },
    ],
    columnNames: currentInputs.map((input) => input.label),
    tableInputs,
  };

  useEffect(() => {
    const scrollableElement = document.getElementById('authLayoutContainerId');
    if (scrollableElement) {
      scrollableElement.scrollTo(0, 0);
    }
  }, []);

  return (
    <div className="relative mx-[20px] flex w-full max-w-[800px] flex-col items-center">
      <h1 className="mb-[28px] w-full text-left text-[2rem] font-medium sm:mb-[32px]  sm:text-[2rem]">
        Financials
      </h1>
      <WrappedInputSection
        methods={methods}
        data={[{ inputs: screenData1[0].inputs?.slice(0, 2) }]}
      />
      <div className="my-[22px] flex w-full ">
        {addButtons.map((item) => (
          <div className="mr-[20px]" key={item.title}>
            <p className="mb-[12px] text-sm opacity-80">{item.title}</p>
            <AddButton
              className=""
              alt
              onClick={(e) => {
                // setOpenModal1(true);
                openModalWithContent(item.title);
              }}
            />
          </div>
        ))}
      </div>
      <WrappedInputSection
        methods={methods}
        data={[{ inputs: screenData1[0].inputs?.slice(2, 99) }]}
      />
      <div className="mt-[30px] w-full">
        <BackNext className="mt-[20px]" numOfLevels={1} finalBtnText={'Save & continue'} />
      </div>

      <TableInputsModal
        title={modalTitle}
        tableData={inputTableData}
        open={openModal}
        setOpen={setOpenModal}
        methods={methods}
        tableState={tableState}
        setTableState={setTableState}
      />
    </div>
  );
};

export default Financials;
