import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import UtilityIcon from '@/assets/UtilityIcon.svg?react';
import { ListUtilitiesDocument } from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';

import SectionDivider from '../ui/section-divider';
import UtilCard from '../ui/utility-card';

const Utilities: FunctionComponent<any> = () => {
  const [utils, setUtils] = useState<UtilCardType[]>([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const handleGetUtils = async () => {
    try {
      const {
        data: { listUtilities: listUtilitiesRes },
      } = await client.query({
        query: ListUtilitiesDocument,
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      // when there is error sometimes we get [null] as response
      const nonNull = listUtilitiesRes.filter((item) => Boolean(item));
      setUtils(nonNull);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetUtils();
  }, []);

  return (
    <div className="grid w-full max-w-[1100px] flex-col gap-[25px]">
      <SectionDivider
        titles={[`Token Gated Videos`]}
        img={<UtilityIcon className="stroke-white" stroke="white" />}
        color="#9256ff"
      />
      <div className="grid grid-cols-3 gap-[20px]">
        <button
          className="hover:bg-card-faded flex h-[293px] w-full items-center justify-center overflow-hidden rounded-[16px] border-4 border-card bg-card shadow-md transition-colors duration-200 ease-in"
          onClick={() => {
            navigate('/create-exclusive-video');
          }}
        >
          <p className="ml-[-15px] flex font-semibold text-primary">+ Create Token Gated Video</p>
        </button>
        {loading
          ? [1, 1, 1, 1].map((item, index) => <UtilCard loading />)
          : utils.map((item, index) => (
              <UtilCard
                key={`util_${index}`}
                name={item?.name}
                description={item?.description}
                image={item?.details?.cover_image_cid}
                active={item?.details?.active}
                onClick={() => {
                  navigate(`/utility-view/${item?.utilityId}`);
                }}
              />
            ))}
      </div>
    </div>
  );
};

export default Utilities;

type UtilCardType = {
  name: string;
  description: string;
  utilityId: string;
  details: {
    cover_image_cid: string;
  };
};
