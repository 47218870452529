import * as jose from 'jose';
import { createJSONStorage, persist } from 'zustand/middleware';

import { lens } from '@dhmk/zustand-lens';

const initialAuthSliceState: AuthSliceState = {
  user: null,
  isCheckingAuth: true,
  authChecked: false,
  firebaseAuthChecked: false,
  // emailVerified: false,
  decodedToken: null,
  tokenExpiresAt: null,
  refetchingAdminToken: false,
};

export const authSlice = lens<AuthSlice>(
  (set, get): AuthSlice => ({
    ...initialAuthSliceState,
    setUser: (user) => {
      set({ user }, false, {
        type: 'authSlice/setUser',
        user,
      });
    },
    setIsCheckingAuth: (isCheckingAuth) => {
      set({ isCheckingAuth }, false, {
        type: 'authSlice/setIsCheckingAuth',
        isCheckingAuth,
      });
    },
    setAuthChecked: (authChecked) => {
      set({ authChecked }, false, {
        type: 'authSlice/setAuthChecked',
        authChecked,
      });
    },
    resetButKeepAuthChecked: () => {
      get().setAuthChecked(false);
      set({ ...initialAuthSliceState, authChecked: true }, false, {
        type: 'authSlice/resetButKeepAuthChecked',
      });
    },
    setFirebaseAuthChecked: (firebaseAuthChecked) => {
      set({ firebaseAuthChecked }, false, {
        type: 'authSlice/setFirebaseAuthChecked',
        firebaseAuthChecked,
      });
    },
    reset: () => {
      set(initialAuthSliceState, false, {
        type: 'authSlice/reset',
      });
    },
    // setEmailVerified: (emailVerified) => {
    //   set({ emailVerified });
    // },
    setDecodedTokenData: (decodedToken) => {
      set({ decodedToken }, false, { type: 'authSlice/setDecodedToken', decodedToken });
    },
    setTokenExpiresAt: (tokenExpiresAt) => {
      set({ tokenExpiresAt }, false, { type: 'authSlice/setTokenExpiresAt', tokenExpiresAt });
    },
    setRefetchingAdminToken: (refetchingAdminToken) => {
      set({ refetchingAdminToken }, false, {
        type: 'authSlice/setRefetchingAdminToken',
        refetchingAdminToken,
      });
    },
  }),
);

export const selectIsLoggedIn = (state: StoreType) => !!state.authSlice.user;

import { User } from '@/lib/__generated__/dashboard/graphql';

import { StoreType } from '.';

export interface AuthSliceState {
  user: null | User; // TODO: revisit
  isCheckingAuth: boolean;
  authChecked: boolean;
  firebaseAuthChecked: boolean;
  decodedToken: null | jose.JWTPayload;
  // emailVerified: boolean;
  tokenExpiresAt: null | number;
  refetchingAdminToken: boolean;
}
export interface AuthSliceActions {
  setUser: (user) => void;
  setIsCheckingAuth: (isCheckingAuth) => void;
  setAuthChecked: (authChecked) => void;
  resetButKeepAuthChecked: () => void;
  setFirebaseAuthChecked: (firebaseAuthChecked: AuthSliceState['firebaseAuthChecked']) => void;
  // setEmailVerified: (emailVerified: AuthSliceState['emailVerified']) => void;
  reset: () => void;
  setDecodedTokenData: (decodedToken: AuthSliceState['decodedToken']) => void;
  setTokenExpiresAt: (tokenExpiresAt: AuthSliceState['tokenExpiresAt']) => void;
  setRefetchingAdminToken: (refetchingAdminToken: AuthSliceState['refetchingAdminToken']) => void;
}
export type AuthSlice = AuthSliceState & AuthSliceActions;
