import { SecurityToken } from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { lens } from '@dhmk/zustand-lens';

const initialSecurityTokensSliceState: SecurityTokenSliceState = {
  securityToken: null,
};

export const securityTokenSlice = lens<SecurityTokenSlice>(
  (set, get): SecurityTokenSlice => ({
    ...initialSecurityTokensSliceState,
    setSecurityToken: (securityToken_) => {
      set({
        securityToken: securityToken_,
      });
    },
  }),
);

export interface SecurityTokenSliceState {
  securityToken: null | SecurityToken;
}

export interface SecurityTokenSliceActions {
  setSecurityToken?: (securityTokenArg) => void;
}

export type SecurityTokenSlice = SecurityTokenSliceState & SecurityTokenSliceActions;
