import React, { FunctionComponent, useMemo, useRef, useState } from 'react';
import ReactHlsPlayer from 'react-hls-player';

import { Stream } from '@cloudflare/stream-react';
import { PlayIcon } from '@radix-ui/react-icons';

type DisplayFileProps = {
  type?: string;
  src?: string;
  cloudflare_uid?: string;
  media_link?: string;
  className?: string;
  onClick?: () => void;
  thumbnail?: string;
};

const DisplayFile: FunctionComponent<DisplayFileProps> = ({
  type = '',
  src = '',
  cloudflare_uid = '',
  media_link = '',
  className = '',
  onClick = () => {},
  thumbnail = '',
}) => {
  const videoRef = useRef<any>();

  const renderFile = () => {
    if (type?.includes('video')) {
      if (thumbnail?.length > 0) {
        return (
          <div className="bg-red relative">
            <div className="absolute flex size-full items-center justify-center">
              <PlayIcon className="m-auto size-[40px] stroke-white" />
            </div>
            <img className={className} src={thumbnail} />
          </div>
        );
      } else if (cloudflare_uid) {
        return <Stream className={className} controls src={cloudflare_uid} />;
      } else if (media_link) {
        return (
          <ReactHlsPlayer
            src={media_link}
            controls={true}
            width="100%"
            height="auto"
            className={className}
            playerRef={videoRef}
            autoPlay
            muted
            loop
          />
        );
      } else {
        return <video className={className} src={src} />;
      }
    } else {
      return <img className={className} src={src} />;
    }
  };

  return <div onClick={onClick}>{renderFile()}</div>;
};

export default DisplayFile;
