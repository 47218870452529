import gql from 'graphql-tag';

import { client } from '../../lib/apollo';

export const getUserCompanies = async () => {
  const { data } = await client.query({
    query: GET_USER_COMPANIES_ALL_DATA,
  });
  if (!data) throw new Error('No data returned from query');
  return data.getUserCompanies;
};

const GET_USER_COMPANIES_ALL_DATA = gql`
  query getUserCompanies {
    getUserCompanies {
      id
      legal_name
      legal_status
      # ceo_officer_id
      state_of_organization
      address
      date_of_organization
      tax_id
      website
      phone
      logo
      facebook
      instagram
      whatsapp
      twitter
      linkedin
      youtube
      active
      created_at
      updated_at
      officers {
        id
        full_legal_name
        title
        responsibilities
        first_date_in_company
        previous_employment
        bio
        previous_role
        employment_start_date
        employment_end_date
        home_address
        dob
        home_phone
        cell_phone
        social_security_number
        drivers_license_number
        id_document_number
        state_or_country_of_issuance
        id_issue_date
        id_expiration_date
        copy_of_id
        ownership_percentage
        birthplace
        mothers_maiden_name
        created_at
        updated_at
        email
      }
      company_users {
        id
        company_id
        user_id
        roles
        created_at
        updated_at
      }
      intellectual_property {
        id
        company_id
        patent
        patent_number
        description_of_patent
        file_date
        grant_date
        country
        created_at
        updated_at
      }
      trademarks {
        id
        company_id
        trademark
        serial_number
        grant_date
        country
        created_at
        updated_at
      }
    }
  }
`;
