import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import spacetime from 'spacetime';

import Gift from '@/assets/Gift.svg?react';
import { Button } from '@/components/ui/button';
import SectionDivider from '@/components/ui/section-divider';
import { cn } from '@/lib/utils';
import useBoundStore from '@/store';
import { ListBulletIcon, PlusIcon } from '@radix-ui/react-icons';

import Table from '../ui/table';

const AllRewards: FunctionComponent = () => {
  const navigate = useNavigate();
  const customListsLoading = useBoundStore(
    (state) => state.listsAndRewardsSlice.customListsLoading,
  );
  const rewardsProgramLoading = useBoundStore(
    (state) => state.listsAndRewardsSlice.rewardsProgramLoading,
  );
  const fetchCustomLists = useBoundStore((state) => state.listsAndRewardsSlice.fetchCustomLists);
  const fetchRewardsProgram = useBoundStore(
    (state) => state.listsAndRewardsSlice.fetchRewardsProgram,
  );
  const customLists = useBoundStore((state) => state.listsAndRewardsSlice.customLists);
  const rewardsPrograms = useBoundStore((state) => state.listsAndRewardsSlice.rewardsPrograms);

  const tableHeights = { maxHeight: 'calc(50vh - 145px)', minHeight: 'calc(50vh - 145px)' };

  const listTable = useMemo(() => {
    return {
      columnNames: ['List Name', '# Members', 'Date created'],
      data: customLists?.map((item) => [
        {
          value: item.name,
          className: 'text-md capitalize',
        },
        {
          value: `${(item?.number_of_members || 0)?.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`,
          className: 'text-md',
        },
        {
          value: spacetime(parseInt(item?.created_at)).format('numeric-us'),
          className: 'text-md',
        },
      ]),
    };
  }, [customLists]);

  const rewardsProgramTable = useMemo(() => {
    return {
      columnNames: ['Rewards Program', '# Members', 'Rewarded Assets', 'Date Created'],
      data: rewardsPrograms?.map((item) => [
        {
          value: item.name,
          className: 'text-md capitalize',
        },
        {
          value: `${(item.number_of_members || 0).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`,
          className: 'text-md',
        },
        {
          value: (
            <div className="flex gap-[2px]">
              {item.assigned_rewards.length > 0 ? (
                item.assigned_rewards
                  .slice(0, 2)
                  .map((item, index) => (
                    <img
                      className="size-[24px] rounded-sm border object-cover"
                      src={item.nft.media_ipfs_link}
                    />
                  ))
              ) : (
                <p> None </p>
              )}
              {item.assigned_rewards.length - 2 > 0 && (
                <p className="ml-1 text-sm font-medium">
                  + {item.assigned_rewards.length - 2} more
                </p>
              )}
            </div>
          ),
          className: 'text-md',
        },
        {
          value: spacetime(parseInt(item.created_at)).format('numeric-us'),
          className: 'text-md',
        },
      ]),
    };
  }, [rewardsPrograms]);

  useEffect(() => {
    if (customLists.length == 0) {
      fetchCustomLists();
    }
    if (rewardsPrograms.length == 0) {
      fetchRewardsProgram();
    }
  }, []);

  return (
    <div className="flex w-full max-w-[1100px] flex-col gap-[25px]">
      <div className="relative">
        <SectionDivider
          titles={[`Rewards Programs`]}
          img={<Gift className="stroke-white" stroke="white" />}
          color="#ff6661"
        />
        <Button
          className="absolute bottom-0 right-0 mb-[10px] "
          onClick={() => {
            navigate('/create-rewards');
          }}
        >
          <PlusIcon className="mr-2 size-[16px] stroke-card" />
          <p className="text-card"> Create Rewards Program </p>
        </Button>
      </div>
      <div
        className={cn(
          'overflow-y-scroll rounded-[10px] border bg-card pb-[20px] pr-[10px] pt-[10px] shadow-md',
          rewardsProgramTable.data.length == 0 && 'flex flex-col justify-center',
        )}
        style={tableHeights}
      >
        <Table
          ariaLabel="rewards-programs-table"
          columnNames={rewardsProgramTable.columnNames}
          data={rewardsProgramTable.data}
          isLoading={customListsLoading}
          onRowClick={(item, index) => {
            console.log({ item });
            navigate(`/rewards-view/${rewardsPrograms[index].list_id}`);
          }}
        />
      </div>
      <div className="relative">
        <SectionDivider
          titles={[`CSV Lists`]}
          img={<ListBulletIcon stroke="white" className="size-[19px] stroke-white text-card" />}
          color="#08C299"
        />
        <Button
          className="absolute bottom-0 right-0 mb-[10px] "
          onClick={() => {
            navigate('/create-list');
          }}
        >
          <PlusIcon className="mr-2 size-[16px] stroke-card" />
          <p className="text-card"> Create CSV List </p>
        </Button>
      </div>
      <div
        className={cn(
          'overflow-y-scroll rounded-[10px] border bg-card pb-[20px] pr-[10px] pt-[10px] shadow-md',
          listTable.data.length == 0 && 'flex flex-col justify-center',
        )}
        style={tableHeights}
      >
        <Table
          ariaLabel="lists-table"
          columnNames={listTable.columnNames}
          data={listTable.data}
          isLoading={rewardsProgramLoading}
        />
      </div>
    </div>
  );
};

export default AllRewards;

type ListType = {
  name: string;
  created_at: string;
  number_of_members: number;
};

type RoyaltyProgramType = {
  name: string;
  created_at: string;
  number_of_members: number;
  list_id?: number;
};
