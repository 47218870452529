import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useLocation, useOutletContext } from 'react-router-dom';

import { BackNext } from '@/components/ui/back-next';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';
import { CreateCompanyDocument } from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';

import { statesInAmerica } from '../../const/index';

const CreateBusiness: FunctionComponent = () => {
  const [defaultValues, setDefaultValues] = useState<any>();
  const [createLoading, setCreateLoading] = useState<boolean>(false);

  type OutletContextType = [number, React.Dispatch<React.SetStateAction<number>>, string[]];
  const [currentLevel, setCurrentLevel, titles] = useOutletContext<OutletContextType>();

  const methods = useForm({
    defaultValues,
  });
  const navigate = useNavigate();
  const { handleSubmit } = methods;

  const [pageLoading, setPageLoading] = useState<boolean>(false);

  const companyDetails = [
    {
      inputs: [
        {
          type: 'input',
          label: 'Company Legal Name',
          state: 'legal_name',
          placeholder: 'Enter the legal name of your company',
          requiredForNextStep: true,
        },
        {
          type: 'checkboxColumn',
          label: 'Type of Organization',
          state: 'legal_status',
          placeholder: 'Select your website theme',
          options: [
            { label: 'Corporation', value: 'Corporation' },
            { label: 'S Corporation', value: 'S Corporation' },
            { label: 'LLC', value: 'LLC' },
            { label: 'Public Benefit Corporation', value: 'Public Benefit Corporation' },
            { label: 'Sole Proprietorship', value: 'Sole Proprietorship' },
            { label: 'Partnership', value: 'Partnership' },
            { label: '501(c)(3) Non-Profit', value: '501(c)(3) Non-Profit' },
          ],
          requiredForNextStep: true,
        },
        {
          type: 'imageUpload',
          label: 'Company Logo',
          state: 'logo',
          smallImg: true,
          noCrop: true,
          placeholder: 'Upload your company logo',
        },
        {
          type: 'dropdown',
          label: 'State of Incorporation',
          state: 'state_of_organization',
          placeholder: 'Select the state of incorporation',
          options: statesInAmerica,
          requiredForNextStep: true,
        },
        {
          type: 'calendar',
          label: 'Date of Incorporation',
          state: 'date_of_organization',
          placeholder: 'MM/DD/YYYY',
          requiredForNextStep: true,
        },
        {
          type: 'input',
          label: 'Tax ID',
          state: 'tax_id',
          placeholder: 'Enter your tax identification number',
          requiredForNextStep: true,
        },
      ],
    },
  ];
  const contactInformation = [
    {
      inputs: [
        {
          type: 'input',
          label: 'Company Website',
          state: 'website',
          placeholder: 'Enter your company’s website URL',
          requiredForNextStep: true,
        },
        {
          type: 'phone',
          label: 'Company Phone Number',
          state: 'compnayPhone',
          placeholder: 'Enter the main phone number for your company',
          requiredForNextStep: true,
        },
        {
          type: 'location',
          label: 'Company Address',
          state: 'address',
          placeholder: 'Enter the primary address of your company',
          requiredForNextStep: true,
        },
        {
          type: 'input',
          label: 'CEO/Managing Partner’s Name',
          state: 'CEOname',
          placeholder: 'Enter the full name of the CEO or managing partner',
          requiredForNextStep: true,
        },
        {
          type: 'input',
          label: 'CEO/Managing Partner’s Email',
          state: 'CEOemail',
          placeholder: 'Enter the email address of the CEO or managing partner',
          requiredForNextStep: true,
        },
        {
          type: 'phone',
          label: 'CEO/Managing Partner’s Phone Number',
          state: 'CEOphone',
          placeholder: 'Enter the phone number of the CEO or managing partner',
          requiredForNextStep: true,
        },
      ],
    },
  ];
  const handleCreateCompany = async (data) => {
    try {
      if (currentLevel == 0) {
        setCurrentLevel(currentLevel + 1);
      } else {
        setCreateLoading(true);
        console.log({ data });
        const {
          website,
          tax_id,
          state_of_organization,
          companyPhone,
          logo,
          address,
          date_of_organization,
          legal_name,
          legal_status,
          CEOphone,
          CEOname,
          CEOemail,
        } = data;
        const {
          data: { createCompany: createCompanyRes },
        } = await client.mutate({
          mutation: CreateCompanyDocument,
          variables: {
            input: {
              website,
              tax_id,
              state_of_organization,
              phone: companyPhone,
              logo: logo.hostedUrl,
              address: address.label,
              date_of_organization: new Date(date_of_organization).toISOString(),
              legal_name,
              legal_status,
              ceo: {
                phone: CEOphone,
                name: CEOname,
                email: CEOemail,
              },
            },
          },
        });
        console.log({ createCompanyRes });
        setCreateLoading(false);
        navigate('/company-home');
      }
    } catch (err) {
      setCreateLoading(false);
      console.log({ err });
    }
  };

  return (
    <form
      className="relative mx-auto flex min-h-[800px] w-full max-w-[800px] flex-col gap-[25px]"
      onSubmit={handleSubmit(handleCreateCompany)}
    >
      <h1 className="text-3xl font-semibold"> {titles[currentLevel]} </h1>
      {pageLoading ? (
        <div className="flex h-full items-center justify-center">
          <div className="m-auto size-10 animate-spin rounded-full border-t-2 border-primary" />
        </div>
      ) : (
        <>
          {currentLevel == 0 && <WrappedInputSection methods={methods} data={companyDetails} />}
          {currentLevel == 1 && (
            <>
              <WrappedInputSection
                methods={methods}
                data={[{ inputs: contactInformation[0].inputs.slice(0, 3) }]}
              />
              <h2 className="text-xl font-semibold"> CEO Information </h2>
              <WrappedInputSection
                methods={methods}
                data={[{ inputs: contactInformation[0].inputs.slice(3, 6) }]}
              />
            </>
          )}
        </>
      )}
      <div className="mb-[30px] mt-auto">
        <BackNext
          numOfLevels={2}
          finalBtnText={`CREATE COMPANY`}
          currentLevel={currentLevel}
          setCurrentLevel={setCurrentLevel}
          loading={createLoading}
        />
      </div>
    </form>
  );
};

export default CreateBusiness;
