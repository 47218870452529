import { FunctionComponent, useState } from 'react';

import { cn } from '@/lib/utils';

import DisplayFile from '../ui/display-file';

const NFTCard: FunctionComponent<any> = ({
  asset,
  loading = false,
  onClick = () => {},
  smallNft = false,
  className = '',
}) => {
  const isCreating = !asset?.batch_id && !loading;

  return (
    <>
      {!smallNft ? (
        <div
          className={cn(
            'box-border min-w-[244px] max-w-[244px] cursor-pointer rounded-[20px] border-4 border-card bg-card shadow-md transition-shadow duration-500 ease-in-out hover:shadow-lg',
            className,
          )}
          onClick={onClick}
        >
          <div className="flex h-full min-h-[340px] flex-col items-center">
            <div
              className={`relative flex max-h-[236px] min-h-[236px] min-w-[236px] max-w-[236px] items-center justify-center overflow-hidden rounded-[16px] bg-gray-700 ${
                loading ? 'animate-pulse' : ''
              }`}
            >
              {!loading && (
                <DisplayFile
                  src={asset?.media_ipfs_link}
                  type={asset?.media_mime_type}
                  media_link={asset?.media_ipfs_link}
                />
              )}
              {isCreating && (
                <button
                  className={
                    'absolute left-0 top-0 ml-[10px] border-0 bg-primary px-2 py-1 text-xs font-normal text-white'
                  }
                >
                  CREATING
                </button>
              )}
            </div>
            <div className="flex h-[102px] w-full flex-col  justify-between px-4 pb-2 pt-3">
              {loading ? (
                <p className="h-[20px] w-[100px] animate-pulse rounded-[6px]" />
              ) : (
                <p className="line-clamp-3 text-base font-semibold">{asset?.name}</p>
              )}
              <div className="flex items-end justify-between">
                {!loading ? (
                  <>
                    <p className="text-sm font-medium text-text-faded">
                      {parseFloat(asset.mint_price) / 100 > 0
                        ? `$${Intl.NumberFormat('en-US').format(asset.mint_price / 100)}`
                        : 'Free'}
                    </p>
                    <p className="text-xs text-text-faded">
                      Printed:
                      <strong className="ml-2">{asset?.max_supply} </strong>
                    </p>
                  </>
                ) : (
                  <>
                    <p className="h-[20px] w-[40px] animate-pulse rounded-[6px]" />
                    <p className="h-[20px] w-[70px] animate-pulse rounded-[6px]" />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <button
          key={asset.id}
          className={cn(
            'overflow-hidden rounded-[10px] border-4 border-card bg-card shadow-md',
            className,
          )}
        >
          <img className="size-[150px] rounded-[5px] object-cover" src={asset.media_ipfs_link} />
          <div className="flex p-[7px]">
            <p className="m-0 truncate text-left text-sm font-medium">{asset.name}</p>
          </div>
        </button>
      )}
    </>
  );
};

export default NFTCard;
