import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import chroma from 'chroma-js';
import { isAddress } from 'ethereum-address';
import { Controller, useForm } from 'react-hook-form';

import Wallet from '@/assets/Wallet.svg?react';
import SelectFooter from '@/components/modals/select-footer';
import PercentSlider from '@/components/ui/percent-slider';
import { SelectorDropdown } from '@/components/ui/selector-dropdown';
import SiteFooter from '@/components/ui/site-footer';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';
import config from '@/config';
import { pickTextColorBasedOnBgColor } from '@/const/index';
import { CreatePayoutProgramDocument } from '@/lib/__generated__/marketplace/graphql';
import {
  GetIngressUrlDocument,
  GetMySiteDetailsDocument,
  UpdateMySiteDetailsDocument,
} from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { cn } from '@/lib/utils';
import useBoundStore from '@/store';
import { DesktopIcon, LaptopIcon, MobileIcon } from '@radix-ui/react-icons';

const MySite: FunctionComponent<any> = () => {
  const [selectedScreen, setSelectedScreen] = useState(1);
  const [ingressUrl, setIngressUrl] = useState('');
  const [selectedFooterTheme, setSelectedFooterTheme] = useState(0);
  const [footermodalOpen, setFootermodalOpen] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageFailed, setImageFailed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedDevice, setSelectedDevice] = useState(1);

  const [outerDivSize, setOuterDivSize] = useState({ width: 'auto', height: 'auto' });

  const innerDivRef = useRef<any>(null);
  const selectedProject = useBoundStore((state) => state.projectSlice.selectedProject);

  const fontsList = [
    { label: 'Poppins', value: 'Poppins' },
    { label: 'ReadexPro', value: 'ReadexPro' },
    { label: 'Sans-Serif', value: 'Sans-Serif' },
    { label: 'Oswald', value: 'Oswald' },
    { label: 'Merriweather', value: 'Merriweather' },
    { label: 'Montserrat', value: 'Montserrat' },
    { label: 'Pattanakarn', value: 'Pattanakarn' },
    { label: 'Noto Sans', value: 'Noto Sans' },
  ];

  const deviceViews = [
    {
      icon: <MobileIcon className="stroke-text-color" />,
      width: 390,
      aspect: 390 / 456,
      state: 'PROJECT_DETAILS_HERO_DETAILS_HERO_BANNER_IMAGE_MOBILE',
    },
    {
      icon: <LaptopIcon className="stroke-text-color" />,
      width: 1366,
      aspect: 3 / 1,
      state: 'PROJECT_DETAILS_HERO_DETAILS_HERO_BANNER_IMAGE',
    },
    {
      icon: <DesktopIcon className="stroke-text-color" />,
      width: 1800,
      aspect: 1800 / 546,
      state: 'PROJECT_DETAILS_HERO_DETAILS_HERO_BANNER_IMAGE_DESKTOP',
    },
  ];

  const [defaultValues, setDefaultValues] = useState<any>({
    // NEED TO ADD GET CUSTOM INGRESS
    DARK_THEME: false,
    THEME_BG_COLOR: {
      hex: '#f9f9f9', // dark 000000
    },
    THEME_CARD_COLOR: {
      hex: '#FFFFFF', // dark 333333
    },
    THEME_TEXT_COLOR: {
      hex: '#000000', // dark FFFFFF
    },
    BUTTON_CUSTOMIZATION_BUTTON_ROUTE: 'Explore Assets',
    THEME_PRIMARY_COLOR: {
      hex: '#FF6661FF',
    },
    THEME_SECONDARY_COLOR: {
      hex: '#4555FF',
    },
    PROJECT_DETAILS_PROJECT_NAME_TEXT_COLOR: {
      hex: '#000000',
    },
    PROJECT_DETAILS_ABOUT_PROJECT_TEXT_COLOR: {
      hex: '#000000',
    },
    BUTTON_CUSTOMIZATION_BUTTON_FILL_COLOR: {
      hex: '', // If no value should be primary color
    },
    BUTTON_CUSTOMIZATION_BUTTON_TEXT: '',
    HEADER_HEADER_COLOR: {
      hex: '#2D344E',
    },
    HEADER_HEADER_TEXT_COLOR: {
      // when user changes primary color it should also change this one unless its different
      hex: '#FF6661FF',
    },
    HEADER_HEADER_BUTTON_TEXT: 'CONNECT',
    IS_PUBLIC: 'true',
    FOOTER_STYLE_FOOTER_COLOR: {
      hex: '#2D344E',
    },
    FOOTER_STYLE_FOOTER_TEXT_COLOR: {
      hex: '#FFFFFF',
    },
    PROJECT_DETAILS_PROJECT_NAME_TEXT_FONT_SIZE: '',
    PROJECT_DETAILS_ABOUT_PROJECT_TEXT_FONT_SIZE: '',
    BODY_CUSTOMIZATION_INSTAGRAM_LINK: '',
    BODY_CUSTOMIZATION_DISCORD_LINK: '',
    BODY_CUSTOMIZATION_TIKTOK_LINK: '',
    BODY_CUSTOMIZATION_YOUTUBE_LINK: '',
    BODY_CUSTOMIZATION_FACEBOOK_LINK: '',
    BODY_CUSTOMIZATION_TWITTER_LINK: '',
    BODY_CUSTOMIZATION_TELEGRAM_LINK: '',
    FOOTER_CONTENT_TERMS_AND_CONDITIONS: '',
    PROJECT_DETAILS_HERO_DETAILS_HERO_BANNER_IMAGE: '',
    PROJECT_DETAILS_HERO_DETAILS_HERO_BANNER_IMAGE_OPACITY: [100],
    PROJECT_DETAILS_HERO_LOGO_IN_USE: true,
    PROJECT_DETAILS_HERO_LOGO: '',
    BUTTON_CUSTOMIZATION_BUTTON_ENABLED: true,
    WALLET_DETAILS_WALLET_VISIBLE: true,
    WALLET_DETAILS_CRYPTO_VISIBLE: true,
    WALLET_DETAILS_METAMASK_ENABLED: false,
    WALLET_DETAILS_HIDE_WITHDRAW: false,
    SECONDARIES_ENABLED: 'true',
    SIGNUP_ON_LOAD: false,
    VISIBILITY_COLLECTIONS: false,
    VISIBILITY_NFTS: false,
    VISIBILITY_PROFILE: false,
    VISIBILITY_REFERRAL_EMAILS: false,
    ENABLE_SELECT_LANGUAGE: false,
    PROFILE_DETAILS_DEFAULT_BANNER: '',
    SITE_LANGUAGE_NFT: '',
    SITE_LANGUAGE_COLLECTION: '',
    SITE_LANGUAGE_UTILITY: '',
    SITE_LANGUAGE_MINT: '',
    SITE_LANGUAGE_AIRDROP: '',
    SITE_LANGUAGE_REFER_TO_EARN: '',
    SITE_LANGUAGE_REFER_BY_SHARING: '',
    INTEGRATIONS_PIXEL_ID: '',
    FOOTER_CONTENT_FOOTER_CONTENT_BLOCK_1_LINE_1: '',
    FOOTER_CONTENT_FOOTER_CONTENT_BLOCK_1_LINE_2: '',
    FOOTER_CONTENT_FOOTER_CONTENT_BLOCK_1_LINE_3: '',
    FOOTER_CONTENT_FOOTER_CONTENT_BLOCK_2_LINE_1: '',
    FOOTER_CONTENT_FOOTER_CONTENT_BLOCK_2_LINE_2: '',
    FOOTER_CONTENT_FOOTER_CONTENT_BLOCK_2_LINE_3: '',
    FAVICON: 'https://round-five-cdn.prime-jackpot-expanse.chainstarters.io/favicon/favicon.ico',
  });

  const methods = useForm({
    defaultValues,
  });
  const { handleSubmit } = methods;
  const formValues = methods.watch();

  const indexOfDefault = deviceViews?.findIndex(
    (view, index) => formValues[view?.state] || formValues[view?.state]?.hostedUrl,
  );

  const handleRenderBanner = () => {
    if (
      formValues?.[`${deviceViews[selectedDevice].state}`]?.hostedUrl ||
      formValues?.[`${deviceViews[selectedDevice].state}`]
    ) {
      return (
        formValues?.[`${deviceViews[selectedDevice].state}`]?.hostedUrl ||
        formValues?.[`${deviceViews[selectedDevice].state}`]
      );
    } else if (
      formValues?.PROJECT_DETAILS_HERO_DETAILS_HERO_BANNER_IMAGE?.hostedUrl ||
      formValues?.PROJECT_DETAILS_HERO_DETAILS_HERO_BANNER_IMAGE
    ) {
      return (
        formValues?.PROJECT_DETAILS_HERO_DETAILS_HERO_BANNER_IMAGE?.hostedUrl ||
        formValues?.PROJECT_DETAILS_HERO_DETAILS_HERO_BANNER_IMAGE
      );
    } else if (indexOfDefault !== -1) {
      return (
        formValues[`${deviceViews[indexOfDefault]?.state}`]?.hostedUrl ||
        formValues[`${deviceViews[indexOfDefault]?.state}`]
      );
    }
  };

  const selectScreenOptions = [
    { label: 'Site Home', value: 1 },
    { label: 'Header / Footer', value: 0 },
    { label: 'Settings', value: 2 },
  ];

  const memoizedTextColor = useCallback(
    (bgColor, lightColor, darkColor) => pickTextColorBasedOnBgColor(bgColor, lightColor, darkColor),
    [],
  );

  const handleUpdateMySiteDetails = async (data) => {
    try {
      console.log({ data });
      const mutatedData = { ...data };
      const firstKey = Object.keys(data)[0];
      console.log({ firstKey });
      if (firstKey.includes('COLOR')) {
        mutatedData[firstKey] = mutatedData[firstKey].slice(1);
      }
      if (firstKey == 'DARK_THEME') {
        const newFormVals = methods.watch();

        if (mutatedData[firstKey] == true) {
          mutatedData.THEME_BG_COLOR = '000000';
          mutatedData.THEME_CARD_COLOR = '333333';
          mutatedData.THEME_TEXT_COLOR = 'FFFFFF';
          console.log({ formValues });
          methods.setValue('THEME_BG_COLOR', { hex: '#000000' });
          methods.setValue('THEME_CARD_COLOR', { hex: '#333333' });
          methods.setValue('THEME_TEXT_COLOR', { hex: '#FFFFFF' });
          // SETTING PROJECT TEXT COLOR IF NOT ALREADY SET
          methods.setValue(
            'PROJECT_DETAILS_PROJECT_NAME_TEXT_COLOR',
            newFormVals?.PROJECT_DETAILS_PROJECT_NAME_TEXT_COLOR?.hex === '#000000'
              ? { hex: '#FFFFFF' }
              : newFormVals?.PROJECT_DETAILS_PROJECT_NAME_TEXT_COLOR?.hex,
          );
          methods.setValue(
            'PROJECT_DETAILS_ABOUT_PROJECT_TEXT_COLOR',
            newFormVals?.PROJECT_DETAILS_ABOUT_PROJECT_TEXT_COLOR?.hex === '#000000'
              ? { hex: '#FFFFFF' }
              : newFormVals?.PROJECT_DETAILS_ABOUT_PROJECT_TEXT_COLOR?.hex,
          );
        } else {
          mutatedData.THEME_BG_COLOR = 'f9f9f9';
          mutatedData.THEME_CARD_COLOR = 'FFFFFF';
          mutatedData.THEME_TEXT_COLOR = '000000';
          methods.setValue('THEME_BG_COLOR', { hex: '#f9f9f9' });
          methods.setValue('THEME_CARD_COLOR', { hex: '#FFFFFF' });
          methods.setValue('THEME_TEXT_COLOR', { hex: '#000000' });

          methods.setValue(
            'PROJECT_DETAILS_PROJECT_NAME_TEXT_COLOR',
            newFormVals?.PROJECT_DETAILS_PROJECT_NAME_TEXT_COLOR?.hex === '#FFFFFF'
              ? { hex: '#000000' }
              : newFormVals?.PROJECT_DETAILS_PROJECT_NAME_TEXT_COLOR?.hex,
          );
          methods.setValue(
            'PROJECT_DETAILS_ABOUT_PROJECT_TEXT_COLOR',
            newFormVals?.PROJECT_DETAILS_ABOUT_PROJECT_TEXT_COLOR?.hex === '#FFFFFF'
              ? { hex: '#000000' }
              : newFormVals?.PROJECT_DETAILS_ABOUT_PROJECT_TEXT_COLOR?.hex,
          );
        }
      }
      console.log({ formValues });
      const {
        data: { updateMySiteDetails: updateMySiteDetailsRes },
      } = await client.mutate({
        mutation: UpdateMySiteDetailsDocument,
        variables: {
          updateMySiteDetailsInput: {
            env: 'prod',
            configMap: { ...mutatedData },
          },
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ updateMySiteDetailsRes });
      if (formValues?.PROJECT_DETAILS_HERO_LOGO.length == 0) {
        setImageLoaded(true);
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const screen1 = useMemo(() => {
    const screen1Theme0 = [
      {
        title: 'Theme',
        sectionClassName: 'gap-[20px] flex flex-col',
        callOnBlur: handleUpdateMySiteDetails,
        inputs: [
          {
            type: 'checkboxRow',
            state: 'DARK_THEME',
            options: [
              { label: 'Light', value: false },
              { label: 'Dark', value: true },
            ],
          },
        ],
      },
      {
        sectionClassName: 'gap-[20px] flex flex-col',
        sectionInputsClassName: 'mt-[-20px]',
        inputs: [
          {
            type: 'imageUpload',
            state: 'FAVICON',
            selectFromModal: false,
            favicon: true,
          },
        ],
      },
      {
        title: 'Choose your colors',
        sectionClassName: 'gap-[20px] flex flex-col',
        sectionInputsClassName: 'flex flex-row',
        callOnBlur: handleUpdateMySiteDetails,
        inputs: [
          {
            type: 'color',
            label: 'Primary',
            state: 'THEME_PRIMARY_COLOR',
            halfWidth: true,
          },
          {
            type: 'color',
            label: 'Secondary',
            state: 'THEME_SECONDARY_COLOR',
            halfWidth: true,
          },
        ],
      },
      {
        title: 'Your Logo',
        callOnBlur: handleUpdateMySiteDetails,
        sectionClassName: 'gap-[5px] flex-col flex',
        inputs: [
          {
            type: 'imageUpload',
            filesInModal: ['image'],
            state: 'PROJECT_DETAILS_COMPANY_LOGO', // When setting state, make hostedUrl
          },
        ],
      },
      {
        title: 'Button text',
        callOnBlur: handleUpdateMySiteDetails,
        sectionInputsClassName: 'mt-[3px]',
        inputs: [
          {
            type: 'input',
            state: 'HEADER_HEADER_BUTTON_TEXT',
            placeholder: 'CONNECT',
          },
        ],
      },
      {
        title: 'Nav Bar color',
        callOnBlur: handleUpdateMySiteDetails,
        sectionClassName: 'mt-[6px]',
        sectionInputsClassName: 'mt-[3px]',
        inputs: [
          {
            type: 'color',
            state: 'HEADER_HEADER_COLOR',
          },
        ],
      },
      {
        title: 'Nav Bar font color',
        callOnBlur: handleUpdateMySiteDetails,
        sectionClassName: 'mt-[6px]',
        sectionInputsClassName: 'mt-[3px]',
        inputs: [
          {
            type: 'color',
            state: 'HEADER_HEADER_TEXT_COLOR',
            label: '',
          },
        ],
      },
      {
        callOnBlur: handleUpdateMySiteDetails,
        sectionClassName: '',
        sectionInputsClassName: '',
        inputs: [
          {
            type: 'color',
            label: 'Footer fill color',
            state: 'FOOTER_STYLE_FOOTER_COLOR',
            halfWidth: true,
          },
          {
            type: 'color',
            label: 'Footer text color',
            state: 'FOOTER_STYLE_FOOTER_TEXT_COLOR',
            halfWidth: true,
          },
        ],
      },
      {
        title: 'Your Info 1',
        callOnBlur: handleUpdateMySiteDetails,
        sectionClassName: 'mb-[30px]',
        sectionInputsClassName: 'gap-[10px] mt-[10px]',
        inputs: [
          {
            type: 'input',
            label: 'Line 1',
            state: 'FOOTER_CONTENT_FOOTER_CONTENT_BLOCK_1_LINE_1',
          },
          {
            type: 'input',
            label: 'Line 2',
            state: 'FOOTER_CONTENT_FOOTER_CONTENT_BLOCK_1_LINE_2',
          },
          {
            type: 'input',
            label: 'Line 3',
            state: 'FOOTER_CONTENT_FOOTER_CONTENT_BLOCK_1_LINE_3',
          },
        ],
      },
      {
        title: 'Social Media Links',
        callOnBlur: handleUpdateMySiteDetails,
        sectionClassName: 'mb-[30px]',
        sectionInputsClassName: 'gap-[10px] mt-[10px]',
        inputs: [
          {
            type: 'input',
            label: 'Instagram',
            state: 'BODY_CUSTOMIZATION_INSTAGRAM_LINK',
            halfWidth: true,
          },
          {
            type: 'input',
            label: 'Discord',
            state: 'BODY_CUSTOMIZATION_DISCORD_LINK',
            halfWidth: true,
          },
          {
            type: 'input',
            label: 'Tiktok',
            state: 'BODY_CUSTOMIZATION_TIKTOK_LINK',
            halfWidth: true,
          },
          {
            type: 'input',
            label: 'Youtube',
            state: 'BODY_CUSTOMIZATION_YOUTUBE_LINK',
            halfWidth: true,
          },
          {
            type: 'input',
            label: 'Facebook',
            state: 'BODY_CUSTOMIZATION_FACEBOOK_LINK',
            halfWidth: true,
          },
          {
            type: 'input',
            label: 'Twitter',
            state: 'BODY_CUSTOMIZATION_TWITTER_LINK',
            halfWidth: true,
          },
          {
            type: 'input',
            label: 'Telegram',
            state: 'BODY_CUSTOMIZATION_TELEGRAM_LINK',
            halfWidth: true,
          },
        ],
      },
      {
        title: 'Terms & Conditions Link',
        callOnBlur: handleUpdateMySiteDetails,
        sectionClassName: 'mb-[30px]',
        sectionInputsClassName: 'gap-[10px] mt-[10px]',
        inputs: [
          {
            type: 'input',
            label: 'Terms & Conditions Link',
            state: 'FOOTER_CONTENT_TERMS_AND_CONDITIONS',
          },
        ],
      },
    ];

    // add theme 1 (2)
    if (selectedFooterTheme == 1) {
      screen1Theme0.splice(9, 0, {
        title: 'Your Info 2',
        callOnBlur: handleUpdateMySiteDetails,
        sectionClassName: 'mb-[30px]',
        sectionInputsClassName: 'gap-[10px] mt-[10px]',
        inputs: [
          {
            type: 'input',
            label: 'Line 1',
            state: 'FOOTER_CONTENT_FOOTER_CONTENT_BLOCK_2_LINE_1',
          },
          {
            type: 'input',
            label: 'Line 2',
            state: 'FOOTER_CONTENT_FOOTER_CONTENT_BLOCK_2_LINE_2',
          },
          {
            type: 'input',
            label: 'Line 3',
            state: 'FOOTER_CONTENT_FOOTER_CONTENT_BLOCK_2_LINE_3',
          },
        ],
      });
    }

    return screen1Theme0;
  }, [selectedFooterTheme]);

  const screen2 = [
    {
      title: 'Hero banner image',
      callOnBlur: handleUpdateMySiteDetails,
      sectionClassName: 'gap-[20px] flex flex-col',
      inputs: [
        {
          type: 'imageUpload',
          filesInModal: ['image'],
          state: deviceViews[selectedDevice].state,
          aspect: deviceViews[selectedDevice].aspect,
        },
      ],
    },
    {
      title: 'Hero Image',
      callOnBlur: (args) => {
        handleUpdateMySiteDetails(args);
        setOuterDivSize({ width: 'auto', height: 'auto' });
        setImageLoaded(false);
      },
      sectionClassName: 'gap-[20px] flex flex-col',
      hasToggle: true,
      toggleState: 'PROJECT_DETAILS_HERO_LOGO_IN_USE',
      inputs: [
        {
          type: 'imageUpload',
          filesInModal: ['image'],
          state: 'PROJECT_DETAILS_HERO_LOGO',
          aspect: undefined,
        },
      ],
    },
    {
      title: '',
      callOnBlur: handleUpdateMySiteDetails,
      sectionClassName: 'gap-[20px] flex flex-col',
      inputs: [
        {
          type: 'percentSlider',
          state: 'PROJECT_DETAILS_HERO_DETAILS_HERO_BANNER_IMAGE_OPACITY',
        },
      ],
    },
    {
      title: 'Project Name',
      callOnBlur: handleUpdateMySiteDetails,
      sectionClassName: 'gap-[20px] flex flex-col',
      inputs: [
        {
          type: 'input',
          placeholder: 'Project Name',
          state: 'PROJECT_DETAILS_PROJECT_NAME',
        },
      ],
    },
    {
      callOnBlur: handleUpdateMySiteDetails,
      sectionClassName: 'gap-[20px]',
      sectionInputsClassName: 'flex flex-row flex-nowrap',
      inputs: [
        {
          type: 'color',
          label: 'Project name text color',
          state: 'PROJECT_DETAILS_PROJECT_NAME_TEXT_COLOR',
        },
        {
          type: 'dropdown',
          label: 'Font',
          state: 'PROJECT_DETAILS_PROJECT_NAME_TEXT_FONT',
          options: fontsList,
          defaultValue: formValues?.PROJECT_DETAILS_PROJECT_NAME_TEXT_FONT,
        },
        {
          type: 'input',
          label: 'Font Size',
          state: 'PROJECT_DETAILS_PROJECT_NAME_TEXT_FONT_SIZE',
          placeholder: '48',
        },
      ],
    },
    {
      title: 'Project Description',
      callOnBlur: handleUpdateMySiteDetails,
      sectionClassName: 'gap-[20px] flex flex-col',
      inputs: [
        {
          type: 'textarea',
          state: 'PROJECT_DETAILS_ABOUT_PROJECT',
          placeholder:
            'Project Description: Let the user know why this project is special and why they should be apart of this community.',
        },
      ],
    },
    {
      sectionClassName: 'gap-[20px]',
      callOnBlur: handleUpdateMySiteDetails,
      sectionInputsClassName: 'flex flex-row flex-nowrap',
      inputs: [
        {
          type: 'color',
          label: 'Project description text color',
          state: 'PROJECT_DETAILS_ABOUT_PROJECT_TEXT_COLOR',
        },
        {
          type: 'dropdown',
          label: 'Font',
          state: 'PROJECT_DETAILS_ABOUT_PROJECT_TEXT_FONT',
          options: fontsList,
          defaultValue: formValues?.PROJECT_DETAILS_ABOUT_PROJECT_TEXT_FONT,
        },
        {
          type: 'input',
          label: 'Font Size',
          state: 'PROJECT_DETAILS_ABOUT_PROJECT_TEXT_FONT_SIZE',
          placeholder: '16',
        },
      ],
    },
    {
      title: 'Explore/Action button customization',
      callOnBlur: handleUpdateMySiteDetails,
      sectionClassName: 'gap-[20px] flex flex-col',
      hasToggle: true,
      toggleState: 'BUTTON_CUSTOMIZATION_BUTTON_ENABLED',
      inputs: [
        {
          type: 'input',
          label: 'Button text (button color is set with primary and secondary colors)',
          state: 'BUTTON_CUSTOMIZATION_BUTTON_TEXT',
          halfWidth: true,
        },
        {
          type: 'color',
          label: 'Button Color',
          state: 'BUTTON_CUSTOMIZATION_BUTTON_FILL_COLOR',
          halfWidth: true,
        },
        {
          type: 'dropdown',
          label: 'Routing',
          state: 'BUTTON_CUSTOMIZATION_BUTTON_ROUTE',
          defaultValue: formValues?.BUTTON_CUSTOMIZATION_BUTTON_ROUTE,
          options: [
            { label: 'Explore Assets', value: 'Explore Assets' },
            { label: 'URL', value: 'URL' },
          ],
          halfWidth: true,
        },
        {
          type: 'input',
          label: 'Url',
          state: 'BUTTON_CUSTOMIZATION_BUTTON_URL',
          halfWidth: true,
          showBasedOn: 'BUTTON_CUSTOMIZATION_BUTTON_ROUTE',
          showIf: (BUTTON_CUSTOMIZATION_BUTTON_ROUTE) => BUTTON_CUSTOMIZATION_BUTTON_ROUTE == 'URL',
        },
      ],
    },
  ];

  const screen3 = [
    {
      callOnBlur: handleUpdateMySiteDetails,
      sectionClassName: 'gap-[20px] flex flex-col',
      inputs: [
        {
          type: 'checkboxRow',
          state: 'IS_PUBLIC',
          options: [
            { label: 'Public', value: 'true' },
            { label: 'Private', value: 'false' },
          ],
        },
      ],
    },
    {
      title: 'Wallet visible on the marketplace',
      callOnBlur: handleUpdateMySiteDetails,
      sectionClassName: 'mb-[20px]',
      hasToggle: true,
      toggleState: 'WALLET_DETAILS_WALLET_VISIBLE',
    },
    {
      title: 'Cryptocurrency visible in the wallet',
      callOnBlur: handleUpdateMySiteDetails,
      sectionClassName: 'mb-[20px]',
      hasToggle: true,
      toggleState: 'WALLET_DETAILS_CRYPTO_VISIBLE',
    },
    {
      title: 'MetaMask (Decentralized Wallet) enabled',
      callOnBlur: handleUpdateMySiteDetails,
      sectionClassName: 'mb-[20px]',
      hasToggle: true,
      toggleState: 'WALLET_DETAILS_METAMASK_ENABLED',
    },
    {
      title: 'Withdraw hidden on wallet',
      callOnBlur: handleUpdateMySiteDetails,
      hasToggle: true,
      toggleState: 'WALLET_DETAILS_HIDE_WITHDRAW',
    },
    {
      callOnBlur: handleUpdateMySiteDetails,
      sectionClassName: 'gap-[20px] flex flex-col',
      inputs: [
        {
          type: 'checkboxRow',
          state: 'SECONDARIES_ENABLED',
          options: [
            { label: 'Enabled', value: 'true' },
            { label: 'Disabled', value: 'false' },
          ],
        },
      ],
    },
    {
      title: 'Sign Up / Sign In',
      callOnBlur: handleUpdateMySiteDetails,
      sectionClassName: 'mb-[20px]',
      subTitle: 'Sign Up / Sign In modal pops up after page reload.',
      hasToggle: true,
      toggleState: 'SIGNUP_ON_LOAD',
    },
    {
      title: 'Collections',
      callOnBlur: handleUpdateMySiteDetails,
      sectionClassName: 'mb-[30px]',
      subTitle: 'Show Collections on marketplace home.',
      hasToggle: true,
      toggleState: 'VISIBILITY_COLLECTIONS',
    },
    {
      title: 'Assets',
      callOnBlur: handleUpdateMySiteDetails,
      sectionClassName: 'mb-[30px]',
      subTitle: 'Show Assets on marketplace home.',
      hasToggle: true,
      toggleState: 'VISIBILITY_NFTS',
    },
    {
      title: 'Profile',
      callOnBlur: handleUpdateMySiteDetails,
      sectionClassName: 'mb-[30px]',
      subTitle: 'Hide wallet address in user profile.',
      hasToggle: true,
      toggleState: 'VISIBILITY_PROFILE',
    },
    {
      title: 'Referral Emails',
      callOnBlur: handleUpdateMySiteDetails,
      sectionClassName: 'mb-[30px]',
      subTitle: 'Hide Referral Emails on referral page.',
      hasToggle: true,
      toggleState: 'VISIBILITY_REFERRAL_EMAILS',
    },
    {
      title: 'English / Español',
      callOnBlur: handleUpdateMySiteDetails,
      subTitle: 'Allow users to toggle between english and spanish.',
      hasToggle: true,
      toggleState: 'ENABLE_SELECT_LANGUAGE',
    },
    {
      callOnBlur: handleUpdateMySiteDetails,
      sectionClassName: 'gap-[20px] flex flex-col',
      inputs: [
        {
          type: 'imageUpload',
          filesInModal: ['image'],
          state: 'PROFILE_DETAILS_DEFAULT_BANNER',
          aspect: 1250 / 200,
        },
      ],
    },
    {
      callOnBlur: handleUpdateMySiteDetails,
      sectionClassName: 'gap-[20px]',
      sectionInputsClassName: 'flex flex-row ',
      inputs: [
        {
          type: 'input',
          label: '"NFT" is referred as',
          state: 'SITE_LANGUAGE_NFT',
          palceholder: 'NFT',
          halfWidth: true,
        },
        {
          type: 'input',
          label: '"Collection" is referred as',
          state: 'SITE_LANGUAGE_COLLECTION',
          palceholder: 'Collection',
          halfWidth: true,
        },
        {
          type: 'input',
          label: '"Utility" is referred as',
          state: 'SITE_LANGUAGE_UTILITY',
          palceholder: 'Utility',
          halfWidth: true,
        },
        {
          type: 'input',
          label: '"Mint" is referred as',
          state: 'SITE_LANGUAGE_MINT',
          palceholder: 'Mint',
          halfWidth: true,
        },
        {
          type: 'input',
          label: '"Airdrop" is referred as',
          state: 'SITE_LANGUAGE_AIRDROP',
          palceholder: 'Airdrop',
          halfWidth: true,
        },
      ],
    },
    {
      callOnBlur: handleUpdateMySiteDetails,
      sectionClassName: 'bg-card py-[20px] px-[25px] shadow-md rounded-[5px]',
      sectionInputsClassName: 'mb-[20px]',
      inputs: [
        {
          type: 'input',
          label: 'Terms & Conditions Link',
          state: 'FOOTER_CONTENT_TERMS_AND_CONDITIONS',
        },
      ],
    },
    {
      callOnBlur: handleUpdateMySiteDetails,
      sectionClassName: '',
      sectionInputsClassName: 'flex flex-col gap-[20px]',
      inputs: [
        {
          type: 'input',
          state: 'SITE_LANGUAGE_REFER_TO_EARN',
          placeholder: 'Refer-To-Earn Program',
        },
        {
          type: 'textarea',
          state: 'SITE_LANGUAGE_REFER_BY_SHARING',
          placeholder:
            'By sharing a unique referral link, users can invite others to sign up with ( Project Name) and earn rewards for each new user they refer.',
        },
      ],
    },
    {
      callOnBlur: handleUpdateMySiteDetails,
      sectionClassName: 'gap-[20px]',
      sectionInputsClassName: 'flex flex-row ',
      inputs: [
        {
          type: 'input',
          label: 'Facebook Pixel ID',
          state: 'INTEGRATIONS_PIXEL_ID',
          halfWidth: true,
        },
      ],
    },
    {
      sectionClassName: '',
      sectionInputsClassName: 'mb-[20px]',
      inputs: [
        {
          type: 'input',
          state: 'CONNECT_DOMAIN',
        },
      ],
    },
    {
      title: 'Only hero show',
      callOnBlur: handleUpdateMySiteDetails,
      sectionClassName: 'mb-[20px]',
      subTitle: 'This means only the hero image will be shown',
      hasToggle: true,
      toggleState: 'ONLY_HERO_IN_USE',
    },
  ];

  const handleGetMySiteDetails = async () => {
    try {
      const {
        data: { getMySiteDetails: getMySiteDetailsRes },
      } = await client.query({
        query: GetMySiteDetailsDocument,
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ getMySiteDetailsRes });
      setLoading(false);

      const valueOrDefault = (val = '', passedDefault: any = null) => {
        if (getMySiteDetailsRes[val]) {
          return { hex: `#${getMySiteDetailsRes[val]}` };
        } else {
          if (passedDefault) {
            return { hex: `#${getMySiteDetailsRes[passedDefault]}` };
          } else {
            return defaultValues[val];
          }
        }
      };

      if (getMySiteDetailsRes?.PROJECT_DETAILS_HERO_LOGO.length == 0) {
        setImageLoaded(true);
      }

      // THEME_PRIMARY_COLOR
      const tempDefaultValues = {
        ...getMySiteDetailsRes,
        // '   '
        THEME_PRIMARY_COLOR: valueOrDefault('THEME_PRIMARY_COLOR'),
        THEME_SECONDARY_COLOR: valueOrDefault('THEME_SECONDARY_COLOR'),
        HEADER_HEADER_COLOR: valueOrDefault('HEADER_HEADER_COLOR'),
        HEADER_HEADER_TEXT_COLOR: valueOrDefault('HEADER_HEADER_TEXT_COLOR'),
        FOOTER_STYLE_FOOTER_COLOR: valueOrDefault('FOOTER_STYLE_FOOTER_COLOR'),
        FOOTER_STYLE_FOOTER_TEXT_COLOR: valueOrDefault('FOOTER_STYLE_FOOTER_TEXT_COLOR'),

        BUTTON_CUSTOMIZATION_BUTTON_FILL_COLOR: valueOrDefault(
          'BUTTON_CUSTOMIZATION_BUTTON_FILL_COLOR',
          'THEME_PRIMARY_COLOR',
        ),
        BUTTON_CUSTOMIZATION_BUTTON_STROKE_COLOR: valueOrDefault(
          'BUTTON_CUSTOMIZATION_BUTTON_STROKE_COLOR',
        ),
        PROJECT_DETAILS_PROJECT_NAME_TEXT_COLOR: valueOrDefault(
          'PROJECT_DETAILS_PROJECT_NAME_TEXT_COLOR',
          'THEME_TEXT_COLOR',
        ),
        PROJECT_DETAILS_ABOUT_PROJECT_TEXT_COLOR: valueOrDefault(
          'PROJECT_DETAILS_ABOUT_PROJECT_TEXT_COLOR',
          'THEME_TEXT_COLOR',
        ),
        PROJECT_DETAILS_HERO_DETAILS_HERO_BANNER_IMAGE_OPACITY:
          getMySiteDetailsRes?.PROJECT_DETAILS_HERO_DETAILS_HERO_BANNER_IMAGE_OPACITY
            ? [
                parseInt(
                  getMySiteDetailsRes?.PROJECT_DETAILS_HERO_DETAILS_HERO_BANNER_IMAGE_OPACITY,
                ),
              ]
            : [100],
        PROJECT_DETAILS_HERO_LOGO_IN_USE:
          getMySiteDetailsRes?.PROJECT_DETAILS_HERO_LOGO_IN_USE !== 'false',
        WALLET_DETAILS_WALLET_VISIBLE:
          getMySiteDetailsRes?.WALLET_DETAILS_WALLET_VISIBLE !== 'false',
        WALLET_DETAILS_CRYPTO_VISIBLE:
          getMySiteDetailsRes?.WALLET_DETAILS_CRYPTO_VISIBLE !== 'false',
        WALLET_DETAILS_METAMASK_ENABLED:
          getMySiteDetailsRes?.WALLET_DETAILS_METAMASK_ENABLED !== 'false',
        WALLET_DETAILS_HIDE_WITHDRAW: getMySiteDetailsRes?.WALLET_DETAILS_HIDE_WITHDRAW !== 'false',
        SECONDARIES_ENABLED: getMySiteDetailsRes?.SECONDARIES_ENABLED,
        IS_PUBLIC: getMySiteDetailsRes?.IS_PUBLIC,
        SIGNUP_ON_LOAD: getMySiteDetailsRes?.SIGNUP_ON_LOAD !== 'false',
        VISIBILITY_COLLECTIONS: getMySiteDetailsRes?.VISIBILITY_COLLECTIONS !== 'false',
        VISIBILITY_NFTS: getMySiteDetailsRes?.VISIBILITY_NFTS !== 'false',
        VISIBILITY_PROFILE: getMySiteDetailsRes?.VISIBILITY_PROFILE !== 'false',
        VISIBILITY_REFERRAL_EMAILS: getMySiteDetailsRes?.VISIBILITY_REFERRAL_EMAILS !== 'false',
        ENABLE_SELECT_LANGUAGE: getMySiteDetailsRes?.ENABLE_SELECT_LANGUAGE == 'true',
        BUTTON_CUSTOMIZATION_BUTTON_ENABLED:
          getMySiteDetailsRes?.BUTTON_CUSTOMIZATION_BUTTON_ENABLED !== 'false',
        // Theme Colors
        THEME_BG_COLOR: {
          hex: getMySiteDetailsRes?.THEME_BG_COLOR == '000000' ? '#000000' : '#f9f9f9',
        },
        THEME_CARD_COLOR: {
          hex: getMySiteDetailsRes?.THEME_BG_COLOR == '000000' ? '#333333' : '#FFFFFF',
        },
        THEME_TEXT_COLOR: {
          hex: getMySiteDetailsRes?.THEME_BG_COLOR == '000000' ? '#FFFFFF' : '#000000',
        },
        DARK_THEME: getMySiteDetailsRes?.THEME_BG_COLOR == '000000',
        FAVICON: config.faviconUrl(selectedProject?.project_name),
      };

      console.log({ favicon: config.faviconUrl(selectedProject?.project_name) });

      methods.reset(tempDefaultValues);
    } catch (err) {
      console.log({ err });
    }
  };

  const handleGetIngressUrl = async () => {
    try {
      const {
        data: { getIngressUrl: getIngressUrlRes },
      } = await client.query({
        query: GetIngressUrlDocument,
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ getIngressUrlRes });
      setIngressUrl(getIngressUrlRes);
    } catch (getIngressUrlErr) {
      console.log({ getIngressUrlErr });
    }
  };

  const handleRenderWidth = (num) => {
    const scaleDownByLaptop = 0.8038;
    // return num * scaleDownByLaptop;
    return num;
  };

  const handleRenderWidth2 = (num) => {
    const scaleDownByLaptop = 1098 / deviceViews[selectedDevice].width;
    return num * scaleDownByLaptop;
  };

  useEffect(() => {
    if (!selectedProject?.project_name) return;
    handleGetMySiteDetails();
    handleGetIngressUrl();
  }, [selectedProject]);

  useEffect(() => {
    console.log({ formValues });
  }, [formValues]);

  const onImageLoad = () => {
    console.log('ONERROR');

    const innerDiv = innerDivRef.current;
    if (innerDiv && !imageLoaded) {
      const rect = innerDiv.getBoundingClientRect();
      console.log('NATURAL WIDTH', { width: rect.width, height: rect.height });
      setOuterDivSize({
        width: rect.width,
        height: rect.height,
      });
      setImageLoaded(true);
      setImageFailed(false);
    } else {
      console.log('NOT LOADED');
    }
  };

  const centeredCallToAction = () =>
    formValues?.PROJECT_DETAILS_HERO_LOGO_IN_USE
      ? {}
      : {
          width: '100%',
          minWidth: '100%',
          alignItems: 'center',
        };

  const isMobile = deviceViews[selectedDevice].width < 500;

  useEffect(() => {
    if (formValues.PROJECT_DETAILS_HERO_LOGO_IN_USE === false) {
      // If image does not load (and trigger load to stop) then this will
      console.log('HERO LOGO NOT BEING USED', formValues.PROJECT_DETAILS_HERO_LOGO_IN_USE);
      setImageLoaded(true);
      setImageFailed(false);
    }
    // if ()
  }, [formValues]);

  useEffect(() => {
    console.log({ isMobile });
  }, [isMobile]);

  const exploreBtn = () => {
    return (
      <button
        style={{
          maxWidth: isMobile ? '348px' : handleRenderWidth2(348),
          minWidth: isMobile ? '100%' : handleRenderWidth2(208),
          height: isMobile ? '3.5rem' : `${handleRenderWidth2(3.5)}rem`,
          fontSize: isMobile ? '1rem' : `${handleRenderWidth2(1)}rem`,
          marginTop: isMobile ? '20px' : handleRenderWidth2(20),
          marginBottom: isMobile ? '30px' : '',
          backgroundColor: `${
            formValues?.BUTTON_CUSTOMIZATION_BUTTON_FILL_COLOR?.hex ||
            formValues?.THEME_PRIMARY_COLOR?.hex
          }`,
          fontWeight: 600,
          borderRadius: 4,
        }}
        className="p-0 text-center uppercase"
      >
        <p
          style={{
            padding: isMobile
              ? '15px 0px'
              : `${handleRenderWidth2(1)}rem ${handleRenderWidth2(2)}rem`,
            color: memoizedTextColor(
              `${
                formValues?.BUTTON_CUSTOMIZATION_BUTTON_FILL_COLOR?.hex ||
                formValues?.THEME_PRIMARY_COLOR.hex
              }`,
              'white',
              'black',
            ),
          }}
          className="z-1 text-bold size-full text-card no-underline"
        >
          {formValues?.BUTTON_CUSTOMIZATION_BUTTON_TEXT || 'Explore'}
        </p>
      </button>
    );
  };

  return (
    <form
      className="relative grid w-full max-w-[1100px] flex-col gap-[30px]"
      // onSubmit={handleSubmit(handleUpdateMySiteDetails)}
    >
      {loading ? (
        <div className="flex h-[60vh] items-center justify-center">
          <div className="m-auto size-10 animate-spin rounded-full border-t-2 border-primary" />
        </div>
      ) : (
        <>
          <SelectorDropdown
            name={'screenSelect'}
            label={''}
            selected={selectedScreen}
            options={selectScreenOptions}
            underText={''}
            isError={false}
            required={false}
            callOnBlur={(item) => {
              console.log({ item });
              setSelectedScreen(parseInt(item.screenSelect));
            }}
            className="h-[42px] w-[250px] rounded-[5px] bg-card text-base"
          />
          {selectedScreen == 0 && (
            <>
              <div className="flex">
                <WrappedInputSection methods={methods} data={screen1.slice(0, 1)} />
                <div className="flex w-full flex-col gap-[20px] ">
                  <h3 className=" text-xl font-medium">Favicon</h3>

                  <div className="flex w-full justify-between ">
                    <div>
                      <p className="mb-[10px] text-lg">
                        <span className="text-primary">Drop</span> your Favicon
                      </p>
                      <p className="mb-[10px] text-sm">Required file type for favicon is ICO</p>
                      <p className="text-sm">Dont have an ICO file?</p>
                      <a
                        className="text-sm text-primary underline"
                        href="https://favicon.io/favicon-converter/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Click here for a free converter
                      </a>
                    </div>
                    <div className="w-[200px]">
                      <WrappedInputSection methods={methods} data={screen1.slice(1, 2)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="">
                  <WrappedInputSection methods={methods} data={screen1.slice(2, 3)} />
                </div>
                <h3 className="mt-[30px] text-xl font-medium">Navigation Bar</h3>
                <div className="mt-[20px] overflow-hidden rounded-[4px] bg-card pb-[90px] shadow-md">
                  <div
                    className={`flex h-[90px] w-full items-center justify-between px-[48px]`}
                    style={{ backgroundColor: formValues.HEADER_HEADER_COLOR.hex }}
                  >
                    {formValues.PROJECT_DETAILS_COMPANY_LOGO ? (
                      <img
                        className="h-[56px]"
                        src={
                          formValues?.PROJECT_DETAILS_COMPANY_LOGO?.hostedUrl ||
                          formValues?.PROJECT_DETAILS_COMPANY_LOGO
                        }
                      />
                    ) : (
                      <h1 className="text-[2.5rem] font-medium text-white">Your Logo</h1>
                    )}
                    <button
                      className="flex h-[48px] items-center rounded border-primary px-[12px] text-base font-medium text-primary"
                      style={{
                        border: `2px solid ${formValues.HEADER_HEADER_TEXT_COLOR.hex}`,
                        color: `${formValues.HEADER_HEADER_TEXT_COLOR.hex}`,
                      }}
                    >
                      <Wallet
                        className="mr-[10px] stroke-primary"
                        style={{ stroke: `${formValues.HEADER_HEADER_TEXT_COLOR.hex}` }}
                      />
                      {formValues.HEADER_HEADER_BUTTON_TEXT}
                    </button>
                  </div>
                </div>
                <div className="mt-[30px] flex gap-[30px]">
                  <div className="w-full">
                    <WrappedInputSection methods={methods} data={screen1.slice(3, 4)} />
                  </div>
                  <div className="w-full">
                    <WrappedInputSection methods={methods} data={screen1.slice(4, 7)} />
                  </div>
                </div>
              </div>
              <div className="flex">
                <h3 className="mt-[0px] text-xl font-medium">Footer Bar</h3>
                <button
                  className="ml-[20px] rounded-[5px] border-2 border-primary px-[10px] py-[2px] text-sm"
                  onClick={(e) => {
                    e.preventDefault();
                    setFootermodalOpen(!footermodalOpen);
                  }}
                >
                  Select Footer
                </button>
              </div>

              <SiteFooter
                background={formValues?.FOOTER_STYLE_FOOTER_COLOR?.hex}
                textColor={formValues?.FOOTER_STYLE_FOOTER_TEXT_COLOR?.hex}
                logoUrl={
                  formValues?.PROJECT_DETAILS_COMPANY_LOGO?.hostedUrl ||
                  formValues?.PROJECT_DETAILS_COMPANY_LOGO
                }
                terms={formValues?.FOOTER_CONTENT_TERMS_AND_CONDITIONS}
                theme1={selectedFooterTheme != 0}
                block1={[
                  formValues?.FOOTER_CONTENT_FOOTER_CONTENT_BLOCK_1_LINE_1,
                  formValues?.FOOTER_CONTENT_FOOTER_CONTENT_BLOCK_1_LINE_2,
                  formValues?.FOOTER_CONTENT_FOOTER_CONTENT_BLOCK_1_LINE_3,
                ]}
                block2={[
                  formValues?.FOOTER_CONTENT_FOOTER_CONTENT_BLOCK_2_LINE_1,
                  formValues?.FOOTER_CONTENT_FOOTER_CONTENT_BLOCK_2_LINE_2,
                  formValues?.FOOTER_CONTENT_FOOTER_CONTENT_BLOCK_2_LINE_3,
                ]}
                socials={{
                  instagram: formValues.BODY_CUSTOMIZATION_INSTAGRAM_LINK,
                  youtube: formValues.BODY_CUSTOMIZATION_YOUTUBE_LINK,
                  discord: formValues.BODY_CUSTOMIZATION_DISCORD_LINK,
                  facebook: formValues.BODY_CUSTOMIZATION_FACEBOOK_LINK,
                  tiktok: formValues.BODY_CUSTOMIZATION_TIKTOK_LINK,
                  twitter: formValues.BODY_CUSTOMIZATION_TWITTER_LINK,
                  telegram: formValues.BODY_CUSTOMIZATION_TELEGRAM_LINK,
                }}
              />

              <div className="flex w-full flex-col gap-[30px]">
                <WrappedInputSection methods={methods} data={screen1.slice(7, 8)} />
                <WrappedInputSection methods={methods} data={screen1.slice(8, 12)} />
              </div>
            </>
          )}
          {selectedScreen == 1 && (
            <>
              <div className="absolute right-0 top-0 flex items-center">
                <p className="mr-[20px] text-lg opacity-80">
                  {`Screen Width: ${deviceViews[selectedDevice].width}px`}
                </p>
                <div className="flex rounded-[10px] border-2 bg-card">
                  {deviceViews.map((item, index) => (
                    <button
                      key={item.width}
                      className={cn(
                        'flex h-[40px] w-[50px] items-center justify-center',
                        selectedDevice == index ? 'bg-primary' : '',
                      )}
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedDevice(index);
                      }}
                    >
                      {item.icon}
                    </button>
                  ))}
                </div>
              </div>
              <div
                className={cn(
                  'relative w-full overflow-hidden rounded-[2px] border',
                  isMobile ? 'mx-auto max-w-[392px]' : '',
                )}
              >
                <div className="flex border-b bg-card p-[6px] px-[15px]">
                  <div className="mr-[20px] flex items-center">
                    <div className="mr-[5px] size-[14px] rounded-[10px] border bg-[#FF605C]" />
                    <div className="mr-[5px] size-[14px] rounded-[10px] border bg-[#FFBD44]" />
                    <div className="size-[14px] rounded-[10px] border bg-[#00CA4E]" />
                  </div>
                  <input
                    className="w-full rounded-[15px] bg-input py-[3px] pl-[20px] text-sm"
                    value={selectedProject?.react_urls}
                  />
                </div>
                <div
                  style={{
                    minHeight: isMobile ? '456px' : handleRenderWidth2(456),
                  }}
                  className="relative box-border flex w-full flex-col overflow-hidden "
                >
                  {!imageLoaded && (
                    <div className="absolute z-20 flex size-full items-center justify-center bg-card">
                      <div className="size-10 animate-spin rounded-full border-t-2 border-primary" />
                    </div>
                  )}

                  <img
                    src={handleRenderBanner()}
                    style={{
                      minHeight: isMobile ? '456px' : handleRenderWidth2(456),
                      opacity: `${formValues?.PROJECT_DETAILS_HERO_DETAILS_HERO_BANNER_IMAGE_OPACITY[0] / 100 || 100}`,
                    }}
                    className="absolute z-0 h-[101%] w-full object-cover"
                  />
                  <div
                    style={{
                      minHeight: isMobile ? '456px' : handleRenderWidth2(456),
                      maxWidth: isMobile ? '100%' : handleRenderWidth2(1760),
                      padding: isMobile ? '0px' : `0px ${handleRenderWidth2(4.5)}rem`,
                      paddingBottom: isMobile ? '0px' : handleRenderWidth2(30),
                      fontFamily: formValues?.PROJECT_DETAILS_ABOUT_PROJECT_TEXT_FONT_SIZE,
                      flexDirection:
                        `${formValues?.ONLY_HERO_IN_USE}` === 'true' ? 'column' : 'row',
                    }}
                    className="relative z-10 m-auto box-border flex size-full items-center justify-center overflow-hidden"
                  >
                    {!!(imageLoaded && `${formValues?.ONLY_HERO_IN_USE}` !== 'true') && (
                      <div
                        style={{
                          ...{
                            maxWidth: handleRenderWidth2(822),
                            marginTop: isMobile
                              ? '30px'
                              : !formValues?.PROJECT_DETAILS_HERO_LOGO_IN_USE
                                ? handleRenderWidth2(60)
                                : handleRenderWidth2(30),
                            alignItems: isMobile
                              ? 'center'
                              : formValues?.PROJECT_DETAILS_HERO_LOGO_IN_USE !== false
                                ? 'start'
                                : 'center',
                          },
                          ...centeredCallToAction(),
                        }}
                        className="flex w-[90%] flex-col items-start"
                      >
                        <h1
                          style={{
                            fontSize: isMobile
                              ? '38px'
                              : handleRenderWidth2(
                                  parseInt(
                                    formValues?.PROJECT_DETAILS_PROJECT_NAME_TEXT_FONT_SIZE,
                                  ) || 48,
                                ),
                            paddingRight: isMobile ? 0 : handleRenderWidth2(15),
                            marginBottom: isMobile ? '30px' : handleRenderWidth2(30),
                            fontWeight: 600,
                            fontFamily: formValues.PROJECT_DETAILS_PROJECT_NAME_TEXT_FONT,
                            // lineHeight: 1.2,
                            color: formValues?.PROJECT_DETAILS_PROJECT_NAME_TEXT_COLOR?.hex,
                            textAlign: formValues?.PROJECT_DETAILS_HERO_LOGO_IN_USE
                              ? 'left'
                              : 'center',
                          }}
                          className="pb-0 text-left text-card"
                        >
                          {formValues?.PROJECT_DETAILS_PROJECT_NAME || 'Project Name'}
                        </h1>
                        <h2
                          style={{
                            fontSize: isMobile ? '16px' : handleRenderWidth2(16),
                            lineHeight: isMobile ? '22.4px' : `${handleRenderWidth2(22.4)}px`,
                            marginBottom: isMobile ? '30px' : handleRenderWidth2(30),
                            fontFamily: formValues.PROJECT_DETAILS_ABOUT_PROJECT_TEXT_FONT,
                            fontWeight: 400,
                            color: formValues?.PROJECT_DETAILS_ABOUT_PROJECT_TEXT_COLOR?.hex,
                            textAlign: isMobile
                              ? 'center'
                              : formValues?.PROJECT_DETAILS_HERO_LOGO_IN_USE
                                ? 'left'
                                : 'center',
                          }}
                          className="w-[80%] text-card"
                        >
                          {formValues?.PROJECT_DETAILS_ABOUT_PROJECT ||
                            'Project Description: Let the user know why this project is special and why they should be apart of this community.'}
                        </h2>
                        {formValues?.BUTTON_CUSTOMIZATION_BUTTON_ENABLED && exploreBtn()}
                      </div>
                    )}
                    {!!(formValues.PROJECT_DETAILS_HERO_LOGO_IN_USE && !isMobile) && (
                      <>
                        <div
                          className="flex items-center justify-center border-red-500"
                          style={{
                            // width: outerDivSize.width,
                            // height: outerDivSize.height,
                            overflow: 'hidden',
                            marginTop: handleRenderWidth2(50),
                            marginBottom: handleRenderWidth2(20),
                          }}
                        >
                          <div
                            ref={innerDivRef}
                            className="flex justify-center border-red-500"
                            style={
                              {
                                // This is here because the parent div will have a non fixable padding around it if we dont scale
                                // transform: outerDivSize.width == 'auto' ? `scale(0.8038)` : `scale(1)`,
                              }
                            }
                          >
                            {!!(
                              !imageFailed &&
                              (formValues?.PROJECT_DETAILS_HERO_LOGO?.hostedUrl ||
                                formValues?.PROJECT_DETAILS_HERO_LOGO)
                            ) && (
                              <img
                                key={
                                  formValues?.PROJECT_DETAILS_HERO_LOGO?.hostedUrl ||
                                  formValues?.PROJECT_DETAILS_HERO_LOGO
                                }
                                src={
                                  formValues?.PROJECT_DETAILS_HERO_LOGO?.hostedUrl ||
                                  formValues?.PROJECT_DETAILS_HERO_LOGO
                                }
                                style={{
                                  maxWidth: handleRenderWidth2(500),
                                }}
                                className="w-[90%] rounded-[10px]"
                                alt="Hero Logo"
                                onLoad={onImageLoad}
                                onError={() => {
                                  console.log('ONERROR');
                                  setImageLoaded(true);
                                  setImageFailed(true);
                                }}
                              />
                            )}
                          </div>
                        </div>
                        {!!(
                          formValues?.BUTTON_CUSTOMIZATION_BUTTON_ENABLED &&
                          `${formValues?.ONLY_HERO_IN_USE}` !== 'false'
                        ) && exploreBtn()}
                      </>
                    )}
                    {/* </div> */}
                  </div>
                </div>
              </div>
              <div className="flex w-full gap-[20px]">
                <WrappedInputSection methods={methods} data={screen2.slice(0, 1)} />
                <div className="min-w-[350px]">
                  <WrappedInputSection methods={methods} data={screen2.slice(1, 2)} />
                </div>
              </div>

              <div className="flex flex-col gap-[20px]">
                <WrappedInputSection methods={methods} data={screen2.slice(2, 3)} />
                <WrappedInputSection methods={methods} data={screen2.slice(3, 4)} />
              </div>
              <div className="flex flex-col gap-[20px]">
                <WrappedInputSection methods={methods} data={screen2.slice(4, 5)} />
                <WrappedInputSection methods={methods} data={screen2.slice(5, 6)} />
              </div>
              <div className="flex flex-col gap-[20px]">
                <WrappedInputSection methods={methods} data={screen2.slice(6, 7)} />
              </div>
              <div className="flex flex-col gap-[20px]">
                <WrappedInputSection methods={methods} data={screen2.slice(7, 8)} />
              </div>
            </>
          )}
          {selectedScreen == 2 && (
            <>
              <h3 className="mt-[20px] border-b pb-[10px] text-xl font-medium">
                Site Accessibility
              </h3>
              <div className="mx-auto w-full pl-[50px]">
                <WrappedInputSection methods={methods} data={screen3.slice(0, 1)} />
              </div>
              <h3 className="mt-[20px] border-b pb-[10px] text-xl font-medium">Wallet Options</h3>
              <div className="w-full max-w-[600px] pl-[50px]">
                <WrappedInputSection methods={methods} data={screen3.slice(1, 5)} />
              </div>
              <h3 className="mt-[20px] border-b pb-[10px] text-xl font-medium">
                Secondary Functionality
              </h3>
              <div className="mx-auto w-full pl-[50px]">
                <WrappedInputSection methods={methods} data={screen3.slice(5, 6)} />
              </div>
              <h3 className="mt-[20px] border-b pb-[10px] text-xl font-medium">Site Visibility</h3>
              <div className="w-full max-w-[600px] pl-[50px]">
                <WrappedInputSection methods={methods} data={screen3.slice(6, 12)} />
              </div>
              <h3 className="mt-[20px] border-b pb-[10px] text-xl font-medium">
                Default Profile Banner
              </h3>
              <div className="w-full max-w-[800px] pl-[50px]">
                <WrappedInputSection methods={methods} data={screen3.slice(12, 13)} />
              </div>
              <h3 className="mt-[20px] border-b pb-[10px] text-xl font-medium">Site Language</h3>
              <div className="w-full max-w-[800px] pl-[50px]">
                <WrappedInputSection methods={methods} data={screen3.slice(13, 14)} />
              </div>
              <h3 className="mt-[20px] border-b pb-[10px] text-xl font-medium">
                Your Terms & Conditions
              </h3>
              <div className="w-full max-w-[800px] pl-[50px]">
                <WrappedInputSection methods={methods} data={screen3.slice(14, 15)} />
              </div>
              <h3 className="mt-[20px] border-b pb-[10px] text-xl font-medium">
                Referral Program Language
              </h3>
              <div className="flex">
                <div className="mr-[30px] flex w-[350px] flex-col pl-[50px]">
                  <p>Refer-To-Earn Program</p>
                  <p className="mt-[44px]">
                    By sharing a unique referral link, users can invite others to sign up with (
                    Project Name) and earn rewards for each new user they refer.
                  </p>
                </div>
                <div className="w-[400px]">
                  <WrappedInputSection methods={methods} data={screen3.slice(15, 16)} />
                </div>
              </div>
              <h3 className="mt-[20px] border-b pb-[10px] text-xl font-medium">Integrations</h3>
              <div className="w-full max-w-[800px] pl-[50px]">
                <WrappedInputSection methods={methods} data={screen3.slice(16, 17)} />
              </div>
              <h3 className="mt-[20px] border-b pb-[10px] text-xl font-medium">
                Connect Your Domain
              </h3>
              <div className="flex w-full max-w-[800px] flex-col gap-[20px] pl-[50px]">
                <p className="opacity-60">In couple of easy steps please connect your Domain.</p>
                <div className="rounded-[5px] bg-card px-[25px] py-[20px] shadow-md">
                  <h4 className="pb-[15px] text-lg font-medium">Instructions</h4>
                  <p className="mb-[10px] text-sm">
                    - Go to your domain provider Create an "A Record" and add the ip address below
                    as the value
                    <br />
                    <span className="text-primary">{ingressUrl}</span>
                  </p>
                  <p className="mb-[10px] text-sm">
                    - Navigate back to the dashboard and input your custom URL
                  </p>
                  <p className="mb-[10px] text-sm">- Click the “Connect” button</p>
                  <WrappedInputSection methods={methods} data={screen3.slice(17, 18)} />
                  <button className="h-[48px] w-[168px] rounded-[5px] bg-[#63c94f] font-semibold uppercase text-white">
                    Connect
                  </button>
                </div>
              </div>
              <h3 className="mt-[20px] border-b pb-[10px] text-xl font-medium">
                Additional Settings
              </h3>
              <div className="w-full max-w-[800px] pl-[50px]">
                <WrappedInputSection methods={methods} data={screen3.slice(18, 19)} />
              </div>
            </>
          )}
          <SelectFooter
            open={footermodalOpen}
            selectedFooterTheme={selectedFooterTheme}
            setSelectedFooterTheme={setSelectedFooterTheme}
            textColor={formValues?.FOOTER_STYLE_FOOTER_TEXT_COLOR?.hex}
            setOpen={() => {
              setFootermodalOpen(!footermodalOpen);
            }}
            background={formValues?.FOOTER_STYLE_FOOTER_COLOR?.hex}
            logoUrl={
              formValues?.PROJECT_DETAILS_COMPANY_LOGO?.hostedUrl ||
              formValues?.PROJECT_DETAILS_COMPANY_LOGO
            }
            terms={formValues?.FOOTER_CONTENT_TERMS_AND_CONDITIONS}
            block1={[
              formValues?.FOOTER_CONTENT_FOOTER_CONTENT_BLOCK_1_LINE_1,
              formValues?.FOOTER_CONTENT_FOOTER_CONTENT_BLOCK_1_LINE_2,
              formValues?.FOOTER_CONTENT_FOOTER_CONTENT_BLOCK_1_LINE_3,
            ]}
            block2={[
              formValues?.FOOTER_CONTENT_FOOTER_CONTENT_BLOCK_2_LINE_1,
              formValues?.FOOTER_CONTENT_FOOTER_CONTENT_BLOCK_2_LINE_2,
              formValues?.FOOTER_CONTENT_FOOTER_CONTENT_BLOCK_2_LINE_3,
            ]}
            socials={{
              instagram: formValues.BODY_CUSTOMIZATION_INSTAGRAM_LINK,
              youtube: formValues.BODY_CUSTOMIZATION_YOUTUBE_LINK,
              discord: formValues.BODY_CUSTOMIZATION_DISCORD_LINK,
              facebook: formValues.BODY_CUSTOMIZATION_FACEBOOK_LINK,
              tiktok: formValues.BODY_CUSTOMIZATION_TIKTOK_LINK,
              twitter: formValues.BODY_CUSTOMIZATION_TWITTER_LINK,
              telegram: formValues.BODY_CUSTOMIZATION_TELEGRAM_LINK,
            }}
          />
        </>
      )}
    </form>
  );
};

export default MySite;
