import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';

import ApiImg from '@/assets/ApiImg.png';
import Key from '@/assets/Key.svg?react';
import SchemaImg from '@/assets/SchemaImg.png';
import { Button } from '@/components/ui/button';
import LinkCard from '@/components/ui/link-card';
import {
  CreateApiKeyDocument,
  CreateUserDocument,
  GetProjectUsersDocument,
  ListApiKeysDocument,
} from '@/lib/__generated__/dashboard/graphql';
import { client, ClientName } from '@/lib/apollo';
import useBoundStore from '@/store';
import { ReloadIcon } from '@radix-ui/react-icons';

import { Input } from '../ui/input';
import SectionDivider from '../ui/section-divider';

const DeveloperView: FunctionComponent<any> = () => {
  const [apiKey, setApiKey] = useState('');
  const [isReloading, setIsReloading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleGetApiKey = async () => {
    try {
      const {
        data: { listApiKeys: listApiKeyRes },
      } = await client.query({
        query: ListApiKeysDocument,
        context: { clientName: ClientName.Dashboard },
      });
      console.log({ listApiKeyRes });
      setApiKey(listApiKeyRes[0].display_str);
      setIsLoading(false);
    } catch (err) {
      console.log({ err });
    }
  };

  const handleCreateApiKey = async () => {
    try {
      setIsReloading(true);
      const {
        data: { createApiKey: createApiKeyRes },
      } = await client.mutate({
        mutation: CreateApiKeyDocument,
        variables: { input: { permissions: {}, name: '' } },
        context: { clientName: ClientName.Dashboard },
      });
      console.log({ createApiKeyRes });
      setApiKey(createApiKeyRes.apikey);
      setIsReloading(false);
    } catch (err) {
      console.log({ err });
      setIsReloading(false);
    }
  };

  useEffect(() => {
    handleGetApiKey();
  }, []);

  return (
    <div className="grid w-full max-w-[1100px] flex-col gap-[35px]">
      <SectionDivider
        color={'#9256FF'}
        titles={['Developer Resources']}
        img={<Key className="fill-transparent stroke-white" />}
      />
      <div className="flex w-full max-w-[910px] items-center gap-[10px]">
        <h1 className="w-[100px] font-semibold">API Key:</h1>
        <Input className="text-lg" label="" value={apiKey} />
        <Button
          className="flex h-full w-[260px] items-center justify-center rounded-md bg-primary text-sm uppercase text-white"
          loading={isReloading || isLoading}
          onClick={() => {
            handleCreateApiKey();
          }}
        >
          <ReloadIcon className="mr-[10px] size-[12px] stroke-white" />
          {apiKey.length == 0 ? 'Generate Key' : 'Regenerate Key'}
        </Button>
      </div>
      <div className="flex gap-[24px]">
        {[
          {
            title: 'Documentation',
            img: ApiImg,
            link: 'https://chainstarters.gitbook.io/welcome/',
          },
          { title: 'Schema', img: SchemaImg, link: localStorage.getItem('graphql_endpoint_url') },
        ].map((item, index) => (
          <LinkCard item={item} />
        ))}
      </div>
    </div>
  );
};

export default DeveloperView;
