import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import spacetime from 'spacetime';

import Dollar from '@/assets/Dollar2.svg?react';
import PercentSlider from '@/components/ui/percent-slider';
import { GetRewardsProgramDocument, Nft } from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { cn } from '@/lib/utils';
import { ListBulletIcon, LockOpen2Icon, TimerIcon } from '@radix-ui/react-icons';

import NftCard from '../ui/nft-card';
import Table from '../ui/table';

const RewardsView: FunctionComponent = () => {
  const [audienceAssets, setAudienceAssets] = useState<Nft[]>([]);
  const [accessAssets, setAccessAssets] = useState<Nft[]>([]);
  const [rangeValues, setRangeValues] = useState([20]);
  const [selectedLists, setSelectedLists] = useState<ListType[]>([]);
  const [selectedRewards, setSelectedRewards] = useState<number | null>(null);
  const [selectedRewardsValue, setSelectedRewardsValue] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  const selectBtns = (types, selected: null | number = null, error = false) => {
    return (
      <div className="flex gap-[10px]">
        {types.map((item, index) => (
          <div
            className={cn(
              'w-[200px] cursor-pointer rounded-[16px] border bg-card py-[14px]',
              selected == index ? 'border-primary' : '',
              error ? 'border-red-500' : '',
            )}
            rel="noreferrer"
            key={`${item.name}`}
            onClick={item.onClick}
          >
            <p className="flex items-center justify-center text-center font-semibold capitalize text-black">
              {item.icon}
              {item.name}
            </p>
          </div>
        ))}
      </div>
    );
  };

  const rewardTypes = [
    {
      name: 'Purchase Access',
      icon: <LockOpen2Icon className="mr-2 size-[18px] text-black" />,
    },
    {
      name: 'Early Access',
      icon: <TimerIcon className="mr-2 size-[18px] text-black" />,
    },
    {
      name: 'Discount',
      icon: <Dollar stroke="#000000" className="mr-2 stroke-black" />,
    },
  ];

  const discountTypes = {
    MINT_PURCHASE: 0,
    EARLY_ACCESS: 1,
    DISCOUNT: 2,
  };

  const listTable = useMemo(() => {
    return {
      columnNames: ['List Name', 'Date created', '# Members'],
      data: selectedLists?.map((item) => [
        {
          value: item.name,
          className: 'text-md capitalize',
        },
        {
          value: spacetime(parseInt(item.created_at)).format('numeric-us'),
          className: 'text-md',
        },
        {
          value: `${(item.number_of_members || 0).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`,
          className: 'text-md',
        },
      ]),
    };
  }, [selectedLists]);

  const getRewardsProgramById = async () => {
    try {
      const {
        data: { getRewardsProgram: getRewardsProgramRes },
      } = await client.query({
        query: GetRewardsProgramDocument,
        variables: {
          getRewardsProgramId: parseInt(id || ''),
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ getRewardsProgramRes });
      setAudienceAssets(getRewardsProgramRes.constraints[0].nfts);
      setSelectedLists(getRewardsProgramRes.constraints[1].uploaded_custom_lists);
      setAccessAssets(getRewardsProgramRes.assigned_rewards.map((item) => item.nft));
      setSelectedRewards(discountTypes[getRewardsProgramRes.assigned_rewards[0].rewards[0].type]);
      setSelectedRewardsValue(getRewardsProgramRes.assigned_rewards[0].rewards[0].value);
      setLoading(false);
    } catch (err) {
      console.log({ err });
      setLoading(false);
    }
  };

  useEffect(() => {
    getRewardsProgramById();
  }, []);

  return (
    <div className="flex items-center">
      {loading ? (
        <div className="flex h-[50vh] flex-col items-center justify-center">
          <div className="size-8 animate-spin rounded-full border-t-2 border-primary" />
          <p className="mt-[30px] text-sm opacity-60">Loading...</p>
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-[25px]">
            <div>
              <h2 className="border-b pb-[5px] text-xl">Your Audience</h2>
              <p className="mt-[10px] w-[80%] text-sm opacity-60">
                This audience includes all users who will access the rewards, either by being on the
                selected lists or owning the assets below.
              </p>
            </div>
            <div className="flex flex-col gap-[10px]">
              {listTable.data.length > 0 && (
                <div className="box-border max-h-[264px] overflow-y-scroll rounded-[10px] border bg-card pb-[20px] pr-[10px] pt-[10px]">
                  <Table
                    ariaLabel="asset-purchase-history"
                    columnNames={listTable.columnNames}
                    data={listTable.data}
                    isLoading={false}
                    noDataText="No Lists Selected"
                  />
                </div>
              )}
              <div className="mt-[10px] flex w-full flex-wrap gap-[20px]">
                {audienceAssets.map((nft, index) => (
                  <NftCard asset={nft} smallNft />
                ))}
              </div>
            </div>
            <div>
              <h2 className="border-b pb-[5px] text-xl">Rewards</h2>
              <p className="mt-[10px] w-[80%] text-sm opacity-60">
                These are the rewards to be linked with the assets below.
              </p>
            </div>
            <div>
              <div className="flex gap-[10px]">
                {selectBtns([rewardTypes[selectedRewards ?? 0]], 0)}
              </div>
            </div>
            {!!(selectedRewards == 1 && selectedRewardsValue?.length > 0) && (
              <div className="flex gap-[10px]">
                <p className="rounded-md border bg-card p-[10px] px-[20px]">
                  {spacetime(selectedRewardsValue[0]).format('nice')}
                </p>
                <p className="rounded-md border bg-card p-[10px] px-[20px]">
                  {spacetime(selectedRewardsValue[1]).format('nice')}
                </p>
              </div>
            )}
            {selectedRewards == 2 && (
              <PercentSlider disabled rangeValues={rangeValues} setRangeValues={setRangeValues} />
            )}

            <div>
              <h2 className="border-b pb-[5px] text-xl">Assets for Reward Application</h2>
              <p className="mt-[10px] w-[80%] text-sm opacity-60">
                These assets will receive the rewards listed above.
              </p>
            </div>

            <div className="flex w-full flex-wrap gap-[20px]">
              {accessAssets.map((nft, index) => (
                <NftCard asset={nft} smallNft />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RewardsView;

type ListType = {
  name: string;
  created_at: string;
  number_of_members: number;
  uploaded_custom_list_id?: number;
};
