import { SearchUsersDocument } from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { lens } from '@dhmk/zustand-lens';

const initialUsersSliceState: UsersSliceState = {
  loadedPage: null,
  searchResults: [],
  isLoading: true,
  isLoadingNext: false,
  isAllLoaded: false,
  searchUsersString: '',
};

export const usersSlice = lens<UsersSlice>(
  (set, get): UsersSlice => ({
    ...initialUsersSliceState,
    dispatchSetIsLoading: (isLoading: UsersSliceState['isLoading']) => {
      set(() => ({
        isLoading,
      }));
    },
    dispatchClearLoadedPage: () => {
      set(() => ({
        loadedPage: null,
        searchResults: [],
        isLoading: false,
        isLoadingNext: false,
        isAllLoaded: false,
      }));
    },
    dispatchSetSearchUsersString: (searchString) => {
      set(() => ({
        searchUsersString: searchString,
      }));
    },
    fetchSearchedUsers: async (
      searchUsersInput: {
        search_string: string;
        search_by: string;
        sort_by?: string | undefined;
        sort_direction?: string | undefined;
        owns_nft?: string | undefined;
      },
      page_number?: number,
    ) => {
      const loadedPage = get().loadedPage;
      const dispatchSetIsLoading = get().dispatchSetIsLoading;
      const dispatchClearLoadedPage = get().dispatchClearLoadedPage;

      const { search_string, search_by, sort_by, sort_direction, owns_nft } = searchUsersInput;

      try {
        const batchSize = 30;

        if (search_string.length > 0 && search_string.length < 3) {
          return;
        }

        if (page_number == 0) {
          dispatchClearLoadedPage();
          set((state) => ({
            isLoading: true,
          }));
        }

        console.log({ batchSize });

        const {
          data: { searchUsers: searchResults },
        } = await client.query({
          query: SearchUsersDocument,
          variables: {
            input: { search_string, search_by, sort_by, sort_direction, owns_nft },
            options: {
              batch_size: batchSize,
              page_number,
            },
          },
          context: {
            clientName: ClientName.Marketplace,
          },
        });

        console.log({
          searchResults,
          inputs: { search_string, search_by, sort_by, sort_direction },
        });

        const newIsAllLoaded = searchResults?.length < batchSize;

        set((state) => ({
          searchResults: state.searchResults.concat(searchResults),
          loadedPage,
          isLoading: false,
          isLoadingNext: false,
          isAllLoaded: newIsAllLoaded,
        }));
      } catch (getUsersErr) {
        console.log({ getUsersErr });
        dispatchSetIsLoading(false);
      }
    },
  }),
);

export interface User {
  user_id: string;
  wallet_address: string;
  username: string;
  name: string;
  email: string;
  age: number;
  customer_vault_id: number;
  phone_number: string;
  photo: string;
  banner: string;
  kyc_status: string;
  kyc_passed: boolean;
  kyc_reason: string;
  created_at: string;
  updated_at: string;
  referral_code: string;
  metamask_user: boolean;
}

interface UsersSliceState {
  loadedPage: null | number;
  searchResults: User[];
  isLoading: boolean;
  isAllLoaded: boolean;
  isLoadingNext: boolean;
  searchUsersString: string;
}

interface UsersSliceActions {
  dispatchSetIsLoading: (isLoading: UsersSliceState['isLoading']) => void;
  dispatchClearLoadedPage: () => void;
  dispatchSetSearchUsersString: (searchString) => void;
  fetchSearchedUsers: (
    searchUsersInput: {
      search_string: string;
      search_by: string;
      sort_by?: string | undefined;
      sort_direction?: string | undefined;
    },
    page_number?: number,
  ) => Promise<void>;
}

export type UsersSlice = UsersSliceState & UsersSliceActions;
