import React, { useEffect, useRef, useState } from 'react';

import { AddButton } from '@/components/ui/add-button';
import FileUploadButton from '@/components/ui/file-upload';
import { Progress } from '@/components/ui/progress';

// Reusable component for uploading document sections
const UploadSection = ({ title, documents, handleFileUpload, setDocuments, fileInputsRef }) => {
  const triggerFileInput = (index) => {
    if (fileInputsRef.current[index]) {
      fileInputsRef.current[index].click();
    }
  };

  return (
    <div className="relative mb-[32px] w-full rounded-[8px] bg-card p-[28px] shadow-[2px_2px_24px_0px_rgba(0,0,0,0.1)]">
      <p className="mb-[32px] text-xl font-semibold">{title}</p>
      <div className="relative grid w-full grid-cols-3 flex-wrap gap-[32px]">
        {documents.map(({ title, uploaded }, index) => (
          <div key={title} className="flex flex-col items-center">
            <p className="mb-[10px] w-full text-nowrap font-light opacity-80">{title}</p>
            <FileUploadButton
              className="min-w-full bg-[#f3f3f3]"
              uploaded={uploaded}
              onFileChange={(e) => handleFileUpload(index, e, documents, setDocuments)}
              onEditClick={() => {
                triggerFileInput(index);
              }}
            />
            <input
              type="file"
              accept="image/*"
              className="hidden"
              ref={(el) => (fileInputsRef.current[index] = el)}
              onChange={(e) => handleFileUpload(index, e, documents, setDocuments)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const BrokerDealerChecklist = () => {
  const [uploadOrgDocuments, setUploadOrgDocuments] = useState([
    { title: 'Certificate of incorporation', uploaded: '' },
    { title: 'EIN documentation', uploaded: '' },
    { title: 'Governing docs', uploaded: '' },
    { title: 'Ownership docs', uploaded: '' },
    { title: 'Amendments to corporate documents', uploaded: '' },
  ]);

  const [uploadOfferingDocuments, setUploadOfferingDocuments] = useState([
    { title: 'PPM (optional)', uploaded: '' },
    { title: 'Offering Memorandum (optional)', uploaded: '' },
    { title: 'White Paper (optional)', uploaded: '' },
    { title: 'Subscription Documents', uploaded: '' },
  ]);

  const fileInputsRef = useRef<(HTMLInputElement | null)[]>([]);

  const handleFileUpload = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
    documentsState,
    setDocumentsState,
  ) => {
    const file = event.target.files?.[0];
    const updatedDocuments = [...documentsState];
    updatedDocuments[index].uploaded = file ? file.name : '';
    setDocumentsState(updatedDocuments);
  };

  useEffect(() => {
    const scrollableElement = document.getElementById('authLayoutContainerId');
    if (scrollableElement) {
      scrollableElement.scrollTo(0, 0);
    }
  }, []);

  return (
    <div className="mx-[20px] flex flex-col items-center">
      <h1 className="mb-[28px] mt-[44px] text-center text-[2rem] font-medium sm:mb-[48px] sm:mt-[64px] sm:text-[2.75rem]">
        Broker Dealer Checklist
      </h1>
      <div className="sticky top-0 z-10 mb-[20px] w-full lg:mb-[0px] lg:ml-[-160px] lg:w-[800px] transferAgentWidth:ml-[-360px]">
        <div className="left-0 w-full pt-[20px] lg:absolute lg:w-[155px]">
          <p className="mb-[12px] text-lg font-semibold">Progress</p>
          <Progress value={85} />
          <p className="mt-[8px] text-right text-sm font-light">
            Percentage: <span className="font-semibold text-primary">85%</span>
          </p>
        </div>
      </div>
      <div className="w-full lg:ml-[200px] lg:w-[800px] transferAgentWidth:ml-[0px]">
        <UploadSection
          title="Upload Organization Docs"
          documents={uploadOrgDocuments}
          handleFileUpload={handleFileUpload}
          setDocuments={setUploadOrgDocuments}
          fileInputsRef={fileInputsRef}
        />
        <UploadSection
          title="Upload Offering Documentation"
          documents={uploadOfferingDocuments}
          handleFileUpload={handleFileUpload}
          setDocuments={setUploadOfferingDocuments}
          fileInputsRef={fileInputsRef}
        />
      </div>
      <button className="mb-[48px] w-[200px] rounded-[4px] bg-primary px-[32px] py-[16px] text-base uppercase text-white">
        Save
      </button>
    </div>
  );
};

export default BrokerDealerChecklist;
