import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useLocation, useOutletContext } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import spacetime from 'spacetime';

import { AddButton } from '@/components/ui/add-button';
import { BackNext } from '@/components/ui/back-next';
import { Button } from '@/components/ui/button';
import { DeleteButton } from '@/components/ui/delete-button';
import { Input } from '@/components/ui/input';
import { useToast } from '@/components/ui/use-toast';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';
import {
  CreateRealEstateDocument,
  EditRealEstateDocument,
  GetRealEstateDocument,
} from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';

const CreateRealEstate: FunctionComponent = () => {
  type OutletContextType = [number, React.Dispatch<React.SetStateAction<number>>, string[]];

  const { pathname } = useLocation();
  const { id } = useParams();

  const path = `/${pathname.split('/')[1]}`;
  const isEdit = path == '/edit-real-estate';

  const currentYear = 2030;
  const yearsArray = Array.from({ length: 60 }, (_, index) => {
    const year = currentYear - index;
    return { label: `${year}`, value: `${year}` };
  });

  const navigate = useNavigate();

  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(isEdit);
  const [currentLevel, setCurrentLevel, titles] = useOutletContext<OutletContextType>();
  const [defaultValues, setDefaultValues] = useState<any>({
    media_uploads: [],
    floor_plans: [],
    target_irr: [5],
    target_avg: [5],
    rental_yield: [0],
    projected_rental_yield: [0],
    projected_appreciation: [0],
    projected_annual_return: [0],
    cap_rate: [0],
  });
  const [level3Features, setLevel3Features] = useState([
    {
      type: 'input',
      label: 'Feature (Optional)',
      state: 'feature_0',
      placeholder:
        'Describe a high-quality feature, e.g., WaterWorks bathroom fixtures, custom countertops',
    },
  ]);

  const methods = useForm({
    defaultValues,
  });
  const { handleSubmit, watch } = methods;
  const { toast } = useToast();
  const formValues = methods.watch();

  const level1Inputs = [
    {
      sectionClassName: '',
      sectionInputsClassName: 'gap-[16px] ',
      inputs: [
        {
          type: 'input',
          label: 'Name',
          state: 'name',
          // requiredForNextStep: true,
          placeholder: 'Enter property name',
        },
        {
          type: 'textarea',
          label: 'Tagline',
          state: 'tagline',
          // requiredForNextStep: true,
          maxLength: 100,
          placeholder: 'Short and catchy property tagline',
          textarea: true,
        },
        {
          type: 'currencyInput',
          label: 'Price',
          state: 'price',
          // requiredForNextStep: true,
          placeholder: 'Listing price in USD',
        },
        // {
        //   type: 'currencyInput',
        //   label: 'Price Per Token',
        //   state: '',
        //   placeholder: 'USD listing price per token',
        // },
        {
          type: 'location',
          label: 'Address',
          state: 'address',
          // requiredForNextStep: true,

          placeholder: 'Full property address',
        },
        {
          type: 'dropdown',
          label: 'Year Built',
          state: 'date_built',
          // requiredForNextStep: true,
          placeholder: 'Year the property was constructed',
          options: yearsArray,
        },
      ],
    },
    {
      sectionClassName: 'mt-[30px] gap-[16px]',
      sectionInputsClassName: 'gap-[16px] mt-[20px]',
      title: 'Images or videos',
      subTitle:
        'Drag and drop or click to select your property images to display as the main visuals for your listing.',
      inputs: [
        {
          type: 'imageUpload',
          label: '',
          state: 'media_uploads',
          filesInModal: ['image'],
          // requiredForNextStep: true,
          placeholder: 'Upload property images or videos',
          uploadMultiple: true,
          noCrop: true,
        },
      ],
    },
    {
      sectionClassName: 'mt-[30px] gap-[16px]',
      sectionInputsClassName: 'gap-[16px] mt-[20px]',
      title: 'About property',
      inputs: [
        {
          type: 'checkboxColumn',
          label: 'Type',
          state: 'type',
          // requiredForNextStep: true,
          options: [
            { label: 'Residential', value: 'Residential' },
            { label: 'Commercial', value: 'Commercial' },
          ],
        },
        {
          type: 'dropdown',
          label: 'Type of Residential Property',
          state: 'subtype',
          // requiredForNextStep: true,
          placeholder: '',
          options: [
            { label: 'Single Family', value: 'Single Family' },
            { label: 'Condo', value: 'Condo' },
          ],
          showIf: (type) => type == 'Residential',
          showBasedOn: 'type',
        },
        {
          type: 'input',
          label: 'Property square footage',
          state: 'square_footage',
          // requiredForNextStep: true,
          placeholder: 'Total square footage of the property',
          showIf: (type) => type == 'Residential',
          showBasedOn: 'type',
        },
        {
          type: 'input',
          label: '# of bedrooms',
          state: 'num_bedrooms',
          // requiredForNextStep: true,
          placeholder: 'Enter number of bedrooms',
          halfWidth: true,
          showIf: (type) => type == 'Residential',
          showBasedOn: 'type',
        },
        {
          type: 'input',
          label: '# of bathrooms',
          state: 'num_bathrooms',
          // requiredForNextStep: true,
          placeholder: 'Enter number of bathrooms',
          halfWidth: true,
          showIf: (type) => type == 'Residential',
          showBasedOn: 'type',
        },
        {
          type: 'input',
          label: 'Floor Level',
          state: 'floor_number',
          placeholder: 'Enter number of levels',
          halfWidth: true,
          showIf: (type) => type == 'Residential',
          showBasedOn: 'type',
        },
        {
          type: 'checkboxColumn',
          label: 'Does it have a garage?',
          state: 'has_garage',
          // requiredForNextStep: true,
          placeholder: '',
          options: [
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ],
          showIf: (type) => type == 'Residential',
          showBasedOn: 'type',
        },
        {
          type: 'input',
          label: 'Garage Capacity (# of Cars)',
          state: 'num_garage_cars',
          // requiredForNextStep: true,
          placeholder: 'Number of garages if applicable',
          showIf: (has_garage) => has_garage == 'Yes',
          showBasedOn: 'has_garage',
        },
        {
          type: 'checkboxColumn',
          label: 'Are appliances included?',
          state: 'appliances',
          // requiredForNextStep: true,
          placeholder: '',
          options: [
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ],
          showIf: (type) => type == 'Residential',
          showBasedOn: 'type',
        },
        // For Commercial
        {
          type: 'dropdown',
          label: 'Type of Commercial Property',
          state: 'subtype',
          // requiredForNextStep: true,
          placeholder: 'Type of commercial property',
          options: [
            { label: 'Multi-Family', value: 'Multi-Family' },
            { label: 'Development', value: 'Development' },
            { label: 'New Construction', value: 'New Construction' },
            { label: 'Apartment', value: 'Apartment' },
            { label: 'Mixed-Use', value: 'Mixed-Use' },
            { label: 'Assisted', value: 'Assisted' },
            { label: 'Living Facility', value: 'Living Facility' },
            { label: 'Strip Center', value: 'Strip Center' },
            { label: 'Self-Storage', value: 'Self-Storage' },
          ],
          showIf: (type) => type == 'Commercial',
          showBasedOn: 'type',
        },
        {
          type: 'checkboxColumn',
          label: 'Select Property Class',
          state: 'class',
          // requiredForNextStep: true,
          placeholder: '',
          options: [
            { label: 'Class A', value: 'Class A' },
            { label: 'Class B', value: 'Class B' },
            { label: 'Class C', value: 'Class C' },
          ],
          showIf: (type) => type == 'Commercial',
          showBasedOn: 'type',
        },
        {
          type: 'input',
          label: '# of acres',
          state: 'acres',
          // requiredForNextStep: true,
          placeholder: 'Enter number of acres',
          showIf: (type) => type == 'Commercial',
          showBasedOn: 'type',
        },
        {
          type: 'input',
          label: 'Property square footage',
          state: 'square_footage',
          // requiredForNextStep: true,
          halfWidth: true,
          placeholder: 'Total square footage of the property',
          showIf: (type) => type == 'Commercial',
          showBasedOn: 'type',
        },
        {
          type: 'input',
          label: 'Average sqaure footage per unit',
          state: 'avg_square_footage',
          // requiredForNextStep: true,
          halfWidth: true,
          placeholder: 'Average square footage per unit',
          showIf: (type) => type == 'Commercial',
          showBasedOn: 'type',
        },
        {
          type: 'input',
          label: 'Average rent per unit',
          state: 'avg_rent',
          // requiredForNextStep: true,
          halfWidth: true,
          placeholder: 'Average rent per unit',
          showIf: (type) => type == 'Commercial',
          showBasedOn: 'type',
        },
        {
          type: 'input',
          label: '# of stories for building',
          state: 'num_building_floors',
          // requiredForNextStep: true,
          halfWidth: true,
          placeholder: 'Number of stories for building',
          showIf: (type) => type == 'Commercial',
          showBasedOn: 'type',
        },
      ],
    },
  ];

  const level2Inputs = useMemo(() => {
    return [
      {
        sectionClassName: '',
        sectionInputsClassName: 'gap-[16px] ',
        inputs: [
          {
            type: 'textarea',
            label: 'Description (Optional)',
            state: 'description',
            requiredForNextStep: true,
            placeholder: 'Detailed description of the property',
          },
          {
            type: 'percentSlider',
            label: 'Target IRR',
            state: 'target_irr',
            requiredForNextStep: true,
            placeholder: 'Desired Internal Rate of Return (IRR)',
            halfWidth: true,
          },
          {
            type: 'percentSlider',
            label: 'Target Avg Cash on Cash',
            state: 'target_avg',
            requiredForNextStep: true,
            placeholder: 'Minimum investment required',
            halfWidth: true,
          },
          {
            type: 'currencyInput',
            label: 'Min Investment ',
            state: 'min_investment',
            requiredForNextStep: true,
            placeholder: 'Minimum investment required',
            halfWidth: true,
          },
        ],
      },
      {
        sectionClassName: 'mt-[30px] gap-[16px]',
        sectionInputsClassName: 'gap-[16px] mt-[25px] items-start',
        title: 'Additional Media',
        inputs: [
          {
            type: 'imageUpload',
            label: 'Add floor plan',
            state: 'floor_plans',
            filesInModal: ['image'],
            requiredForNextStep: true,
            placeholder: 'Upload floor plan images',
            uploadMultiple: true,
            noCrop: true,
          },
        ],
      },
    ];
  }, []);

  const financialDetailsInputs = useMemo(() => {
    return [
      {
        title: 'Initial Investment and Setup Costs',
        sectionClassName: '',
        sectionInputsClassName: 'gap-[16px] my-[20px]',
        inputs: [
          {
            type: 'currencyInput',
            label: 'Total Investment Value',
            state: 'total_investment_value',
            placeholder: 'Enter the total amount invested in the property',
            halfWidth: true,
          },
          {
            type: 'currencyInput',
            label: 'Underlying asset price',
            state: 'underlying_asset_price',
            placeholder: 'Enter the purchase price of the asset',
            halfWidth: true,
          },
          {
            type: 'currencyInput',
            label: 'Total Closing costs',
            state: 'total_closing_costs',
            placeholder: 'Enter the total costs associated with closing',
            halfWidth: true,
          },
          {
            type: 'currencyInput',
            label: 'Upfront LLC fees',
            state: 'upfront_llc_fees',
            placeholder: 'Enter the initial fees for setting up an LLC',
            halfWidth: true,
          },
          {
            type: 'currencyInput',
            label: 'Operating reserve',
            state: 'operating_reserve',
            placeholder: 'Enter the amount kept in reserve for operating expenses',
            halfWidth: true,
          },
        ],
      },
      {
        title: 'Investment Yield Projections',
        sectionClassName: '',
        sectionInputsClassName: 'gap-[16px] my-[20px]',
        inputs: [
          {
            type: 'percentSlider',
            label: 'Projected Annual Return',
            state: 'projected_annual_return',
            placeholder: 'Enter the expected annual return from the investment',
            halfWidth: true,
          },
          {
            type: 'percentSlider',
            label: 'Projected Appreciation',
            state: 'projected_appreciation',
            placeholder: 'Enter the expected increase in property value per year',
            halfWidth: true,
          },
          {
            type: 'percentSlider',
            label: 'Projected Rental Yield',
            state: 'projected_rental_yield',
            placeholder:
              'Enter the expected annual rental income as a percentage of the asset price',
            halfWidth: true,
          },
          {
            type: 'percentSlider',
            label: 'Rental Yield',
            state: 'rental_yield',
            placeholder:
              'Enter the current annual rental income as a percentage of the asset price',
            halfWidth: true,
          },
        ],
      },
      {
        title: 'Operating Costs and Financial Performance',
        sectionClassName: '',
        sectionInputsClassName: 'gap-[16px] my-[20px]',
        inputs: [
          {
            type: 'currencyInput',
            label: 'Annual gross rents',
            state: 'annual_gross_rents',
            placeholder: 'Enter the total rental income received before expenses',
            halfWidth: true,
          },
          {
            type: 'currencyInput',
            label: 'Property taxes',
            state: 'property_taxes',
            placeholder: 'Enter the annual property tax amount',
            halfWidth: true,
          },
          {
            type: 'currencyInput',
            label: 'Homeowners insurance',
            state: 'homeowners_insurance',
            placeholder: 'Enter the annual cost of insurance for the property',
            halfWidth: true,
          },
          {
            type: 'currencyInput',
            label: 'Property management',
            state: 'property_management_cost',
            placeholder: 'Enter the annual cost for property management services',
            halfWidth: true,
          },
          {
            type: 'currencyInput',
            label: 'Utilities',
            state: 'utilities_cost',
            placeholder: 'Enter the annual utilities expenses',
            halfWidth: true,
          },
          {
            type: 'currencyInput',
            label: 'Annual LLC administration and filing fees',
            state: 'annual_llc_administration_and_filing_fees',
            placeholder: 'Enter the annual fees for LLC administration and filing',
            halfWidth: true,
          },
          {
            type: 'currencyInput',
            label: 'Annual cash flow',
            state: 'annual_cash_flow',
            placeholder: 'Enter the net cash flow after all expenses',
            halfWidth: true,
          },
          {
            type: 'percentSlider',
            label: 'Cap rate',
            state: 'cap_rate',
            placeholder: 'Enter the capitalization rate, expressed as a percentage',
            halfWidth: true,
          },
          {
            type: 'currencyInput',
            label: 'Monthly cash flow',
            state: 'monthly_cash_flow',
            placeholder: 'Enter the monthly cash flow after expenses',
            halfWidth: true,
          },
          {
            type: 'currencyInput',
            label: 'Projected Annual Cash Flow',
            state: 'projected_annual_cash_flow',
            placeholder: 'Enter the projected net annual cash flow from the property',
            halfWidth: true,
          },
          {
            type: 'currencyInput',
            label: 'Current loan',
            state: 'current_loan',
            placeholder: 'Enter the details of any existing loan on the property',
            halfWidth: true,
          },
        ],
      },
    ];
  }, []);

  const level3Inputs = useMemo(() => {
    return [
      {
        sectionClassName: '',
        sectionInputsClassName: 'gap-[16px] ',
        inputs: [
          {
            type: 'textarea',
            label: 'Description (Optional)',
            state: 'additional_description',
            placeholder: 'Outline Enhancements and Premium Features',
          },
        ],
      },
      {
        sectionClassName: 'mt-[30px] gap-[16px]',
        sectionInputsClassName: 'gap-[16px] mt-[20px]',
        title: 'Add features',
        inputs: level3Features, // Assuming placeholders for level3Features would be similar in context.
      },
    ];
  }, [level3Features]);

  const [level4Inputs, setLevel4Inputs] = useState([
    {
      sectionClassName: 'gap-[20px]',
      sectionInputsClassName: 'gap-[5px]',
      inputs: [
        {
          type: 'input',
          label: 'Question',
          state: 'faqQuestion_0',
          placeholder: 'Frequently asked question about the property',
        },
        {
          type: 'textarea',
          state: 'faqAnswer_0',
          placeholder: 'Answer to the provided question',
          textarea: true,
        },
      ],
    },
  ]);

  const handleCreateProperty = async (data) => {
    try {
      if (currentLevel < 4) {
        setCurrentLevel(currentLevel + 1);
      } else {
        console.log({ data });
        setCreateLoading(true);

        const tempData = {
          ...data,
          address: data.address.label,
          property_video: data?.property_video?.id,
          floor_plans: data?.floor_plans.map((item) => item.id || item.uuid),
          media_uploads: data?.media_uploads.map((item) => item.id || item.uuid),
          appliances: data?.appliances == 'Yes',

          num_garage_cars: data?.has_garage == 'Yes' ? parseInt(data.num_garage_cars) : 0,
          num_bathrooms: parseInt(data.num_bathrooms),
          num_bedrooms: parseInt(data.num_bedrooms),
          floor_number: parseInt(data.floor_number),
          price: parseInt(data.price),
          square_footage: parseInt(data.square_footage),
          target_avg: parseInt(data.target_avg[0]),
          target_irr: parseInt(data.target_irr[0]),
          min_investment: parseInt(data.min_investment),
          acres: parseInt(data.acres),
          avg_square_footage: parseInt(data.avg_square_footage),
          avg_rent: parseInt(data.avg_rent),
          num_building_floors: parseInt(data.num_building_floors),

          total_investment_value: parseInt(data.total_investment_value),
          underlying_asset_price: parseInt(data.underlying_asset_price),
          total_closing_costs: parseInt(data.total_closing_costs),
          upfront_llc_fees: parseInt(data.upfront_llc_fees),
          operating_reserve: parseInt(data.operating_reserve),
          projected_annual_return: parseInt(data.projected_annual_return),
          projected_rental_yield: parseInt(data.projected_rental_yield),
          projected_appreciation: parseInt(data.projected_appreciation),
          rental_yield: parseInt(data.rental_yield),
          annual_gross_rents: parseInt(data.annual_gross_rents),
          property_taxes: parseInt(data.property_taxes),
          homeowners_insurance: parseInt(data.homeowners_insurance),
          property_management_cost: parseInt(data.property_management_cost),
          utilities_cost: parseInt(data.utilities_cost),
          annual_llc_administration_and_filing_fees: parseInt(
            data.annual_llc_administration_and_filing_fees,
          ),
          annual_cash_flow: parseInt(data.annual_cash_flow),
          cap_rate: parseInt(data.cap_rate),
          monthly_cash_flow: parseInt(data.monthly_cash_flow),
          projected_annual_cash_flow: parseInt(data.projected_annual_cash_flow),
          current_loan: parseInt(data.current_loan),
        };

        delete tempData.has_garage;

        if (data.type == 'Residential') {
          delete tempData.acres;
          delete tempData.avg_square_footage;
          delete tempData.avg_rent;
          delete tempData.num_building_floors;
        } else {
          tempData.num_bedrooms = 0;
          tempData.num_bathrooms = 0;
          tempData.floor_number = 0;
          delete tempData.has_garage;
          tempData.num_garage_cars = 0;
          delete tempData.appliances;
        }

        const faq = [];
        const additional_features = [];

        for (let i = 0; i < 6; i++) {
          const questionKey = `faqQuestion_${i}`;
          const answerKey = `faqAnswer_${i}`;

          if (tempData.hasOwnProperty(questionKey) && tempData.hasOwnProperty(answerKey)) {
            faq.push({ q: tempData[questionKey], a: tempData[answerKey] });

            delete tempData[questionKey];
            delete tempData[answerKey];
          }
        }
        tempData.faq = faq.filter((item) => item.a !== '' || item.q !== '');

        for (let i = 0; i < 9; i++) {
          const featureKey = `feature_${i}`;

          if (tempData.hasOwnProperty(featureKey)) {
            additional_features.push(tempData[featureKey]);

            delete tempData[featureKey];
          }
        }
        tempData.additional_features = additional_features.filter((item) => item !== '');

        console.log({ tempData });
        if (isEdit) {
          const editRealEstateRes = await client.mutate({
            mutation: EditRealEstateDocument,
            variables: {
              input: { ...tempData, id: parseInt(`${id}`) },
            },
            context: {
              clientName: ClientName.Marketplace,
            },
          });
          console.log({ editRealEstateRes });
        } else {
          const createRealEstateRes = await client.mutate({
            mutation: CreateRealEstateDocument,
            variables: {
              input: tempData,
            },
            context: {
              clientName: ClientName.Marketplace,
            },
          });
          console.log({ createRealEstateRes });
        }

        setCreateLoading(false);
        navigate('/all-real-estate');
      }
    } catch (err) {
      setCreateLoading(false);
      toast({
        title: 'Error',
        variant: 'destructive',
        description: `${err}`,
      });
      console.log({ err });
    }
  };

  const handleGetRealEstate = async () => {
    try {
      const {
        data: { getRealEstate: getRealEstateRes },
      } = await client.query({
        query: GetRealEstateDocument,
        variables: { id: parseInt(id || '0') },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ getRealEstateRes });
      const {
        name,
        description,
        address,
        additional_description,
        tagline,
        type,
        price,
        square_footage,
        target_avg,
        target_coc,
        target_irr,
        target_usd,
        num_bedrooms,
        num_bathrooms,
        floor_number,
        num_units,
        date_built,
        media_uploads,
        num_garage_cars,
        appliances,
        floor_plans,
        min_investment,
        additional_features,
        faq,
        acres,
        class: theClass,
        subtype,
        avg_square_footage,
        avg_rent,
        num_building_floors,

        total_investment_value,
        underlying_asset_price,
        total_closing_costs,
        upfront_llc_fees,
        operating_reserve,
        projected_annual_return,
        projected_rental_yield,
        projected_appreciation,
        rental_yield,
        annual_gross_rents,
        property_taxes,
        homeowners_insurance,
        property_management_cost,
        utilities_cost,
        annual_llc_administration_and_filing_fees,
        annual_cash_flow,
        cap_rate,
        monthly_cash_flow,
        projected_annual_cash_flow,
        current_loan,
      } = getRealEstateRes;

      setPageLoading(false);

      const timestampNumber = parseInt(date_built, 10);

      const s = spacetime(timestampNumber);
      const year = s.year();
      console.log({ year });

      type inputsType = {
        type: string;
        label?: string;
        state: string;
        placeholder?: string;
        textarea?: boolean;
      };
      const features = {};
      const stateFeatures: inputsType[] = [];

      additional_features.forEach((item, index) => {
        features[`feature_${index}`] = item;
        stateFeatures.push({
          type: 'input',
          label: `Feature ${index == 0 ? '' : index}`,
          state: `feature_${index}`,
          placeholder:
            'Describe a high-quality feature, e.g., WaterWorks bathroom fixtures, custom countertops',
        });
      });

      const stateFAQ: {
        sectionClassName?: string;
        sectionInputsClassName?: string;
        label?: string;
        inputs: inputsType[];
      }[] = [];
      const formFaq = {};

      faq.forEach((item, index) => {
        stateFAQ.push({
          sectionClassName: 'gap-[10px] mt-[20px]',
          sectionInputsClassName: 'gap-[10px]',
          inputs: [
            {
              type: 'input',
              label: `Question`,
              state: `faqQuestion_${index}`,
              placeholder: 'Frequently asked question about the property',
            },
            {
              type: 'textarea',
              state: `faqAnswer_${index}`,
              placeholder: 'Answer to the provided question',
              textarea: true,
            },
          ],
        });
        formFaq[`faqQuestion_${index}`] = item.q;
        formFaq[`faqAnswer_${index}`] = item.a;
      });

      setLevel4Inputs(stateFAQ);

      setLevel3Features(stateFeatures);

      console.log({ features });

      methods.reset({
        ...defaultValues,
        name,
        description,
        address: { label: address },
        additional_description,
        tagline,
        type,
        price,
        square_footage,
        target_coc,
        target_usd,
        num_bedrooms,
        num_bathrooms,
        floor_number,
        num_units,

        total_investment_value,
        underlying_asset_price,
        total_closing_costs,
        upfront_llc_fees,
        operating_reserve,
        annual_gross_rents,
        property_taxes,
        homeowners_insurance,
        property_management_cost,
        utilities_cost,
        annual_llc_administration_and_filing_fees,
        annual_cash_flow,
        monthly_cash_flow,
        projected_annual_cash_flow,
        current_loan,

        acres,
        avg_square_footage,
        avg_rent,
        num_building_floors,

        target_irr: [parseInt(target_irr)],
        target_avg: [parseInt(target_avg)],
        projected_annual_return: [parseInt(projected_annual_return)],
        projected_appreciation: [parseInt(projected_appreciation)],
        projected_rental_yield: [parseInt(projected_rental_yield)],
        rental_yield: [parseInt(rental_yield)],
        cap_rate: [parseInt(cap_rate)],

        class: theClass,
        subtype,
        num_garage_cars: parseInt(num_garage_cars),
        min_investment,
        appliances: appliances ? 'Yes' : 'No',
        has_garage: num_garage_cars > 0 ? 'Yes' : 'No',
        date_built: `${year}`,
        media_uploads: media_uploads.map((item) => ({
          ...item,
          id: item.uuid,
          hostedUrl: item.s3_link,
        })),
        floor_plans: floor_plans.map((item) => ({
          ...item,
          id: item.uuid,
          hostedUrl: item.s3_link,
        })),
        ...features,
        ...formFaq,
      });
    } catch (err) {
      console.log({ err });
    }
  };

  useEffect(() => {
    if (isEdit) {
      handleGetRealEstate();
    }
  }, [defaultValues]);

  useEffect(() => {
    console.log({ formValues });
  }, [formValues]);

  return (
    <div>
      <form
        className="relative mx-auto flex min-h-[800px] w-full max-w-[800px] flex-col gap-[25px]"
        onSubmit={handleSubmit(handleCreateProperty)}
      >
        <h1 className="text-3xl font-semibold"> {titles[currentLevel]} </h1>

        <>
          {pageLoading ? (
            <div className="flex h-[40vh] items-center justify-center">
              <div className="m-auto size-10 animate-spin rounded-full border-t-2 border-primary" />
            </div>
          ) : (
            <>
              {currentLevel == 0 && (
                <div className="mb-[30px]">
                  {/* <WrappedInputSection methods={methods} data={level1Inputs} /> */}
                  <WrappedInputSection
                    methods={methods}
                    data={[{ inputs: level1Inputs[0].inputs.slice(0, 3) }]}
                  />
                  <div className="mt-[22px]" />
                  <Input className="" label="Price Per Token" value="$1.00" />
                  <div className="mt-[22px]" />
                  <WrappedInputSection
                    methods={methods}
                    data={[{ inputs: level1Inputs[0].inputs.slice(3, 100) }]}
                  />
                  <WrappedInputSection methods={methods} data={level1Inputs.slice(1, 3)} />
                </div>
              )}
              {currentLevel == 1 && <WrappedInputSection methods={methods} data={level2Inputs} />}
              {currentLevel == 2 && (
                <WrappedInputSection methods={methods} data={financialDetailsInputs} />
              )}
              {currentLevel == 3 && (
                <>
                  <WrappedInputSection
                    methods={methods}
                    data={[{ inputs: level3Inputs[0].inputs.slice(0, 1) }]}
                  />
                  <WrappedInputSection
                    methods={methods}
                    data={[{ inputs: level3Inputs[1].inputs }]}
                  />
                  <div className="ml-auto flex gap-[20px]">
                    <DeleteButton
                      onClick={(e) => {
                        e.stopPropagation();
                        const beforeBeforeDelete = [...level3Features];
                        beforeBeforeDelete.pop();
                        setLevel3Features(beforeBeforeDelete);
                      }}
                    />
                    <AddButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setLevel3Features([
                          ...level3Features,
                          {
                            type: 'input',
                            label: `Feature ${level3Features.length + 1}`,
                            state: `feature_${level3Features.length}`,
                            placeholder:
                              'Describe a high-quality feature, e.g., WaterWorks bathroom fixtures, custom countertops',
                          },
                        ]);
                      }}
                    />
                  </div>
                </>
              )}
              {currentLevel == 4 && (
                <>
                  <WrappedInputSection methods={methods} data={level4Inputs} />
                  <div className="ml-auto flex gap-[20px]">
                    <DeleteButton
                      onClick={(e) => {
                        e.stopPropagation();
                        const beforeBeforeDelete = [...level4Inputs];
                        beforeBeforeDelete.pop();
                        setLevel4Inputs(beforeBeforeDelete);
                      }}
                    />
                    <AddButton
                      onClick={(e) => {
                        setLevel4Inputs([
                          ...level4Inputs,
                          {
                            sectionClassName: 'gap-[10px] mt-[20px]',
                            sectionInputsClassName: 'gap-[10px]',
                            inputs: [
                              {
                                type: 'input',
                                label: 'Question',
                                state: `faqQuestion_${level4Inputs.length}`,
                                placeholder: 'Frequently asked question about the property',
                              },
                              {
                                type: 'textarea',
                                state: `faqAnswer_${level4Inputs.length}`,
                                placeholder: 'Answer to the provided question',
                                textarea: true,
                              },
                            ],
                          },
                        ]);
                      }}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </>
        <BackNext
          numOfLevels={5}
          finalBtnText={isEdit ? 'APPLY EDIT' : 'Create'}
          currentLevel={currentLevel}
          setCurrentLevel={setCurrentLevel}
          loading={createLoading}
        />
      </form>
    </div>
  );
};

export default CreateRealEstate;
