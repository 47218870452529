import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useOutletContext } from 'react-router-dom';
import spacetime from 'spacetime';

import Dollar from '@/assets/Dollar2.svg?react';
import NFTImg from '@/assets/NFTImg.svg?react';
import SelectAsset from '@/components/modals/select-asset';
import ListModal from '@/components/modals/select-list';
import { BackNext } from '@/components/ui/back-next';
import PercentSlider from '@/components/ui/percent-slider';
import { useToast } from '@/components/ui/use-toast';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';
import { CreateRewardsProgramDocument, Nft } from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { cn } from '@/lib/utils';
import { ListBulletIcon, LockOpen2Icon, TimerIcon } from '@radix-ui/react-icons';

import NftCard from '../ui/nft-card';
import Table from '../ui/table';

const CreateRewards: FunctionComponent = () => {
  const [defaultValues, setDefaultValues] = useState({
    reward_name: '',
    start_date: '',
    end_date: '',
  });
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [listModalOpen, setListModalOpen] = useState(false);
  const [assetModalOpen, setAssetModalOpen] = useState(false);
  const [assignUtilLoading, setAssignUtilLoading] = useState(false);

  const [selectedLists, setSelectedLists] = useState<ListType[]>([]);

  const [audienceAssets, setAudienceAssets] = useState<Nft[]>([]);
  const [accessAssets, setAccessAssets] = useState<Nft[]>([]);
  const [rewardsAssets, setRewardsAssets] = useState<Nft[]>([]);
  const [rangeValues, setRangeValues] = useState([20]);

  const [selectedRewards, setSelectedRewards] = useState<number | null>(null);
  const [audienceErr, setAudienceErr] = useState(false);
  const [rewardsAssetsErr, setRewardsAssetsErr] = useState(false);
  const [rewardsDiscountsErr, setRewardsDiscountsErr] = useState(false);

  const { toast } = useToast();
  const navigate = useNavigate();

  type OutletContextType = [number, React.Dispatch<React.SetStateAction<number>>, string[]];
  const [currentLevel, setCurrentLevel, titles] = useOutletContext<OutletContextType>();

  const methods = useForm({
    defaultValues,
  });
  const { handleSubmit, watch } = methods;
  const formValues = watch();

  const level1Inputs = [
    {
      sectionClassName: '',
      inputs: [
        {
          type: 'input',
          label: 'Reward Name',
          state: 'reward_name',
          placeholder: 'Enter reward name',
          requiredForNextStep: true,
        },
      ],
    },
  ];

  const level2Inputs = [
    {
      sectionClassName: '',
      inputs: [
        {
          type: 'calendar',
          label: 'Start date',
          state: 'start_date',
          halfWidth: true,
          requiredForNextStep: true,
        },
        {
          type: 'calendar',
          label: 'End date',
          state: 'end_date',
          halfWidth: true,
          requiredForNextStep: true,
        },
      ],
    },
  ];

  const audienceTypes = [
    {
      name: 'Select Assets',
      icon: <NFTImg stroke="black" className="mr-2 stroke-black" />,
      onClick: () => {
        setAssetModalOpen(true);
      },
    },
    {
      name: 'Select Lists',
      icon: <ListBulletIcon className="mr-2 size-[20px] text-black" />,
      onClick: () => {
        setListModalOpen(true);
      },
    },
  ];

  const accessToTypes = [
    {
      name: 'Select Asset',
      icon: <NFTImg stroke="black" className="mr-2 stroke-black" />,
      onClick: () => {
        setAssetModalOpen(true);
      },
    },
  ];

  const rewardTypes = [
    {
      name: 'Purchase Access',
      icon: <LockOpen2Icon className="mr-2 size-[18px] text-black" />,
      onClick: () => {
        setSelectedRewards(0);
        setRewardsDiscountsErr(false);
      },
    },
    {
      name: 'Early Access',
      icon: <TimerIcon className="mr-2 size-[18px] text-black" />,
      onClick: () => {
        setSelectedRewards(1);
        setRewardsDiscountsErr(false);
      },
    },
    {
      name: 'Discount',
      icon: <Dollar stroke="#000000" className="mr-2 stroke-black" />,
      onClick: () => {
        setSelectedRewards(2);
        setRewardsDiscountsErr(false);
      },
    },
  ];

  const listTable = useMemo(() => {
    return {
      columnNames: ['List Name', 'Date created', '# Members'],
      data: selectedLists?.map((item) => [
        {
          value: item.name,
          className: 'text-md capitalize',
        },
        {
          value: spacetime(parseInt(item.created_at)).format('numeric-us'),
          className: 'text-md',
        },
        {
          value: `${(item.number_of_members || 0).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`,
          className: 'text-md',
        },
      ]),
    };
  }, [selectedLists]);

  const handleCreateRewards = async (data) => {
    try {
      if (currentLevel < 2) {
        if (currentLevel == 0 && !audienceAssets.length && !selectedLists.length) {
          setAudienceErr(true);
          return;
        }
        if (currentLevel == 1) {
          if (accessAssets.length == 0) {
            setRewardsAssetsErr(true);
            return;
          } else if (selectedRewards == null) {
            setRewardsDiscountsErr(true);
            return;
          }
        }
        setAudienceErr(false);
        setCurrentLevel(currentLevel + 1);
      } else {
        setCreateLoading(true);

        const rewardsObj = () => {
          if (selectedRewards === 0) {
            return {
              type: 'MINT_PURCHASE',
              value: true,
            };
          } else if (selectedRewards === 1) {
            return {
              type: 'EARLY_ACCESS',
              value: [
                new Date(formValues.start_date).getTime(),
                new Date(formValues.end_date).getTime(),
              ],
            };
          } else if (selectedRewards === 2) {
            return {
              type: 'DISCOUNT',
              value: rangeValues[0],
            };
          }
        };

        const createRewardsRes = await client.mutate({
          mutation: CreateRewardsProgramDocument,
          variables: {
            input: {
              name: formValues.reward_name,
              condition: 'atLeast',
              min_amount_required: 1,
              list_constraints: [
                {
                  type: 'Batch',
                  condition: 'atLeast',
                  min_amount_required: 1,
                  values:
                    audienceAssets.length > 0 ? audienceAssets.map((item) => item.batch_id) : [],
                },
                {
                  type: 'CustomList',
                  condition: 'atLeast',
                  min_amount_required: 1,
                  values: selectedLists.map((item) => item.uploaded_custom_list_id?.toString()),
                },
              ],
              assigned_rewards: [
                {
                  nft_ids: accessAssets.map((item) => item.batch_id),
                  rewards: [rewardsObj()],
                },
              ],
            },
            context: {
              clientName: ClientName.Marketplace,
            },
          },
        });
        console.log({ createRewardsRes });
        setCreateLoading(false);
        navigate('/rewards');
      }
    } catch (err) {
      setCreateLoading(false);
      toast({
        title: 'Error',
        variant: 'destructive',
        description: `${err}`,
      });
      console.log({ err });
    }
  };

  const handleSelectedAssets = useMemo(() => {
    if (currentLevel == 0) {
      return audienceAssets;
    } else if (currentLevel == 1) {
      return accessAssets;
    } else if (currentLevel == 2) {
      return rewardsAssets;
    }
  }, [currentLevel, audienceAssets, accessAssets, rewardsAssets]);

  const selectBtns = (types, selected: null | number = null, error = false) => {
    return (
      <div className="flex gap-[10px]">
        {types.map((item, index) => (
          <div
            className={cn(
              'w-[200px] cursor-pointer rounded-[16px] border bg-card py-[14px]',
              selected == index ? 'border-primary' : '',
              error ? 'border-red-500' : '',
            )}
            rel="noreferrer"
            key={`${item.name}`}
            onClick={item.onClick}
          >
            <p className="flex items-center justify-center text-center font-semibold capitalize text-black">
              {item.icon}
              {item.name}
            </p>
          </div>
        ))}
      </div>
    );
  };

  const handleRenderRewards = () => {
    if (selectedRewards == 0) {
      return 'Enables your selected audience to purchase the assets specified above.';
    } else if (selectedRewards == 1) {
      return 'Grants your selected audience early access to the assets specified above, available from the start date through the end date.';
    } else if (selectedRewards == 2) {
      return 'Offers your selected audience discounts on the assets specified above. The indicated number reflects the percentage reduction.';
    }
  };

  return (
    <div>
      <form
        className="relative mx-auto flex min-h-[800px] w-full max-w-[800px] flex-col gap-[25px]"
        onSubmit={handleSubmit(handleCreateRewards)}
      >
        <h1 className="text-3xl font-semibold"> {titles[currentLevel]} </h1>
        {currentLevel == 0 && (
          <div className="flex flex-col gap-[25px]">
            <WrappedInputSection methods={methods} data={level1Inputs} />
            <div>
              <h2 className="border-b pb-[5px] text-xl">Choose Your Audience</h2>
              <p className="mt-[10px] w-[70%] text-sm opacity-60">
                Users featured on any of these lists, or those who possess any of these specified
                assets, will gain access to exclusive rewards.
              </p>
            </div>
            <div>
              <div className="flex gap-[10px]">
                {selectBtns([audienceTypes[0]], null, audienceErr)}
              </div>
              <div className="mt-[10px] flex w-full flex-wrap gap-[20px]">
                {audienceAssets.length == 0 ? (
                  <div className="flex h-[192px] w-full items-center justify-center overflow-y-scroll rounded-[10px] border bg-card pb-[20px] pr-[10px] pt-[10px]">
                    <p className="opacity-60">No Assets Selected</p>
                  </div>
                ) : (
                  audienceAssets.map((nft, index) => <NftCard asset={nft} smallNft />)
                )}
              </div>
            </div>
            <div>
              <div className="flex gap-[10px]">
                {selectBtns([audienceTypes[1]], null, audienceErr)}
              </div>
              <div className="mt-[10px] box-border max-h-[264px] overflow-y-scroll rounded-[10px] border bg-card pb-[20px] pr-[10px] pt-[10px]">
                <Table
                  ariaLabel="asset-purchase-history"
                  columnNames={listTable.columnNames}
                  data={listTable.data}
                  isLoading={false}
                  noDataText="No Lists Selected"
                />
              </div>
            </div>
          </div>
        )}
        {currentLevel == 1 && (
          <div className="flex flex-col gap-[25px]">
            <div>
              <h2 className="border-b pb-[5px] text-xl">Choose Your Assets</h2>
              <p className="mt-[10px] w-[80%] text-sm opacity-60">
                By selecting from the assets listed below, users will have the opportunity to link
                rewards to them, including the ability to make purchases, gain early access, or
                receive discounts.
              </p>
            </div>
            <div>
              <div className="flex gap-[10px]">
                {selectBtns(accessToTypes, null, rewardsAssetsErr)}
              </div>
              <div className="mt-[10px] flex w-full flex-wrap gap-[20px]">
                {accessAssets.length == 0 ? (
                  <div className="flex h-[192px] w-full items-center justify-center overflow-y-scroll rounded-[10px] border bg-card pb-[20px] pr-[10px] pt-[10px]">
                    <p className="opacity-60">No Assets Selected</p>
                  </div>
                ) : (
                  accessAssets.map((nft, index) => <NftCard asset={nft} smallNft />)
                )}
              </div>
            </div>
            <h2 className="border-b pb-[5px] text-xl">Choose Your Rewards</h2>
            <div>
              <div className="flex gap-[10px]">
                {selectBtns(rewardTypes, selectedRewards, rewardsDiscountsErr)}
              </div>
            </div>
            <p className="w-[80%] text-primary opacity-80">{handleRenderRewards()}</p>
            {selectedRewards == 1 && <WrappedInputSection methods={methods} data={level2Inputs} />}
            {selectedRewards == 2 && (
              <PercentSlider rangeValues={rangeValues} setRangeValues={setRangeValues} />
            )}
          </div>
        )}
        {currentLevel == 2 && (
          <>
            <div className="flex flex-col gap-[25px]">
              <div>
                <h2 className="border-b pb-[5px] text-xl">Your Audience</h2>
                <p className="mt-[10px] w-[80%] text-sm opacity-60">
                  This audience includes all users who will access the rewards, either by being on
                  the selected lists or owning the assets below.
                </p>
              </div>
              <div className="flex flex-col gap-[10px]">
                <div className="box-border max-h-[264px] overflow-y-scroll rounded-[10px] border bg-card pb-[20px] pr-[10px] pt-[10px]">
                  <Table
                    ariaLabel="asset-purchase-history"
                    columnNames={listTable.columnNames}
                    data={listTable.data}
                    isLoading={false}
                    noDataText="No Lists Selected"
                  />
                </div>
                <div className="mt-[10px] flex w-full flex-wrap gap-[20px]">
                  {audienceAssets.map((nft, index) => (
                    <NftCard asset={nft} smallNft />
                  ))}
                </div>
              </div>
              <div>
                <h2 className="border-b pb-[5px] text-xl">Rewards</h2>
                <p className="mt-[10px] w-[80%] text-sm opacity-60">
                  These are the rewards to be linked with the assets below. Ensure they match your
                  intent.
                </p>
              </div>
              <div>
                <div className="flex gap-[10px]">
                  {selectBtns([rewardTypes[selectedRewards ?? 0]], 0)}
                </div>
              </div>
              {selectedRewards == 1 && (
                <WrappedInputSection methods={methods} data={level2Inputs} />
              )}
              {selectedRewards == 2 && (
                <PercentSlider rangeValues={rangeValues} setRangeValues={setRangeValues} />
              )}

              <div>
                <h2 className="border-b pb-[5px] text-xl">Assets for Reward Application</h2>
                <p className="mt-[10px] w-[80%] text-sm opacity-60">
                  These assets will receive the rewards listed above. Verify they are the correct
                  selections.
                </p>
              </div>

              <div className="flex w-full flex-wrap gap-[20px]">
                {accessAssets.map((nft, index) => (
                  <NftCard asset={nft} smallNft />
                ))}
              </div>
            </div>
          </>
        )}
        <BackNext
          numOfLevels={3}
          finalBtnText={`CREATE REWARD`}
          currentLevel={currentLevel}
          setCurrentLevel={setCurrentLevel}
          loading={createLoading}
        />
        <ListModal
          open={listModalOpen}
          setOpen={setListModalOpen}
          setSelectedLists={(list) => {
            setSelectedLists(list);
            setAudienceErr(false);
          }}
          selectedLists={selectedLists}
          onButtonClick={() => {
            setListModalOpen(false);
          }}
        />
        <SelectAsset
          open={assetModalOpen}
          setOpen={setAssetModalOpen}
          title={currentLevel == 0 ? 'Choose Your Audience' : 'Choose Your Assets'}
          buttonLoading={assignUtilLoading}
          withButton
          selectMultiple
          onButtonClick={() => {
            setAssetModalOpen(false);
          }}
          selectedAssets={handleSelectedAssets}
          setSelectedAssets={(i) => {
            if (currentLevel == 0) {
              setAudienceAssets(i);
              setAudienceErr(false);
            } else if (currentLevel == 1) {
              setAccessAssets(i);
              setRewardsAssetsErr(false);
            }
          }}
        />
      </form>
    </div>
  );
};

export default CreateRewards;

type ListType = {
  name: string;
  created_at: string;
  number_of_members: number;
  uploaded_custom_list_id?: number;
};
