/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { BackNext } from '@/components/ui/back-next';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';
import { yesOrNoDropdownInputs } from '@/const';

const CapitalRaise = () => {
  const [defaultValues, setDefaultValues] = useState<any>({
    percentSlider: [0],
    discountPercent: [0],
  });

  const methods = useForm({ defaultValues });
  const formValues = methods.watch();

  const MOCKData = [
    {
      title: '',
      sectionClassName: '',
      sectionInputsClassName: '',
      inputs: [
        {
          type: 'checkboxColumn',
          label: 'What kind of securities are you offering',
          state: 'asdf1',
          options: [
            { label: 'SAFE', value: 'SAFE' },
            { label: 'Convertible Note ', value: 'Convertible Note ' },
          ],
        },
        {
          type: 'currencyInput',
          label: 'What is your value cap',
          state: 'asdf2',
          placeholder: '$10,000,000,000.',
        },
        {
          type: 'currencyInput',
          label: 'What is the target amount you are looking to raise',
          state: 'asdf3',
          placeholder: '$100,000,000',
        },
        {
          type: 'percentSlider',
          label: 'What is the discount',
          state: 'discountPercent',
          placeholder: '20%',
        },
        {
          type: 'checkboxRow',
          label: 'Will you accept oversubscriptions',
          state: 'asdf5',
          placeholder: 'Chainstarters',
          options: yesOrNoDropdownInputs,
        },
        {
          type: 'currencyInput',
          label: 'What is the maximum you are willing to accept',
          state: 'asdf6',
          placeholder: 'Enter maximum acceptible amount',
        },
        {
          type: 'calendar',
          label: 'What is your deadline to reach the maximum amount',
          state: 'asdf7',
          placeholder: '',
        },
        {
          type: 'percentSlider',
          label:
            'Percentage ownership of the company by holders of the SAFE (Simple Agreement for Future Equity) (assuming conversion of convertible securities)',
          state: 'percentSlider',
        },
        {
          type: 'checkboxColumn',
          label: 'Minimum investment amount',
          state: 'asdf1',
          options: [
            { label: '$250', value: '$250' },
            { label: '$500', value: '$500' },
            { label: '$1,000', value: '$1,000' },
          ],
        },
        {
          type: 'textarea',
          label: 'How can an investor cancel',
          state: 'asdf1',
          placeholder: 'How can an investor cancel',
        },
      ],
    },
  ];

  useEffect(() => {
    const scrollableElement = document.getElementById('authLayoutContainerId');
    if (scrollableElement) {
      scrollableElement.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    console.log({ formValues });
  }, [formValues]);

  return (
    <div className="relative mx-[20px] flex w-full max-w-[800px] flex-col items-center">
      <h1 className="mb-[28px] w-full text-left text-[2rem] font-medium sm:mb-[32px]  sm:text-[2rem]">
        Capital Raise Details
      </h1>
      <WrappedInputSection methods={methods} data={MOCKData} />
      <div className="mt-[30px] w-full">
        <BackNext className="mt-[20px]" numOfLevels={1} finalBtnText={'Save & continue'} />
      </div>
    </div>
  );
};

export default CapitalRaise;
