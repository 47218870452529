import { GetAllCollectionsDocument } from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { lens } from '@dhmk/zustand-lens';

const initialMyCollectionsSliceState: MyCollectionsSliceState = {
  loadedPage: null,
  collections: [],
  isLoading: false,
  isLoadingNext: false,
  isAllLoaded: false,
  initialLoad: true,
};

export const myCollectionsSlice = lens<MyCollectionsSlice>(
  (set, get): MyCollectionsSlice => ({
    ...initialMyCollectionsSliceState,
    reset: () => {
      set(initialMyCollectionsSliceState, false, {
        type: 'collectionsSlice/reset',
      });
    },
    dispatchSetIsLoading: (isLoading: MyCollectionsSliceState['isLoading']) => {
      set({
        isLoading,
      });
    },
    dispatchSetCollections: (collections) => {
      set({
        collections,
      });
    },
    dispatchSetIsLoadingNext: (isLoadingNext: MyCollectionsSliceState['isLoadingNext']) => {
      set({
        isLoadingNext,
      });
    },
    dispatchSetInitialLoad: (initialLoad: MyCollectionsSliceState['initialLoad']) => {
      set({
        initialLoad,
      });
    },
    dispatchAddToAllCollections: ({
      collections,
      loadedPage,
      isAllLoaded,
      addToEnd = false,
    }: Pick<
      MyCollectionsSliceState,
      'collections' | 'loadedPage' | 'isAllLoaded' | 'addToEnd'
    >) => {
      if (!collections || !Array.isArray(collections)) {
        throw Error('expecting collections to be an array');
      }
      set((state) => ({
        collections: addToEnd
          ? state.collections.concat(collections)
          : collections.concat(state.collections),
        loadedPage: loadedPage !== null ? loadedPage : get().loadedPage,
        isAllLoaded: isAllLoaded || get().isAllLoaded,
        isLoading: false,
        isLoadingNext: false,
      }));
    },
    dispatchUpdateCollection: (collection) => {
      const { number_of_nfts, contract_address, name } = collection;
      console.log({ collection });
      let newCollections: Array<Record<any, any>> = get().collections.map((c) => {
        if (c.contract_address === contract_address || c.name === name) {
          return {
            ...c,
            ...collection,
            number_of_fats: number_of_nfts,
          };
        }
        return c;
      });
      const indexOfCollection = newCollections.findIndex(
        (item) => item.contract_address === contract_address || item.name === name,
      );
      if (indexOfCollection == -1) {
        newCollections = [{ ...collection, number_of_fats: number_of_nfts }, ...newCollections];
      }
      console.log({ newCollections });
      set({
        collections: newCollections,
      });
    },
    fetchNextCollections: async () => {
      const isAllLoaded = get().isAllLoaded;
      const loadedPage = get().loadedPage;
      const dispatchSetIsLoading = get().dispatchSetIsLoading;
      const dispatchSetIsLoadingNext = get().dispatchSetIsLoadingNext;
      const dispatchAddToAllCollections = get().dispatchAddToAllCollections;
      const dispatchSetInitialLoad = get().dispatchSetInitialLoad;

      if (isAllLoaded) {
        console.log('🦊 | fetchNextCollections | isAllLoaded so do not proceed');
      }
      try {
        const newPage = loadedPage === null ? 0 : loadedPage + 1;
        const batchSize = 30;
        console.log('🦊 | fetchNextCollections | newPage', newPage, 'batchSize', batchSize);
        dispatchSetIsLoading(true);
        if (newPage > 0) {
          dispatchSetIsLoadingNext(true);
        }
        const response = await client.query({
          query: GetAllCollectionsDocument,
          variables: {
            input: {
              batch_size: batchSize,
              page_number: newPage,
              custom_events: false,
            },
          },
          context: {
            clientName: ClientName.Marketplace,
          },
        });
        console.debug('🦊 | fetchNextCollections: | response:', response);
        let getAllCollectionsRes;
        if (response?.data && response.data.getAllCollections) {
          getAllCollectionsRes = response.data.getAllCollections;
          console.log({ getAllCollectionsRes });
        }
        const newIsAllLoaded = getAllCollectionsRes?.length < batchSize;
        // prettier-ignore
        console.log('🦊 | fetchNextFats | isAllLoaded', newIsAllLoaded, 'colls.length', getAllCollectionsRes?.length, 'newPage', newPage)
        dispatchSetInitialLoad(false);
        dispatchAddToAllCollections({
          loadedPage: newPage,
          isAllLoaded: newIsAllLoaded,
          collections: getAllCollectionsRes, // TODO: recheck performance and consider moving to format function
          addToEnd: true,
        });
      } catch (getMyCollectionsErr) {
        console.log({ getMyCollectionsErr });
        dispatchSetIsLoading(false);
      }
    },
    deleteCollection: (contract_address) => {
      set({
        collections: get().collections.filter((item) => item.contract_address !== contract_address),
      });
    },
  }),
);

export type Collection = any; // TODO: revisit

export interface MyCollectionsSliceState {
  loadedPage: null | number;
  collections: Collection[]; // all
  isLoading: boolean;
  isAllLoaded: boolean;
  isLoadingNext: boolean;
  initialLoad: boolean;
  addToEnd?: boolean;
}

export interface MyCollectionsSliceActions {
  reset: () => void;
  dispatchSetIsLoading: (isLoading: MyCollectionsSliceState['isLoading']) => void;
  dispatchSetCollections: (collections) => void;
  dispatchSetIsLoadingNext: (isLoadingNext: MyCollectionsSliceState['isLoadingNext']) => void;
  dispatchSetInitialLoad: (initialLoad: MyCollectionsSliceState['initialLoad']) => void;
  dispatchAddToAllCollections: ({
    collections,
    loadedPage,
    isAllLoaded,
    addToEnd,
  }: Pick<
    MyCollectionsSliceState,
    'collections' | 'loadedPage' | 'isAllLoaded' | 'addToEnd'
  >) => void;
  dispatchUpdateCollection: (collection) => void;
  fetchNextCollections: () => Promise<void>;
  deleteCollection: (arg) => void;
}

export type MyCollectionsSlice = MyCollectionsSliceState & MyCollectionsSliceActions;
