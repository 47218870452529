import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router';
import { Outlet, useParams } from 'react-router-dom';
import spacetime from 'spacetime';

import AddBold from '@/assets/AddBold.svg?react';
import Blocks from '@/assets/Blocks.svg?react';
import CollectionsImg from '@/assets/CollectionsImg.svg?react';
import LeftArroww from '@/assets/LeftArrow.svg?react';
import NFTImg from '@/assets/NFTImg.svg?react';
import RightArroww from '@/assets/RightArrow.svg?react';
import Sort from '@/assets/sort-svgrepo.svg?react';
import GlobalUseModal from '@/components/modals/global-use-modal';
import { Button } from '@/components/ui/button';
import SortableItem from '@/components/ui/sortable-item';
import { useToast } from '@/components/ui/use-toast';
import {
  GetCountsDocument,
  ReorderCollectionsDocument,
  ReorderNftsDocument,
  Tables,
} from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import useBoundStore from '@/store';
import { Asset } from '@/store/assets-slice';
import { selectMarketplaceClientIsReady } from '@/store/project-slice';
import {
  closestCenter,
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import { arrayMove, rectSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import CollectionCard from '../ui/collection-card';
import NftCard from '../ui/nft-card';
import SectionDivider from '../ui/section-divider';

import 'react-horizontal-scrolling-menu/dist/styles.css';

const AssetHome: FunctionComponent<any> = () => {
  const [tableCount, setTableCount] = useState({
    collections: '',
    nfts: '',
  });

  const [modalOpen, setModalOpen] = useState(false);

  const [reOrderCollections, setReOrderCollections] = useState(false);
  const [reOrderAssets, setReOrderAssets] = useState(false);
  const [globalModalValues, setGlobalModalValues] = useState({
    title: '',
    subText: '',
    buttonText: '',
    onButtonClick: () => {},
  });

  const navigate = useNavigate();
  const { toast } = useToast();

  const collections = useBoundStore((state) => state.collectionsSlice.collections);
  const allCollectionsLoaded = useBoundStore((state) => state.collectionsSlice.isAllLoaded);
  const collectionsInitialLoad = useBoundStore((state) => state.collectionsSlice.initialLoad);

  const payoutPrograms = useBoundStore((state) => state.payoutProgramsSlice.payoutPrograms);
  const payoutsIsLoading = useBoundStore((state) => state.payoutProgramsSlice.isLoading);

  const fetchNextCollections = useBoundStore(
    (state) => state.collectionsSlice.fetchNextCollections,
  );
  const dispatchSetCollections = useBoundStore(
    (state) => state.collectionsSlice.dispatchSetCollections,
  );

  const assets = useBoundStore((state) => state.assetsSlice.assets);
  const assetsInitialLoad = useBoundStore((state) => state.assetsSlice.initialLoad);
  const isAllLoaded = useBoundStore((state) => state.assetsSlice.isAllLoaded);
  const fetchNextAssets = useBoundStore((state) => state.assetsSlice.fetchNextAssets);
  const dispatchUpdateAllAssets = useBoundStore(
    (state) => state.assetsSlice.dispatchUpdateAllAssets,
  );

  const restrictToHorizontal = ({ transform }) => {
    return {
      ...transform,
      y: 0, // Set the vertical transform to 0, allowing only horizontal movement
    };
  };

  const handleGetTableCountsRes = async () => {
    const {
      data: { getCounts: getCountsRes },
    } = await client.query({
      query: GetCountsDocument,
      context: {
        clientName: ClientName.Marketplace,
      },
    });
    setTableCount({
      collections: `${parseInt(getCountsRes.collections)}`,
      nfts: `${parseInt(getCountsRes.nfts)}`,
    });
  };

  const handleReorderAssets = async () => {
    try {
      const {
        data: { reorderNfts: reorderNftsRes },
      } = await client.mutate({
        mutation: ReorderNftsDocument,
        variables: {
          input: { ordering: items.map((item, index) => ({ id: item.batch_id, number: index })) },
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ reorderNftsRes });
      setReOrderAssets(!reOrderAssets);
      dispatchUpdateAllAssets(items);
    } catch (err: any) {
      console.log({ err });
      setReOrderAssets(!reOrderAssets);
      toast({
        title: 'Error sorting assets',
        variant: 'destructive',
        description: `${err.message}`,
      });
    }
  };

  const handleReorderCollections = async () => {
    try {
      const {
        data: { reorderNfts: reorderNftsRes },
      } = await client.mutate({
        mutation: ReorderCollectionsDocument,
        variables: {
          input: {
            ordering: items2.map((item, index) => ({
              contract_address: item.contract_address,
              number: index,
            })),
          },
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ reorderNftsRes });
      setReOrderCollections(!reOrderCollections);
      dispatchSetCollections(items2);
    } catch (err: any) {
      console.log({ err });
      setReOrderCollections(!reOrderCollections);
      toast({
        title: 'Error sorting collections',
        variant: 'destructive',
        description: `${err.message}`,
      });
    }
  };

  const collectionsWithLoad = useMemo(() => {
    if (collectionsInitialLoad) {
      return [
        { loading: true },
        { loading: true },
        { loading: true },
        { loading: true },
        { loading: true },
        { loading: true },
      ];
    } else {
      return collections;
    }
  }, [collections, collectionsInitialLoad]);

  const assetsWithLoad = useMemo(() => {
    if (assetsInitialLoad) {
      return [{ loading: true }, { loading: true }, { loading: true }];
    } else {
      return assets;
    }
  }, [assets, assetsInitialLoad]);

  const createBtns = [
    {
      label: `Create Collection`,
      navigate: () => {
        if (payoutPrograms.length == 0) {
          setModalOpen(true);
          setGlobalModalValues({
            title: 'Payout Program Needed',
            subText: 'Create a payout program to start making collections and receiving payments.',
            buttonText: 'Create a Payout Program',
            onButtonClick: () => {
              navigate(`/payout-program`);
            },
          });
          return;
        }
        navigate(`/create-collection`);
      },
    },
    {
      label: `Create Asset`,
      navigate: () => {
        if (collections.length == 0) {
          setModalOpen(true);
          setGlobalModalValues({
            title: 'Collection Needed',
            subText: 'Your must create a collection before creating an asset.',
            buttonText: 'Create a Collection',
            onButtonClick: () => {
              navigate(`/create-collection`);
            },
          });
          return;
        }
        navigate(`/create-asset`);
      },
    },
  ];

  const LeftArrow = () => {
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);

    if (isFirstItemVisible || collections.length < 4) {
      return <></>;
    }
    return (
      <div className="absolute z-10 ml-[-15px] flex h-full items-center">
        <div className="h-full w-[60px] bg-gradient-to-r from-background to-transparent" />
        <button
          className="ml-[-60px] mt-[-40px] flex size-[40px] cursor-pointer items-center justify-center rounded-full border bg-card shadow-md transition-colors duration-100 ease-in hover:bg-card-faded"
          disabled={isFirstItemVisible}
          onClick={() => scrollPrev()}
        >
          <LeftArroww />
        </button>
      </div>
    );
  };

  const RightArrow = () => {
    const { isLastItemVisible, scrollNext, scrollPrev, scrollToItem, getNextElement } =
      React.useContext(VisibilityContext);

    const handleFetchNext = async () => {
      await fetchNextCollections();
      scrollNext();
      // scrollToItem({});
    };

    useEffect(() => {
      if (isLastItemVisible && !allCollectionsLoaded && !collectionsInitialLoad) {
        console.log('asdf End of scroll, make API call');
        handleFetchNext();
      }
    }, [isLastItemVisible, allCollectionsLoaded, collectionsInitialLoad]);

    useEffect(() => {
      console.log({ isAllLoaded });
    }, [isAllLoaded]);

    if (isLastItemVisible || collections.length < 4) {
      return <></>;
    }

    return (
      <div className="absolute z-10 ml-[-20px] flex h-full items-center">
        <div className="ml-[-20px] h-full w-[60px] bg-gradient-to-l from-background to-transparent" />
        <button
          className="ml-[-40px] mt-[-40px] flex size-[40px] cursor-pointer items-center justify-center rounded-full border bg-card shadow-md transition-colors duration-100 ease-in hover:bg-card-faded"
          disabled={isLastItemVisible}
          onClick={() => {
            scrollNext();
          }}
        >
          <RightArroww />
        </button>
      </div>
    );
  };

  const marketplaceClientIsReady = useBoundStore(selectMarketplaceClientIsReady);
  useEffect(() => {
    if (marketplaceClientIsReady) handleGetTableCountsRes();
  }, [marketplaceClientIsReady]);

  const [items, setItems] = useState<Asset[]>([]);
  const [items2, setItems2] = useState<any[]>([]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
  );

  const handleDragNFT = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.batch_id === active.id);
      const newIndex = items.findIndex((item) => item.batch_id === over.id);

      setItems((items) => arrayMove(items, oldIndex, newIndex));
    }
  };

  const handleDragCollection = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = items2.findIndex((item) => item.contract_address === active.id);
      const newIndex = items2.findIndex((item) => item.contract_address === over.id);

      setItems2((items2) => arrayMove(items2, oldIndex, newIndex));
    }
  };

  useEffect(() => {
    setItems(assetsWithLoad);
  }, [assetsWithLoad]);

  useEffect(() => {
    setItems2(collectionsWithLoad);
  }, [collectionsWithLoad]);

  return (
    <div className="grid w-full max-w-[1310px] flex-col gap-[25px]">
      <div className="relative flex justify-between">
        <SectionDivider
          titles={[`Payout Programs`]}
          img={<CollectionsImg stroke="white" />}
          color="#FFBD39"
        />
        <Button
          className="absolute bottom-0 right-0 mb-[10px] w-[250px] rounded-[10px] border-[4px] border-card bg-card p-[10px] text-[15px] font-semibold capitalize text-primary shadow-md hover:bg-card/90"
          onClick={() => {
            navigate('/payout-program');
          }}
        >
          <AddBold className="mr-2 size-[20px] stroke-primary" />
          Create Payout Program
        </Button>
      </div>
      <>
        {!payoutsIsLoading && payoutPrograms.length == 0 ? (
          <div className="flex h-[200px] w-full items-center justify-center rounded-[16px] bg-card opacity-60 shadow-md">
            <p>No Payout Programs</p>
          </div>
        ) : (
          <div className="flex max-h-[230px] flex-col gap-[12px] overflow-y-scroll pr-[10px]">
            <div className="flex px-[40px] opacity-60">
              <p className="w-[30%] text-sm">Name</p>
              <p className="w-[20%] text-sm">Created At</p>
              <p className="w-[20%] text-sm">Payout Receivers</p>
              <p className="w-[120px] text-sm">Referral Tiers</p>
            </div>
            {payoutsIsLoading
              ? [1, 1, 1, 1].map((item, index) => (
                  <button
                    key={`payout_program_${index}`}
                    className="flex rounded-[10px] bg-card py-[15px] pl-[40px] shadow-md transition-shadow duration-500 ease-in-out hover:shadow-lg"
                  >
                    <div className=" w-[30%] text-left font-medium">
                      <div className="h-[24px] w-[90%] animate-pulse rounded-[6px]" />
                    </div>
                    <div className=" w-[20%] text-left">
                      <div className="h-[24px] w-[90%] animate-pulse rounded-[6px]" />
                    </div>
                    <div className=" w-[20%] text-left">
                      <div className="h-[24px] w-[90%] animate-pulse rounded-[6px]" />
                    </div>
                    <div className=" w-[120px] text-left">
                      <div className="h-[24px] w-[90%] animate-pulse rounded-[6px]" />
                    </div>
                  </button>
                ))
              : payoutPrograms.map((item, index) => (
                  <button
                    key={`payout_program_${index}`}
                    className="flex rounded-[10px] bg-card py-[15px] pl-[40px] shadow-md transition-shadow duration-500 ease-in-out hover:shadow-lg"
                    onClick={() => {
                      navigate(`/edit-payout-program/${item.payout_program_id}`);
                    }}
                  >
                    <p className="w-[30%] text-left font-medium">{item.name}</p>
                    <p className="w-[20%] text-left">
                      {spacetime(parseInt(item.created_at)).format('mm/dd/yyyy')}
                    </p>
                    <p className="w-[20%] text-left">{item?.royalties?.mint?.length}</p>
                    <p className="w-[120px] text-left">{item?.referrals?.mint?.length}</p>
                  </button>
                ))}
          </div>
        )}
      </>
      <div className="relative">
        <SectionDivider
          titles={[
            `Collections ${tableCount.collections.length > 0 ? `(${tableCount.collections})` : ''}`,
          ]}
          img={<CollectionsImg stroke="white" />}
          color="#ff6661"
        />
        <Button
          className="absolute bottom-0 left-0 mb-[10px] ml-[240px] w-[210px] rounded-[10px] border-[4px] border-card bg-card p-[10px] text-[15px] font-semibold capitalize text-primary shadow-md hover:bg-card/90"
          onClick={() => {
            if (reOrderCollections) {
              handleReorderCollections();
            } else {
              setReOrderCollections(!reOrderCollections);
            }
          }}
        >
          <Sort className="mr-[8px]" />
          {reOrderCollections ? 'Confirm Order' : 'Re-Order Collections'}
        </Button>
      </div>
      <div className="flex gap-[20px] pl-[5px]">
        <button
          className="box-border flex max-h-[288px] min-h-[288px] min-w-[244px] max-w-[244px] cursor-pointer items-center justify-center rounded-[20px] border-4 border-card bg-card shadow-md transition-colors duration-200 ease-in hover:bg-card-faded"
          onClick={createBtns[0].navigate}
        >
          <p className="ml-[-15px] flex font-semibold text-primary">
            <AddBold className="mr-3 stroke-primary" />
            {createBtns[0].label}
          </p>
        </button>
        {!reOrderCollections ? (
          <div className={`relative border-red-500`}>
            <ScrollMenu
              scrollContainerClassName="collectionScroll flex gap-[10px] pb-[7px] relative scrollbar scrollbar-thin scrollbar-thumb-[lightgrey] scrollbar-track-bg "
              LeftArrow={LeftArrow}
              RightArrow={RightArrow}
            >
              {collectionsWithLoad.map((collection, index) => (
                <CollectionCard
                  key={`collection_${index}`}
                  loading={collection.loading === true}
                  marginBottom={collection.loading === true ? 8 : 0}
                  collection={collection}
                  onClick={() => {
                    if (collection.contract_address) {
                      navigate(`/collection/${collection.contract_address}`);
                    }
                  }}
                />
              ))}
            </ScrollMenu>
          </div>
        ) : (
          <div className={`relative border-red-500`}>
            <DndContext
              modifiers={[restrictToFirstScrollableAncestor, restrictToHorizontal]}
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragCollection}
            >
              <SortableContext
                items={items2.map((item) => item.contract_address)}
                strategy={rectSortingStrategy}
              >
                <ScrollMenu
                  scrollContainerClassName="collectionScroll flex gap-[10px] pb-[7px] relative scrollbar scrollbar-thin scrollbar-thumb-[lightgrey] scrollbar-track-bg "
                  LeftArrow={LeftArrow}
                  RightArrow={RightArrow}
                >
                  {items2.map((collection, index) => (
                    <SortableItem
                      key={collection.contract_address}
                      id={collection.contract_address}
                      content={
                        <CollectionCard
                          className="animate-wiggle border-2 border-primary p-[2px]"
                          key={`collection_${index}`}
                          loading={collection.loading === true}
                          marginBottom={collection.loading === true ? 8 : 0}
                          collection={collection}
                          onClick={() => {
                            if (collection.contract_address) {
                              navigate(`/collection/${collection.contract_address}`);
                            }
                          }}
                        />
                      }
                    />
                  ))}
                </ScrollMenu>
              </SortableContext>
            </DndContext>
          </div>
        )}
      </div>

      <div className="relative">
        <SectionDivider
          titles={[`Assets ${tableCount.nfts.length > 0 ? `(${tableCount.nfts})` : ''}`]}
          img={<NFTImg className="stroke-white" stroke="white" />}
          color="#9256FF"
        />
        <Button
          className="absolute bottom-0 left-0 mb-[10px] ml-[200px] w-[180px] rounded-[10px] border-[4px] border-card bg-card p-[10px] text-[15px] font-semibold capitalize text-primary shadow-md hover:bg-card/90"
          onClick={() => {
            if (reOrderAssets) {
              handleReorderAssets();
            } else {
              setReOrderAssets(!reOrderAssets);
            }
          }}
        >
          <Sort className="mr-[8px]" />
          {reOrderAssets ? 'Confirm Order' : 'Re-Order Assets'}
        </Button>
      </div>

      <InfiniteScroll
        className="block overflow-visible"
        dataLength={assetsWithLoad.length}
        next={() => {
          console.log('NEXT FIRED');
          // setCurrentPageNum(currentPageNum + 1);
          fetchNextAssets();
        }}
        scrollableTarget="authLayoutContainerId"
        hasMore={!isAllLoaded}
        loader={
          <div className="flex h-12 items-center justify-center">
            <div className="m-auto size-6 animate-spin rounded-full border-t-2 border-primary" />
          </div>
        }
      >
        <div className="flex">
          <>
            {!reOrderAssets ? (
              <div className="flex w-full flex-wrap gap-[20px] p-[5px]">
                <button
                  className="box-border flex min-h-[340px] min-w-[244px] max-w-[244px] cursor-pointer items-center justify-center rounded-[20px] border-4 border-card bg-card shadow-md transition-colors duration-200 ease-in hover:bg-card-faded"
                  onClick={createBtns[1].navigate}
                >
                  <p className="ml-[-15px] flex font-semibold text-primary">
                    <AddBold className="mr-3 stroke-primary" />
                    {createBtns[1].label}
                  </p>
                </button>
                {assetsWithLoad.map((nft, index) => (
                  <NftCard
                    key={index}
                    loading={nft.loading === true}
                    asset={nft}
                    className=" "
                    onClick={() => {
                      if (nft?.batch_id) {
                        navigate(`/asset/${nft.batch_id}`);
                      }
                    }}
                  />
                ))}
              </div>
            ) : (
              <>
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragNFT}
                >
                  <SortableContext
                    items={items.map((item) => item.batch_id)}
                    strategy={rectSortingStrategy}
                  >
                    <div className="flex flex-row flex-wrap gap-[20px] p-[5px]">
                      <button
                        className="box-border flex min-h-[340px] min-w-[244px] max-w-[244px] cursor-pointer items-center justify-center rounded-[20px] border-4 border-card bg-card shadow-md transition-colors duration-200 ease-in hover:bg-card-faded"
                        onClick={createBtns[1].navigate}
                      >
                        <p className="ml-[-15px] flex font-semibold text-primary">
                          <AddBold className="mr-3 stroke-primary" />
                          {createBtns[1].label}
                        </p>
                      </button>
                      {items.map((nft, index) => (
                        <SortableItem
                          key={nft.batch_id}
                          id={nft.batch_id}
                          content={
                            // animate-wiggle
                            <NftCard
                              className="box-border animate-wiggle rounded-[20px] border-[2px] border-primary bg-transparent pt-[2px]"
                              key={index}
                              loading={nft.loading === true}
                              asset={nft}
                              onClick={() => {
                                if (nft?.batch_id) {
                                  navigate(`/asset/${nft.batch_id}`);
                                }
                              }}
                            />
                          }
                        />
                      ))}
                    </div>
                  </SortableContext>
                </DndContext>
              </>
            )}
          </>
        </div>
      </InfiniteScroll>
      <GlobalUseModal
        open={modalOpen}
        setOpen={setModalOpen}
        onButtonClick={globalModalValues.onButtonClick}
        title={globalModalValues.title}
        subText={globalModalValues.subText}
        buttonText={globalModalValues.buttonText}
      />
    </div>
  );
};

export default AssetHome;
