import React, { DependencyList, useEffect, useRef, useState } from 'react';
import ReactCrop, { Crop, makeAspectCrop, PixelCrop } from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';

const TO_RADIANS = Math.PI / 180;

async function canvasPreview(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0,
) {
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('No 2d context');
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();
  ctx.translate(-cropX, -cropY);
  ctx.translate(centerX, centerY);
  ctx.rotate(rotateRads);
  ctx.scale(scale, scale);
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  );
  ctx.restore();
}

// Custom hook to debounce effect
const useDebounceEffect = (fn: () => void, waitTime: number, deps?: DependencyList) => {
  useEffect(() => {
    const t = setTimeout(() => {
      fn();
    }, waitTime);
    return () => {
      clearTimeout(t);
    };
  }, deps);
};

interface ImageCropperProps {
  imgSrc: string;
  aspect: number;
  crop: Crop;
  setCrop: (crop: Crop) => void;
  previewCanvasRef: React.RefObject<HTMLCanvasElement>;
  imgRef: React.RefObject<HTMLImageElement>;
  completedCrop: PixelCrop | undefined;
  setCompletedCrop: (crop: PixelCrop) => void;
}

// ImageCropper Component
const ImageCropper: React.FC<ImageCropperProps> = ({
  imgSrc,
  aspect,
  crop,
  setCrop,
  previewCanvasRef,
  imgRef,
  completedCrop,
  setCompletedCrop,
}) => {
  const [initialLoad, setInitialLoad] = useState(true);

  const scale = 1;
  const rotate = 0;

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    console.log('THEASPECT', { aspect });
    setInitialLoad(true);
    if (aspect) {
      const { width, height } = e.currentTarget;
      const initialCrop = makeAspectCrop(
        {
          unit: 'px',
          x: 0,
          y: 0,
          width: width,
          height: width / aspect,
        },
        aspect,
        width,
        height,
      );
      setCrop(initialCrop);
      setCompletedCrop(initialCrop); // Set the completed crop initially
    }
    setInitialLoad(false);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);
      }
    },
    100,
    [completedCrop, scale, rotate],
  );

  useEffect(() => {
    console.log({ aspect });
  }, [aspect]);

  return (
    <>
      {initialLoad ? (
        <div className="flex min-h-[200px] w-full items-center justify-center">
          <div className="size-7 animate-spin rounded-full border-t-2 border-primary" />
        </div>
      ) : (
        <></>
      )}
      {!!imgSrc && (
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => {
            setCrop(percentCrop);
          }}
          onComplete={(c) => {
            setCompletedCrop(c);
          }}
          aspect={aspect}
          className="m-0 p-0"
        >
          <img ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} />
        </ReactCrop>
      )}
      {!!completedCrop && (
        <div>
          <canvas
            className="max-h-0 max-w-0"
            ref={previewCanvasRef}
            style={{
              border: '1px solid black',
              width: completedCrop.width,
              height: completedCrop.height,
            }}
          />
        </div>
      )}
    </>
  );
};

export default ImageCropper;
