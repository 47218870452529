import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

import DiscordLogo from '@/assets/DiscordLogo.svg?react';
import FacebookLogo from '@/assets/FacebookLogo.svg?react';
import InstagramLogo from '@/assets/InstagramLogo.svg?react';
import TelegramLogo from '@/assets/TelegramLogo.svg?react';
import TiktokLogo from '@/assets/TiktokLogo.svg?react';
import TwitterLogo from '@/assets/TwitterLogo.svg?react';
import YoutubeLogo from '@/assets/YoutubeLogo.svg?react';
import config from '@/config';

const SiteFooter: FunctionComponent<any> = ({
  background = '',
  logoUrl = '',
  theme1 = true,
  textColor = '',
  terms = '',
  block1 = ['', '', ''],
  block2 = ['', '', ''],
  socials = {
    instagram: '',
    youtube: '',
    discord: '',
    facebook: '',
    tiktok: '',
    twitter: '',
    telegram: '',
  },
}) => {
  const { instagram, youtube, discord, facebook, tiktok, twitter, telegram } = socials;

  const socialsList = useMemo(
    () =>
      [
        {
          link: instagram,
          logo: (
            <InstagramLogo
              stroke={`${textColor}`}
              onClick={() => {
                window.open(instagram);
              }}
            />
          ),
        },
        {
          link: youtube,
          logo: (
            <YoutubeLogo
              stroke={`${textColor}`}
              onClick={() => {
                window.open(youtube);
              }}
            />
          ),
        },
        {
          link: discord,
          logo: (
            <DiscordLogo
              stroke={`${textColor}`}
              onClick={() => {
                window.open(discord);
              }}
            />
          ),
        },
        {
          link: facebook,
          logo: (
            <FacebookLogo
              stroke={`${textColor}`}
              onClick={() => {
                window.open(facebook);
              }}
            />
          ),
        },
        {
          link: tiktok,
          logo: (
            <TiktokLogo
              stroke={`${textColor}`}
              onClick={() => {
                window.open(tiktok);
              }}
            />
          ),
        },
        {
          link: twitter,
          logo: (
            <TwitterLogo
              stroke={`${textColor}`}
              onClick={() => {
                window.open(twitter);
              }}
            />
          ),
        },
        {
          link: telegram,
          logo: (
            <TelegramLogo
              stroke={`${textColor}`}
              onClick={() => {
                window.open(telegram);
              }}
            />
          ),
        },
      ].filter((item) => item.link !== ''),
    [textColor],
  );

  const yourInfoBox = (lines = []) => {
    if (lines.filter((item) => item !== '').length == 0) {
      return (
        <div className="flex h-[106px] w-[140px] items-center justify-center rounded-[6px] border-2 border-dashed border-[#dcdfea]">
          <p className="text-[26px] font-medium text-[#fff]">Your Info</p>
        </div>
      );
    }
    return (
      <div className="">
        {lines.map((item) => (
          <p style={{ color: textColor }} className="my-[10px] text-[18px] font-medium">
            {item}
          </p>
        ))}
      </div>
    );
  };

  const logoUrlComponent = () => {
    if (logoUrl?.length == 0)
      return (
        <p className="text-[18px]" style={{ color: textColor }}>
          Your Logo
        </p>
      );
    return <img className="h-[65px]" src={logoUrl} />;
  };

  const socialsComponent = () => {
    if (socialsList.length !== 0) {
      return (
        <div className="flex">
          {socialsList.map((item) => (
            <div className="p-[5px]">{item.logo}</div>
          ))}
        </div>
      );
    }
  };

  return (
    <div className="overflow-hidden rounded-[5px] bg-card shadow-md">
      {theme1 ? (
        <div className="flex w-full items-center justify-between p-[30px]" style={{ background }}>
          {logoUrlComponent()}
          {yourInfoBox(block1)}
          {yourInfoBox(block2)}
          {
            <div className="flex flex-col items-center gap-[15px]">
              {socialsComponent()}
              {terms.length > 0 && (
                <a
                  className="text-[18px] font-semibold text-[#fff]"
                  style={{ color: textColor }}
                  target="_blank"
                  href={terms}
                  rel="noreferrer"
                >
                  Terms & Conditions
                </a>
              )}
            </div>
          }
        </div>
      ) : (
        <div className="flex w-full items-center justify-between p-[30px]" style={{ background }}>
          {logoUrlComponent()}
          {socialsComponent()}
          {yourInfoBox(block1)}
        </div>
      )}
    </div>
  );
};

export default SiteFooter;
