import { createJSONStorage, persist } from 'zustand/middleware';

import { getUserCompanies } from '@/lib/api/query';
import { lens } from '@dhmk/zustand-lens';

const initialCompanySliceState: CompanySliceState = {
  companies: [],
  companyLoading: true,
};

export const companySlice = lens<CompanySlice>(
  (set, get): CompanySlice => ({
    ...initialCompanySliceState,
    fetchCompanies: async () => {
      try {
        const getUserCompaniesRes = await getUserCompanies();
        console.log({ getUserCompaniesRes });
        set({ companies: getUserCompaniesRes, companyLoading: false });
      } catch (getUserCompaniesErr) {
        console.log({ getUserCompaniesErr });
        set({ companyLoading: false });
      }
    },
  }),
);

interface CompanySliceState {
  companies: any[];
  companyLoading: boolean;
}

interface CompanySliceActions {
  fetchCompanies: () => void;
}

export type CompanySlice = CompanySliceState & CompanySliceActions;
