import * as React from 'react';

import { cn } from '@/lib/utils';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  label?: string;
  register?: any;
  underText?: string;
  isError?: any;
  textarea?: boolean;
  containerClassName?: string;
  callOnBlur?: (input: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const Input = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>(
  (
    {
      className,
      containerClassName = '',
      type,
      label,
      register,
      underText,
      isError,
      textarea = false,
      callOnBlur = (input) => {
        console.log('INPUT BLUR', input);
      },
      ...props
    },
    ref,
  ) => {
    return (
      <div className={cn('relative w-full', containerClassName)}>
        {label && label?.length > 0 && <p className="mb-1 text-sm opacity-80">{label}</p>}
        {!textarea ? (
          <input
            type={type}
            className={cn(
              `flex w-full rounded-md border border-input bg-card px-[16px] py-[13px] text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 ${
                isError ? 'border-red-500' : ''
              }`,
              className,
            )}
            ref={ref as React.LegacyRef<HTMLInputElement>}
            {...props}
            onBlur={callOnBlur}
          />
        ) : (
          <textarea
            className={cn(
              `flex h-[80px] w-full rounded-md border border-input bg-card px-[16px] py-[13px] text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 ${
                isError ? 'border-red-500' : ''
              }`,
              className,
            )}
            ref={ref as React.LegacyRef<HTMLTextAreaElement>}
            {...props}
            onBlur={callOnBlur}
          />
        )}
        <p
          className={`absolute ml-[5px] mt-[1px] text-xs opacity-80 ${
            isError ? 'text-red-500' : ''
          }`}
        >
          {' '}
          {underText}{' '}
        </p>
      </div>
    );
  },
);
Input.displayName = 'Input';

export { Input };
