import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from '@/components/ui/button.tsx';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { Switch } from '@/components/ui/switch.tsx';

function TokengateModal({
  open = true,
  setOpen = (bool) => {},
  tokengated = false,
  setTokengated = (bool) => {},
  handleSubmit = () => {},
}) {
  return (
    <Dialog
      open={open}
      onOpenChange={(open_) => {
        setOpen(!open);
        handleSubmit();
      }}
    >
      <DialogContent
        noPadding
        className="z-[9999] w-[500px] max-w-[90%] rounded-[16px] p-[0px]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="flex flex-col gap-[20px] p-[30px]">
          <h1 className="text-center text-[26px] font-medium"> Set Video Access </h1>
          <p>
            Toggle to enable tokengating. When activated, the video will be accessible only on
            designated utilities, restricting public access
          </p>
          <div className="mb-[10px] flex justify-center">
            <p>Tokengated:</p>
            <div className="relative w-[60px]">
              <Switch
                className="absolute right-0"
                checked={tokengated}
                onCheckedChange={(bool) => {
                  setTokengated(bool);
                }}
              />
            </div>
          </div>
          <Button
            type="submit"
            onClick={() => {
              setOpen(false);
              handleSubmit();
            }}
          >
            Submit
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default TokengateModal;
