import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import DigitalMeeting from '@/assets/Digital-meeting-icon.svg?react';
import Ebook from '@/assets/Ebook-icon.svg?react';
import ExclusiveAudio from '@/assets/Exclusive-audio-icon.svg?react';
import ExclusiveVideo from '@/assets/Exclusive-video-icon.svg?react';
import IrlMeeting from '@/assets/Irl-meeting-icon.svg?react';
import LiveStream from '@/assets/Livestream-icon.svg?react';
import NftDiscount from '@/assets/Nft-discount-icon.svg?react';
import PhysicalTickets from '@/assets/Physical-tickets-icon.svg?react';
import Promotion from '@/assets/Promotion-icon.svg?react';
import UtilityIcon from '@/assets/UtilityIcon.svg?react';

import SectionDivider from '../ui/section-divider';

const SelectUtil: FunctionComponent<any> = () => {
  const navigate = useNavigate();

  const stepsDefinition = [
    {
      icon: <Promotion className="stroke-primary" />,
      title: 'Promotion',
      subtitle: 'We love a deal',
    },
    {
      icon: <ExclusiveVideo className="stroke-primary" />,
      title: 'Exclusive Video',
      subtitle: 'Watch here',
      route: 'create-exclusive-video',
    },
    {
      icon: <PhysicalTickets className="stroke-primary" />,
      title: 'Events',
      subtitle: 'Use your Asset to redeem tickets',
    },
    {
      icon: <ExclusiveAudio className="stroke-primary" />,
      title: 'Exclusive Audio',
      subtitle: 'Turn up the volume',
    },
    {
      icon: <DigitalMeeting className="stroke-primary" />,
      title: 'Digital Meeting',
      subtitle: 'Schedule a meeting',
    },
    {
      icon: <LiveStream className="stroke-primary" />,
      title: 'Live Stream',
      subtitle: 'Access to a live online event',
    },
    {
      icon: <Ebook className="stroke-primary" />,
      title: 'Ebook',
      subtitle: 'Access to content',
    },
    {
      icon: <NftDiscount className="stroke-primary" />,
      title: 'Announcement',
      subtitle: 'Create Announcement to Content',
    },
    {
      icon: <IrlMeeting className="stroke-primary" />,
      title: 'Irl Meeting',
      subtitle: 'Meet in real life',
    },
  ];

  return (
    <div className="grid w-full max-w-[1100px] flex-col gap-[25px]">
      <SectionDivider
        titles={['Select Utility']}
        img={<UtilityIcon className="stroke-card" />}
        color="#FFBD39"
      />
      <div className="grid grid-cols-3 gap-[25px]">
        {stepsDefinition.map((item, index) => (
          <button
            key={`${index}`}
            onClick={() => {
              if (index == 1) {
                navigate(`/${item.route}`);
              }
            }}
            className={`box-border flex h-[185px] flex-col items-center gap-[10px] rounded-[15px] bg-card px-[25px] pt-[20px] shadow-md ${
              index == 1
                ? 'border-[5px] border-card transition-colors duration-200 ease-in hover:bg-[#f3f3f3]'
                : 'cursor-not-allowed opacity-60'
            }`}
          >
            {item.icon}
            <h5 className="text-xl font-semibold">{item.title}</h5>
            <p>{item.subtitle}</p>
          </button>
        ))}
      </div>
    </div>
  );
};

export default SelectUtil;
