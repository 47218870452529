import * as jose from 'jose';

import config from '@/config';

import { auth } from '../firebase';
export const getLocalStorageToken = (): string | null => {
  return localStorage.getItem('adminToken') ?? null;
};

export const decodeToken = (
  token: string | null /* access_token, wallet_token */,
  validateSource = true,
  firebaseToken = false,
): null | jose.JWTPayload /* TODO: revisit type for decoded token */ => {
  if (!token) return null;
  const source = token.split(':')[0];
  // not check for appToken
  if (!source) return null;
  if (validateSource && firebaseToken && source !== 'fire') return null;
  if (validateSource && !firebaseToken && source !== 'admin') return null;
  try {
    const _token = token.split(':')[1];
    const decodedToken = jose.decodeJwt(_token);
    console.debug('🦊 | decodedToken:', decodedToken);
    if (!decodedToken) return null;
    // const exp = (decodedToken as { exp: number })?.exp;
    // if (!exp) return true;
    // const now = Math.floor(Date.now() / 1000);
    // const expired = exp < now;

    return decodedToken;
  } catch (err) {
    console.error('🦊 | checkTokenExpire decode error', err);
    return null;
  }
};

export const getNewRawFireToken = async () => {
  try {
    const idToken = await auth?.currentUser?.getIdToken(true);
    return idToken;
  } catch (err) {
    console.error('getNewRawFireToken', err);
  }
};

export const checkTokenExpire = (
  token: string | null /* access_token, wallet_token */,
  validateSource = true,
  firebaseToken = false,
): boolean /* true = expired */ => {
  if (!token) return true;
  const source = token.split(':')[0];
  // not check for appToken
  if (!source) return false;
  if (validateSource && firebaseToken && source !== 'fire') return false;
  if (validateSource && !firebaseToken && source !== 'admin') return false;
  try {
    const _token = token.split(':')[1];
    const decodedToken = jose.decodeJwt(_token);
    console.debug('🦊 | decodedToken:', decodedToken);
    if (!decodedToken) return true;
    const exp = (decodedToken as { exp: number })?.exp;
    if (!exp) return true;
    const now = Math.floor(Date.now() / 1000);
    const expired = exp < now;

    console.debug('🦊 | checkTokenExpire expired', expired);
    return expired;
  } catch (err) {
    console.error('🦊 | checkTokenExpire decode error', err);
    return true;
  }
};

export const checkLocalStorageTokenExpire = () => {
  const token = getLocalStorageToken();
  if (!token) return false;
  return checkTokenExpire(token, true);
};

export const waitForFirebaseCurrentUser = async (retryCount = 0) => {
  if (retryCount > 5) {
    console.debug(
      'singletonHandleCheckAndRenewToken | waitForFirebaseCurrentUser | Maximum retry limit exceeded',
    );
    return;
  }
  if (!auth?.currentUser) {
    console.debug(
      'singletonHandleCheckAndRenewToken | waitForFirebaseCurrentUser | Waiting. Current user:',
      auth?.currentUser,
    );
    // Wait for a short period of time
    await new Promise((resolve) => setTimeout(resolve, 1000));
    retryCount++;
    await waitForFirebaseCurrentUser(retryCount); // Recursive call with incremented retryCount
  } else {
    // Once currentUser is not null, continue with your logic
    console.debug(
      'singletonHandleCheckAndRenewToken | waitForFirebaseCurrentUser | Current user:',
      auth?.currentUser,
    );
  }
  return auth?.currentUser;
};

export const handleCheckAndRenewToken = async (
  skipTokenExpiredCheck = false,
): Promise<string | null /* null if has to re signin */> => {
  let token = getLocalStorageToken();
  const tokenExpired = checkLocalStorageTokenExpire();
  if (!skipTokenExpiredCheck && !tokenExpired) {
    return token;
  }
  // renew token if expired
  if (token) {
    // wait until auth.currentUser is available
    const firebaseAuth = await waitForFirebaseCurrentUser();
    if (!firebaseAuth) return null;
    token = (await getNewRawFireToken()) ?? null;
    return token;
  }
  return null;
};

// trigger a token reload
export const singletonHandleCheckAndRenewToken = async (
  skipTokenExpiredCheck = false,
): Promise<string | null> => {
  // if token is not expired, no need to try to renew it
  const tokenExpired = checkLocalStorageTokenExpire();
  console.debug('singletonHandleCheckAndRenewToken | call', {
    skipTokenExpiredCheck,
    tokenExpired,
  });
  if (!skipTokenExpiredCheck && !tokenExpired) {
    const token = getLocalStorageToken();
    return token;
  }
  // if its not running yet, AND the token is expiredß
  if (!window.handleCheckAndRenewToken) {
    window.handleCheckAndRenewToken = handleCheckAndRenewToken(skipTokenExpiredCheck);
    setTimeout(() => {
      window.handleCheckAndRenewToken = null;
      console.debug('singletonHandleCheckAndRenewToken | reset');
    }, 5000);
    console.debug('singletonHandleCheckAndRenewToken | started');
  }
  console.debug('singletonHandleCheckAndRenewToken | awaiting');
  const token = await window.handleCheckAndRenewToken;
  console.debug('singletonHandleCheckAndRenewToken | done');
  return token;
};

export function insertUrlParam(key, value) {
  if (window.history.pushState) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      searchParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
  }
}

// vvv this reload the page
export const redirectToProjectParam = (projPetName: string) => {
  // Step 1: Create a URL object from the current location
  const currentUrl = new URL(window.location as any);

  // Step 2: Use URLSearchParams to work with the query string
  const searchParams = new URLSearchParams(currentUrl.search);

  // Add your parameter or modify it if it already exists
  searchParams.set(config.projectQueryParamsKey, projPetName);

  // Update the search property of the URL object
  currentUrl.search = searchParams.toString();

  // Step 3: Navigate to the new URL
  window.location.href = currentUrl.href;
};
