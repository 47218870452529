import { useEffect, useMemo, useState } from 'react';
import {
  Cell,
  Column,
  Row,
  Table as AriaTable,
  TableBody,
  TableHeader,
} from 'react-aria-components';

// import { ReactComponent as BoxOpen } from 'images/box-open2.svg';
// import { cn } from 'lib/utils/tailwind';
import { cn } from '@/lib/utils';

// import TableRow from './components/TableRow';

interface DataValue {
  accordion?: boolean;
  row: Row[];
  accordionRows: Row[][];
}

interface Row {
  value: string | React.ReactNode;
  type?: string;
  link?: string;
  className?: string;
}

type DataRow = DataValue[];

interface TableProps {
  data?: DataRow[];
  columnNames?: string[];
  className?: string;
  cellClassName?: string;
  rowClassName?: string;
  accordionClassName?: string;
  isLoading?: boolean;
  onRowClick?: any;
  ariaLabel: string;
  noDataText?: string;
}

export const AltTable = ({
  data = [],
  columnNames = [],
  className = '',
  cellClassName = '',
  rowClassName = '',
  accordionClassName = '',
  isLoading = false,
  onRowClick = (item, index) => {},
  ariaLabel = '',
  noDataText = 'No Data',
}: TableProps) => {
  const [showNoData, setShowNoData] = useState(false);

  const renderClassname = (type) => {
    switch (type) {
      case 'link':
        return 'text-blue-500';
      case 'status':
        return 'bg-[#6b8cef33] text-center rounded-[5px] text-xs font-medium py-[4px]';
      default:
        return ``;
    }
  };

  const loadingColumnsArray = columnNames.map(() => ({
    value: <div className="h-[24px] w-full animate-pulse rounded-[6px] opacity-40" />,
  }));

  const renderData = useMemo(() => {
    if (isLoading) {
      return [1, 1, 1, 1, 1].map((item) => ({ row: loadingColumnsArray }));
    } else {
      return data;
    }
  }, [data, isLoading]);

  useEffect(() => {
    console.log({ isLoading, data });
    if (!isLoading && data.length == 0) {
      setShowNoData(true);
    } else {
      setShowNoData(false);
    }
  }, [isLoading, data]);

  return (
    <div key={`${showNoData}`} className={className}>
      {showNoData ? (
        <div className="flex h-[232px] w-full flex-col items-center justify-center rounded-[10px] bg-card pb-[20px] pr-[10px] pt-[10px]">
          {/* <BoxOpen className="text-label-color" /> */}
          <p className="mt-[20px] text-[1rem] opacity-60">{noDataText}</p>
        </div>
      ) : (
        <AriaTable className={cn('w-full min-w-[800px] sm:min-w-full')} aria-label={ariaLabel}>
          <TableHeader>
            {columnNames.map((item, index) => (
              <Column
                key={index}
                isRowHeader={index == 0}
                className="px-4 py-2 pl-[24px] text-left text-xs font-normal capitalize text-text-color opacity-60"
              >
                {item}
              </Column>
            ))}
          </TableHeader>
          <TableBody>
            {renderData?.map((item, index) => (
              <TableRow
                key={index}
                index={index}
                rowClassName={rowClassName}
                cellClassName={cellClassName}
                accordionClassName={accordionClassName}
                item={item}
              />
            ))}
          </TableBody>
        </AriaTable>
      )}
    </div>
  );
};

export default AltTable;

const TableRow = ({
  index = 0,
  rowClassName = '',
  cellClassName = '',
  accordionClassName = '',
  item,
  onRowClick = (item, index) => {},
}) => {
  const [toggleOpen, setToggleOpen] = useState(true);

  const renderClassname = (type) => {
    switch (type) {
      case 'link':
        return 'text-blue-500';
      case 'status':
        return 'bg-[#6b8cef33] text-center rounded-[5px] text-xs font-medium py-[4px]';
      default:
        return ``;
    }
  };

  return (
    <>
      <Row
        className={cn(
          'relative border-input transition-colors duration-100 ease-in',
          index % 2 !== 0 && 'bg-transparent-lm-dark',
          // isLightMode
          //   ? index % 2 !== 0 && 'bg-transparent-lm-dark'
          //   : index % 2 === 0 && 'bg-transparent-dm-dark',
          rowClassName,
          item?.rowLink || item?.accordion ? 'cursor-pointer' : '',
        )}
        key={`row_${index}`}
        onAction={() => {
          setToggleOpen(!toggleOpen);
        }}
      >
        {item?.row?.map((item2, index2) => (
          <Cell
            key={index2}
            className={cn('group select-text text-left text-sm font-light', item2?.className)}
          >
            <div
              className={cn(
                'relative h-full w-full select-text px-4 py-2 pl-[24px]',
                cellClassName,
              )}
              onClick={() => {
                onRowClick(item, index);
              }}
            >
              {item?.rowLink?.length > 0 && (
                <a
                  target="_blank"
                  href={item.rowLink}
                  className="absolute right-0 top-0 size-full border border-red-500 bg-transparent-lm-dark opacity-0 transition-all duration-300 group-hover:opacity-100"
                  rel="noreferrer"
                />
              )}

              {!!(item?.accordion && item?.row?.length - 1 == index2) && (
                <div
                  className={`absolute right-0 top-0 mr-[30px] flex h-full items-center justify-center`}
                >
                  {/* <FontAwesomeIcon
                    icon={!toggleOpen ? faChevronDown : faChevronUp}
                    className="size-[20px]"
                  /> */}
                </div>
              )}
              <p className={cn('select-text', renderClassname(item2?.type))}>{item2?.value}</p>
            </div>
          </Cell>
        ))}
      </Row>
      {!!(item?.accordion && toggleOpen) && (
        <>
          {item?.accordionRows?.map((accordRows, accordionRowsIndex) => (
            <Row
              className={cn(
                'border-label-color relative border-input transition-colors duration-100 ease-in',
                // isLightMode
                //   ? index % 2 !== 0 && 'bg-transparent-lm-dark'
                //   : index % 2 === 0 && 'bg-transparent-dm-dark',
                item?.accordionRows.length == accordionRowsIndex + 1 && 'border-b',
                accordionClassName?.length > 0 ? accordionClassName : rowClassName,
              )}
              key={`row_${index}`}
              onAction={() => {
                setToggleOpen(!toggleOpen);
              }}
            >
              {accordRows.map((item2, index2) => (
                <Cell key={index2} className={cn('text-left text-sm font-light', item2?.className)}>
                  {!!(accordionRowsIndex === 0 && index2 == 0) && (
                    <div className="absolute top-0 mt-[-9px] flex w-full items-center justify-center">
                      <div className="bg-label-color h-px w-full" />
                      <p className="text-label-color mx-[16px] text-nowrap text-[12px]">
                        Asset Breakdown
                      </p>
                      <div className="bg-label-color h-px w-full" />
                    </div>
                  )}
                  <div
                    className={cn('w-full px-4 py-2 pl-[24px]', cellClassName)}
                    onClick={() => {
                      onRowClick(item, index);
                    }}
                  >
                    <p className={renderClassname(item2?.type)}>{item2?.value}</p>
                  </div>
                </Cell>
              ))}
            </Row>
          ))}
        </>
      )}
    </>
  );
};
