import { GetAllNftsDocument } from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { lens } from '@dhmk/zustand-lens';

const initialMyAssetsSliceState: MyAssetsSliceState = {
  assets: [],
  loadedPage: null,
  isLoading: false,
  isLoadingNext: false,
  isAllLoaded: false,
  initialLoad: true,
};

export const myAssetsSlice = lens<MyAssetsSlice>(
  (set, get): MyAssetsSlice => ({
    ...initialMyAssetsSliceState,
    reset: () => {
      set(initialMyAssetsSliceState, false, {
        type: 'assetsSlice/reset',
      });
    },
    dispatchSetIsLoading: (isLoading: MyAssetsSliceState['isLoading']) => {
      set({
        isLoading,
      });
    },
    dispatchSetIsLoadingNext: (isLoadingNext: MyAssetsSliceState['isLoadingNext']) => {
      set({
        isLoadingNext,
      });
    },
    dispatchSetInitialLoad: (initialLoad: MyAssetsSliceState['initialLoad']) => {
      set({
        initialLoad,
      });
    },
    dispatchAddToAllAssets: ({
      assets,
      loadedPage,
      isAllLoaded,
      addToEnd = false,
    }: Pick<MyAssetsSliceState, 'assets' | 'loadedPage' | 'isAllLoaded' | 'addToEnd'>) => {
      if (!assets || !Array.isArray(assets)) {
        throw Error('expecting assets to be an array');
      }
      set((state) => ({
        assets: addToEnd ? state.assets.concat(assets) : assets.concat(state.assets),
        loadedPage: loadedPage !== null ? loadedPage : get().loadedPage,
        isAllLoaded: isAllLoaded || get().isAllLoaded,
        isLoading: false,
        isLoadingNext: false,
      }));
    },
    dispatchUpdateAsset: (asset) => {
      console.log({ asset });
      let newAssets: Array<Record<any, any>> = get().assets.map((item) => {
        if (item.name === asset.name || asset.nft_id == item.batch_id) {
          return {
            ...item,
            ...asset,
            batch_id: asset.nft_id,
          };
        }
        return item;
      });
      const indexOfAsset = newAssets.findIndex(
        (item) => item.name === asset.name || asset.nft_id == item.batch_id,
      );
      if (indexOfAsset == -1) {
        newAssets = [{ ...asset, batch_id: asset.nft_id }, ...newAssets];
      }
      console.log({ newAssets });
      set({
        assets: newAssets,
      });
    },
    dispatchUpdateAllAssets: (assets) => {
      set({
        assets,
      });
    },
    fetchNextAssets: async () => {
      const isAllLoaded = get().isAllLoaded;
      const loadedPage = get().loadedPage;
      const dispatchSetIsLoading = get().dispatchSetIsLoading;
      const dispatchSetIsLoadingNext = get().dispatchSetIsLoadingNext;
      const dispatchAddToAllAssets = get().dispatchAddToAllAssets;
      const dispatchSetInitialLoad = get().dispatchSetInitialLoad;

      if (isAllLoaded) {
        console.log('🦊 | fetchNextAssets | isAllLoaded so do not proceed');
      }
      try {
        const newPage = loadedPage === null ? 0 : loadedPage + 1;
        const batchSize = 15;
        console.log('🦊 | fetchNextAssets | newPage', newPage, 'batchSize', batchSize);
        dispatchSetIsLoading(true);
        if (newPage > 0) {
          dispatchSetIsLoadingNext(true);
        }
        const response = await client.query({
          query: GetAllNftsDocument,
          variables: {
            input: {
              batch_size: batchSize,
              page_number: newPage,
              event_passes: false,
            },
          },
          context: {
            clientName: ClientName.Marketplace,
          },
        });
        let getAllNftsRes;
        if (response.data && response.data.getAllNfts) {
          getAllNftsRes = response.data.getAllNfts;
          console.log({ getAllNftsRes });
        }
        const newIsAllLoaded = getAllNftsRes?.length < batchSize;
        // prettier-ignore
        console.log('🦊 | fetchNextFats | isAllLoaded', newIsAllLoaded, 'colls.length', getAllNftsRes?.length, 'newPage', newPage)
        dispatchSetInitialLoad(false);
        dispatchAddToAllAssets({
          loadedPage: newPage,
          isAllLoaded: newIsAllLoaded,
          assets: getAllNftsRes,
          addToEnd: true,
        });
      } catch (getMyAssetsErr) {
        console.log({ getMyAssetsErr });
        dispatchSetIsLoading(false);
      }
    },
    deleteAsset: (batch_id) => {
      set({
        assets: get().assets.filter((item) => item.batch_id !== batch_id),
      });
    },
  }),
);

export type Asset = any; // TODO: revisit

export interface MyAssetsSliceState {
  loadedPage: null | number;
  assets: Asset[]; // all
  isLoading: boolean;
  isAllLoaded: boolean;
  isLoadingNext: boolean;
  initialLoad: boolean;
  addToEnd?: boolean;
}

export interface MyAssetsSliceActions {
  dispatchSetIsLoading: (isLoading: MyAssetsSliceState['isLoading']) => void;
  dispatchSetIsLoadingNext: (isLoadingNext: MyAssetsSliceState['isLoadingNext']) => void;
  dispatchSetInitialLoad: (initialLoad: MyAssetsSliceState['initialLoad']) => void;
  dispatchAddToAllAssets: ({
    assets,
    loadedPage,
    isAllLoaded,
    addToEnd,
  }: Pick<MyAssetsSliceState, 'assets' | 'loadedPage' | 'addToEnd' | 'isAllLoaded'>) => void;
  dispatchUpdateAsset: (Asset) => void;
  dispatchUpdateAllAssets: (assets) => void;
  fetchNextAssets: () => Promise<void>;
  deleteAsset: (arg) => void;
  reset: () => void;
}

export type MyAssetsSlice = MyAssetsSliceState & MyAssetsSliceActions;
